import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        height: '100%',
        padding: theme.spacing(2),
    },
    postContainer: {
        height: '100%',
    },
    asideContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
        paddingTop: 0,
        paddingLeft: theme.spacing(4),
    },
    socialMediaGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    sectionContainer: {
        paddingTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

export default makeStyles(styles);
