import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperStyle: {
            padding: 0,
            minWidth: '450px',
            minHeight: '450px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '60px',
                minWidth: '90vw',
                minHeight: '80vh',
            },
        },
        appbar: {
            height: '60px',
            padding: 0,
        },
        appBarToolbar: {
            height: '100%',
            // TODO: not sure what this color is, doesn't appear in either theme
            // backgroundColor: theme.palette.branding.elevator_dark,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
        },
    }),
);

export default useStyles;
