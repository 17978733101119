import React, { FormEvent, MouseEvent } from 'react';
import useStyles from './styles';
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

const TitleSearchbar = (props: TitleSearchbarPropTypes) => {
    const classes = useStyles();
    const { handleSubmit, useIndicator, onClear, ...rest } = props;

    return (
        <form onSubmit={handleSubmit} className={classes.inputStyling}>
            <InputBase
                // type={'search'}
                {...rest}
                className={classes.inputStyling}
                inputProps={{
                    className: classes.inputStyling,
                }}
                fullWidth
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                }
                endAdornment={
                    onClear ? (
                        <InputAdornment position="end">
                            <IconButton className={classes.clearButton} onClick={onClear}>
                                <ClearIcon className={classes.clearIcon} />
                            </IconButton>
                        </InputAdornment>
                    ) : null
                }
            />
            <InputBase
                id={'searchSubmit'}
                type={'submit'}
                value={'submit'}
                className={classes.submitButton}
            />
        </form>
    );
};

TitleSearchbar.defaultProps = {
    useIndicator: false,
};
export interface TitleSearchbarPropTypes extends InputBaseProps {
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    useIndicator?: boolean;
    onClear?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default TitleSearchbar;
