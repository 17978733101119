import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useRouter } from 'next/router';
import Submission from './Submission';
import { MY_SUBMISSION_BY_ORDER_NUMBER } from 'lib/graphql/queries/submissions';

const SubmissionWithData: React.FC = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const router = useRouter();
    const { submission: orderNumber } = router.query;

    useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    const { data, loading, error, refetch } = useQuery(MY_SUBMISSION_BY_ORDER_NUMBER, {
        notifyOnNetworkStatusChange: true,
        variables: { orderNumber },
        fetchPolicy: 'network-only',
    });

    const handleRetryQuery = () => {
        refetch();
    };

    const submission =
        data && data.mySubmissionByOrderNumber ? data.mySubmissionByOrderNumber : null;
    const isLoading = loading && !submission;

    const renderLoadingState = () => {
        return (
            <Box
                width="100%"
                minHeight="70vh"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        );
    };

    const renderErrorState = () => {
        return (
            <Box
                minHeight="70vh"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant={'h5'} gutterBottom>
                    Oh snap!
                </Typography>
                <Box maxWidth={mobile ? '80%' : '50%'}>
                    <Typography
                        variant="body1"
                        align="center"
                        gutterBottom
                        color="textSecondary"
                    >
                        Something went wrong while retrieiving this submission.
                    </Typography>
                </Box>
                <Box paddingTop={2}>
                    <Button variant={'text'} color={'primary'} onClick={handleRetryQuery}>
                        Retry
                    </Button>
                </Box>
            </Box>
        );
    };

    const renderContent = () => {
        if (isLoading) {
            return renderLoadingState();
        } else if (error) {
            return renderErrorState();
        } else {
            return <Submission submission={submission} />;
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignContent="center"
            alignItems="center"
        >
            <Box maxWidth={theme.static_component_sizes.content_max_width} width={'100%'}>
                {renderContent()}
            </Box>
        </Box>
    );
};

export default SubmissionWithData;
