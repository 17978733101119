import React from 'react';
import useStyles from './styles';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CopyrightNotice } from 'atoms';
import { FOOTER_ROUTES, SOCIAL_MEDIA } from 'config/Nav';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import NavLink from '@elevatormedia/duffel-bag/dist/atoms/NavLink';
import { Divider } from '@material-ui/core';

const Footer = () => {
    const classes = useStyles();

    const theme: any = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderSocialMedia = () => {
        return (
            <GridContainer
                direction={'row'}
                alignItems={'flex-end'}
                justifyContent={mobile ? 'center' : 'flex-start'}
                spacing={4}
            >
                {Object.values(SOCIAL_MEDIA).map((routeConfig, index) => (
                    <GridItem key={index}>
                        <a href={routeConfig.route} target="_blank">
                            <routeConfig.icon className={classes.socialMediaIcon} />
                        </a>
                    </GridItem>
                ))}
            </GridContainer>
        );
    };

    const renderTopLinks = () => {
        return (
            <GridContainer
                className={classes.topLinks}
                direction={'column'}
                alignItems={'flex-end'}
                justifyContent={mobile ? 'center' : 'flex-end'}
                spacing={mobile ? 3 : 0}
            >
                {FOOTER_ROUTES.slice(0, FOOTER_ROUTES.length - 3).map(
                    ({ navLinkText, to, external }, index) => (
                        <GridItem
                            className={classes.footerLinkContainer}
                            key={index}
                            xs={mobile ? 12 : 4}
                        >
                            <NavLink variant={'footer'} href={to} external={external}>
                                {navLinkText}
                            </NavLink>
                        </GridItem>
                    ),
                )}
            </GridContainer>
        );
    };

    const renderBotLinks = () => {
        return (
            <GridContainer
                className={classes.bottomFooterLinks}
                direction={'row'}
                alignItems={'flex-end'}
                justifyContent={mobile ? 'center' : 'flex-end'}
                spacing={2}
            >
                {FOOTER_ROUTES.slice(-3).map(({ navLinkText, to, external }, index) => (
                    <GridItem className={classes.footerLinkContainer} key={index}>
                        <NavLink variant={'footer2'} href={to} external={external}>
                            {navLinkText}
                        </NavLink>
                    </GridItem>
                ))}
            </GridContainer>
        );
    };

    return (
        <footer className={classes.root}>
            <div className={classes.footerContentContainer}>
                <div className={classes.footerContentTop}>
                    <img
                        className={classes.logo}
                        src="https://elevator-media.imgix.net/elevator-header-logo.png?ixlib=js-2.3.1&s=38f13353a3d61a22b222363a9032f2ce"
                        alt="Elevator Logo"
                    />
                    {renderTopLinks()}
                </div>
                {renderSocialMedia()}

                <Divider className={classes.divider} />

                <GridContainer
                    className={classes.bottomFooterRow}
                    direction={mobile ? 'column' : 'row'}
                    justifyContent={'space-between'}
                    alignItems={mobile ? 'center' : 'flex-end'}
                >
                    <GridItem>{renderBotLinks()}</GridItem>
                    <GridItem className={classes.copyrightNoticeContainer}>
                        <CopyrightNotice />
                    </GridItem>
                </GridContainer>
            </div>
        </footer>
    );
};

export default Footer;
