import React from 'react';
import AdForm from './AdForm';
import { useQuery } from '@apollo/client';
import { CAMPAIGN_TYPES } from 'lib/graphql/queries/campaign';
import { StepTwoProps } from 'layouts/Advertising/StepTwo/StepTwo';
import Typography from '@material-ui/core/Typography';

const AdFormWithData = (props: StepTwoProps) => {
    const { data, loading, networkStatus, error } = useQuery(CAMPAIGN_TYPES, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    const campaignTypes = data && data.campaignTypes ? data.campaignTypes : [];
    const isLoading = loading || networkStatus === 4;

    if (isLoading) return null;
    else if (error) return <Typography>Error loading campaign types...</Typography>;
    else {
        return <AdForm {...props} campaignTypes={campaignTypes} />;
    }
};

export default AdFormWithData;
