import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paymentInfoHeader: {
            paddingBottom: theme.spacing(2),
        },
        // Radio Groups
        radioGroupContainer: {
            minHeight: '80px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },

        //credit card styles
        textField: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                margin: theme.spacing(2, 0),
            },
        },
        stateTextField: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                paddingRight: 0,
                margin: theme.spacing(2, 0),
            },
            zIndex: 1000,
        },
        links: {
            padding: theme.spacing(0, 1, 0, 1),
        },
        // Custom Stripe Elements
        cardInputContainer: {
            padding: theme.spacing(2, 1),
            backgroundColor: '#E6E6E6',
            flexDirection: 'row',
            alignItems: 'center',
            zIndex: 0,
            borderBottom: '1px solid #9E9E9E',
        },
        errorText: {
            color: 'red',
        },
        activeText: {
            color: 'black',
        },
        defaultText: {
            color: theme.palette.text.secondary,
        },
        menu: {
            padding: 0,
            maxHeight: '300px',
        },
        bitPayContainer: {
            [theme.breakpoints.down('lg')]: {
                marginTop: theme.spacing(1),
            },
        },
    }),
);

export default useStyles;
