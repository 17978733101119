import React from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import PostTypeLink from 'atoms/PostTypeLink';
import TeaserThumbnail from 'atoms/TeaserThumbnail';
import { PostCardInfo } from 'types/post';
import Typography from '@material-ui/core/Typography';
import { ROUTES } from 'config/Nav';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import Card from '@elevatormedia/duffel-bag/dist/atoms/Card';
import { formatDate } from '@elevatormedia/duffel-bag/dist/utils/string';

const PostCard = (props: PostCardProps) => {
    const classes = useStyles();

    const { title, image, slug, type, authors, categories, publishedAt, variant } = props;

    const getCategories = () => {
        return categories.map((category: any, index) => (
            <React.Fragment key={category.slug}>
                <Link
                    href={ROUTES.CATEGORY.fileApiPath}
                    as={ROUTES.CATEGORY.to + category.slug}
                    variant={'category-card'}
                    inline
                >
                    {category.name}
                </Link>
                {index !== categories.length - 1 && (
                    <Typography
                        variant={'body1'}
                        className={classes.listSep}
                        color={'textSecondary'}
                    >
                        {','}&nbsp;
                    </Typography>
                )}
            </React.Fragment>
        ));
    };

    const getAuthors = () => {
        return authors.map((author: any, index: number) => (
            <React.Fragment key={author.preferredUsername}>
                <Link
                    key={author.preferredUsername}
                    variant={'author-card'}
                    inline
                    href={ROUTES.AUTHORS.to + author.preferredUsername}
                    as={ROUTES.AUTHORS.to + author.preferredUsername}
                >
                    {author.displayName || author.preferredUsername}
                </Link>
                {index !== authors.length - 1 && (
                    <Typography
                        variant={'body2'}
                        className={classes.listSep}
                        color={'textSecondary'}
                        display={'inline'}
                    >
                        {','}&nbsp;
                    </Typography>
                )}
            </React.Fragment>
        ));
    };

    const className = [];
    const infoStyles = [classes.infoContainer];

    if (type === 'Promoted') className.push(classes.greyBackground);
    if (variant === 'main') {
        infoStyles.push(classes.mainPadding);
    } else {
        infoStyles.push(classes.sidebarPadding);
    }

    return (
        <GridItem key={slug} xs={12} sm={6} md={4} className={classes.container}>
            <Card className={clsx(...className)}>
                <div className={classes.teaserContainer}>
                    <Link
                        href={ROUTES.POST.fileApiPath}
                        as={`/${slug}`}
                        variant={'wrapper'}
                    >
                        <TeaserThumbnail
                            src={image.sourceUrl}
                            alt={`thumbnail for ${title}`}
                            //srcSet={image.sourceSet}
                        />
                    </Link>
                    <div className={classes.postTypeLinkContainer}>
                        <PostTypeLink type={type} />
                    </div>
                </div>
                <div className={clsx(infoStyles)}>
                    <div className={classes.postMetaContainer}>
                        <div className={classes.categoryRowContainer}>
                            {getCategories()}
                        </div>

                        <Typography
                            variant={'caption'}
                            color={'textSecondary'}
                            display={'inline'}
                        >
                            {formatDate(parseInt(publishedAt as string))} | by&nbsp;
                            {getAuthors()}
                        </Typography>
                    </div>
                    <Link
                        className={
                            variant == 'main' ? classes.mainLink : classes.sidebarLink
                        }
                        href={ROUTES.POST.fileApiPath}
                        as={`/${slug}`}
                        variant={'title-card'}
                    >
                        {title}
                    </Link>
                </div>
            </Card>
        </GridItem>
    );
};

export interface PostCardProps extends PostCardInfo {
    variant?: 'main' | 'sidebar';
}

PostCard.defaultProps = {
    variant: 'main',
};

export default PostCard;
