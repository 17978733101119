import React from 'react';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FieldsProps } from './CheckoutForm';
import { PasswordFeedback } from 'molecules';
import Check from 'mdi-material-ui/Check';
import useStyles from './styles';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import {
    notTooGuessable,
    isValidPassword,
} from '@elevatormedia/duffel-bag/dist/utils/auth';
import ToggleShowPasswordsButton from '@elevatormedia/duffel-bag/dist/atoms/ToggleShowPasswordsButton';

const AccountFields = (props: FieldsProps) => {
    const classes = useStyles();
    const { values, handleChange, touched, errors } = props;

    const [showPasswords, setShowPasswords] = React.useState(false);

    const renderPasswordFieldEndAdornment = () => {
        return (
            <InputAdornment position={'end'}>
                <div className={classes.passwordFieldAdornment}>
                    <Check fontSize={'small'} className={classes.checkmark} />
                </div>
            </InputAdornment>
        );
    };

    return (
        <>
            <GridItem xs={12} paddingY={1}>
                <Typography variant={'caption'} color={'textSecondary'}>
                    Account
                </Typography>
                <Typography variant={'subtitle2'}>
                    Finish creating your new account
                </Typography>
                <Typography>
                    Track your advertising campaigns by creating an Elevator account
                </Typography>
            </GridItem>
            <GridItem xs={12} paddingY={2}>
                <GridContainer direction={'row'} spacing={3}>
                    <GridItem xs={12} sm={6}>
                        <TextField
                            id={'firstName'}
                            name={'firstName'}
                            label={'First Name'}
                            autoComplete={'given-name'}
                            fullWidth
                            required
                            disabled
                            value={values.firstName}
                            onChange={handleChange}
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName ? errors.firstName : ''}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <TextField
                            id={'lastName'}
                            name={'lastName'}
                            label={'Last Name'}
                            autoComplete={'family-name'}
                            fullWidth
                            required
                            disabled
                            value={values.lastName}
                            onChange={handleChange}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName ? errors.lastName : ''}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={12} paddingY={2}>
                <TextField
                    id={'username'}
                    name={'username'}
                    label={'Username'}
                    fullWidth
                    required
                    value={values.username}
                    onChange={handleChange}
                    error={touched.username && Boolean(errors.username)}
                    helperText={touched.username ? errors.username : ''}
                />
            </GridItem>
            <GridItem xs={12} paddingY={2}>
                <TextField
                    id={'email'}
                    name={'email'}
                    label={'Email'}
                    autoComplete={'email'}
                    fullWidth
                    required
                    disabled
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ''}
                />
            </GridItem>
            <GridItem xs={12} paddingY={2}>
                <TextField
                    id={'password'}
                    name={'password'}
                    label={'Password'}
                    type={showPasswords ? 'text' : 'password'}
                    fullWidth
                    required
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={<PasswordFeedback password={values.password} />}
                    endAdornment={
                        isValidPassword(values.password) &&
                        notTooGuessable(values.password) &&
                        renderPasswordFieldEndAdornment()
                    }
                />
            </GridItem>
            <GridItem xs={12} paddingY={2}>
                <TextField
                    id={'confirmPassword'}
                    name={'confirmPassword'}
                    label={'Confirm Password'}
                    type={showPasswords ? 'text' : 'password'}
                    fullWidth
                    required
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                    endAdornment={
                        touched.confirmPassword &&
                        !errors.confirmPassword &&
                        renderPasswordFieldEndAdornment()
                    }
                />
                <ToggleShowPasswordsButton
                    showPasswords={showPasswords}
                    toggleShowPasswords={() => setShowPasswords((prev) => !prev)}
                />
            </GridItem>
        </>
    );
};

export default AccountFields;
