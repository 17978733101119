import React from 'react';
import withLayout from 'hocs/withLayout';
import { useQuery } from '@apollo/client';
import PostType, { PostsByTypeQueryVariables } from './PostType';
import { PostType as PostTypeEnum } from 'types/post';
import { POSTS_BY_TYPE } from 'lib/graphql/queries/post';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import { PageProps } from 'types/page';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';

const Index = ({ type, sectionTitle }: PostTypeIndexPropTypes) => {
    const variables: PostsByTypeQueryVariables = {
        type,
        limit: 9,
        skip: 0,
    };

    const { data, loading, error, networkStatus, fetchMore } = useQuery(POSTS_BY_TYPE, {
        fetchPolicy: 'cache-and-network',
        variables,
        notifyOnNetworkStatusChange: true,
    });

    const posts = data && data.publishedPosts ? data.publishedPosts : [];
    const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;
    const loadingMore = networkStatus === 3;

    const renderContent = () => {
        if (isLoading) return null;
        else
            return (
                <PostType
                    type={type}
                    posts={posts}
                    loading={isLoading}
                    loadingMore={loadingMore}
                    error={error}
                    fetchMore={fetchMore}
                    variables={variables}
                    sectionTitle={sectionTitle}
                />
            );
    };

    return (
        <>
            <Seo
                type={'website'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: type,
                    path: ROUTES.TYPE.to + type,
                }}
            />
            {renderContent()}
        </>
    );
};

export type PostTypeIndexPropTypes = PageProps & {
    type: PostTypeEnum;
    sectionTitle?: string;
};

export default withLayout(Index, false);
