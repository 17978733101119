import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { object as yupObject, string } from 'yup';
import VerifyForm from './VerifyForm';
import Fade from '@material-ui/core/Fade';
import { reportError } from '../../lib/errors';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { confirmEmailVerification } from '@elevatormedia/duffel-bag/dist/lib/auth';
import PageTitleDescription from '@elevatormedia/duffel-bag/dist/atoms/PageTitleDescription';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';

const StepOne: React.FC<VerifyEmailProps> = ({ onSuccess, currentUser }) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = yupObject({
        verificationCode: string().required(
            'A verification code is required to verify your email',
        ),
    });

    const initialValues: StepOneFormikValues = {
        verificationCode: '',
    };

    const handleSubmitForm = async (
        values: StepOneFormikValues,
        actions: FormikHelpers<StepOneFormikValues>,
    ) => {
        const { verificationCode } = values;
        try {
            await confirmEmailVerification(verificationCode, process.env.authEndpoint);
            actions.setSubmitting(false);
            onSuccess();
        } catch (err) {
            actions.setSubmitting(false);
            enqueueSnackbar(err, {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'error',
                preventDuplicate: true,
            });
            reportError(err, {
                metaData: {
                    operation: 'cognito confirmEmailVerification',
                },
                user: {
                    username: currentUser.username,
                },
            });
        }
    };

    return (
        <Fade in timeout={1000}>
            <>
                <PageTitleDescription pageTitle={'Verify Your Email'} divider={false}>
                    Please enter your email verification code that you should have
                    received at <b className={classes.userEmail}>{currentUser.email}</b>.
                </PageTitleDescription>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={handleSubmitForm}
                >
                    {(props) => <VerifyForm currentUser={currentUser} {...props} />}
                </Formik>
            </>
        </Fade>
    );
};

interface VerifyEmailProps {
    currentUser: User;
    onSuccess: (forward?: boolean) => void;
}

export interface StepOneFormikValues {
    verificationCode: string;
}

export default StepOne;
