import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import CheckDecagram from 'mdi-material-ui/CheckDecagram';
import Star from 'mdi-material-ui/Star';
import USER_BADGE_TYPES from 'config/UserBadgeTypes';
import clsx from 'clsx';
import useStyles from './styles';
import { BadgeType } from '@elevatormedia/duffel-bag/dist/types/user';
import Image from 'next/image';

const UserBadge = (props: UserBadgePropTypes) => {
    const { variant } = props;
    const classes = useStyles();

    const [arrowRef, setArrowRef] = React.useState(null);

    const renderBadgeVariant = () => {
        switch (variant) {
            case USER_BADGE_TYPES.STAFF:
                return (
                    <Image
                        src={'/static/img/elevator_logo_flat.svg'}
                        className={classes.badgeImage}
                        width={16}
                        height={16}
                        alt={'User Badge'}
                    />
                );
            case USER_BADGE_TYPES.PREFERRED:
                return <Star className={classes.badgeIcon} />;
            case USER_BADGE_TYPES.VERIFIED:
                return <CheckDecagram className={classes.verifiedBadge} />;

            default:
                break;
        }
    };

    return (
        <div className={classes.badgeContainer}>
            <Tooltip
                title={
                    <React.Fragment>
                        {`ELEVATOR ${variant.toUpperCase()} MEMBER`}
                        <span className={classes.arrow} ref={setArrowRef} />
                    </React.Fragment>
                }
                classes={{
                    tooltip: classes.tooltip,
                    popper: classes.popper,
                    tooltipPlacementBottom: classes.tooltipPlacementBottom,
                }}
                disableFocusListener
                disableTouchListener
                PopperProps={{
                    popperOptions: {
                        modifiers: {
                            arrow: {
                                enabled: Boolean(arrowRef),
                                element: arrowRef,
                            },
                        },
                    },
                }}
                placement={'bottom'}
            >
                <div
                    className={clsx(classes.badge, {
                        [classes.staffBackground]: variant === USER_BADGE_TYPES.STAFF,
                        [classes.preferredBackground]:
                            variant === USER_BADGE_TYPES.PREFERRED,
                        [classes.verifiedBackground]:
                            variant === USER_BADGE_TYPES.VERIFIED,
                    })}
                >
                    {renderBadgeVariant()}
                </div>
            </Tooltip>
        </div>
    );
};

export type UserBadgePropTypes = {
    variant: BadgeType;
};

export default UserBadge;
