import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    copyrightNoticeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    footerLogo: {
        height: '20px',
        width: 'auto',
    },
    copyrightNotice: {
        color: theme.palette.custom.grey.main,
        //TODO: OVERRIDE
        fontSize: theme.typography.pxToRem(13),
        paddingLeft: theme.spacing(1),
    },
});

export default makeStyles(styles);
