import React, { FormEvent } from 'react';
import { SearchResults } from 'types/search';
import useStyles from './styles';
import { SearchQueryVariables } from '.';
import { AdSpace } from 'atoms';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SearchResultPostList, SearchResultUserList } from 'organisms';
import SearchIcon from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { ROUTES } from 'config/Nav';
import { useRouter } from 'next/router';
import { reportError } from 'lib/errors';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';
import ScrollToTop from '@elevatormedia/duffel-bag/dist/atoms/ScrollToTop';
import { QueryResult } from '@elevatormedia/duffel-bag/dist/types/graphql';

const Search = (props: SearchPropTypes) => {
    const classes = useStyles();
    const { searchParam } = props;
    const { error, searchResults, fetchMore, variables } = props;

    const posts =
        searchResults && searchResults.publishedPosts ? searchResults.publishedPosts : [];
    const users =
        searchResults && searchResults.searchUsers ? searchResults.searchUsers : [];

    const [activeTab, setActiveTab] = React.useState(0);
    const [searchInputValue, setSearchInputValue] = React.useState(searchParam);
    const router = useRouter();

    const onChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    if (error) {
        return (
            <LoadingErrorDisclaimer
                error={error}
                failedTarget={'search results page'}
                reportError={reportError}
            />
        );
    }

    const onLoadMore = () => {
        fetchMore({
            variables: {
                searchInput: variables.search,
                limit: variables.limit,
                skip: posts.length,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) return prev;

                const newPosts = [
                    ...prev.publishedPosts,
                    ...fetchMoreResult.publishedPosts,
                ];
                const newData = Object.assign({}, prev, {
                    publishedPosts: newPosts,
                });

                return newData;
            },
        });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        router.push({
            pathname: ROUTES.SEARCH.to,
            query: {
                s: searchInputValue.trim(),
            },
        });
    };

    const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
        setSearchInputValue(value);

    const handleClear = () => {
        setSearchInputValue('');
    };

    const renderAdSpace = () => {
        return (
            <div className={classes.adSpaceContainer}>
                <AdSpace type={'banner-ad'} adUnit={'ELEVATOR-Bottom'} />
            </div>
        );
    };

    const renderSearchResultInput = () => {
        return (
            <form onSubmit={handleSubmit} className={classes.inputStyling}>
                <InputBase
                    type={'text'}
                    id={'search'}
                    value={searchInputValue}
                    onChange={handleChange}
                    className={classes.inputStyling}
                    inputProps={{
                        className: classes.inputStyling,
                        'aria-label': 'search',
                    }}
                    fullWidth
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                    }
                    endAdornment={
                        searchInputValue.length > 0 && (
                            <InputAdornment position="end" onClick={handleClear}>
                                <Clear fontSize="small" className={classes.clearIcon} />
                            </InputAdornment>
                        )
                    }
                />
                <InputBase
                    id={'searchSubmit'}
                    type={'submit'}
                    value={'submit'}
                    className={classes.submitButton}
                />
            </form>
        );
    };

    /**
     * NOTE: These could potentially be their own component, but since they are
     * single use-case at the time of this writing it will be localized to this layout
     */
    const renderTabFilters = () => {
        return (
            <div className={classes.tabGroupContainer}>
                <Tabs
                    value={activeTab}
                    onChange={onChangeTab}
                    aria-label="search-criteria-filter"
                    classes={{
                        indicator: classes.tabIndicator,
                    }}
                >
                    <Tab
                        label="All"
                        classes={{
                            root: classes.tabRoot,
                        }}
                    />
                    <Tab
                        label="Posts"
                        classes={{
                            root: classes.tabRoot,
                        }}
                    />
                    <Tab
                        label="Users"
                        classes={{
                            root: classes.tabRoot,
                        }}
                    />
                </Tabs>
            </div>
        );
    };

    const renderAllTabView = () => {
        return (
            <div className={classes.allSearchResultsContainer}>
                <div className={classes.postSearchResultsListContainer}>
                    <SearchResultPostList
                        title={'Posts'}
                        posts={posts}
                        loadLimit={variables.limit}
                        onLoadMore={onLoadMore}
                    />
                </div>

                <div className={classes.usersSearchResultsListContainer}>
                    <SearchResultUserList title={'Users'} users={users} />
                </div>
            </div>
        );
    };

    const renderPostTabView = () => {
        return (
            <div className={classes.singleTabContainer}>
                <SearchResultPostList
                    posts={posts}
                    loadLimit={variables.limit}
                    onLoadMore={onLoadMore}
                />
            </div>
        );
    };

    const renderUserTabView = () => {
        return (
            <div
                className={clsx(
                    classes.singleTabContainer,
                    classes.usersSearchResultsListContainer,
                )}
            >
                <SearchResultUserList users={users} />
            </div>
        );
    };

    const renderContent = () => {
        if (posts.length && users.length) {
            switch (activeTab) {
                case 0:
                    return renderAllTabView();
                case 1:
                    return renderPostTabView();
                case 2:
                    return renderUserTabView();
                default:
                    return null;
            }
        } else if (posts.length && !users.length) {
            return renderPostTabView();
        } else if (!posts.length && users.length) {
            return renderUserTabView();
        } else {
            return (
                <div className={classes.emptyResultsContainer}>
                    <Typography variant={'h4'} color={'textSecondary'} align={'center'}>
                        No results found for &quot;{searchParam}&quot;
                    </Typography>
                </div>
            );
        }
    };

    return (
        <>
            <div className={classes.root}>
                {renderSearchResultInput()}
                {posts.length > 0 && users.length > 0 && renderTabFilters()}
                <Divider variant={'fullWidth'} className={classes.divider} />
                {renderContent()}
                {renderAdSpace()}
            </div>
            <ScrollToTop visibleYOffset={90} />
        </>
    );
};

export interface SearchPropTypes
    extends QueryResult<'searchResults', SearchResults, SearchQueryVariables> {
    searchParam: string;
}

export default Search;
