import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { string as yupString, object as yupObject } from 'yup';
import UserInfoForm from './UserInfoForm';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import { useApolloClient } from '@apollo/client';
import { EMAIL_EXISTS } from 'lib/graphql/queries/user';
import { useSnackbar } from 'notistack';
import { reportError } from 'lib/errors';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';
import Image from 'next/image';

const StepOne = (props: StepOneProps) => {
    const { handleSubmit, currentUser } = props;

    const classes = useStyles();
    const apolloClient = useApolloClient();
    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = yupObject({
        firstName: yupString().required('First Name is required'),
        lastName: yupString().required('Last Name is required'),
        email: yupString().email('Please enter a valid email').required(),
    });

    const initialValues: UserInfoFormValues = {
        firstName: '',
        lastName: '',
        email: '',
    };

    const onSubmit = async (
        values: UserInfoFormValues = null,
        actions?: FormikHelpers<UserInfoFormValues>,
    ) => {
        if (!values) handleSubmit();
        else {
            const { data, errors } = await apolloClient.query<
                { emailUsed: boolean },
                { email: string }
            >({
                query: EMAIL_EXISTS,
                variables: {
                    email: values.email.trim(),
                },
            });

            if (data) {
                if (!data.emailUsed) handleSubmit(values, actions);
                else {
                    actions.setFieldError('email', 'This email is already in use');
                    actions.setSubmitting(false);
                }
            } else if (errors) {
                actions.setSubmitting(false);
                enqueueSnackbar('Error checking email availability', {
                    variant: 'error',
                    preventDuplicate: true,
                });
                reportError(errors[0], {
                    metaData: {
                        operation: 'query userExists email',
                        input: values.email,
                    },
                    user: {
                        preferredUsername: currentUser
                            ? currentUser.preferredUsername
                            : 'NOT_AUTHENTICATED',
                    },
                });
            }
        }
    };

    return (
        <div className={classes.stepContainer}>
            <Typography>
                Standard ad campaigns fill all available ad inventory site-wide. Ad sizes
                970×250, 300×250, 300×600, 320×50. All submitted campaign ad creative is
                subject to approval.
            </Typography>
            <Image
                className={classes.sampleImage}
                src={
                    'https://elevator-media.imgix.net/2019/11/elevator-ad-designs-two9.png?fm=png&ixlib=php-1.2.1&s=7e68bdd4600ffff4362cdf05ab224c37'
                }
                width={828}
                height={633}
                alt="Ad Design Layout Example"
            />
            {currentUser ? (
                <Button variant={'primary'} onClick={() => onSubmit()}>
                    Next
                </Button>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnMount={false}
                >
                    {(props) => <UserInfoForm {...props} />}
                </Formik>
            )}
        </div>
    );
};

export type UserInfoFormValues = {
    firstName: string;
    lastName: string;
    email: string;
};

export type StepOneProps = {
    handleSubmit: (
        values?: UserInfoFormValues,
        helpers?: FormikHelpers<UserInfoFormValues>,
    ) => void;
    currentUser?: User;
};

export default StepOne;
