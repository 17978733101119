import React, { MouseEventHandler } from 'react';
import { FormikProps, FormikTouched, FormikErrors } from 'formik';
import { TotalBar } from 'atoms';
import clsx from 'clsx';
import useStyles from './styles';
import { StepThreeValues } from './StepThree';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { reportError } from 'lib/errors';
import CreditCardOutline from 'mdi-material-ui/CreditCardOutline';
import Paypal from 'mdi-material-ui/Paypal';
import CreditCardFields from './CreditCardFields';
import AccountFields from './AccountFields';
import { useApolloClient } from '@apollo/client';
import { CAMPAIGN_TYPES } from 'lib/graphql/queries/campaign';
import { CampaignType } from 'types/campaign';
import PayPalButton from 'molecules/PayPalButton';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import ReCaptcha from '@elevatormedia/duffel-bag/dist/atoms/ReCaptcha';
import { PaymentType } from '@elevatormedia/duffel-bag/dist/types/order';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';
import { centsToDollars } from '@elevatormedia/duffel-bag/dist/utils/string';

const CheckoutFields = (props: CheckoutFormProps) => {
    const classes = useStyles();
    const {
        currentUser,
        paymentType,
        setPaymentType,
        values,
        handleChange,
        setFieldValue,
        touched,
        errors,
        handleSubmit,
        isSubmitting,
        isValid,
        validateForm,
        totalCost,
        handleBackClick,
        setPaypalOrderId,
        typeId,
    } = props;

    const apolloClient = useApolloClient();

    const getCampaignTypeName = () => {
        const campaignTypes = apolloClient.readQuery<CampaignType[]>({
            query: CAMPAIGN_TYPES,
        });
        const selectedType = campaignTypes.find((type) => type.typeId === typeId);
        if (selectedType) return selectedType.name;
    };

    const RadioCard = (props: any) => {
        const isActive = paymentType === props.type;
        const Icon = props.type === 'PayPal' ? Paypal : CreditCardOutline;
        const label = props.type === 'PayPal' ? props.type : 'Card';
        return (
            <div className={classes.radioCardContent}>
                <Icon
                    className={clsx(
                        classes.paymentIcon,
                        isActive && classes.paymentActiveColor,
                    )}
                />
                <Typography
                    className={clsx(
                        classes.paymentTypeLabel,
                        isActive && classes.paymentActiveColor,
                    )}
                >
                    {label}
                </Typography>
            </div>
        );
    };

    const isRadioActive = (radioValue: PaymentType) => {
        return paymentType === radioValue;
    };

    const renderPaymentOptions = () => {
        return (
            <>
                <RadioGroup
                    id={'paymentType'}
                    aria-label="Payment Type"
                    name="paymentType"
                    value={paymentType}
                    onChange={(event: any) => {
                        setPaymentType(event.target.value);
                        validateForm();
                    }}
                    className={classes.radioGroupContainer}
                >
                    <FormControlLabel
                        className={clsx(
                            classes.radioCard,
                            isRadioActive('Stripe') && classes.activeRadioCard,
                        )}
                        value={'Stripe'}
                        checked={paymentType === 'Stripe'}
                        control={<Radio color={'primary'} />}
                        label={<RadioCard type={'Stripe'} />}
                    />
                    <FormControlLabel
                        className={clsx(
                            classes.radioCard,
                            isRadioActive('PayPal') && classes.activeRadioCard,
                        )}
                        value={'PayPal'}
                        checked={paymentType === 'PayPal'}
                        control={<Radio color={'primary'} />}
                        label={<RadioCard type={'PayPal'} />}
                    />
                </RadioGroup>
                {paymentType === 'PayPal' && (
                    <Typography>
                        PayPal will open in a new window upon checkout.
                    </Typography>
                )}
            </>
        );
    };

    const renderCardFields = () => {
        if (paymentType === 'Stripe')
            return (
                <CreditCardFields
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                />
            );
        else return null;
    };

    const renderAccountFields = () => {
        if (!currentUser)
            return (
                <AccountFields
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                />
            );
        else return null;
    };

    const renderSubmitButton = () => {
        if (paymentType === 'PayPal')
            return (
                <PayPalButton
                    intent={'capture'}
                    createOrder={(data, actions) => {
                        const priceString = centsToDollars(totalCost);

                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: 'USD',
                                        value: priceString,
                                        breakdown: {
                                            item_total: {
                                                currency_code: 'USD',
                                                value: priceString,
                                            },
                                        },
                                    },
                                    soft_descriptor: 'ELEVATOR LLC',
                                    items: [
                                        {
                                            name: getCampaignTypeName(),
                                            unit_amount: {
                                                currency_code: 'USD',
                                                value: priceString,
                                            },
                                            quantity: '1',
                                        },
                                    ],
                                },
                            ],
                        });
                    }}
                    onApprove={async (data, _actions) => {
                        setPaypalOrderId(data.orderID);
                        handleSubmit();
                    }}
                    disabled={!isValid || isSubmitting}
                />
            );
        else {
            return (
                <Button
                    type={'submit'}
                    onClick={() => handleSubmit()}
                    variant={'primary'}
                    disabled={!isValid || isSubmitting}
                    fullWidth
                >
                    Pay & Submit
                </Button>
            );
        }
    };

    const renderCaptcha = () => {
        if (paymentType === 'Stripe')
            return (
                <GridItem xs={12} paddingY={2}>
                    <Typography variant={'caption'} color={'textSecondary'}>
                        CAPTCHA
                    </Typography>
                    <ReCaptcha
                        onChange={(resp: string) => setFieldValue('recaptcha', resp)}
                        onErrored={(error: any) => {
                            reportError(error, {
                                metaData: {
                                    operation: 'captcha',
                                },
                            });
                            setFieldValue('recaptcha', '');
                        }}
                        onExpired={() => setFieldValue('recaptcha', '')}
                        googleRecaptchaKey={process.env.googleRecaptchaV2SiteKey}
                    />
                </GridItem>
            );
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <GridContainer className={classes.container}>
                <Typography variant={'caption'} color={'textSecondary'}>
                    Payment Method
                </Typography>
                {renderPaymentOptions()}
                {renderCardFields()}
                {renderAccountFields()}
                <GridItem xs={12} paddingY={2}>
                    <TotalBar total={totalCost / 100} />
                </GridItem>
                {renderCaptcha()}
                <GridItem xs={12} paddingY={2}>
                    <GridContainer direction={'column'} spacing={1}>
                        <GridItem xs={12}>{renderSubmitButton()}</GridItem>
                        <GridItem xs={12}>
                            <Button
                                variant={'text'}
                                size={'small'}
                                onClick={handleBackClick}
                                fullWidth
                            >
                                back
                            </Button>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </form>
    );
};

export type CheckoutFormProps = FormikProps<StepThreeValues> & {
    handleBackClick: MouseEventHandler;
    paymentType: PaymentType;
    setPaymentType: (value: PaymentType) => void;
    paypalOrderId?: string;
    setPaypalOrderId?: (value: string) => void;
    setBitPayInvoiceId?: (value: string) => void;
    typeId: string;
    totalCost: number;
    currentUser: User;
};

export type FieldsProps = {
    values: StepThreeValues;
    handleChange: (e: React.ChangeEvent<any>) => void;
    touched: FormikTouched<StepThreeValues>;
    errors: FormikErrors<StepThreeValues>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export default CheckoutFields;
