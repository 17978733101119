import { Theme, makeStyles, createStyles } from '@material-ui/core';

const iconSize = 20;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.spacing(2),
        },
        statusText: {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.pxToRem(14),
        },
        pendingIconContainer: {
            height: iconSize - 2,
            width: iconSize - 2,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.submissionStatuses.review,
            marginRight: theme.spacing(1),
        },
        pendingIcon: {
            color: theme.palette.common.white,
            fontSize: iconSize - 4,
        },
        approvedIcon: {
            color: theme.palette.submissionStatuses.approved,
            fontSize: iconSize,
            marginRight: theme.spacing(1),
        },
        rejectedIcon: {
            color: theme.palette.submissionStatuses.rejected,
            fontSize: iconSize,
            marginRight: theme.spacing(1),
        },
        flaggedIcon: {
            color: theme.palette.submissionStatuses.flagged,
            fontSize: iconSize,
            marginRight: theme.spacing(1),
        },
    }),
);

export default useStyles;
