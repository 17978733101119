import React from 'react';
import ErrorPage from 'next/error';
import bugsnagClient from 'lib/errors/bugsnag';
import { Error } from 'layouts';

export default class Page extends React.Component<ErrorPagePropTypes> {
    static async getInitialProps(ctx: any) {
        if (ctx.err) bugsnagClient.notify(ctx.err);
        return ErrorPage.getInitialProps(ctx);
    }

    render() {
        return (
            <Error
                statusCode={this.props.statusCode || 'An unkown error has occurred.'}
            />
        );
    }
}

type ErrorPagePropTypes = {
    statusCode: any;
};
