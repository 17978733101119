import React, { useEffect, useState } from 'react';
import Paypal from 'mdi-material-ui/Paypal';
import { PayPalButton as PayPalButtonBase } from 'react-paypal-button-v2';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import { PayPalButtonsComponentOptions } from '@paypal/paypal-js/types/components/buttons';

const PayPalButton: React.FunctionComponent<PayPalButtonProps> = (
    props: PayPalButtonProps,
) => {
    const { disabled, intent, ...rest } = props;

    const [ready, setReady] = useState(false);

    // get existing PayPal script element
    const getPayPalElement = () => {
        const scriptElements = document.querySelectorAll<HTMLScriptElement>(
            "script[type='text/javascript']",
        );
        const scriptList = Array.from(scriptElements);

        return scriptList.find((script) =>
            script.src.startsWith('https://www.paypal.com/sdk/js'),
        );
    };

    useEffect(() => {
        const paypalScript = getPayPalElement();

        // if script is already loaded
        if (paypalScript) {
            const url = new URL(paypalScript.src);

            // if intent has changed, reload the script with new params
            if (url.searchParams.get('intent') !== intent) {
                setReady(false);

                window.paypal = undefined;
                paypalScript.remove();

                url.searchParams.set('intent', intent);

                const script = document.createElement('script');

                script.type = 'text/javascript';
                script.src = url.href;
                script.async = true;

                script.onload = () => {
                    setReady(true);
                };

                document.body.appendChild(script);
            }
        }
    }, [intent]);

    useEffect(() => {
        // when component unmounts, remove PayPal script
        return () => {
            if (ready) {
                const paypalScript = getPayPalElement();

                if (paypalScript) {
                    paypalScript.remove();
                    window.paypal = undefined;
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (disabled)
        return (
            <Button variant={'primary'} disabled onClick={null} fullWidth>
                <Paypal />
            </Button>
        );
    else
        return (
            <PayPalButtonBase
                options={{
                    clientId: process.env.paypalClientId,
                    disableFunding: 'credit,card',
                    intent,
                }}
                style={{
                    label: 'pay',
                    layout: 'horizontal',
                    color: 'black',
                    tagline: false,
                    height: 40,
                    padding: 0,
                }}
                onError={(err: any) => {
                    // eslint-disable-next-line no-console
                    console.error('window closed');
                }}
                {...rest}
                onButtonReady={() => {
                    setReady(true);
                }}
            />
        );
};

export type PayPalButtonProps = Omit<
    PayPalButtonsComponentOptions,
    'options' | 'onButtonReady'
> & {
    disabled?: boolean;
    intent: 'authorize' | 'capture';
};

export default PayPalButton;
