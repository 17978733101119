import React from 'react';
import useStyles from './styles';
import SubmissionReviewRow from './SubmissionReviewRow';
import ActionButtons from 'molecules/ActionButtons';
import CreditChip from 'molecules/CreditChip';
import { Media } from '@elevatormedia/duffel-bag';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useCartContext } from 'lib/cart';

const SubmissionReview: React.FC<SubmissionReviewProps> = ({
    nextStep,
    handleBackPress,
}) => {
    const classes = useStyles();

    const { cart } = useCartContext();
    const submission = cart?.submissions[0];
    const credits = submission?.credits || [];

    return (
        <>
            <Typography variant={'body2'} className={classes.submissionInfoTitle}>
                Submission Info
            </Typography>
            <Paper>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                    width={'100%'}
                >
                    <div className={classes.submissionContainer}>
                        <div className={classes.topContainer}>
                            <SubmissionReviewRow
                                label="Submission Title"
                                content={submission.contentTitle}
                            />
                            <SubmissionReviewRow
                                label="Content Link"
                                content={
                                    <a
                                        href={submission.contentUrl}
                                        target={'_blank'}
                                        className={classes.link}
                                    >
                                        {submission.contentUrl}
                                    </a>
                                }
                            />
                            <SubmissionReviewRow
                                label="Description"
                                content={submission.contentDescription}
                            />
                        </div>
                        <div className={classes.imageContainer}>
                            <SubmissionReviewRow
                                label="Image"
                                content={
                                    submission.contentImage ? (
                                        <a
                                            href={submission.contentImage.sourceUrl}
                                            target={'_blank'}
                                        >
                                            <img
                                                className={classes.imagePreviewMobile}
                                                src={submission.contentImage.sourceUrl}
                                                alt={'Submission Image'}
                                            />
                                        </a>
                                    ) : (
                                        '(No image attached)'
                                    )
                                }
                            />
                        </div>
                    </div>
                </Box>
            </Paper>
            <Typography variant={'body2'} className={classes.creditsListTitle}>
                Credits
            </Typography>
            <div className={classes.creditsList}>
                {credits.length !== 0 ? (
                    <>
                        {credits.map((item) => {
                            const { name, creditType: type, user } = item;

                            return (
                                <CreditChip
                                    key={user.userId}
                                    variant={'view'}
                                    name={name}
                                    type={type.typeName}
                                    avatar={user && (user.avatar as Media)}
                                />
                            );
                        })}
                    </>
                ) : (
                    <div>(No credits)</div>
                )}
            </div>
            <ActionButtons
                submit={{
                    label: 'Checkout',
                    onClick: nextStep,
                }}
                cancel={{
                    label: 'Back',
                    onClick: handleBackPress,
                }}
            />
        </>
    );
};

export interface SubmissionReviewProps {
    nextStep: () => void;
    handleBackPress: React.MouseEventHandler;
}

export default SubmissionReview;
