import React from 'react';
import { ROUTES } from 'config/Nav';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import useStyles from './styles';
import { useRouter } from 'next/router';
import MarkunreadMailboxOutlined from '@material-ui/icons/MarkunreadMailboxOutlined';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';

const StepTwo: React.FC<StepTwoProps> = ({ hydrateUser, setOpen }) => {
    const theme = useTheme();
    const classes = useStyles();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const router = useRouter();

    const goToAccount = async () => {
        await hydrateUser(false);
        setOpen(false);
        router.push(ROUTES.ACCOUNT.ACCOUNT_SETTINGS.to);
    };

    return (
        <Fade in timeout={1000}>
            <Box
                height={'100%'}
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    width={'100%'}
                    maxWidth={'400px'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <MarkunreadMailboxOutlined className={classes.icon} />
                    <Typography component={'h6'} variant={'h6'} align={'center'}>
                        Email Verification Successful
                    </Typography>
                    <Typography
                        component="p"
                        variant="body2"
                        color={'textSecondary'}
                        align={'center'}
                    >
                        Thank you for verifying your email! In the future, your email only
                        needs to be verified if you change it.
                    </Typography>
                    <Box
                        paddingTop={3}
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Button
                            variant="contained"
                            size={'medium'}
                            color={'primary'}
                            fullWidth={mobile}
                            onClick={goToAccount}
                        >
                            MY ACCOUNT
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Fade>
    );
};

interface StepTwoProps {
    hydrateUser: (useCache?: boolean) => Promise<User>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default StepTwo;
