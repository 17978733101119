import gql from 'graphql-tag';

export const SEARCH_POSTS = gql`
    query search($limit: Int, $skip: Int, $search: String) {
        publishedPosts(limit: $limit, skip: $skip, search: $search) {
            postId
            title
            slug
            type
            description
            publishedAt
            modifiedAt
            image {
                sourceUrl
                sourceSet
            }
            authors {
                displayName
                preferredUsername
            }
            categories {
                name
                slug
            }
        }
    }
`;

export const SEARCH_ALL = gql`
    query searchResults($limit: Int, $skip: Int, $search: String!) {
        publishedPosts(limit: $limit, skip: $skip, search: $search) {
            postId
            title
            slug
            type
            description
            publishedAt
            modifiedAt
            image {
                sourceUrl
                sourceSet
            }
            authors {
                displayName
                preferredUsername
            }
            categories {
                name
                slug
            }
        }
        searchUsers(input: $search) {
            preferredUsername
            socialMedia {
                type
                link
            }
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
        }
    }
`;

export const POST = gql`
    query post($slug: String!) {
        publishedPost(slug: $slug) {
            postId
            title
            slug
            type
            status
            description
            content
            imageId
            image {
                sourceUrl
                sourceSet
            }
            featured {
                position
            }
            modifiedAt
            publishedAt
            createdAt
            updatedAt
            deletedAt
            authors {
                displayName
                preferredUsername
                role
                avatar {
                    mediaId
                    sourceUrl
                    sourceSet
                }
                socialMedia {
                    type
                    link
                }
            }
            submission {
                sponsoredBy
            }
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    avatar {
                        sourceUrl
                        sourceSet
                    }
                    preferredUsername
                }
                socialMedia {
                    type
                    link
                }
            }
            categories {
                name
                slug
            }
            tags {
                name
                slug
            }
        }
    }
`;

export const PREVIEW_POST = gql`
    query post($slug: String) {
        post(slug: $slug) {
            postId
            title
            slug
            type
            status
            description
            content
            imageId
            image {
                sourceUrl
                sourceSet
            }
            featured {
                position
            }
            modifiedAt
            publishedAt
            createdAt
            updatedAt
            deletedAt
            authors {
                displayName
                preferredUsername
                role
                avatar {
                    mediaId
                    sourceUrl
                    sourceSet
                }
                socialMedia {
                    type
                    link
                }
            }
            submission {
                sponsoredBy
            }
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    avatar {
                        sourceUrl
                        sourceSet
                    }
                    preferredUsername
                }
                socialMedia {
                    type
                    link
                }
            }
            categories {
                name
                slug
            }
            tags {
                name
                slug
            }
        }
    }
`;

export const POPULAR_POSTS = gql`
    query popularPosts($limit: Int!, $skip: Int!) {
        publishedPosts(limit: $limit, skip: $skip) {
            postId
            title
            slug
            type
            modifiedAt
            publishedAt
            authors {
                userId
                preferredUsername
            }
            image {
                sourceUrl
            }
            categories {
                name
                slug
            }
        }
    }
`;

export const POSTS_BY_TYPE = gql`
    query postsByType($limit: Int, $skip: Int, $type: PostType!) {
        publishedPosts(limit: $limit, skip: $skip, type: $type) {
            postId
            title
            slug
            type
            description
            publishedAt
            modifiedAt
            image {
                sourceUrl
                sourceSet
            }
            authors {
                displayName
                preferredUsername
            }
            categories {
                name
                slug
            }
        }
    }
`;

export const POSTS_BY_USER = gql`
    query postsByUser($preferredUsername: String!, $limit: Int, $skip: Int) {
        posts: postsByUser(
            preferredUsername: $preferredUsername
            limit: $limit
            skip: $skip
        ) {
            postId
            title
            slug
            modifiedAt
            publishedAt
            authors {
                preferredUsername
                displayName
            }
            image {
                sourceUrl
            }
            categories {
                name
                slug
            }
        }
    }
`;

export const POSTS_ABOUT_USER = gql`
    query postsAboutUser($preferredUsername: String!, $limit: Int, $skip: Int) {
        posts: postsAboutUser(
            preferredUsername: $preferredUsername
            limit: $limit
            skip: $skip
        ) {
            postId
            title
            slug
            modifiedAt
            publishedAt
            authors {
                userId
                preferredUsername
            }
            image {
                sourceUrl
            }
            categories {
                name
                slug
            }
        }
    }
`;
