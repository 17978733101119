import SocialMediaIconLink from '@elevatormedia/duffel-bag/dist/atoms/SocialMediaIconLink';
import { SocialMediaLink } from '@elevatormedia/duffel-bag/dist/types/socialMedia';
import { SizeVariant, ThemeVariant } from '@elevatormedia/duffel-bag/dist/types/variants';
import React from 'react';
import useStyles from './styles';

const SocialMediaGroup = (props: SocialMediaGroupPropTypes) => {
    const classes = useStyles();

    const { socialMedia, variant, theme, ...rest } = props;

    return (
        <div className={classes.root}>
            {socialMedia.map((socialMediaLink, index) => (
                <div
                    key={index}
                    className={
                        index !== socialMedia.length - 1
                            ? classes[`${variant}Padding` as keyof typeof classes]
                            : null
                    }
                >
                    <SocialMediaIconLink
                        href={socialMediaLink.link}
                        type={socialMediaLink.type}
                        variant={variant}
                        theme={theme}
                        {...rest}
                    />
                </div>
            ))}
        </div>
    );
};

export interface SocialMediaGroupPropTypes {
    socialMedia: SocialMediaLink[];
    variant: SizeVariant;
    theme?: ThemeVariant;
}

SocialMediaIconLink.defaultProps = {
    theme: 'dark',
};

export default SocialMediaGroup;
