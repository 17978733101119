import gql from 'graphql-tag';

export const UPDATE_MY_SUBMISSION = gql`
    mutation updateMySubmission($id: ID!, $updates: UpdateMySubmissionInput) {
        updateMySubmission(id: $id, updates: $updates) {
            orderNumber
            contentTitle
            contentUrl
            contentDescription
            status
            flagged
            createdAt
            submissionType
            terms
            order {
                paymentType
                amountPaid
                ccLast4
            }
            contentImage {
                sourceUrl
                sourceSet
            }
            post {
                title
                slug
            }
            credits {
                creditId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                }
                socialMedia {
                    type
                    link
                }
            }
            service {
                serviceId
                name
            }
        }
    }
`;
