import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        height: '100%',
        width: '100%',
    },
    textHeader: {
        paddingBottom: theme.spacing(2),
    },
    dividerContainer: {
        padding: theme.spacing(1, 0, 3, 0),
    },
    divider: {
        backgroundColor: 'black',
    },
    paragraphContainer: {
        padding: theme.spacing(0, 0, 4, 0),
    },
    externalLink: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
    },
    addBold: {
        fontWeight: 'bold',
    },
    sampleImage: {
        height: 'auto',
        width: '80%',
    },
});

export default makeStyles(styles);
