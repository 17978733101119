import React, { MouseEventHandler } from 'react';
import useStyles from './styles';
import { NextComponentType, NextPageContext } from 'next';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Subtract } from 'utility-types';

/**
 * More Info: https://www.pluralsight.com/guides/higher-order-composition-typescript-react
 */
const withProgress =
    <T extends WithProgressTypes>(
        Component: NextComponentType<
            NextPageContext,
            any,
            Subtract<T, WithProgressTypes>
        >,
    ) =>
    (props: Subtract<T, WithProgressTypes>) => {
        const classes = useStyles();
        const [processing, setProcessing] = React.useState(false);
        const [displayText, setDisplayText] = React.useState(null);

        const toggleProcess = (_e: {}, reason: string) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setProcessing((prevValue) => !prevValue);
            }
        };

        return (
            <>
                <Modal
                    aria-label="processing-screen"
                    open={processing}
                    onClose={toggleProcess}
                >
                    <div className={classes.modalContentContainer}>
                        <CircularProgress color={'inherit'} />
                        <Typography className={classes.displayText}>
                            {displayText}
                        </Typography>
                    </div>
                </Modal>
                <Component
                    {...props}
                    toggleProcess={toggleProcess}
                    setProcessingText={setDisplayText}
                />
            </>
        );
    };

export interface WithProgressTypes {
    toggleProcess: MouseEventHandler | any;
    setProcessingText: (value: string) => void;
}

export default withProgress;
