import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import { ROUTES } from 'config/Nav';
import { Skeleton } from '@material-ui/lab';
import BaseLink from '@elevatormedia/duffel-bag/dist/atoms/BaseLink';
import { ImageProps } from '@elevatormedia/duffel-bag/dist/types/media';
import Image from 'next/image';

const HeroPost = (props: HeroPostPropTypes) => {
    const classes = useStyles();

    const { src, alt, srcSet, postTitle, postUrl, isLoading } = props;

    return (
        <BaseLink href={ROUTES.POST.fileApiPath} as={postUrl}>
            <div className={classes.root}>
                {isLoading ? (
                    <Skeleton
                        variant="rect"
                        width="100%"
                        height="100%"
                        animation="pulse"
                        className={classes.skeleton}
                    >
                        <div className={classes.heroImage} />
                    </Skeleton>
                ) : (
                    <Image
                        src={src}
                        className={classes.heroImage}
                        alt={alt}
                        layout="fill"
                    />
                )}
                <div className={classes.gradientContainer}>
                    <Typography
                        variant={'h5'}
                        className={classes.postTitle}
                        align={'center'}
                    >
                        {postTitle}
                    </Typography>
                </div>
            </div>
        </BaseLink>
    );
};

export interface HeroPostPropTypes extends ImageProps {
    postTitle: string;
    postUrl: string;
    isLoading: boolean;
}

export default HeroPost;
