import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.spacing(1),
        },
        heavyFont: {
            fontWeight: 'bold',
        },
        description: {
            color: 'rgba(0, 0, 0, 0.5)',
        },
    });

export default makeStyles(styles);
