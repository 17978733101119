import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 0),
            },
            minHeight: '70vh',
        },
        allSearchResultsContainer: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down(theme.static_component_sizes.content_max_width)]: {
                flexDirection: 'column-reverse',
            },
            justifyContent: 'space-between',
        },
        singleTabContainer: {
            paddingTop: theme.spacing(4),
        },
        postSearchResultsListContainer: {
            [theme.breakpoints.down(theme.static_component_sizes.content_max_width)]: {
                paddingRight: 0,
            },
            paddingRight: theme.spacing(4),
            width: '100%',
        },
        usersSearchResultsListContainer: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: '320px',
            maxWidth: '350px',
            [theme.breakpoints.down('xs')]: {
                maxWidth: 'unset',
                minWidth: 'unset',
            },
        },
        adSpaceContainer: {
            paddingTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        emptyResultsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: theme.spacing(4, 0),
        },

        searchQueryHeader: {
            textTransform: 'uppercase',
        },

        tabGroupContainer: {
            paddingTop: theme.spacing(2),
            width: '100%',
        },
        tabRoot: {
            zIndex: 10,
            minWidth: '96px',
            fontWeight: 700,
        },
        tabIndicator: {
            position: 'absolute',
            height: '4px',
        },

        divider: {
            backgroundColor: theme.palette.primary.light,
            height: '1px',
            width: '100%',
        },

        inputStyling: {
            fontSize: theme.typography.pxToRem(24),
            [theme.breakpoints.up(700)]: {
                fontSize: theme.typography.pxToRem(40),
            },
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        searchIcon: {
            fontSize: theme.typography.pxToRem(24),
            [theme.breakpoints.up(700)]: {
                fontSize: theme.typography.pxToRem(40),
            },
        },
        clearIcon: {
            fontSize: theme.typography.pxToRem(24),
            [theme.breakpoints.up(700)]: {
                fontSize: theme.typography.pxToRem(40),
            },
            '&:hover': {
                cursor: 'pointer',
            },
        },
        submitButton: {
            display: 'none',
        },
    }),
);

export default useStyles;
