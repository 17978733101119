import React from 'react';

const InstagramEmbed = (props: InstagramEmbedPropTypes) => {
    const { rawHTML } = props;

    return <div dangerouslySetInnerHTML={{ __html: rawHTML }} />;
};

export type InstagramEmbedPropTypes = {
    rawHTML: string;
};

export default InstagramEmbed;
