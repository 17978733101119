import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        serviceInfoContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.custom.grey.main + '80',
            borderRadius: '3px',
            padding: theme.spacing(1, 1, 1, 2),
            marginBottom: theme.spacing(2),
        },
        addOnText: {
            fontWeight: 'normal',
        },
    }),
);

export default useStyles;
