import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import withLayout from 'hocs/withLayout';
import { Sidebar } from 'layouts';
import { PageProps } from 'types/page';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';
import clsx from 'clsx';

const Contact = (props: ContactProps) => {
    const classes = useStyles();

    const TextHeader = ({ children }: any) => (
        <Typography variant={'subtitle1'} className={classes.textHeader}>
            {children}
        </Typography>
    );

    const Section = ({
        header,
        children,
    }: {
        header: string;
        children: React.ReactNode;
    }) => (
        <div className={classes.paragraphContainer}>
            <TextHeader>{header}</TextHeader>

            {children}
        </div>
    );

    const ExternalLink = ({
        href,
        addBold,
        children,
    }: {
        href: string;
        addBold: boolean;
        children: string;
    }) => (
        <a
            href={href}
            target={'_blank'}
            rel={'noreferrer'}
            className={clsx(classes.externalLink, addBold && classes.addBold)}
        >
            {children}
        </a>
    );

    const renderContent = () => {
        return (
            <div className={classes.root}>
                <Typography className={classes.pageTitle} component={'h1'} variant={'h1'}>
                    CONTACT
                </Typography>
                <div className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                </div>

                <Section header={'GENERAL'}>
                    <Typography component={'span'}>
                        For general inquiries or feedback email{' '}
                        <ExternalLink
                            addBold={false}
                            href={'mailto:info@elevatormag.com'}
                        >
                            info@elevatormag.com
                        </ExternalLink>
                        .
                    </Typography>
                </Section>

                <Section header={'CONTENT PITCHES'}>
                    <Typography component={'span'}>
                        For editorial content pitches please email{' '}
                        <ExternalLink
                            addBold={false}
                            href={'mailto:editor@elevatormag.com'}
                        >
                            editor@elevatormag.com
                        </ExternalLink>
                        .
                    </Typography>
                </Section>

                <Section header={'SUBMIT CONTENT'}>
                    <Typography component={'span'}>
                        ELEVATOR accepts all forms of content submissions including music,
                        videos, articles, products ect.{' '}
                        <Link inline href={ROUTES.SUBMIT.to} variant={'default-bold'}>
                            Submit Content
                        </Link>
                        .
                    </Typography>
                </Section>

                <Section header={'ADVERTISE'}>
                    <Typography component={'span'}>
                        ELEVATOR offers paid placements, ad campaigns and branded content.
                        Email{' '}
                        <ExternalLink
                            addBold={false}
                            href={'mailto:advertising@elevatormag.com'}
                        >
                            advertising@elevatormag.com
                        </ExternalLink>{' '}
                        or{' '}
                        <Link inline href={ROUTES.ADVERTISE.to} variant={'default-bold'}>
                            Advertise Now
                        </Link>
                        .
                    </Typography>
                </Section>

                <Section header={'DIGITAL MUSIC DISTRIBUTION'}>
                    <Typography component={'span'}>
                        Digital distribution companies will help you monetize and collect
                        revenu from your music on Soundcloud and Youtube, as well as
                        publish your music on Apple Music, Spotify, Tidal and more. We’ve
                        partnered with{' '}
                        <ExternalLink
                            addBold={false}
                            href={'https://www.repostnetwork.com/'}
                        >
                            Repost Network
                        </ExternalLink>
                        , one of the top digital distributors and one of the few that have
                        a director partnership with soundcloud. or{' '}
                        <Link
                            inline
                            href={ROUTES.DISTRIBUTION.to}
                            variant={'default-bold'}
                        >
                            Apply for Repost Network
                        </Link>
                        .
                    </Typography>
                </Section>
            </div>
        );
    };

    return (
        <>
            <Seo
                type={'website'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: 'Contact',
                    path: ROUTES.CONTACT.to,
                    description:
                        'Support options and contact us. For general inquiries, submissions, and more.',
                }}
            />
            <Sidebar {...props}>{renderContent()}</Sidebar>
        </>
    );
};

export interface ContactProps extends PageProps {}

export default withLayout(Contact, true, true);
