import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { AdSpace } from 'atoms';
import { UserCard } from 'molecules';
import useStyles from './styles';
import { reportError } from 'lib/errors';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import { QueryResult } from '@elevatormedia/duffel-bag/dist/types/graphql';
import { StaffUser, StaffUsers } from '@elevatormedia/duffel-bag/dist/types/user';

const Staff = (props: StaffTypes) => {
    const classes = useStyles();

    const { staffUsers, error } = props;

    if (error) {
        return (
            <LoadingErrorDisclaimer
                error={error}
                failedTarget={'staff page'}
                reportError={reportError}
            />
        );
    }

    let editors = staffUsers.filter((user) => user.role.includes('Editor'));
    let engineers = staffUsers.filter((user) => user.role === 'Engineer');

    const TextHeader = ({ children }: any) => (
        <Typography variant={'subtitle1'} className={classes.textHeader}>
            {children}
        </Typography>
    );

    /**
     * Renders text-header an list of user cards
     */
    const Section = ({
        header,
        children,
    }: {
        header: string;
        children: React.ReactNode;
    }) => (
        <div className={classes.paragraphContainer}>
            <TextHeader>{header}</TextHeader>
            {children}
        </div>
    );

    // When we are ready to create a full hierarchy structure
    const renderUserCard = (user: StaffUser, key: string) => {
        return (
            <GridItem key={key} xs={12} sm={6} md={4}>
                <UserCard
                    user={user}
                    name={`${user.firstName} ${user.lastName}`}
                    socialMedia={user.socialMedia}
                />
            </GridItem>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.root}>
                <Typography variant={'h3'}>STAFF</Typography>
                <div className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                </div>

                <Section header={'Editor-in-Chief'}>
                    <GridContainer direction={'row'} spacing={2}>
                        {editors.map((editor) =>
                            renderUserCard(editor, editor.preferredUsername),
                        )}
                    </GridContainer>
                </Section>

                {/* TODO: add back in when these roles are included 
                <Section header={'Editors'}>
                    <GridContainer direction={'row'} spacing={2}>
                        TODO: add editors
                    </GridContainer>
                </Section>

                <Section header={'Writers'}>
                    <GridContainer direction={'row'} spacing={2}>
                        TODO: add writers
                    </GridContainer>
                </Section>

                <Section header={'A&R'}>
                    <GridContainer direction={'row'} spacing={2}>
                        TODO: add A&R
                    </GridContainer>
                </Section> */}

                <Section header={'Developers'}>
                    <GridContainer direction={'row'} spacing={2}>
                        {engineers
                            .reverse()
                            .map((engineer) =>
                                renderUserCard(engineer, engineer.preferredUsername),
                            )}
                    </GridContainer>
                </Section>
                <div className={classes.adContainer}>
                    <AdSpace type={'banner-ad'} adUnit={'ELEVATOR-Bottom'} />
                </div>
            </div>
        );
    };

    return renderContent();
};

export interface StaffTypes extends QueryResult<'staffUsers', StaffUsers> {}
export default Staff;
