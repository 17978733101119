import { makeStyles, createStyles } from '@material-ui/core';

const styles = makeStyles((theme) =>
    createStyles({
        inputRoot: {
            borderRadius: 0,
            height: 50,
        },
        selectRoot: {
            backgroundColor: theme.palette.custom.grey.light,
            borderRadius: 0,
            height: 50,
            padding: 0,
        },
        dropdownArrow: {
            color: theme.palette.text.secondary,
        },
        dropdownContainer: {
            maxHeight: '250px',
        },
        inputLabelRoot: {
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: 'bold',
            '&:focused': {
                color: theme.palette.common.black,
            },
            fontSize: '0.825rem',
            marginBottom: theme.spacing(0.25),
        },
    }),
);

export default styles;
