import React, { MouseEventHandler } from 'react';
import { AdForm } from 'organisms';
import { AdFormValues } from 'organisms/AdForm/AdForm';
import { FormikHelpers } from 'formik';
import useStyles from './styles';

const StepOne = (props: StepTwoProps) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <AdForm {...props} />
        </div>
    );
};

export type StepTwoProps = {
    campaign: AdFormValues;
    handleSubmit: (values: AdFormValues, helpers: FormikHelpers<AdFormValues>) => void;
    handleBackClick: MouseEventHandler;
    totalCost: number;
    updateTotalCost: (campaignCost: number) => void;
};

export default StepOne;
