import React from 'react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import withLayout from 'hocs/withLayout';
import { PageProps } from 'types/page';
import { reportError } from 'lib/errors';
import { CREATE_DIST_REQ } from 'lib/graphql/mutations/distribution';
import { useSnackbar } from 'notistack';
import { CreateDistributionRequestInput } from 'types/distribution';
import isURL from 'validator/lib/isURL';
import { object as yupObject, string as yupString } from 'yup';
import Confirmation from './Confirmation';
import Distribution from './Distribution';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import useStyles from './styles';

const Index = (props: DistributionIndexPropTypes) => {
    const { currentUser } = useAuth();
    const classes = useStyles();

    const [renderConfirmation, setRenderConfirmation] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [requestDistribution] = useMutation<
        any,
        { input: CreateDistributionRequestInput }
    >(CREATE_DIST_REQ);

    const snackbarActions = ({ key }: { key: number }) => (
        <React.Fragment>
            <Button
                variant={'text'}
                onClick={() => {
                    closeSnackbar(key);
                }}
                className={classes.snackbarButton}
            >
                Dismiss
            </Button>
        </React.Fragment>
    );

    const handleSubmit = async (values: any, actions: any) => {
        const input: CreateDistributionRequestInput = {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            email: values.email.trim(),
            soundcloudUrl: values.soundCloudUrl.trim(),
        };

        try {
            const { data } = await requestDistribution({
                variables: { input },
            });

            setRenderConfirmation(true);
        } catch (err) {
            reportError(err, {
                metaData: {
                    operation: 'mutation createDistributionReq',
                },
                severity: 'error',
            });
            enqueueSnackbar('Unable to complete your request. Please try again', {
                persist: true,
                variant: 'error',
                action: snackbarActions,
            });
        }

        return;
    };

    const validationSchema = yupObject({
        email: yupString()
            .email('Please enter a valid email')
            .test('noWhiteSpace', 'Emails cannot contain spaces', (value) => {
                return !/\s/.test(value);
            })
            .required('An email is required'),
        firstName: yupString().required('First name is required'),
        lastName: yupString().required('Last name is required'),
        soundCloudUrl: yupString()
            .test(
                'validSoundCloudURL',
                'Please enter a valid SoundCloud Url',
                (value) => {
                    if (!value) {
                        return false;
                    }

                    const isValid = isURL(value, {
                        require_host: true,
                        require_protocol: false,
                        host_whitelist: [
                            'm.soundcloud.com',
                            'soundcloud.com',
                            'www.soundcloud.com',
                        ],
                        require_tld: process.env.cookieDomain !== 'localhost',
                    });

                    return isValid;
                },
            )
            .required('A url is required'),
        recaptcha: yupString().required('ReCAPTCHA must be verified'),
    });

    const getInitialValues = () => {
        const values: DistributionValues = {
            email: currentUser ? currentUser.email : '',
            firstName: currentUser ? currentUser.firstName : '',
            lastName: currentUser ? currentUser.lastName : '',
            soundCloudUrl: '',
            recaptcha: '',
        };

        return values;
    };

    return (
        <div>
            {renderConfirmation ? (
                <Confirmation toggleConfirmation={() => setRenderConfirmation(false)} />
            ) : (
                <Formik
                    // Do not remove this line.
                    // Necessary for changes to initialValues to trigger
                    // a reset
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={getInitialValues()}
                    validateOnMount={false}
                    onSubmit={handleSubmit}
                >
                    {(props) => <Distribution {...props} />}
                </Formik>
            )}
        </div>
    );
};

export interface DistributionIndexPropTypes extends PageProps {}

export type DistributionValues = {
    email: string;
    firstName: string;
    lastName: string;
    soundCloudUrl: string;
    recaptcha: string;
};

export default withLayout(Index, true, true);
