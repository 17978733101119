import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        link: {
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'none',
                cursor: 'pointer',
            },
            textDecoration: 'none',
            textTransform: 'uppercase',
            // TODO OVERRIDE
            fontSize: theme.typography.pxToRem(10),
            fontWeight: 'bold',
            letterSpacing: '2px',
            padding: '2px 6px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            [theme.breakpoints.up('lg')]: {
                padding: '4px 6px 2px 6px',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '4px 6px 2px 6px',
            },
        },
        blackText: {
            color: theme.palette.common.black,
        },
        whiteText: {
            color: 'white !important',
        },
        yellowBackground: {
            backgroundColor: theme.palette.custom.yellow,
        },
        blackBackground: {
            backgroundColor: theme.palette.common.black,
        },
        blueBackground: {
            backgroundColor: '#2196F3',
        },
        icon: {
            fontSize: '0.75rem !important',
        },

        //Sponsored By Styles
        sponsoredByContainer: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                marginLeft: theme.spacing(5),
            },
        },
        sponsoredText: {
            paddingTop: theme.spacing(0.5),
        },
        sponsoredBy: {
            color: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.secondary.main,
                textDecoration: 'none',
                cursor: 'pointer',
            },
            fontSize: theme.typography.pxToRem(10),
            fontWeight: 'bold',
            letterSpacing: '2px',
            paddingLeft: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
    });

export default makeStyles(styles);
