import React from 'react';
import useStyles from './styles';
import { DistributionValues } from '.';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import ReCaptcha from '@elevatormedia/duffel-bag/dist/atoms/ReCaptcha';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';
import { FormikProps } from 'formik';
import { reportError } from 'lib/errors';
import Image from 'next/image';

import { OpenGraphMedia } from 'next-seo/lib/types';

const Distribution = (props: DistributionPropTypes) => {
    const classes = useStyles();

    const {
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        setFieldTouched,
    } = props;

    const handleInputChange = (event: any, fieldName: string): void => {
        setFieldTouched(fieldName, true);
        handleChange(event);
    };

    const SectionTitle = ({ children }: { children: string }) => {
        return (
            <div className={classes.sectionTitleContainer}>
                <Typography variant={'caption'} color={'textSecondary'}>
                    {children}
                </Typography>
            </div>
        );
    };

    const seoImage: OpenGraphMedia = {
        url: 'http://d1fs2x8pjgwjnh.cloudfront.net/app/uploads/2016/09/Screen-Shot-2016-09-02-at-4.39.01-PM.png',
        alt: 'Repost network landing-page image',
    };

    return (
        <>
            <Seo
                type={'website'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: 'Distribution',
                    description: 'Apply to distribute your music through ELEVATOR.',
                    path: ROUTES.DISTRIBUTION.to,
                    image: seoImage,
                }}
            />
            <div className={classes.root}>
                <Link href={ROUTES.DISTRIBUTION.to} variant={'title-general'}>
                    MUSIC DISTRIBUTION & MONETIZATION
                </Link>
                <div className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                </div>
                <Image
                    src={
                        'http://d1fs2x8pjgwjnh.cloudfront.net/app/uploads/2016/09/Screen-Shot-2016-09-02-at-4.39.01-PM.png'
                    }
                    alt={'Repost network landing-page image'}
                    className={classes.repostScreenshot}
                    width={1100}
                    height={500}
                />
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className={classes.formContainer}
                >
                    <div className={classes.sectionContainer}>
                        <SectionTitle>Your info</SectionTitle>
                        <div className={classes.fieldContainer}>
                            <TextField
                                id={'email'}
                                name={'email'}
                                label={'Email'}
                                autoComplete={'email'}
                                fullWidth
                                required
                                value={values.email}
                                onChange={(e) => handleInputChange(e, 'email')}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email ? errors.email : ''}
                                disabled={isSubmitting}
                            />
                        </div>

                        <GridContainer
                            direction={'row'}
                            spacing={2}
                            className={classes.fieldContainer}
                        >
                            <GridItem xs={6}>
                                <TextField
                                    id={'firstName'}
                                    name={'firstName'}
                                    label={'First Name'}
                                    autoComplete={'given-name'}
                                    fullWidth
                                    required
                                    value={values.firstName}
                                    onChange={(e) => handleInputChange(e, 'firstName')}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName ? errors.firstName : ''}
                                    disabled={isSubmitting}
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <TextField
                                    id={'lastName'}
                                    name={'lastName'}
                                    label={'Last Name'}
                                    autoComplete={'family-name'}
                                    fullWidth
                                    required
                                    value={values.lastName}
                                    onChange={(e) => handleInputChange(e, 'lastName')}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName ? errors.lastName : ''}
                                    disabled={isSubmitting}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>

                    <div className={classes.sectionContainer}>
                        <SectionTitle>Soundcloud</SectionTitle>

                        <TextField
                            id={'soundCloudUrl'}
                            name={'soundCloudUrl'}
                            label={'SoundCloud Url'}
                            fullWidth
                            required
                            value={values.soundCloudUrl}
                            onChange={(e) => handleInputChange(e, 'soundCloudUrl')}
                            error={touched.soundCloudUrl && Boolean(errors.soundCloudUrl)}
                            helperText={touched.soundCloudUrl ? errors.soundCloudUrl : ''}
                            disabled={isSubmitting}
                        />
                    </div>

                    <div className={classes.sectionContainer}>
                        <SectionTitle>CAPTCHA</SectionTitle>
                        <ReCaptcha
                            googleRecaptchaKey={process.env.googleRecaptchaV2SiteKey}
                            onChange={(resp: string) => {
                                setFieldValue('recaptcha', resp);
                                setFieldTouched('recaptcha', true);
                            }}
                            onErrored={(error: any) => {
                                reportError(error, {
                                    metaData: {
                                        operation: 'captcha',
                                    },
                                });
                                setFieldValue('recaptcha', '');
                                setFieldTouched('recaptcha', false);
                            }}
                            onExpired={() => {
                                setFieldValue('recaptcha', '');
                                setFieldTouched('recaptcha', false);
                            }}
                        />
                    </div>

                    <div className={classes.buttonContainer}>
                        <Button
                            type={'submit'}
                            disabled={!isValid || isSubmitting}
                            onClick={() => handleSubmit()}
                            variant={'primary'}
                            fullWidth
                        >
                            {isSubmitting ? 'Creating your request' : 'Submit'}
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
};

export interface DistributionPropTypes extends FormikProps<DistributionValues> {}

export default Distribution;
