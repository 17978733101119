import React from 'react';
import Tag from './Tag';
import { PageProps } from 'types/page';
import { TAG_WITH_POSTS } from 'lib/graphql/queries/tag';
import { TagWithPosts } from 'types/tag';
import withLayout from 'hocs/withLayout';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

const Index = (props: TagIndexProps) => {
    const router = useRouter();
    let { tag, slug, variables, error, loading, networkStatus } = props;

    // If slug was not passed directly (via curated routes)
    // Assume this layout was dynamically generated and retrieve the slug
    // from the pathname
    if (!slug) slug = String(router.query.slug);

    const { fetchMore } = useQuery(TAG_WITH_POSTS, {
        fetchPolicy: 'cache-and-network',
        variables,
        notifyOnNetworkStatusChange: true,
    });

    const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;
    const loadingMore = networkStatus === 3;

    if (isLoading) {
        return null;
    } else {
        return (
            <Tag
                tag={tag}
                slug={slug}
                loading={isLoading}
                loadingMore={loadingMore}
                error={error}
                fetchMore={fetchMore}
                variables={variables}
            />
        );
    }
};

export interface TagIndexProps extends PageProps {
    tag?: TagWithPosts;
    slug?: string;
    variables: any;
    error?: any;
    loading?: any;
    networkStatus?: any;
}

export default withLayout(Index, false);
