import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    container: {
        minHeight: '35px',
    },
    input: {
        borderRadius: '0px !important',
        border: '0 !important',
        backgroundColor: '#EBEBEB !important',
        color: '#333333 !important',
        fontSize: '1em !important',
        minHeight: '35px',
        width: '100% !important',
        paddingLeft: '56px !important',
    },
    button: {
        borderRadius: '0px !important',
        border: '0 !important',
        backgroundColor: '#EBEBEB !important',
        '& .selected-flag:hover': {
            backgroundColor: '#EBEBEB !important',
        },
        '& .selected-flag:focus': {
            backgroundColor: '#EBEBEB !important',
        },
        '& .selected-flag': {
            backgroundColor: '#EBEBEB !important',
        },
        paddingLeft: '8px !important',
        paddingRight: '8px !important',
    },
    dropdown: {
        margin: '0 !important',
        padding: '0 !important',
        left: '0 !important',
        width: 'auto !important',
        boxShadow: 'none !important',
        borderRadius: '0 !important',
        '& .country-list': {
            width: '100% !important',
        },

        // Scrollbars
        // Chrome, Safari, Opera
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        // IE, Edge
        '-ms-overflow-style': 'none',
        // Firefox
        scrollbarWidth: 'none',
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54) !important',
        fontSize: '0.8rem !important',
        backgroundColor: '#EBEBEB !important',
        width: 'auto !important',
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '500 !important' as any,
        padding: theme.spacing(0.8, 0, 0, 2),
        margin: '0 !important',
        zIndex: 10,
    },
});

export default makeStyles(styles);
