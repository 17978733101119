import gql from 'graphql-tag';

export const SUBMISSION_TYPE_OPTIONS = gql`
    query {
        submissionTypeOptions {
            value
            productDescription
            price
            onSale
        }
    }
`;

export const MY_SUBMISSIONS = gql`
    query {
        mySubmissions {
            submissionId
            orderNumber
            contentTitle
            contentUrl
            contentDescription
            status
            flagged
            createdAt
            submissionType
            order {
                status
                flagged
                amountPaid
            }
            contentImage {
                sourceUrl
                sourceSet
            }
            post {
                title
                slug
            }
            service {
                serviceId
                name
                parent {
                    serviceId
                    name
                }
                vendorId
                vendor {
                    name
                }
            }
            services {
                serviceId
                name
                parent {
                    serviceId
                    name
                }
                vendorId
                vendor {
                    name
                }
            }
        }
    }
`;

export const MY_SUBMISSION_BY_ORDER_NUMBER = gql`
    query mySubmissionByOrderNumber($orderNumber: String!) {
        mySubmissionByOrderNumber(orderNumber: $orderNumber) {
            submissionId
            orderNumber
            contentTitle
            contentUrl
            contentDescription
            status
            flagged
            createdAt
            submissionType
            terms
            order {
                status
                flagged
                paymentType
                amountPaid
                ccLast4
            }
            contentImage {
                sourceUrl
                sourceSet
            }
            post {
                title
                slug
            }
            credits {
                creditId
                submissionId
                creditType {
                    typeName
                }
                name
                user {
                    userId
                    preferredUsername
                    avatar {
                        sourceUrl
                        sourceSet
                    }
                }
                socialMedia {
                    type
                    link
                }
            }
            service {
                serviceId
                name
                parent {
                    serviceId
                }
                vendorId
                vendor {
                    name
                }
            }
            services {
                serviceId
                name
                parent {
                    serviceId
                    name
                }
                vendorId
                vendor {
                    name
                }
            }
        }
    }
`;
