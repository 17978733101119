import React from 'react';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { TeaserThumbnail } from 'atoms';
import { DOMAIN_BASE, ROUTES } from 'config/Nav';
import clsx from 'clsx';
import useStyles from './styles';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import BaseLink from '@elevatormedia/duffel-bag/dist/atoms/BaseLink';
import { Media } from '@elevatormedia/duffel-bag/dist/types/media';
import Card from '@elevatormedia/duffel-bag/dist/atoms/Card';

const SearchResultPostCard = (props: SearchResultPostCardPropTypes) => {
    const classes = useStyles();

    const { title, slug, description, image } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const postPath = '/' + slug;
    const postUrl = DOMAIN_BASE + postPath;

    const renderCardMedia = () => {
        return (
            <div className={classes.cardMediaContainer}>
                <Link variant={'wrapper'} href={ROUTES.POST.fileApiPath} as={postPath}>
                    <TeaserThumbnail
                        src={image.sourceUrl}
                        srcSet={image.sourceSet}
                        alt={`thumbnail for ${title}`}
                        useSrcSet={Boolean(image.sourceSet)}
                    />
                </Link>
            </div>
        );
    };

    const renderCardContent = () => {
        return (
            <div className={classes.cardContentContainer}>
                <BaseLink
                    href={ROUTES.POST.fileApiPath}
                    as={postPath}
                    className={clsx(classes.linkBase, classes.titleSearchLink)}
                >
                    {title}
                </BaseLink>
                <div className={classes.linkContainer}>
                    <BaseLink
                        href={ROUTES.POST.fileApiPath}
                        as={postPath}
                        className={clsx(classes.linkBase, classes.nakedLink)}
                    >
                        {postUrl}
                    </BaseLink>
                </div>

                <div className={classes.descriptionContainer}>
                    <Typography
                        color={'textSecondary'}
                        variant={isMobile ? 'body2' : 'body1'}
                        display={'block'}
                        noWrap
                        className={classes.excerpt}
                    >
                        {description}
                    </Typography>
                </div>
            </div>
        );
    };

    return (
        <Card>
            <div className={classes.cardInnerContainer}>
                {renderCardMedia()}
                {renderCardContent()}
            </div>
        </Card>
    );
};

export type SearchResultPostCardPropTypes = {
    title: string;
    slug: string;
    description: string;
    image: Media;
};

export default SearchResultPostCard;
