import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import { FeaturedPostCard } from 'molecules';
import { PostCardInfo } from 'types/post';
import { Skeleton } from '@material-ui/lab';
import { reportError } from 'lib/errors';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';

/**
 * Posts are rendered in a column basis:
 * | 1 | 3 |
 * | 2 | 4 |
 */
const FeaturedPostGrid = (props: FeaturedPostGridPropTypes) => {
    const classes = useStyles();

    const {
        position1Post,
        position2Post,
        position3Post,
        position4Post,
        isLoading,
        error,
    } = props;

    if (error) {
        return (
            <LoadingErrorDisclaimer
                error={error}
                failedTarget={'featured posts'}
                reportError={reportError}
            />
        );
    }

    // TODO: abstract this out so we have one global skeleton value set that can be
    // imported where necessary (needs to probably be formalized for stories too)
    const skeletonDataPlaceholder: PostCardInfo = {
        title: '',
        image: {
            mediaId: 'e647bc92-ee7c-44aa-9ed3-ec5e1791627d',
            path: '2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg',
            sourceUrl:
                'https://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=400&h=300&ixlib=js-2.1.2&s=b43ca36e9ae014eefa63c9fafa0c8d5d',
            sourceSet:
                'https://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=100&ixlib=js-2.1.2&s=d11e388e45c8825f19f97e878b453ea1 100w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=116&ixlib=js-2.1.2&s=94bc0315bde9773f73c25b5e55e62fe8 116w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=134&ixlib=js-2.1.2&s=97a151ff076d56f030172490462d6c48 134w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=156&ixlib=js-2.1.2&s=b1cdd44aef8234e1b9e8b4ea1b6fc241 156w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=182&ixlib=js-2.1.2&s=f40a2ba5c5407fac1f310247d18f59b7',
        },
        slug: '',
        type: null,
        publishedAt: '',
        authors: [],
        categories: [],
    };

    return (
        <section className={classes.root}>
            <GridContainer direction={'row'}>
                <GridItem xs={12} sm={6} md={7} lg={8}>
                    {/** Position 1 */}
                    {isLoading ? (
                        <Skeleton
                            variant="rect"
                            animation="pulse"
                            className={classes.skeletonLeft}
                        >
                            <div>
                                <FeaturedPostCard
                                    {...skeletonDataPlaceholder}
                                    position={1}
                                />
                            </div>
                        </Skeleton>
                    ) : (
                        <div
                            className={clsx(classes.bottomPadding, classes.rightPadding)}
                        >
                            <FeaturedPostCard {...position1Post} position={1} />
                        </div>
                    )}

                    {/** Position 2 */}
                    {isLoading ? (
                        <Skeleton
                            variant="rect"
                            animation="pulse"
                            className={classes.skeletonLeft}
                        >
                            <div>
                                <FeaturedPostCard
                                    {...skeletonDataPlaceholder}
                                    position={2}
                                />
                            </div>
                        </Skeleton>
                    ) : (
                        <div className={classes.rightPadding}>
                            <FeaturedPostCard {...position2Post} position={2} />
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={6} md={5} lg={4}>
                    {/** Position 3 */}
                    {isLoading ? (
                        <Skeleton
                            variant="rect"
                            animation="pulse"
                            width="100%"
                            className={classes.skeletonRight}
                        >
                            <div>
                                <FeaturedPostCard
                                    {...skeletonDataPlaceholder}
                                    position={3}
                                />
                            </div>
                        </Skeleton>
                    ) : (
                        <div className={classes.bottomPadding}>
                            <FeaturedPostCard {...position3Post} position={3} />
                        </div>
                    )}

                    {/** Position 4 */}
                    {isLoading ? (
                        <Skeleton
                            variant="rect"
                            animation="pulse"
                            className={classes.skeletonRight}
                        >
                            <div>
                                <FeaturedPostCard
                                    {...skeletonDataPlaceholder}
                                    position={4}
                                />
                            </div>
                        </Skeleton>
                    ) : (
                        <div>
                            <FeaturedPostCard {...position4Post} position={4} />
                        </div>
                    )}
                </GridItem>
            </GridContainer>
        </section>
    );
};

export type FeaturedPostGridPropTypes = {
    position1Post: PostCardInfo;
    position2Post: PostCardInfo;
    position3Post: PostCardInfo;
    position4Post: PostCardInfo;
    isLoading: boolean;
    error?: any;
};

export default FeaturedPostGrid;
