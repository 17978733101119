import React, { ChangeEvent } from 'react';
import dynamic from 'next/dynamic';
import { FormikProps } from 'formik';
import { AdFormValues } from './AdForm';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import useTheme from '@material-ui/core/styles/useTheme';
import useStyles from './styles';
import {
    ImageUploaderPropTypes,
    ImageUploaderVariant,
} from 'molecules/ImageUploader/ImageUploader';
import TotalBar from 'atoms/TotalBar';
import { CampaignType } from 'types/campaign';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import RadioGroup from '@elevatormedia/duffel-bag/dist/atoms/RadioGroup';
const DatePicker = dynamic(
    () => import('@elevatormedia/duffel-bag/dist/atoms/DatePicker'),
);

// NOTE ImageUploader implements Blob which is not defined on server
// this was not an issue on otis because most buttons aren't rendered via initial state
const ImageUploaderNoSsr = dynamic<ImageUploaderPropTypes<ImageUploaderVariant>>(
    () => import('../../molecules/ImageUploader'),
    { ssr: false },
);

const imageHelperText = `Please upload a .zip file containing ALL of the following ad sizes 
970x250, 300x600, 300x250, 300x50 ( png / jpg / gif, 1mb max size per file) 
OR creative assets (photos, logos, cover art files, etc) to be used in the ad designs.`;

const Fields = (props: FieldsProps) => {
    const classes = useStyles();
    const {
        values,
        handleChange,
        setFieldValue,
        touched,
        setFieldTouched,
        errors,
        handleSubmit,
        onBackClick,
        isSubmitting,
        validateForm,
        campaignTypes,
        totalCost,
        updateTotalCost,
    } = props;

    const theme = useTheme();

    React.useEffect(() => {
        validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fileInputField = React.useRef(null);

    const startDateOptions = ['Soonest Available', 'Choose Date'];
    const [startDateMode, setStartDateMode] = React.useState('Soonest Available');

    const handleInputChange = (event: any, fieldName: string): void => {
        setFieldTouched(fieldName, true);
        handleChange(event);
    };

    const handleStartDateToggle = (_e: ChangeEvent, value: string) => {
        if (value === 'Soonest Available') setFieldValue('startDate', 'default');
        else setFieldValue('startDate', new Date());

        setStartDateMode(value);
    };

    const designProvidedOptions = [
        `I'll Provide Ad Files`,
        `Ad Design by ELEVATOR - $150`,
    ];

    const handleDesignProvided = (_e: ChangeEvent, value: string) => {
        const designProvided = value === designProvidedOptions[0];

        const campaignSelected = campaignTypes.find(
            (type) => type.typeId === values.campaignType,
        );
        if (!designProvided && values.designProvided)
            updateTotalCost(campaignSelected.cost + 15000);
        else if (designProvided) updateTotalCost(campaignSelected.cost);

        setFieldValue('designProvided', designProvided);
    };

    const handleCampaignType = (event: any) => {
        const campaignSelected = campaignTypes.find(
            (type) => type.typeId === event.target.value,
        );

        if (campaignSelected) {
            let newCost = campaignSelected.cost;
            if (!values.designProvided) newCost += 15000;
            updateTotalCost(newCost);
        }

        handleChange(event);
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <GridContainer direction={'column'} justifyContent={'flex-start'}>
                <GridItem xs={12} paddingY={2}>
                    <TextField
                        id={'campaignName'}
                        name={'campaignName'}
                        label={'Campaign Name'}
                        fullWidth
                        required
                        value={values.campaignName}
                        onChange={(e) => handleInputChange(e, 'campaignName')}
                        error={touched.campaignName && Boolean(errors.campaignName)}
                        helperText={touched.campaignName ? errors.campaignName : ''}
                    />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <TextField
                        id={'campaignType'}
                        name={'campaignType'}
                        label={'Campaign Length'}
                        select
                        fullWidth
                        required
                        value={values.campaignType}
                        onChange={(e) => handleInputChange(e, 'campaignType')}
                        error={touched.campaignType && Boolean(errors.campaignType)}
                        helperText={touched.campaignType ? errors.campaignType : ''}
                    >
                        {campaignTypes.map(({ typeId, name }) => (
                            <MenuItem key={typeId} value={typeId}>
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <RadioGroup
                        label={
                            <div className={classes.inputLabelContainer}>
                                <InputLabel
                                    required
                                    shrink
                                    className={classes.inputLabel}
                                >
                                    Campaign Start Date
                                </InputLabel>
                            </div>
                        }
                        name={'startDate'}
                        ariaLabel={'Campaign Start Date'}
                        selectedValue={startDateMode}
                        handleChange={handleStartDateToggle}
                        values={startDateOptions}
                    />
                    {values.startDate !== 'default' && (
                        <DatePicker
                            pickerType="date"
                            dateValue={values.startDate}
                            onDateChange={(newDate) => {
                                setFieldValue('startDate', newDate);
                                setFieldTouched('startDate', true);
                            }}
                        />
                    )}
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <RadioGroup
                        label={
                            <div className={classes.inputLabelContainer}>
                                <InputLabel
                                    required
                                    shrink
                                    className={classes.inputLabel}
                                >
                                    Add Design
                                </InputLabel>
                            </div>
                        }
                        name={'designProvided'}
                        ariaLabel={'Add Design'}
                        selectedValue={
                            values.designProvided
                                ? designProvidedOptions[0]
                                : designProvidedOptions[1]
                        }
                        handleChange={handleDesignProvided}
                        values={designProvidedOptions}
                    />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <ImageUploaderNoSsr
                        label={'Image asset(s) *'}
                        error={touched.files && Boolean(errors.files)}
                        helperText={
                            touched.files
                                ? errors.files
                                    ? errors.files.toString()
                                    : imageHelperText
                                : imageHelperText
                        }
                        variant={'multiple'}
                        inputProps={{
                            id: 'files',
                            // allow .zip and image files
                            accept: 'zip,image/*',
                            ref: fileInputField,
                            onChange: (event: any) => {
                                setFieldTouched('files', true, false); // set as touched, but don't validate per next line
                                setFieldValue('files', new Array(...event.target.files));
                            },
                        }}
                        onRemoveImage={(index: number) => {
                            values.files.splice(index, 1);
                            setFieldValue('files', new Array(...values.files));
                        }}
                        files={values.files}
                    />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <TextField
                        id={'clickThroughUrl'}
                        name={'clickThroughUrl'}
                        label={'Click-Through URL'}
                        placeholder={'https://'}
                        fullWidth
                        required
                        value={values.clickThroughUrl}
                        onChange={(e) => handleInputChange(e, 'clickThroughUrl')}
                        error={touched.clickThroughUrl && Boolean(errors.clickThroughUrl)}
                        helperText={
                            errors.clickThroughUrl
                                ? errors.clickThroughUrl
                                : 'Please provide url to direct clicks'
                        }
                    />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <TotalBar total={totalCost / 100} />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <Button variant={'text'} size={'small'} onClick={() => onBackClick()}>
                        back
                    </Button>
                    <Button
                        variant={'primary'}
                        size={'small'}
                        onClick={() => handleSubmit()}
                        disabled={isSubmitting}
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        next
                    </Button>
                </GridItem>
            </GridContainer>
        </form>
    );
};

export type FieldsProps = FormikProps<AdFormValues> & {
    onBackClick: Function;
    campaignTypes: Array<CampaignType>;
    totalCost: number;
    updateTotalCost: (campaignCost: number) => void;
};

export default Fields;
