import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'unset',
        },
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    icon: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.text.secondary,
    },
    title: {
        textTransform: 'uppercase',
        paddingBottom: theme.spacing(1),
    },
    tagsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    tagChip: {
        height: 'auto',
        textTransform: 'uppercase',
        borderRadius: 0,
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary.main,
        },
        '&:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
        marginBottom: theme.spacing(1),
    },
    tagLabel: {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(1),
    },
});

export default makeStyles(styles);
