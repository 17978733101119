import React from 'react';
import useStyles from './styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

const disclaimerText = `We use 🍪 cookies to secure your sessions and data while using our application(s). If you have questions about our cookie usage, please contact support at support@elevatormag.com.`;
const disclaimerDate = '02-17-2021';
export const disclaimerKey = `cookieDisclaimer_${disclaimerDate}`;

const CookieDisclaimer = () => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        shouldRenderDisclaimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [renderDisclaimer, setRenderDisclaimer] = React.useState(false);

    const shouldRenderDisclaimer = () => {
        if (!window) return false;

        if (navigator.cookieEnabled && typeof window.localStorage !== 'undefined') {
            let hasReadDisclaimer = window.localStorage.getItem(disclaimerKey);
            setRenderDisclaimer(hasReadDisclaimer ? false : true);
        } else {
            enqueueSnackbar(`Site missing permissions`, {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            setRenderDisclaimer(true);
        }
    };

    const setDisclaimerAsRead = () => {
        if (!window) return;

        window.localStorage.setItem(disclaimerKey, 'true');
        setRenderDisclaimer(false);
    };

    return (
        renderDisclaimer && (
            <Box
                position={'fixed'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.cookieDisclaimer}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    className={classes.cookieDisclaimerContent}
                >
                    <Typography className={classes.cookieDisclaimerText}>
                        {disclaimerText}
                    </Typography>
                    <Button
                        onClick={setDisclaimerAsRead}
                        variant={'outlined'}
                        className={classes.cookieDisclaimerButton}
                    >
                        Okay
                    </Button>
                </Box>
            </Box>
        )
    );
};

export default CookieDisclaimer;
