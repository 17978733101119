import React, { useEffect } from 'react';
import useStyles from '../styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Check from 'mdi-material-ui/Check';
import Close from 'mdi-material-ui/Close';
import { PasswordFeedback } from 'molecules';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import {
    isValidPassword,
    notTooGuessable,
} from '@elevatormedia/duffel-bag/dist/utils/auth';
import { useFormikContext } from 'formik';
import { ChangePasswordFormIndexProps } from '.';

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
    const { togglePasswordModalOpen } = props;

    const {
        values,
        touched,
        errors,
        handleSubmit,
        isValid,
        setFieldTouched,
        setFieldValue,
        isSubmitting,
        validateForm,
    } = useFormikContext<ChangePasswordFormikValues>();

    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(799));

    useEffect(() => {
        validateForm();
    }, [values, validateForm]);

    /**
     * Handler func for field changes in the formik form. Will set the new state value
     * as well a mark the field as "touched" for validation purposes.
     */
    const handleInputChange = async (fieldName: string, event: any) => {
        // if value is being set imperatively, use the standard React event.target.value scheme.
        setFieldValue(fieldName, event.target.value);
        setFieldTouched(fieldName, true);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const showPopover = Boolean(anchorEl);

    const renderMobileHeader = () => {
        return (
            <AppBar elevation={0} position={'fixed'} className={classes.appbar}>
                <div className={classes.appBarToolbar}>
                    <IconButton
                        onClick={togglePasswordModalOpen}
                        color={'inherit'}
                        aria-label={'close'}
                    >
                        <Close />
                    </IconButton>
                    <Typography
                        className={classes.mobileDialogueTitleColor}
                        variant={'h6'}
                    >
                        Change Password
                    </Typography>
                </div>
            </AppBar>
        );
    };

    const renderPasswordFieldEndAdornment = () => {
        return (
            <InputAdornment position="end">
                <Box paddingRight={1}>
                    <Check fontSize={'small'} className={classes.checkmark} />
                </Box>
            </InputAdornment>
        );
    };

    return (
        <>
            {!mobile && (
                <DialogTitle id={'change-password-title'}>Change Password</DialogTitle>
            )}
            {mobile && renderMobileHeader()}
            <Box paddingBottom={2} paddingX={2}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <DialogContent className={classes.dialogueContentContainer}>
                        <Box>
                            <TextField
                                id={'oldPassword'}
                                label={'Current Password'}
                                type={'password'}
                                autoComplete={'current-password'}
                                fullWidth
                                required
                                value={values.oldPassword}
                                onChange={(event) =>
                                    handleInputChange('oldPassword', event)
                                }
                                error={touched.oldPassword && Boolean(errors.oldPassword)}
                                helperText={touched.oldPassword ? errors.oldPassword : ''}
                                disabled={isSubmitting}
                            />
                            <ClickAwayListener onClickAway={handlePopoverClose}>
                                <Typography
                                    variant={'caption'}
                                    color={'primary'}
                                    onClick={handlePopoverOpen}
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    I forgot my current password. What do I do?
                                </Typography>
                            </ClickAwayListener>
                            <Popover
                                open={showPopover}
                                anchorEl={anchorEl}
                                className={classes.popover}
                                classes={{
                                    paper: classes.popoverContent,
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <Typography variant="caption">
                                    To change your password without providing your current
                                    password, you will need to signout and request a reset
                                    password code. Your email must be verified.
                                </Typography>
                            </Popover>
                        </Box>
                        <Box paddingTop={3}>
                            <TextField
                                id={'newPassword'}
                                label={'New Password'}
                                type={'password'}
                                autoComplete={'new-password'}
                                fullWidth
                                required
                                value={values.newPassword}
                                onChange={(event) =>
                                    handleInputChange('newPassword', event)
                                }
                                error={touched.newPassword && Boolean(errors.newPassword)}
                                helperText={
                                    <PasswordFeedback password={values.newPassword} />
                                }
                                disabled={isSubmitting}
                                InputProps={{
                                    endAdornment:
                                        isValidPassword(values.newPassword) &&
                                        notTooGuessable(values.newPassword) &&
                                        renderPasswordFieldEndAdornment(),
                                }}
                            />
                        </Box>
                        <Box paddingTop={3}>
                            <TextField
                                id={'confirmPassword'}
                                label={'Confirm Password'}
                                type={'password'}
                                autoComplete={'new-password'}
                                fullWidth
                                required
                                value={values.confirmPassword}
                                onChange={(event) =>
                                    handleInputChange('confirmPassword', event)
                                }
                                error={
                                    touched.confirmPassword &&
                                    Boolean(errors.confirmPassword)
                                }
                                helperText={
                                    touched.confirmPassword ? errors.confirmPassword : ''
                                }
                                disabled={isSubmitting}
                                InputProps={{
                                    endAdornment:
                                        touched.confirmPassword &&
                                        !errors.confirmPassword &&
                                        renderPasswordFieldEndAdornment(),
                                }}
                            />
                        </Box>
                        <Box paddingTop={5}>
                            <Button
                                fullWidth
                                size={'large'}
                                type={'submit'}
                                onClick={() => handleSubmit()}
                                disabled={!isValid || isSubmitting}
                                color={'primary'}
                                variant={'contained'}
                            >
                                Update Password
                            </Button>
                        </Box>
                    </DialogContent>
                </form>
            </Box>
        </>
    );
};

export interface ChangePasswordFormProps extends ChangePasswordFormIndexProps {}

export interface ChangePasswordFormikValues {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export default ChangePasswordForm;
