import React from 'react';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import MySubmissions from './MySubmissions';
import { ROUTES } from 'config/Nav';
import { MY_SUBMISSIONS } from 'lib/graphql/queries/submissions';
import { useRouter } from 'next/router';
import Loading from '@elevatormedia/duffel-bag/dist/atoms/Loading';

const MySubmissionsWithData = () => {
    const theme = useTheme();
    const router = useRouter();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    // TODO navigate if not signed in
    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    const { data, loading, networkStatus, error, refetch } = useQuery(MY_SUBMISSIONS, {
        notifyOnNetworkStatusChange: true,
    });

    const submissions = data && data.mySubmissions ? data.mySubmissions : [];

    const isLoading = loading || networkStatus === 4;

    const onRetryQueryClick = () => {
        refetch();
    };

    const onStartNewSubmission = () => {
        router.push(ROUTES.SUBMIT.to);
    };

    const renderErrorState = () => {
        return (
            <Box
                minHeight={'70vh'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Typography variant={'h5'} gutterBottom>
                    Oh snap!
                </Typography>
                <Box maxWidth={mobile ? '80%' : '50%'}>
                    <Typography
                        variant={'body1'}
                        align={'center'}
                        gutterBottom
                        color={'textSecondary'}
                    >
                        Something went wrong while retrieiving your submissions.
                    </Typography>
                </Box>
                <Box paddingTop={2}>
                    <Button
                        variant={'text'}
                        color={'primary'}
                        onClick={onRetryQueryClick}
                    >
                        Retry
                    </Button>
                </Box>
            </Box>
        );
    };

    const renderEmptyState = () => {
        return (
            <Box
                minHeight={'70vh'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Typography variant={'h5'} gutterBottom>
                    No Submissions
                </Typography>
                <Box maxWidth={mobile ? '80%' : '50%'}>
                    <Typography
                        variant={'body1'}
                        color={'textSecondary'}
                        align={'center'}
                        gutterBottom
                    >
                        Your submissions will appear here any time you make one. Need to
                        start a new submission? Click the button below!
                    </Typography>
                </Box>
                <Box paddingTop={2}>
                    <Button
                        variant={'text'}
                        color={'primary'}
                        onClick={onStartNewSubmission}
                    >
                        Start a new submission
                    </Button>
                </Box>
            </Box>
        );
    };

    const renderContent = () => {
        if (isLoading) {
            return <Loading />;
        } else if (error) {
            return renderErrorState();
        } else {
            if (submissions.length < 1 && !isLoading) {
                return renderEmptyState();
            } else {
                return <MySubmissions submissions={submissions} />;
            }
        }
    };

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignContent={'center'}
            alignItems={'center'}
        >
            <Box maxWidth={theme.static_component_sizes.content_max_width} width={'100%'}>
                {renderContent()}
            </Box>
        </Box>
    );
};

export default MySubmissionsWithData;
