import React, { FormEvent, ChangeEvent } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import useStyles from './styles';
import clsx from 'clsx';

const Searchbar: React.FC<SearchBarProps> = ({
    searchInput,
    handleSearchInput,
    onSubmit,
    rootContainerClass,
}) => {
    const classes = useStyles();
    const inputRef = React.useRef(null);

    const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
        handleSearchInput(value);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        inputRef.current.blur();
        event.preventDefault();
        onSubmit(event);
    };

    const handleClear = () => {
        handleSearchInput('');
    };

    return (
        <div className={clsx(classes.searchbarRootContainer, rootContainerClass)}>
            <form onSubmit={handleSubmit} className={classes.inputStyling}>
                <InputBase
                    type="text"
                    id="search"
                    inputRef={inputRef}
                    value={searchInput}
                    onChange={handleChange}
                    className={classes.inputStyling}
                    inputProps={{
                        className: classes.inputStyling,
                        'aria-label': 'search',
                    }}
                    placeholder="Search"
                    startAdornment={
                        <InputAdornment position="start">
                            <Search fontSize="small" />
                        </InputAdornment>
                    }
                    endAdornment={
                        searchInput.length > 0 && (
                            <InputAdornment position="end" onClick={handleClear}>
                                <Clear fontSize="small" className={classes.clearIcon} />
                            </InputAdornment>
                        )
                    }
                />
            </form>
        </div>
    );
};

export interface SearchBarProps {
    searchInput: string;
    handleSearchInput: (input: string) => void;
    onSubmit: Function;
    rootContainerClass?: string;
}

export default Searchbar;
