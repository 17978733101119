import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import { useRouter } from 'next/router';
import { ROUTES } from 'config/Nav';
import { INTERNAL_SERVER_ERROR_IMAGE, PAGE_NOT_FOUND_IMAGE } from './Error.utils';
import clsx from 'clsx';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';

const Error = (props: ErrorPropTypes) => {
    const classes = useStyles();
    const { statusCode } = props;

    const router = useRouter();

    const onGoHomeClick = () => {
        router.replace(ROUTES.HOME.to);
    };

    const getErrorMessageComponent = () => {
        if (statusCode && statusCode === 404) {
            return (
                <>
                    <Typography
                        align={'center'}
                        variant={'h4'}
                        className={classes.errorTitle}
                        gutterBottom
                    >
                        404 page not found
                    </Typography>
                    <Typography
                        align={'center'}
                        variant={'body1'}
                        className={classes.bodyText}
                    >
                        Looks like that floor doesn&apos;t exist
                    </Typography>
                </>
            );
        } else {
            return (
                <>
                    <Typography
                        align={'center'}
                        variant={'h4'}
                        className={classes.errorTitle}
                        gutterBottom
                    >
                        Oh snap! Something went wrong
                    </Typography>
                    <Typography
                        align={'center'}
                        variant={'body1'}
                        className={classes.bodyText}
                    >
                        Your error has been reported to the ELEVATOR dev team
                    </Typography>
                </>
            );
        }
    };

    const getBackgroundImageVariant = () => {
        if (statusCode && statusCode === 404) {
            return classes['404Background'];
        } else {
            // unknown error
            return classes['500Background'];
        }
    };

    const getGIPHYAttribution = () => {
        if (statusCode && statusCode === 404) {
            return PAGE_NOT_FOUND_IMAGE.href;
        } else {
            // unknown error
            return INTERNAL_SERVER_ERROR_IMAGE.href;
        }
    };

    return (
        <div className={clsx(classes.root, getBackgroundImageVariant())}>
            <div className={classes.filterContainer}>
                <div className={classes.textContainer}>
                    {getErrorMessageComponent()}
                    <div className={classes.buttonContainer}>
                        <Button variant={'secondary'} onClick={onGoHomeClick}>
                            Take me home
                        </Button>
                    </div>
                </div>

                <a href={getGIPHYAttribution()} className={classes.giphyRef}>
                    via GIPHY
                </a>
            </div>
        </div>
    );
};

export type ErrorPropTypes = {
    statusCode?: any;
};

export default Error;
