import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    contentContainer: {
        height: '100%',
        minHeight: '60vh',
        width: '100%',
        maxWidth: theme.static_component_sizes.content_max_width,
        padding: theme.spacing(1, 0, 0, 1),
    },
    extraBold: {
        fontWeight: 700,
    },
    content: {
        margin: theme.spacing(1),
    },
    linkContainer: {
        paddingTop: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
    },
    linkText: {
        textTransform: 'uppercase',
        fontWeight: 700,
    },
    bottomButton: {
        marginTop: theme.spacing(1),
    },
});

export default makeStyles(styles);
