import React from 'react';
import useStyles from './styles';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

export type ActionConfig = {
    label: string;
    onClick: (() => any) | React.MouseEventHandler<any>;
    disabled?: boolean;
};

const ActionButtons = (props: ActionButtonsProps) => {
    const { submit, cancel, paddingTop } = props;

    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const containerStyles = [classes.container];
    if (paddingTop) {
        containerStyles.push(classes.padding);
    }

    return (
        <div className={clsx(containerStyles)}>
            {cancel && (
                <Button
                    className={clsx(classes.cancelButton, classes.button)}
                    variant={'primary'}
                    fullWidth={mobile}
                    size={'large'}
                    onClick={cancel.onClick}
                    disabled={cancel.disabled}
                >
                    {cancel.label}
                </Button>
            )}
            {submit && (
                <Button
                    className={classes.button}
                    variant={'contained'}
                    fullWidth={mobile}
                    size={'large'}
                    type={'submit'}
                    onClick={submit.onClick}
                    disabled={submit.disabled}
                >
                    {submit.label}
                </Button>
            )}
        </div>
    );
};

export type ActionButtonsProps = {
    submit?: ActionConfig;
    cancel?: ActionConfig;
    paddingTop?: boolean;
};

ActionButtons.defaultProps = {
    paddingTop: true,
};

export default ActionButtons;
