import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.common.white,
            padding: 0,
            height: 80,
            width: '100%',
            maxWidth: theme.static_component_sizes.content_max_width,
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        horizontalStatus: {
            width: 16,
            height: 80,
        },
        colorPending: {
            background: theme.palette.submissionStatuses.review,
        },
        colorApproved: {
            background: theme.palette.submissionStatuses.approved,
        },
        colorRejected: {
            background: theme.palette.submissionStatuses.rejected,
        },
        colorFlagged: {
            background: theme.palette.submissionStatuses.flagged,
        },
        attachedImage: {
            height: 80,
            width: 80,
            objectFit: 'cover',
        },
        textContainer: {
            marginLeft: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: 300,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('md')]: {
                marginLeft: theme.spacing(2),
                maxWidth: 200,
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(1),
                maxWidth: 122,
            },
        },
        dateAndType: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.text.secondary,
        },
        submissionTitle: {
            fontWeight: 500,
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.common.black,
        },
        addOnText: {
            color: theme.palette.text.secondary,
        },
    }),
);

export default useStyles;
