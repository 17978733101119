import React, { ChangeEvent, ChangeEventHandler, MouseEvent } from 'react';
import { useQuery } from '@apollo/client';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { SEARCH_USERS } from 'lib/graphql/queries/user';
import useStyles from './styles';
import AddNewMenuItem from './AddNewMenuItem';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';
import dynamic from 'next/dynamic';
import { SuggestionsFetchRequestedParams } from 'react-autosuggest';

const Autosuggest = dynamic(() => import('react-autosuggest'));

const AutoSuggestInput = (props: AutoSuggestInputProps) => {
    const {
        id,
        label, // label for the text field input,
        placeholder,
        disableUnderline,
        onUserSelected,
        textFieldInput,
        setTextFieldInput,
        disabled,
        isAddCreditOption, // for the extra menu item at end of suggestions list
        handleManualAddNew,
    } = props;

    const classes = useStyles();

    const [inputHasFocus, setInputHasFocus] = React.useState(false);

    // Search Query
    const { data, refetch } = useQuery(SEARCH_USERS, {
        variables: {
            input: '',
        },
    });

    let UsernameSuggestions = data && data.searchUsers ? data.searchUsers : [];

    /**
     * Renders a single list item based on the list of generated suggestions
     */
    const renderSuggestion = (
        suggestion: any,
        { query, isHighlighted }: { query: string; isHighlighted: boolean },
    ) => {
        const matches = match(suggestion.preferredUsername, query);
        const parts = parse(suggestion.preferredUsername, matches);

        let avatarSrc = {};

        if (suggestion.avatar)
            avatarSrc = {
                src: suggestion.avatar.sourceUrl,
                srcSet: suggestion.avatar.sourceSet,
            };

        return (
            <MenuItem
                selected={isHighlighted}
                // prevent the click causing the input to be blurred
                onMouseDown={(e: MouseEvent) => e.preventDefault()}
                component={'div'}
            >
                <ListItemAvatar>
                    <Avatar {...avatarSrc}>{suggestion.preferredUsername[0]}</Avatar>
                </ListItemAvatar>
                {/**
                    Renders the text-editing distance in bold, and completion in 
                    default text.
                 */}
                <ListItemText
                    primary={parts.map((part, index) => {
                        return part.highlight ? (
                            <Typography
                                key={String(index)}
                                component={'span'}
                                className={classes.editDistanceText}
                            >
                                {part.text}
                            </Typography>
                        ) : (
                            <Typography
                                variant={'body2'}
                                key={String(index)}
                                component={'span'}
                            >
                                {part.text}
                            </Typography>
                        );
                    })}
                    secondary={suggestion.stageName}
                />
            </MenuItem>
        );
    };

    /**
     * Utility function, retrieves the name attribute from a suggestion object.
     */
    const getSuggestionValue = (suggestion: any) => {
        return suggestion.preferredUsername;
    };

    const handletextFieldInputChange = (
        event: any,
        { newValue }: { newValue: string },
    ) => {
        setTextFieldInput(newValue);
    };

    const handleSuggestionSelected = (
        e: ChangeEvent,
        { suggestionValue }: { suggestionValue: string },
    ) => {
        e.preventDefault();
        onUserSelected(
            data.searchUsers.find(
                (values: { preferredUsername: string }) =>
                    values.preferredUsername === suggestionValue,
            ),
        );
    };

    /**
     * Renders the input component. In our case it is a chip input
     */
    const renderInput = ({
        value,
        onChange,
        ref,
        ...other
    }: {
        value: string;
        onChange: ChangeEventHandler;
        ref: any;
    }) => {
        return (
            <TextField
                value={value}
                label={label}
                onChange={onChange}
                fullWidth
                InputProps={{
                    disableUnderline: disableUnderline,
                    startAdornment: (
                        <InputAdornment position="start">
                            <div className={classes.searchIcon}>
                                <Search color={'action'} fontSize={'small'} />
                            </div>
                        </InputAdornment>
                    ),
                }}
                disabled={disabled}
                {...other}
            />
        );
    };

    const renderSuggestionContainer = ({
        containerProps,
        children,
        query,
        isHighlighted,
    }: {
        containerProps: any;
        children: any;
        query: string;
        isHighlighted: boolean;
    }) => {
        // There are results to show
        if (children !== null && inputHasFocus) {
            return (
                <Paper elevation={3} {...containerProps}>
                    {children}
                    <AddNewMenuItem
                        isAddCreditOption={isAddCreditOption}
                        isHighlighted={isHighlighted}
                        handleManualAddNew={handleManualAddNew}
                    />
                </Paper>
            );
        } else if (children === null && inputHasFocus && query.length !== 0) {
            // There are no results to show but we want to tell the user
            return (
                <Paper
                    elevation={3}
                    {...containerProps}
                    className={classes.noResultsFoundContainer}
                >
                    <Typography variant={'caption'}>
                        No results found for &quot;{query}.&quot; Why not try adding them
                        manually?
                    </Typography>
                    <AddNewMenuItem
                        isAddCreditOption={isAddCreditOption}
                        isHighlighted={isHighlighted}
                        handleManualAddNew={handleManualAddNew}
                    />
                </Paper>
            );
        } else {
            // Render nothing
            return null;
        }
    };

    return (
        <Autosuggest
            id={id}
            theme={{
                container: classes.container,
                input: classes.input,
                suggestionsContainer: classes.suggestionsContainer,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            renderInputComponent={renderInput}
            suggestions={UsernameSuggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            // What should happen when the user selects a value from the
            // list of suggestions.
            onSuggestionSelected={handleSuggestionSelected}
            focusInputOnSuggestionClick={false}
            highlightFirstSuggestion
            inputProps={{
                value: textFieldInput,
                placeholder: { placeholder },
                onChange: handletextFieldInputChange,
                onBlur: () => {
                    setInputHasFocus(false);
                },
                onFocus: () => setInputHasFocus(true),
                ...props,
            }}
            renderSuggestionsContainer={renderSuggestionContainer}
            onSuggestionsFetchRequested={({
                value,
                reason,
            }: SuggestionsFetchRequestedParams) => {
                if (value.length >= 3) {
                    refetch({ input: value });
                }
            }}
        />
    );
};

export interface AutoSuggestInputProps {
    id: string;
    label?: string;
    placeholder: string;
    disableUnderline?: boolean;
    onUserSelected: Function;
    textFieldInput: string;
    setTextFieldInput: (input: string) => void;
    disabled: boolean;
    isAddCreditOption?: boolean;
    currentUser?: User;
    handleManualAddNew: Function;
}

export default AutoSuggestInput;
