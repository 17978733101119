import React from 'react';
import useStyles from './styles';
import withLayout from 'hocs/withLayout';
import { PageProps } from 'types/page';
import Post from './Post';
import { Post as PostType } from 'types/post';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PopularPostList from 'organisms/PopularPostList';
import { reportError } from 'lib/errors';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';

const Index = (props: PostIndexProps) => {
    const classes = useStyles();

    let { post, slug, error, hasInstagramEmbed, hasTwitterEmbed, isLoading } = props;

    if (error) {
        return (
            <div className={classes.errorLoadingDisclaimer}>
                <LoadingErrorDisclaimer
                    error={error}
                    failedTarget={'post'}
                    reportError={reportError}
                />
            </div>
        );
    }

    if (!post) {
        return (
            <div className={classes.errorRootContainer}>
                <div className={classes.textContainer}>
                    <Typography align={'center'} variant={'h4'} gutterBottom>
                        404 post not found
                    </Typography>
                    <Typography
                        align={'center'}
                        variant={'body1'}
                        className={classes.bodyText}
                    >
                        We had trouble finding that post
                    </Typography>
                    <div className={classes.buttonContainer}>
                        <Link variant={'wrapper'} href={ROUTES.HOME.to}>
                            <Button variant={'primary'} onClick={() => {}}>
                                Take me home
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    const authors = post.authors.map((author) => author.preferredUsername);

    return (
        <Box paddingBottom={8} className={classes.root}>
            <Seo
                type={'article'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: post.title,
                    description: post.description + ' -- by ' + authors.join(', '),
                    path: '/' + slug,
                    image: {
                        url: post.image.sourceUrl,
                        alt: post.description,
                    },
                    article: {
                        publishedTime: post.publishedAt?.toString() || '',
                        modifiedTime: post.modifiedAt,
                        authors: authors,
                        tags: post.tags.map((tag) => tag.name),
                    },
                }}
            />
            <Post
                post={post}
                slug={slug}
                error={error}
                hasInstagramEmbed={hasInstagramEmbed}
                hasTwitterEmbed={hasTwitterEmbed}
                isLoading={isLoading}
            />
            <PopularPostList />
        </Box>
    );
};

export interface PostIndexProps extends PageProps {
    post?: PostType;
    slug: string;
    error?: any;
    hasInstagramEmbed?: Boolean;
    hasTwitterEmbed?: Boolean;
    isLoading: Boolean;
}

export default withLayout(Index, false, false);
