import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centeredContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        contentHeight: {
            height: '100px',
        },
        loadingMoreContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing(9),
        },
        skeletonContainer: {
            minHeight: '400px',
            height: '100%',
            minWidth: '100%',
            maxWidth: '100%',
        },
    }),
);

export default useStyles;
