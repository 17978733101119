import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        paperRoot: {
            padding: '50px',
            width: '100%',
            maxWidth: '1100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
        },
        headerRoot: {
            width: '100%',
            maxWidth: '650px',
        },
        featureHeaderRoot: {
            maxWidth: '1000px',
        },
        root: {
            width: '100%',
            maxWidth: '650px',
            paddingBottom: theme.spacing(8),
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(4),
            },
        },
        featureRoot: {
            maxWidth: '800px',
            '& img': {
                width: '650px',
                display: 'block',
                margin: 'auto',
            },
        },
        separator: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        shareButtonGroup: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingTop: 0,
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'flex-start',
                paddingTop: theme.spacing(3),
            },
            alignItems: 'flex-start',
        },
        shareIcon: {
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.text.secondary,
        },
        iconContainer: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(2),
        },
        skeleton: {
            transform: 'scale(1,1) !important',
            '&:not(:first-child)': {
                marginLeft: theme.spacing(1),
            },
        },
    });

export default makeStyles(styles);
