import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    paddedContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: theme.spacing(6),
    },
    searchResultListItem: {
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(2),
        },
    },
    sectionHeaderContainer: {
        padding: theme.spacing(4, 0, 1, 0),
    },
    sectionHeaderText: {
        textTransform: 'uppercase',
        fontWeight: 700,
    },
});

export default makeStyles(styles);
