import { SocialMediaType } from '@elevatormedia/duffel-bag';
import isURL from 'validator/lib/isURL';

export const splitSocialMediaLink = (link: string) => {
    const emptyValue = !link;
    if (!emptyValue && isURL(link)) {
        // parse out the username at the end of the url
        const pathIndex = link.lastIndexOf('/');

        // parse out the domian prefix e.g., https://www.youtube.com/c/
        const normalizedPrefix = link.slice(0, pathIndex + 1);

        // get username
        const normalizedUsername = link.slice(pathIndex + 1);

        return [normalizedPrefix, normalizedUsername];
    }

    return [];
};

export const isValidAppleDomain = (value: string) => {
    const emptyValue = !value;
    if (!emptyValue && isURL(value) && isNotMalformedInput(value)) {
        // Narrow url paramaters to whitelisted domains and secure protocols
        return isURL(value, {
            require_host: true,
            host_whitelist: [`www.music.apple.com`, `music.apple.com`],
            // host_whitelist: [`www.applemusic.com`, `applemusic.com`], // This goes to iTunes
        });
    }

    return true;
};

/**
 * Checks the input value for characters that "look" like a url but is actually a
 * malformed url. for example (https:/www.youtube.com) is almost a valid url but is missing
 * a '/'
 */
const isNotMalformedInput = (value: string) => {
    // Check that input value is not empty and not a url
    if (Boolean(value) && !isURL(value)) {
        // Check for anything that looks like a malformed url
        const pattern = /\//gi;
        return !value.match(pattern);
    }

    // otherwise it's good
    return true;
};

/**
 * Checks that the input youtube url is a valid user value
 * Here, we only support recent youtube user links:
 * youtube.com/channel/{channelId}
 * youtube.com/c/{channelId}
 * youtube.com/user/{username}
 *
 * the legacy youtube.com/{username} is not supported
 */
export const whiteListedYoutubePaths = (value: string) => {
    if (Boolean(value) && isURL(value)) {
        const [normalizedPrefix] = splitSocialMediaLink(value);

        if (normalizedPrefix.includes('youtube.com/channel/')) {
            return true;
        }

        if (normalizedPrefix.includes('youtube.com/c/')) {
            return true;
        }

        if (normalizedPrefix.includes('youtube.com/user/')) {
            return true;
        }
        return false;
    }

    // if the value was empty, we say this is a valid link because the value can be empty
    return true;
};

/**
 * Because the user is only entering a username into a social media field, we supply
 * a default url prefix for them.
 */
export const getDefaultSocialMediaPrefixes = (type: SocialMediaType) => {
    switch (type) {
        case 'facebook':
            return 'facebook.com/';
        case 'instagram':
            return 'instagram.com/';
        case 'twitter':
            return 'twitter.com/';
        case 'soundcloud':
            return 'soundcloud.com/';
        case 'youtube':
            return 'Paste YouTube URL';
        case 'spotify':
            return 'open.spotify.com/artist/';
        case 'appleMusic':
            return 'music.apple.com/us/artist/';
        default:
            break;
    }
};

/**
 * Normalize link removes protocols and the www. prefixes and appends a '//'
 * to allow hrefs to open non-www links in a new window rather than appending them
 * to the local domain (e.g, submissions.elevatormag.com/user/test/facebook.com/test)
 */
export const normalizeLink = (linkRaw: string, addPrefix = true) => {
    return (addPrefix ? '//' : '') + linkRaw.replace('https://', '').replace('www.', '');
};
