import React, { MouseEventHandler } from 'react';
import Typography from '@material-ui/core/Typography';
import { SearchResultPostCard } from 'molecules';
import { SearchResultPostCardPropTypes } from 'molecules/SearchResultPostCard/SearchResultPostCard';
import useStyles from './styles';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';

const SearchResultPostList = (props: SearchResultPostListPropTypes) => {
    const { loadLimit, posts, onLoadMore, title } = props;

    const classes = useStyles();

    if (posts.length === 0)
        return (
            <div className={classes.paddedContainer}>
                <Typography variant={'h6'} color={'textSecondary'}>
                    No posts found
                </Typography>
            </div>
        );
    else
        return (
            <>
                {title && (
                    <div className={classes.sectionHeaderContainer}>
                        <Typography
                            variant={'subtitle1'}
                            className={classes.sectionHeaderText}
                        >
                            {title}
                        </Typography>
                    </div>
                )}
                <div>
                    {posts.map((post, index) => (
                        <div
                            key={`search-result-item-${post.slug}`}
                            className={classes.searchResultListItem}
                        >
                            <SearchResultPostCard {...post} />
                        </div>
                    ))}
                </div>

                <div className={classes.paddedContainer}>
                    <Button
                        onClick={onLoadMore}
                        variant={'primary'}
                        fullWidth
                        disabled={posts.length % loadLimit !== 0}
                    >
                        Load More
                    </Button>
                </div>
            </>
        );
};

export type SearchResultPostListPropTypes = {
    loadLimit: number;
    posts: SearchResultPostCardPropTypes[];
    onLoadMore: MouseEventHandler;
    title?: string;
};

export default SearchResultPostList;
