import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    container: {
        width: '100%',
        minHeight: '40vh',
    },
});

export default makeStyles(styles);
