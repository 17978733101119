import React from 'react';
import { useQuery } from '@apollo/client';
import { AUTHORS } from 'lib/graphql/queries/user';
import SearchAuthorsDropDown from './SearchAuthorsDropDown';
import { SearchAuthorResult } from '@elevatormedia/duffel-bag/dist/types/user';

const SearchAuthorsIndex = (props: SearchAuthorsIndexPropTypes) => {
    const { onChange } = props;

    const { data } = useQuery<{
        authors?: SearchAuthorResult[];
    }>(AUTHORS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
    });

    const authors = (data && data.authors) || [];

    return (
        authors.length > 0 && (
            <SearchAuthorsDropDown searchAuthors={authors} onChange={onChange} />
        )
    );
};

export interface SearchAuthorsIndexPropTypes {
    onChange: (preferredUsername: string) => void;
}

export default SearchAuthorsIndex;
