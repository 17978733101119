import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import Instagram from 'mdi-material-ui/Instagram';
import Youtube from 'mdi-material-ui/Youtube';
import HelpBox from 'mdi-material-ui/HelpBox';
import DesktopMacDashboard from 'mdi-material-ui/DesktopMacDashboard';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import { centsToDollars } from '@elevatormedia/duffel-bag/dist/utils/string';
import Card from '@elevatormedia/duffel-bag/dist/atoms/Card';
import { SubmissionTypeInfo } from 'types/submissions';

const SubmissionTypeCard: React.FC<SubmissionTypeCardProps> = ({ service, selected }) => {
    const classes = useStyles();
    const { currentUser } = useAuth();

    let dynamicStyles = '';
    if (selected) {
        dynamicStyles = classes.activeContainer;
    } else {
        dynamicStyles = classes.container;
    }

    const social =
        service.platform && service.name.startsWith('Instagram')
            ? 'http://instagram.com/elevator_'
            : service.platform && service.name.startsWith('Youtube')
            ? 'https://youtube.com/user/LVTRmag'
            : null;

    const getIcon = (platform: string) => {
        switch (platform) {
            case 'instagram':
                return <Instagram className={classes.instagram} fontSize={'default'} />;
            case 'youtube':
                return <Youtube className={classes.youtube} fontSize={'default'} />;
            case 'elevator':
                return (
                    <DesktopMacDashboard
                        fontSize={'default'}
                        className={classes.desktopMac}
                    />
                );
            default:
                return <HelpBox />;
        }
    };

    return (
        <Card className={dynamicStyles}>
            <>
                <div className={classes.titlePrice}>
                    <div className={classes.titleAndIcon}>
                        {getIcon(service.platform)}
                        <Typography
                            style={{ fontWeight: 'bold' }}
                            className={classes.title}
                        >
                            {service.name}
                        </Typography>
                    </div>
                    <Typography style={{ fontWeight: 'bold' }}>
                        {service.name === 'Standard Submission' &&
                        currentUser &&
                        currentUser.preferred
                            ? 'Free'
                            : `$${centsToDollars(service.cost)}`}
                    </Typography>
                </div>
                <Typography variant={'caption'} className={classes.description}>
                    {service.description}
                </Typography>
            </>
        </Card>
    );
};

export type SubmissionTypeCardProps = {
    service: SubmissionTypeInfo;
    selected: boolean;
};

export default SubmissionTypeCard;
