import React, { useState, useEffect, ChangeEvent } from 'react';
import useStyles from './styles';
import { SubmissionCard } from 'atoms';
import { Submission, SubmissionStatus } from 'types/submissions';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

interface MySubmissionsProps {
    submissions: Submission[];
}

type FilterValue = SubmissionStatus | 'Flagged' | 'All';

const filters: { label: string; value: SubmissionStatus | 'Flagged' | 'All' }[] = [
    { label: 'All', value: 'All' },
    { label: 'Pending', value: 'Review' },
    { label: 'Action', value: 'Flagged' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Published', value: 'Completed' },
    { label: 'Declined', value: 'Rejected' },
];

const MySubmissions: React.FC<MySubmissionsProps> = ({ submissions }) => {
    const classes = useStyles();
    const [filter, setFilter] = useState<FilterValue>('All');
    const [filteredSubmissions, setFilteredSubmissions] = useState(submissions);

    useEffect(() => {
        const updateSubmissions =
            filter === 'All'
                ? submissions
                : submissions.filter(({ status, flagged }) =>
                      filter === 'Flagged' ? flagged : status === filter,
                  );
        setFilteredSubmissions(updateSubmissions);
    }, [filter, submissions]);

    const handleFilter = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setFilter(value as FilterValue);
    };

    const underReview = filteredSubmissions.filter(
        ({ status }) => status === 'Approval' || status === 'Review',
    );
    const approved = filteredSubmissions.filter(({ status }) => status === 'Approved');
    const completed = filteredSubmissions.filter(
        ({ status }) => status === 'Rejected' || status === 'Completed',
    );

    const valToLabel = (value: string) => {
        let label;
        filters.filter((filterObject) => {
            if (filterObject.value == value) {
                label = filterObject.label;
            }
        });
        return label;
    };

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <Typography className={classes.pageTitle} variant="h1">
                    MY SUBMISSIONS
                </Typography>
                <TextField
                    className={classes.filter}
                    onChange={handleFilter}
                    SelectProps={{
                        classes: {
                            root: classes.select,
                        },
                    }}
                    select
                    defaultValue="All"
                    InputProps={{
                        disableUnderline: true,
                    }}
                >
                    {filters.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <Divider variant="fullWidth" className={classes.divider} />
            {underReview.length > 0 && (
                <div className={classes.statusSection}>
                    <Typography className={classes.sectionHeader} gutterBottom>
                        Under Review
                    </Typography>
                    {underReview.map((item, index) => (
                        <SubmissionCard key={index} submission={item} />
                    ))}
                </div>
            )}
            {approved.length > 0 && (
                <div className={classes.statusSection}>
                    <Typography className={classes.sectionHeader} gutterBottom>
                        Approved
                    </Typography>
                    {approved.map((item, index) => (
                        <SubmissionCard key={index} submission={item} />
                    ))}
                </div>
            )}
            {completed.length > 0 && (
                <div className={classes.statusSection}>
                    <Typography className={classes.sectionHeader} gutterBottom>
                        Completed
                    </Typography>
                    {completed.map((item, index) => (
                        <SubmissionCard key={index} submission={item} />
                    ))}
                </div>
            )}

            {/* Empty Filtered State */}
            {underReview.length == 0 && approved.length == 0 && completed.length == 0 && (
                <div className={classes.empty}>
                    You have no submissions of {valToLabel(filter)} status.
                </div>
            )}
        </div>
    );
};

export default MySubmissions;
