import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
            padding: theme.spacing(2),
        },
        textHeader: {
            paddingBottom: theme.spacing(2),
        },
        dividerContainer: {
            padding: theme.spacing(1, 0, 3, 0),
        },
        divider: {
            backgroundColor: 'black',
        },
        paragraphContainer: {
            padding: theme.spacing(0, 0, 4, 0),
        },
        externalLink: {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
        },
        addBold: {
            fontWeight: 'bold',
        },
        contentContainer: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
            maxWidth: '80%',
        },

        adContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default makeStyles(styles);
