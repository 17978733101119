import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    inputLabelContainer: {
        marginBottom: '10px',
    },
    inputLabel: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '1.10rem',
        fontWeight: 'bold',
    },
});

export default makeStyles(styles);
