import React from 'react';
import Chip from '@material-ui/core/Chip';
import useStyles from './styles';
import { Tag } from 'types/tag';
import { ROUTES } from 'config/Nav';
import BaseLink from '@elevatormedia/duffel-bag/dist/atoms/BaseLink';
import TagIcon from 'mdi-material-ui/Tag';

const PostTagGroup = (props: PostTagGroupPropTypes) => {
    const classes = useStyles();

    const { tags } = props;

    const renderTags = () => {
        return tags.map(({ name, slug }) => (
            <Chip
                key={slug}
                label={name}
                component={BaseLink}
                href={ROUTES.TAG.fileApiPath}
                as={`${ROUTES.TAG.to}${slug}`}
                classes={{ root: classes.tagChip, label: classes.tagLabel }}
            />
        ));
    };

    return (
        <section className={classes.container}>
            <div className={classes.iconContainer}>
                <TagIcon className={classes.icon} />
            </div>
            <div>{renderTags()}</div>
        </section>
    );
};

export type PostTagGroupPropTypes = {
    tags: Array<Tag>;
};

export default PostTagGroup;
