import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CreditInput } from 'types/credit';
import { normalizeLink } from 'util/socials';
import useStyles from './styles';

interface SubmissionReviewRowProps {
    label: string;
    content: string | React.ReactNode;
    isCredit?: boolean;
}

const SubmissionReviewRow: React.FC<SubmissionReviewRowProps> = ({
    label,
    content,
    isCredit = false,
}) => {
    const classes = useStyles();
    return (
        <Box
            paddingY={1}
            paddingX={1}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            minHeight={'50px'}
        >
            <Box maxWidth={'30%'} width={'100%'}>
                <Typography variant={'caption'} className={classes.fieldName}>
                    {label}
                </Typography>
            </Box>

            {isCredit ? (
                <Box maxWidth={'70%'} className={classes.submissionReviewContent}>
                    {(content as CreditInput[]).map((value, index) => (
                        <Typography key={index} component={'span'} variant={'body1'}>
                            {value.type} - {value.name}
                            <Box
                                paddingLeft={2}
                                paddingTop={1}
                                display={'flex'}
                                flexDirection={'column'}
                            >
                                {value.socialMedia.map(({ link }, index) => {
                                    return (
                                        <Box key={index} paddingBottom={2}>
                                            <a
                                                href={normalizeLink(link)}
                                                target={'_blank'}
                                                className={classes.link}
                                            >
                                                {link}
                                            </a>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Typography>
                    ))}
                </Box>
            ) : (
                <Box maxWidth={'70%'} className={classes.submissionReviewContent}>
                    {typeof content === 'string' ? (
                        <Typography variant={'body1'}>{content}</Typography>
                    ) : (
                        content
                    )}
                </Box>
            )}
        </Box>
    );
};

export default SubmissionReviewRow;
