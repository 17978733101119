import { createContext, useContext } from 'react';
import localForage from 'localforage';
import { useCart } from '@elevatormedia/pitch-hooks/order/use-cart';
import { CartQuery } from '@elevatormedia/pitch-hooks/types/schema';

type SubmitCartMeta = {
    cartId?: string;
    step: number;
};

const STORAGE_KEY = 'submit-cart';

export const deleteCartMeta = () => {
    return localForage.removeItem(STORAGE_KEY);
};

export const getCartMeta = () => {
    return localForage.getItem<SubmitCartMeta>(STORAGE_KEY);
};

export const setCartMeta = (cartId: string, step: number) => {
    return localForage.setItem<SubmitCartMeta>(STORAGE_KEY, { cartId, step });
};

export const setCartStep = async (step: number) => {
    const cartMeta = await getCartMeta();

    if (cartMeta) {
        await setCartMeta(cartMeta.cartId, step);
    }
};

export type Cart = CartQuery['cart'];

export const CartContext = createContext<ReturnType<typeof useCart> & { step: number }>(
    null,
);

export const CartProvider = CartContext.Provider;

export const useCartContext = () => useContext(CartContext);
