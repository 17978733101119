import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'relative',
    },
    badgeContainer: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
    },

    badge: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        height: '20px',
        width: '20px',
    },

    /***********************
     * ELEVATOR ICON BADGE *
     ***********************/
    badgeImage: {
        height: '70%',
    },
    staffBackground: {
        backgroundColor: theme.palette.secondary.main,
    },
    /**********************
     * REGULAR BADGE ICON *
     **********************/
    badgeIcon: {
        height: '70%',
        color: theme.palette.common.white,
    },
    preferredBackground: {
        backgroundColor: theme.palette.custom.blue.light,
    },

    /**************************
     * SPECIAL VERIFIED BADGE *
     **************************/
    verifiedBadgeContainer: {
        height: '70%',
    },
    verifiedBadge: {
        height: '100%',
        color: theme.palette.custom.blue.main,
    },
    verifiedBackground: {
        backgroundColor: 'transparent',
    },

    /************
     * TOOP TIP *
     ************/
    tooltip: {
        position: 'relative',
        backgroundColor: theme.palette.common.black,
    },
    arrow: {
        position: 'absolute',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    tooltipPlacementBottom: {
        margin: '8px 0',
    },
    popper: {
        '&[x-placement*="bottom"] $arrow': {
            top: 5,
            left: 0,
            marginTop: '-1em',
            width: '2em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.black} transparent`,
            },
        },
    },
});

export default makeStyles(styles);
