import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // Submission Review
        submissionReviewContent: {
            overflowWrap: 'break-word',
            wordWrap: 'break-word',

            '-ms-word-break': 'break-all',
            /* Instead use this non-standard one: */
            wordBreak: 'break-word',
        },
        submissionContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: '100%',
            padding: theme.spacing(2),
        },
        topContainer: {
            flex: 5,
        },
        imageContainer: {
            flex: 4,
        },
        imagePreviewMobile: {
            width: '90%',
            objectFit: 'fill',
        },
        imagePreviewDesktop: {
            width: '300px',
            objectFit: 'fill',
        },
        imageDesktopContainer: {
            padding: theme.spacing(0, 0, 33, 0),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
        },
        fieldName: {
            color: 'black',
            opacity: 0.54,
        },

        //Typography
        submissionInfoTitle: {
            fontWeight: 'bold',
            paddingBottom: theme.spacing(2),
        },

        //credits list
        creditsListTitle: {
            fontWeight: 'bold',
            paddingTop: theme.spacing(3),
        },
        creditsList: {
            width: '100%',
            maxWidth: '450px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            padding: theme.spacing(2, 0, 4, 0),
        },
    }),
);

export default useStyles;
