import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        mobileRoot: {
            display: 'block',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        desktopRoot: {
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        desktopHeader: {
            display: 'block',
            // Hidden on smaller screens
            [theme.breakpoints.down(1000)]: {
                display: 'none',
            },
        },
        desktopAppbar: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            height: theme.static_component_sizes.desktop_appbar_height,
        },
        headerToolbarContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexGrow: 1,
            width: '100%',
        },
        headerLeftContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '75%',
        },
        fadeLeft: {
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            pointerEvents: 'none',
            background: `linear-gradient(to left, rgba(38, 38, 38, 0) 11%, ${theme.palette.primary.main} 95%)`,
            width: '5%',
            height: '100%',
        },
        fadeRight: {
            position: 'absolute',
            zIndex: 1,
            top: 0,
            right: 0,
            pointerEvents: 'none',
            backgroundImage: `linear-gradient(to right, rgba(38, 38, 38, 0) 11%, ${theme.palette.primary.main} 95%)`,
            width: '5%',
            height: '100%',
        },
        fadeContainer: {
            position: 'relative',
            overflowX: 'scroll',
            '-ms-overflow-style': 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            scrollbarWidth: 'none',
        },
        navLinksContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflowX: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
        },
        desktopTopBrandLogoContainer: {
            paddingRight: theme.spacing(1),
            minWidth: '180x',
        },
        navLinkContainer: {
            paddingRight: theme.spacing(4),
        },
        firstNavLink: {
            paddingLeft: theme.spacing(3),
        },
        desktopAccountHandlerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            minWidth: '25%',
        },
        desktopSearchContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        searchBar: {
            height: '33px !important',
        },
        submitButton: {
            borderRadius: '2px',
            minWidth: '100px',
            height: '32px',
            [theme.breakpoints.down('sm')]: {
                height: '28px',
                minWidth: 0,
                padding: theme.spacing(1),
                fontSize: theme.typography.pxToRem(12),
            },
        },
        submitIcon: {
            fontSize: theme.typography.pxToRem(20),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(16),
            },
        },
        signInLink: {
            minWidth: '50px',
            marginLeft: theme.spacing(2),
        },
        userAvatar: {
            borderRadius: '3px !important',
            backgroundColor: theme.palette.primary.light,
        },
        appBarAvatar: {
            marginLeft: theme.spacing(1),
            height: '28px',
            width: '28px',
        },
        toolbar: {
            width: '100%',
        },

        // MOBILE ONLY
        root: {
            position: 'fixed',
            flexGrow: 1,
            zIndex: 3,
            width: '100%',
        },
        mobileAppBar: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            height: theme.static_component_sizes.mobile_appbar_height,
        },
        headerLogoContainer: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
        collapseMenuContainer: {
            width: '100%',
            zIndex: 3,
        },
        listMenuContainer: {
            width: '100%',
            backgroundColor: theme.palette.custom.grey.dark,
            color: 'red',
        },
        list: {
            padding: 0,
            color: 'white',
        },
        userOptionsList: {
            padding: 0,
            paddingTop: theme.spacing(1),
        },
        listItem: {
            color: 'white',
            textTransform: 'uppercase',
            '&:hover': {
                color: 'red',
                backgroundColor: 'transparent',
                cursor: 'pointer',
            },
        },
        listItemHighEmphasis: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            textTransform: 'uppercase',
            cursor: 'pointer',
            '&:hover': {
                color: 'red',
                cursor: 'pointer',
            },
        },
        listDivider: {
            background: theme.palette.getRgba(theme.palette.common.white, 0.08),
            listStyle: 'none',
        },
        mobileList: {
            paddingLeft: theme.spacing(2),
        },
        lastItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
        },
        disabledHover: {
            '&:hover': {
                color: 'white',
                backgroundColor: 'transparent',
            },
        },
        listIcon: {
            color: theme.palette.getRgba(theme.palette.common.white, 0.25),
            paddingLeft: theme.spacing(1),
        },
        signInRoot: {
            paddingTop: 0,
        },
        profileListItem: {
            paddingTop: theme.spacing(2),
        },
    });

export default makeStyles(styles);
