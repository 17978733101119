import React from 'react';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';

const PostBody = (props: PostBodyPropTypes) => {
    const classes = useStyles();
    const { content } = props;

    /**
     * Adds additional dom attributes to YouTube iframes by merging props
     * that may already exist in the element.
     */
    const mergeYouTubeAttributes = (defaultAttributes: any) => {
        const requiredAttributes = {
            allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
            allowFullScreen: true,
        };

        return { ...requiredAttributes, ...defaultAttributes };
    };

    const dupAttribs = (node: any) => {
        if (node.name === 'script') return;

        if (node.attribs && !node['x-attribsNamespace'])
            node['x-attribsNamespace'] = node.attribs;

        if (node.attribs && !node['x-attribsPrefix'])
            node['x-attribsPrefix'] = node.attribs;

        if (node.attribs && node.attribs.style) {
            return node.attribs.style as Object;
        }

        if (node.children) node.children.forEach(dupAttribs);

        return node;
    };

    /**
     * HTML parser options. will intercept HTML element parsing and replace them with
     * styled components.
     * Step 1: Purify HTML - Remove possible XSS attacks
     * Step 2: Replace HTML Nodes with Material UI.
     */
    const htmlParserOptions: HTMLReactParserOptions = {
        replace: (domNode) => {
            // patches CAB-168
            if (!domNode || !domNode.name) return null;
            // // patches CAB-274
            // if (domNode && !domNode.children.includes('path')) return null;

            domNode = dupAttribs(domNode);
            if (!domNode || !domNode.name) return null;

            if (domNode.name === 'p') {
                return (
                    <Typography component={'p'} className={classes.styledText}>
                        {domToReact(domNode.children, htmlParserOptions)}
                    </Typography>
                );
            } else if (domNode.name === 'span') {
                if (domNode.attribs && domNode.attribs.style) {
                    return (
                        <React.Fragment>
                            {domToReact(domNode.children, htmlParserOptions)}
                        </React.Fragment>
                    );
                } else {
                    return <span>{domToReact(domNode.children, htmlParserOptions)}</span>;
                }
            } else if (domNode.name === 'a') {
                return (
                    <a
                        href={domNode.attribs.href}
                        className={classes.styledLink}
                        {...domNode.attribs}
                    >
                        {domToReact(domNode.children, htmlParserOptions)}
                    </a>
                );
            } else if (domNode.name === 'b' || domNode.name === 'strong') {
                return (
                    <b className={classes.styledBold}>
                        {domToReact(domNode.children, htmlParserOptions)}
                    </b>
                );
            } else if (domNode.name === 'blockquote') {
                if (domNode.attribs && domNode.attribs.class) {
                    if (domNode.attribs.class === 'instagram-media') {
                        return (
                            <div className={classes.socialMediaEmbedContainer}>
                                <blockquote className={'instagram-media'}>
                                    {domToReact(domNode.children, htmlParserOptions)}
                                </blockquote>
                            </div>
                        );
                    } else if (domNode.attribs.class === 'twitter-tweet') {
                        return (
                            <div className={classes.socialMediaEmbedContainer}>
                                <blockquote className={'twitter-tweet'}>
                                    {domToReact(domNode.children, htmlParserOptions)}
                                </blockquote>
                            </div>
                        );
                    }
                }

                return (
                    <blockquote className={classes.styledBlockQuote}>
                        {domToReact(domNode.children, htmlParserOptions)}
                    </blockquote>
                );
            } else if (domNode.name === 'img') {
                // let srcset;
                // try {
                //     if (domNode.attribs.srcset) {
                //         let imgixSrc = domNode.attribs.srcset.split('4x,');
                //         if (imgixSrc.length === 2) {
                //             srcset = imgixSrc[1].replace(' 5x', '');
                //         }
                //     }
                // } catch (error) {
                //     srcset = domNode.attribs.srcset;
                // }

                return (
                    <img
                        src={domNode.attribs.src}
                        srcSet={domNode.attribs.srcset ? domNode.attribs.srcset : null}
                        alt={domNode.attribs.alt}
                        className={classes.styledImage}
                    />
                );
            } else if (domNode.name === 'iframe') {
                const { src } = domNode.attribs;
                if (src.includes('youtu') || src.includes('vimeo')) {
                    // ref: https://github.com/toddmotto/fluidvids-react/blob/master/react.fluidvids.js
                    const { width, height } = domNode.attribs;
                    const ratio =
                        (parseInt(height, 10) / parseInt(width, 10)) * 100 + '%';

                    return (
                        <div
                            className={classes.responsiveEmbedContainer}
                            style={{ paddingTop: ratio }}
                        >
                            <iframe
                                {...mergeYouTubeAttributes(domNode.attribs)}
                                className={classes.styledVideoEmbed}
                            >
                                {domToReact(domNode, htmlParserOptions)}
                            </iframe>
                        </div>
                    );
                } else if (
                    src.includes('soundcloud') ||
                    src.includes('spotify') ||
                    src.includes('tidal')
                ) {
                    domNode.attribs.className = classes.styledAudioEmbed;
                    return domToReact(domNode, htmlParserOptions);
                } else {
                    return domToReact(domNode, htmlParserOptions);
                }
            } else if (domNode.name === 'div') {
                domNode.attribs.style = '';
                domNode.attribs.class = classes.styledDiv;
                return domToReact(domNode, htmlParserOptions);
            } else {
                return domToReact(domNode, htmlParserOptions);
            }
        },
        // ALLOWED_TAGS: ['iframe', 'twitter-widget']
    };

    const renderPostBody = () => {
        //TODO: Santize HTML
        const parsedHTML = parse(content, htmlParserOptions);

        return parsedHTML;
    };

    return <div className={classes.root}>{renderPostBody()}</div>;
};

export type PostBodyPropTypes = {
    content: string;
};

export default PostBody;
