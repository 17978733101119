import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
        },

        /*************
         * POST INFO *
         *************/
        postMetaContainer: {
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        featuredPostMetaContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textAlign: 'center',
        },

        spacedRow: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            width: '100%',
            justifyContent: 'space-between',
        },
        featureSpacedRow: {
            width: '66.66%',
            alignSelf: 'flex-end',
        },

        featureSpacedRowItem: {
            flex: 1,
        },
        featureSpacedRowLinkItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
        },

        rowAlignBottom: {
            alignItems: 'flex-end',
        },
        rowAlignCenter: {
            alignItems: 'center',
        },
        categoriesContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        postMetaTitleSubContainer: {
            padding: theme.spacing(1, 0, 2, 0),
        },
        gutterBottom: {
            paddingBottom: theme.spacing(1),
        },
        listSep: {
            //TODO: OVERRIDE
            fontSize: theme.typography.pxToRem(24),
            lineHeight: '1em',
        },

        authorContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
        authorMetaContainer: {
            paddingLeft: theme.spacing(1),
        },
        authorLine: {
            fontWeight: 700,
            //TODO: OVERRIDE
            fontSize: theme.typography.pxToRem(12),
        },
        italics: {
            fontStyle: 'italic',
        },

        categoryNameSkeleton: {
            marginBottom: theme.spacing(1),
        },
    });

export default makeStyles(styles);
