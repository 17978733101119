import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import { TeaserThumbnail, PostTypeLink } from 'atoms';
import { PostCardInfo } from 'types/post';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { ROUTES } from 'config/Nav';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import Card from '@elevatormedia/duffel-bag/dist/atoms/Card';
import { formatDate } from '@elevatormedia/duffel-bag/dist/utils/string';

const FeaturedPostCard = (props: FeaturedPostCardPropTypes) => {
    const classes = useStyles();
    const {
        title,
        image,
        slug,
        type,
        authors,
        categories,
        publishedAt,
        position,
        description,
    } = props;

    const theme = useTheme();
    const mediumScreens = useMediaQuery(theme.breakpoints.down('md'));
    const smallScreens = useMediaQuery(theme.breakpoints.down('xs'));

    const getCategories = () => {
        return categories.map((category: any, index: number) => (
            <React.Fragment key={category.slug}>
                <Link
                    as={ROUTES.CATEGORY.to + category.slug}
                    href={ROUTES.CATEGORY.fileApiPath}
                    variant={'category-card'}
                    inline
                >
                    {category.name}
                </Link>
                {index !== categories.length - 1 && (
                    <Typography
                        variant={'body1'}
                        className={classes.listSep}
                        color={'textSecondary'}
                    >
                        {','}&nbsp;
                    </Typography>
                )}
            </React.Fragment>
        ));
    };

    const getAuthors = () => {
        return authors.map((author: any, index: number) => (
            <React.Fragment key={author.preferredUsername}>
                <Link
                    key={author.preferredUsername}
                    variant={'author-card'}
                    inline
                    href={ROUTES.AUTHORS.to + author.preferredUsername}
                    as={ROUTES.AUTHORS.to + author.preferredUsername}
                >
                    {author.displayName || author.preferredUsername}
                </Link>
                {index !== authors.length - 1 && (
                    <Typography
                        variant={'body2'}
                        className={classes.listSep}
                        color={'textSecondary'}
                        display={'inline'}
                    >
                        {','}&nbsp;
                    </Typography>
                )}
            </React.Fragment>
        ));
    };

    const getTeaserThumbnailVariant = () => {
        // On small screens all cards have the same thumbnail sizes
        if (smallScreens) {
            return classes.mobileTeaser;
        }

        switch (position) {
            case 1:
            case 4:
                return classes.largeTeaser;
            case 2:
                return mediumScreens ? classes.mediumTeaser : classes.fullTeaser;
            case 3:
            default:
                return classes.mediumTeaser;
        }
    };

    const getCardOrientation = () => {
        // Position two card needs to be reset as we move to mobile
        if (position === 2) {
            return mediumScreens
                ? classes.verticalOrientation
                : classes.horizontalOrientation;
        } else {
            return classes.verticalOrientation;
        }
    };

    const getCardHeight = () => {
        // All cards have the same height on mobile
        if (smallScreens) {
            return classes.mobileHeight;
        }

        switch (position) {
            case 1:
            case 4:
                return classes.tallHeight;
            case 2:
            case 3:
                return classes.shortHeight;
            default:
                return null;
        }
    };

    const omitDescription = (): Boolean => {
        // Larger cards must omit their descriptions when resizing to mobile
        if (position === 2 || position === 1) {
            return mediumScreens ? true : false;
        } else {
            return true;
        }
    };

    return (
        <Card
            className={clsx(
                type === 'Sponsored' && classes.greyBackground,
                getCardOrientation(),
                getCardHeight(),
            )}
        >
            <div className={clsx(classes.teaserBase, getTeaserThumbnailVariant())}>
                <Link href={ROUTES.POST.fileApiPath} as={`/${slug}`} variant={'wrapper'}>
                    <TeaserThumbnail
                        src={image.sourceUrl}
                        alt={`thumbnail for ${title}`}
                        //srcSet={image.sourceSet}
                        //useSrcSet={image.sourceSet && position !== 4 ? true : false}
                    />
                </Link>
                <div className={classes.postTypeLinkContainer}>
                    <PostTypeLink type={type} />
                </div>
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.postMetaContainer}>
                    <div className={classes.categoryRowContainer}>{getCategories()}</div>
                    <Typography
                        variant={'caption'}
                        color={'textSecondary'}
                        display={'inline'}
                    >
                        {formatDate(parseInt(publishedAt as string))} | by {getAuthors()}
                    </Typography>
                </div>
                <div className={classes.linkContainer}>
                    <Link
                        href={ROUTES.POST.fileApiPath}
                        as={`/${slug}`}
                        variant={'title-card'}
                    >
                        {title}
                    </Link>
                </div>
                {!omitDescription() && <Typography>{description}</Typography>}
            </div>
        </Card>
    );
};

export interface FeaturedPostCardPropTypes extends PostCardInfo {
    position: 1 | 2 | 3 | 4;
    description?: string;
}

export default FeaturedPostCard;
