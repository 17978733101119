import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down(theme.static_component_sizes.content_max_width)]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(2),
            width: '100%',
            maxWidth: theme.static_component_sizes.content_max_width,
        },
        pageTitle: {
            fontSize: theme.typography.pxToRem(26),
        },
        filter: {
            width: 120,
            paddingLeft: theme.spacing(1),
            background: theme.palette.custom.gainsboro,
        },
        select: {
            '&:focus': {
                background: theme.palette.custom.gainsboro,
            },
        },
        divider: {
            maxWidth: theme.static_component_sizes.content_max_width,
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        statusSection: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: theme.static_component_sizes.content_max_width,
            marginBottom: theme.spacing(2),
        },
        sectionHeader: {
            fontSize: theme.typography.pxToRem(18),
            color: theme.palette.text.secondary,
        },
        empty: {
            marginTop: theme.spacing(5),
        },
    }),
);

export default useStyles;
