import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        // Applied only to sponsored submission cards
        greyBackground: {
            backgroundColor: theme.palette.custom.grey.light,
        },

        /***************
         * ORIENTATION *
         ***************/
        horizontalOrientation: {
            display: 'flex',
            flexDirection: 'row',
        },
        verticalOrientation: {
            display: 'flex',
            flexDirection: 'column',
        },

        /****************
         * CARD HEIGHTS *
         ****************/
        mobileHeight: {
            maxHeight: '700px',
        },
        tallHeight: {
            maxHeight: '890px',
        },
        shortHeight: {
            maxHeight: '400px',
        },

        /*******************
         * THUMBNAIL SIZES *
         *******************/
        teaserBase: {
            position: 'relative',
        },
        fullTeaser: {
            height: '400px',
            width: '50%',
        },
        mobileTeaser: {
            height: '460px',
        },
        mediumTeaser: {
            height: '170px',
        },
        largeTeaser: {
            height: '590px',
        },

        /********
         * MISC *
         ********/
        infoContainer: {
            padding: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            minHeight: '250px',
        },
        postMetaContainer: {
            paddingBottom: theme.spacing(2),
        },
        linkContainer: {
            paddingBottom: theme.spacing(1),
        },
        postTypeLinkContainer: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        categoryRowContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        listSep: {
            //TODO: OVERRIDE
            fontSize: theme.typography.pxToRem(12),
            lineHeight: '1em',
        },
    });

export default makeStyles(styles);
