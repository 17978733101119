import { SubmissionContentType } from 'types/submissions';

const SUBMISSION_CONTENT_TYPES: Record<string, SubmissionContentType> = {
    STRING: 'string',
    LINK: 'link',
    IMAGE: 'image',
    CREDITS: 'credits',
};

export default SUBMISSION_CONTENT_TYPES;
