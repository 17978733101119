import React, { useEffect, useMemo } from 'react';
import useStyles from './styles';
import { CreditField, PostBody, PostHeader, PostTagGroup } from 'molecules';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { Post as PostInfo } from 'types/post';
import {
    handleInstagramEmbeds,
    handleTwitterEmbeds,
    unmountSocialMediaScripts,
} from './Embeds.util';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import { useRouter } from 'next/router';

const Post = (props: PostPropTypes) => {
    const classes = useStyles();

    const {
        content,
        tags,
        hasInstagramEmbed,
        hasTwitterEmbed,
        submission,
        credits,
        isLoading,
        ...headerProps
    } = props;

    const router = useRouter();

    const isFeature = headerProps.type === 'Featured';

    useEffect(() => {
        if (hasInstagramEmbed) {
            handleInstagramEmbeds();
        }

        if (hasTwitterEmbed) {
            handleTwitterEmbeds();
        }

        return () => {
            unmountSocialMediaScripts();
        };
    }, [hasInstagramEmbed, hasTwitterEmbed]);

    const renderSkeleton = useMemo(() => {
        return router.isFallback || isLoading;
    }, [router.isFallback, isLoading]);

    return (
        <Paper className={classes.paperRoot}>
            <div
                className={clsx(classes.headerRoot, {
                    [classes.featureHeaderRoot]: isFeature,
                })}
            >
                <PostHeader
                    {...headerProps}
                    submission={submission}
                    isLoading={isLoading}
                />
                <Divider variant={'fullWidth'} className={classes.separator} />
            </div>
            <article className={clsx(classes.root, { [classes.featureRoot]: isFeature })}>
                {renderSkeleton ? (
                    <Skeleton
                        animation={'pulse'}
                        variant={'rect'}
                        width={'100%'}
                        height={'50vh'}
                    />
                ) : (
                    <PostBody content={content} />
                )}
            </article>
            <div
                className={clsx(classes.headerRoot, {
                    [classes.featureHeaderRoot]: isFeature,
                })}
            >
                <Divider variant={'fullWidth'} className={classes.separator} />

                <GridContainer
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    spacing={2}
                >
                    {renderSkeleton ? (
                        <GridItem xs={12}>
                            <GridContainer direction={'row'}>
                                <Skeleton
                                    width={65}
                                    height={35}
                                    className={classes.skeleton}
                                />
                                <Skeleton
                                    className={classes.skeleton}
                                    width={65}
                                    height={35}
                                />
                                <Skeleton
                                    className={classes.skeleton}
                                    width={65}
                                    height={35}
                                />
                            </GridContainer>
                        </GridItem>
                    ) : (
                        <GridItem xs={12}>
                            {tags.length > 0 && <PostTagGroup tags={tags} />}
                        </GridItem>
                    )}
                    <GridItem xs={12}>
                        {credits && credits.length > 0 && (
                            <CreditField credits={credits} />
                        )}
                    </GridItem>
                </GridContainer>
            </div>
        </Paper>
    );
};

export interface PostPropTypes extends PostInfo {
    hasInstagramEmbed?: Boolean;
    hasTwitterEmbed?: Boolean;
    isLoading: Boolean;
}

export default Post;
