/**
 * Creates & loads a script tag to the DOM with a
 * required call-back. Note that the script is loaded
 * in a synchronous nature.
 */
const loadScript = (id: string, src: string, onLoad: any) => {
    const script = document.createElement('script');

    script.src = src;
    script.id = id;
    script.onload = onLoad;

    script.async = false;
    document.body.appendChild(script);

    return script;
};

/**
 * Search Document body for blockquotes to be converted to an instagram embed
 */
const processInstagramEmbeds = () => {
    window.instgrm.Embeds.process();
};

/**
 * Search Document body for blockquote to be converted to an tweet embed.
 *
 * Optionally pass a div container id to only load a single tweet (better performance)
 */
const processTweetEmbeds = (containerId: string = null) => {
    if (containerId) {
        window.twttr.widgets.load(document.getElementById(containerId));
    } else {
        window.twttr.widgets.load();
    }
};

/**
 * Handles loading the tweet embed script if it hasnt been loaded. If it has,
 * process any tweet embed code present in the document body.
 */
export const handleTwitterEmbeds = () => {
    const tweetEmbedLoader: Element = document.getElementById('twitter-embed-loader');

    if (!tweetEmbedLoader) {
        loadScript(
            'twitter-embed-loader',
            'https://platform.twitter.com/widgets.js',
            processTweetEmbeds,
        );
    } else {
        processTweetEmbeds();
    }
};

/**
 * Handles loading the instagram embed script if it hasnt been loaded. If it has,
 * process any instagram embed code present in the document body.
 */
export const handleInstagramEmbeds = () => {
    const instagramEmbedLoader: Element = document.getElementById(
        'instagram-embed-loader',
    );

    if (!instagramEmbedLoader) {
        loadScript(
            'instagram-embed-loader',
            'https://www.instagram.com/embed.js',
            processInstagramEmbeds,
        );
    } else {
        processInstagramEmbeds();
    }
};

/**
 * Handles remove tweet and instagram post embed scripts from the document body if they
 * are present.
 */
export const unmountSocialMediaScripts = () => {
    const tweetEmbedLoader: Element = document.getElementById('twitter-embed-loader');
    const instagramEmbedLoader: Element = document.getElementById(
        'instagram-embed-loader',
    );

    if (instagramEmbedLoader) {
        document.body.removeChild(instagramEmbedLoader);
    }

    if (tweetEmbedLoader) {
        document.body.removeChild(tweetEmbedLoader);
    }
};
