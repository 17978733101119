import React from 'react';
import useStyles from './styles';
import SubmissionStatusIndicator from 'atoms/SubmissionStatusIndicator';
import Typography from '@material-ui/core/Typography';
import { SubmissionTypeInfo } from 'types/submissions';
import { OrderStatus, Service, SubmissionStatus } from '@elevatormedia/pitch-hooks/types';

const ServiceInfo = (props: ServiceInfoProps) => {
    const classes = useStyles();

    const { addOns, submissionType, statusLabel, orderStatus, orderFlagged } = props;

    const addOnsTitleArray = addOns.map((service) => {
        return service.name;
    });

    return (
        <div className={classes.serviceInfoContainer}>
            <div>
                <Typography variant={'subtitle1'}>{submissionType} Submission</Typography>
                {addOns.length > 0 && (
                    <Typography className={classes.addOnText} variant={'subtitle2'}>
                        Add Ons: <b>{addOnsTitleArray}</b>
                    </Typography>
                )}
            </div>
            <SubmissionStatusIndicator
                status={statusLabel}
                orderStatus={orderStatus}
                orderFlagged={orderFlagged}
            />
        </div>
    );
};

export interface ServiceInfoProps {
    submissionType: SubmissionTypeInfo;
    statusLabel: SubmissionStatus | 'Flagged';
    orderStatus: OrderStatus;
    orderFlagged: boolean;
    addOns?: Service[];
}

export default ServiceInfo;
