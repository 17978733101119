import React from 'react';
import useStyles from './styles';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SUBMISSION_STATUSES from '../../config/SubmissionStatuses';
import { SubmissionStatus } from 'types/submissions';
import { OrderStatus } from '@elevatormedia/pitch-hooks/types';

const SubmissionStatusChip: React.FC<SubmissionStatusChipProps> = ({
    variant,
    orderStatus,
    orderFlagged,
}) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getClassName = () => {
        switch (variant) {
            case SUBMISSION_STATUSES.REVIEW:
            case SUBMISSION_STATUSES.APPROVAL:
                return classes.pending;
            case SUBMISSION_STATUSES.FLAGGED:
                if (orderStatus == 'failed' || orderFlagged === true) {
                    return classes.rejected;
                } else {
                    return classes.flagged;
                }
            case SUBMISSION_STATUSES.REJECTED:
                return classes.rejected;
            case SUBMISSION_STATUSES.COMPLETED:
            case SUBMISSION_STATUSES.APPROVED:
                return classes.approved;
            default:
                break;
        }
    };

    const getPopoverTipText = () => {
        switch (variant) {
            case SUBMISSION_STATUSES.REVIEW:
            case SUBMISSION_STATUSES.APPROVAL:
                return `Your submission is being reviewed by an Elevator Staff member. 
                        Due to the high volume of submissions, we are unable to respond to every request. 
                        We review most submissions within 72 hours but may take up to 7 days.`;
            case SUBMISSION_STATUSES.FLAGGED:
                if (orderStatus == 'failed' || orderFlagged === true) {
                    return `An issue occurred while processing your payment. Please create a new submission 
                    and try again.`;
                } else {
                    return `Your submission has been flagged and is awaiting further 
                        review from an Elevator staff member.`;
                }
            case SUBMISSION_STATUSES.COMPLETED:
                return `Your submission has been posted on the Elevator site!
                         Be sure to share the news with your followers on social media.`;
            case SUBMISSION_STATUSES.REJECTED:
                return `Your submission has been declined and will not be posted on the Elevator site.`;
            case SUBMISSION_STATUSES.APPROVED:
                return `Your submission has been approved and is in the process of being published on our site.`;
            default:
                break;
        }
    };

    const getLabel = () => {
        switch (variant) {
            case SUBMISSION_STATUSES.REVIEW:
            case SUBMISSION_STATUSES.APPROVAL:
                return 'PENDING';
            case SUBMISSION_STATUSES.FLAGGED:
                if (orderStatus == 'failed' || orderFlagged === true) {
                    return 'DECLINED';
                } else {
                    return 'FLAGGED';
                }
            case SUBMISSION_STATUSES.COMPLETED:
                return 'PUBLISHED';
            case SUBMISSION_STATUSES.REJECTED:
                return 'DECLINED';
            case SUBMISSION_STATUSES.APPROVED:
                return 'ACCEPTED';
            default:
                break;
        }
    };

    const renderLink = () => {
        return (
            <div>
                <Popover
                    id="submission-chip-mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Box maxWidth={'150px'}>
                        <Typography variant={'body2'}>{getPopoverTipText()}</Typography>
                    </Box>
                </Popover>
                <Chip
                    label={getLabel()}
                    className={getClassName()}
                    size={'small'}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                />
            </div>
        );
    };

    return renderLink();
};

export interface SubmissionStatusChipProps {
    variant: SubmissionStatus | 'Flagged';
    orderStatus?: OrderStatus;
    orderFlagged?: boolean;
}

export default SubmissionStatusChip;
