import React from 'react';
import useStyles from './styles';
import BaseLink from '@elevatormedia/duffel-bag/dist/atoms/BaseLink';
import { ROUTES } from 'config/Nav';

const TagLink = (props: TagLinkPropTypes) => {
    const classes = useStyles();

    const { children, href, ...rest } = props;

    return (
        <BaseLink
            className={classes.tagLink}
            href={ROUTES.TAG.fileApiPath}
            as={href}
            {...rest}
        >
            {children}
        </BaseLink>
    );
};

export type TagLinkPropTypes = {
    children: React.ReactNode;
    href: string;
};

export default TagLink;
