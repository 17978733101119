import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        /**********
         * SHARED *
         **********/
        root: {
            height: '100%',
            width: '100%',
            minHeight: '60vh',
            padding: theme.spacing(2),
        },
        dividerContainer: {
            padding: theme.spacing(1, 0, 3, 0),
        },
        sectionTitleContainer: {
            paddingBottom: theme.spacing(1),
        },
        divider: {
            backgroundColor: 'black',
        },
        formContainer: {
            maxWidth: '60%',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        sectionContainer: {
            paddingBottom: theme.spacing(3),
        },
        buttonContainer: {
            padding: theme.spacing(2, 0),
        },
        snackbarButton: {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
            pointerEvents: 'all',
        },

        /********
         * FORM *
         ********/
        repostScreenshot: {
            height: 'auto',
            width: '100%',
        },
        fieldContainer: {
            paddingBottom: theme.spacing(2),
        },

        /****************
         * CONFIRMATION *
         ****************/
        buttonGroupContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        homeButtonContainer: {
            paddingLeft: theme.spacing(1),
        },
    });

export default makeStyles(styles);
