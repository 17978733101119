import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import { ROUTES } from 'config/Nav';
import { useRouter } from 'next/router';
import useStyles from './styles';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';

const Confirmation = (props: ConfirmationPropTypes) => {
    const classes = useStyles();

    const { toggleConfirmation } = props;
    const router = useRouter();

    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    });

    const navigateHome = () => {
        router.push(ROUTES.HOME.to);
    };

    return (
        <div className={classes.root}>
            <Link href={ROUTES.DISTRIBUTION.to} variant={'title-general'}>
                MUSIC DISTRIBUTION & MONETIZATION
            </Link>

            <div className={classes.dividerContainer}>
                <Divider className={classes.divider} />
            </div>

            <div className={classes.sectionContainer}>
                <Typography variant={'h5'} gutterBottom>
                    Your request has been recieved!
                </Typography>
            </div>

            <div className={clsx(classes.sectionContainer, classes.formContainer)}>
                <Typography variant={'body1'} component={'span'}>
                    Thank you for submitting your request. If you have any further
                    questions please reach out on social media!
                </Typography>
            </div>

            <div className={clsx(classes.buttonContainer, classes.buttonGroupContainer)}>
                <Button
                    size={'small'}
                    variant={'primary'}
                    onClick={() => toggleConfirmation()}
                >
                    Submit Another Request
                </Button>
                <div className={classes.homeButtonContainer}>
                    <Button size={'small'} variant={'text'} onClick={navigateHome}>
                        Home
                    </Button>
                </div>
            </div>
        </div>
    );
};

export type ConfirmationPropTypes = {
    toggleConfirmation: Function;
};

export default Confirmation;
