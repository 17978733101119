import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        screenContentOffset: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
            minHeight: `calc(100vh - ${theme.static_component_sizes.desktop_appbar_height}px)`,
            top: theme.static_component_sizes.desktop_appbar_height,
            // Styles for mobile
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.background.default,
                minHeight: `calc(100vh - ${theme.static_component_sizes.mobile_appbar_height}px)`,
                top: theme.static_component_sizes.mobile_appbar_height,
            },
        },
    });

export default makeStyles(styles);
