import React from 'react';
import { useSnackbar } from 'notistack';
import { reportError } from '../../lib/errors';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { FormikProps } from 'formik';
import { StepOneFormikValues } from './StepOne';
import useStyles from './styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { requestEmailVerificationCode } from '@elevatormedia/duffel-bag/dist/lib/auth';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';
import ActionAreaContainer from '@elevatormedia/duffel-bag/dist/atoms/ActionAreaContainer';

interface VerifyFormProps extends FormikProps<StepOneFormikValues> {
    currentUser: User;
}

const VerifyForm: React.FC<VerifyFormProps> = ({
    values,
    errors,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
    handleChange,
    currentUser,
}) => {
    const classes = useStyles();
    const theme: Theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { enqueueSnackbar } = useSnackbar();

    const sendVerificationCode = async () => {
        try {
            await requestEmailVerificationCode(process.env.authEndpoint);
            enqueueSnackbar('Verification code sent!', {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } catch (err) {
            reportError(err, {
                metaData: {
                    operation: 'cognito requestEmailVerificationCode',
                },
                user: {
                    username: currentUser.username,
                },
            });
            enqueueSnackbar(
                `Could not send verification code, please make sure you're logged in`,
                {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                },
            );
        }
    };

    return (
        <div className={classes.formContainer}>
            <form onSubmit={(e) => e.preventDefault()}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="verificationCode"
                    label="Verification Code"
                    id="verificationCode"
                    value={values.verificationCode}
                    onChange={handleChange}
                    error={touched.verificationCode && Boolean(errors.verificationCode)}
                    helperText={touched.verificationCode ? errors.verificationCode : ''}
                />
                <ActionAreaContainer direction={mobile ? 'column' : 'row'}>
                    {!mobile && (
                        <Button
                            size="medium"
                            fullWidth={mobile}
                            color="primary"
                            onClick={sendVerificationCode}
                            disabled={false}
                        >
                            Send Verification Code
                        </Button>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        size="medium"
                        fullWidth={mobile}
                        color="primary"
                        onClick={() => handleSubmit()}
                        disabled={!isValid || isSubmitting}
                    >
                        Verify Email
                    </Button>
                    {mobile && (
                        <Button
                            size="medium"
                            fullWidth={mobile}
                            color="primary"
                            onClick={sendVerificationCode}
                            disabled={false}
                        >
                            Send Verification Code
                        </Button>
                    )}
                </ActionAreaContainer>
            </form>
        </div>
    );
};

export default VerifyForm;
