import React from 'react';
import { useQuery } from '@apollo/client';
import PopularPostList from './PopularPostList';
import { POPULAR_POSTS } from 'lib/graphql/queries/post';

const PopularPostListWithData = () => {
    const { data, loading, networkStatus, error } = useQuery(POPULAR_POSTS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            limit: 6,
            skip: 0,
        },
    });

    const isLoading = loading || networkStatus === 4;
    const posts = data && data.publishedPosts ? data.publishedPosts : [];

    return <PopularPostList posts={posts} loading={isLoading} error={error} />;
};

export default PopularPostListWithData;
