import gql from 'graphql-tag';

export const HOME_POSTS = gql`
    query homePosts($limit: Int!, $skip: Int!) {
        featuredPosts {
            _0 {
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                publishedAt
                modifiedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _1 {
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                publishedAt
                modifiedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _2 {
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                publishedAt
                modifiedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _3 {
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                publishedAt
                modifiedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
            _4 {
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                publishedAt
                modifiedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
        }
        publishedPosts(limit: $limit, skip: $skip) {
            postId
            title
            slug
            type
            description
            publishedAt
            modifiedAt
            image {
                sourceUrl
                sourceSet
            }
            authors {
                displayName
                preferredUsername
            }
            categories {
                name
                slug
            }
        }
    }
`;
