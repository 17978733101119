import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            minHeight: '400px',
            height: '100%',
            minWidth: '100%',
            maxWidth: '100%',
        },
        teaserContainer: {
            height: '170px',
            position: 'relative',
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        mainPadding: {
            padding: theme.spacing(4),
        },
        sidebarPadding: {
            padding: theme.spacing(1.5),
        },
        greyBackground: {
            backgroundColor: theme.palette.custom.grey.light,
        },
        postTypeLinkContainer: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        postMetaContainer: {
            paddingBottom: theme.spacing(2),
        },
        categoryRowContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        listSep: {
            //TODO: OVERRIDE
            fontSize: theme.typography.pxToRem(12),
            lineHeight: '1em',
        },
        mainLink: {
            fontSize: '',
        },
        sidebarLink: {
            fontSize: theme.typography.pxToRem(16),
        },
    });

export default makeStyles(styles);
