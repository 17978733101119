import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useQuery } from '@apollo/client';
import { ROUTES } from 'config/Nav';
import { HOME_POSTS } from 'lib/graphql/queries/home';
import { HeroPost } from 'molecules';
import { PageProps } from 'types/page';
import Home from './Home';
import { reportError } from 'lib/errors';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';
import { HomePosts } from 'types/home';
import { Skeleton } from '@material-ui/lab';

const Index: React.FC<HomePageProps> = ({ posts, ...props }) => {
    const classes = useStyles();

    const [homePosts, setHomePosts] = useState(posts);

    const variables = {
        limit: 9,
        skip: 0,
    };

    const { data, loading, networkStatus, error, fetchMore } = useQuery<HomePosts>(
        HOME_POSTS,
        {
            fetchPolicy: 'cache-and-network',
            variables,
            notifyOnNetworkStatusChange: true,
        },
    );

    const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;
    const loadingMore = networkStatus === 3;

    useEffect(() => {
        if (data) {
            setHomePosts(data);
        }
    }, [data]);

    const getHeroPost = () => {
        if (error) {
            return (
                <LoadingErrorDisclaimer
                    error={error}
                    failedTarget={'featured post banner'}
                    reportError={reportError}
                />
            );
        } /*else if (isLoading) {
            return (
                <Skeleton animation={'pulse'}>
                    <HeroPost
                        postTitle={''}
                        postUrl={''}
                        isLoading={false}
                        src={''}
                        alt={''}
                    />
                </Skeleton>
            );
        }*/ else {
            // Get Hero post data
            // Check if Hero featuredPost is not null or undefined
            if (homePosts.featuredPosts._0) {
                const { title, slug, image } = homePosts.featuredPosts._0;
                return (
                    <HeroPost
                        postTitle={title}
                        postUrl={ROUTES.HOME.to + slug}
                        alt={title}
                        src={image ? image.sourceUrl : ''}
                        srcSet={image ? image.sourceSet : ''}
                        isLoading={isLoading}
                    />
                );
            } else {
                // Optionally handle the case where their is no Hero Post set (Feature Post slot 0)
                return null;
            }
        }
    };

    return (
        <>
            {getHeroPost()}
            <Home
                {...props}
                homePosts={homePosts}
                error={error}
                fetchMore={fetchMore}
                variables={variables}
                loadingMore={loadingMore}
                loading={isLoading}
            />
        </>
    );
};

interface HomePageProps extends PageProps {
    posts: HomePosts;
}

export default Index;
