import gql from 'graphql-tag';

export const MY_USER = gql`
    query myUser {
        myUser {
            userId
            email
            emailVerified
            username
            preferredUsername
            displayName
            firstName
            lastName
            role
            title {
                typeId
                typeName
            }
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            banner {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
            disabled
            preferred
            createdAt
            updatedAt
            permissionGroup {
                groupName
            }
        }
    }
`;

export const STAFF = gql`
    query staffUsers {
        staffUsers {
            firstName
            lastName
            preferredUsername
            displayName
            role
            socialMedia {
                link
                type
            }
            avatar {
                sourceUrl
                sourceSet
            }
        }
    }
`;

export const EMAIL_EXISTS = gql`
    query userExists($email: String) {
        emailUsed: userExists(email: $email)
    }
`;

export const USERNAME_EXISTS = gql`
    query userExists($username: String) {
        usernameUsed: userExists(preferredUsername: $username)
    }
`;

export const USER_DIRECTORY = gql`
    query userDirectory($staff: Boolean, $creditTypes: [ID!], $searchInput: String) {
        userDirectory(
            staff: $staff
            creditTypes: $creditTypes
            searchInput: $searchInput
        ) {
            char
            results {
                preferredUsername
                displayName
                role
                socialMedia {
                    type
                    link
                }
                avatar {
                    sourceUrl
                    sourceSet
                }
                credits {
                    creditType {
                        typeName
                    }
                }
            }
        }
        creditTypes {
            typeId
            typeName
            typeDescription
        }
    }
`;

export const USER_PROFILE = gql`
    query profile($preferredUsername: String!) {
        profile(preferredUsername: $preferredUsername) {
            userId
            preferredUsername
            displayName
            preferred
            role
            title {
                typeId
                typeName
            }
            socialMedia {
                type
                link
            }
            avatar {
                sourceUrl
                sourceSet
            }
            banner {
                sourceUrl
                sourceSet
            }
            bio
            permissionGroup {
                groupName
            }
        }
    }
`;

export const USERNAME_OR_EMAIL_EXISTS = gql`
    query userExists($email: String, $username: String) {
        emailUsed: userExists(email: $email)
        usernameUsed: userExists(preferredUsername: $username)
    }
`;

export const SEARCH_USERS = gql`
    query searchUsers($input: String!) {
        searchUsers(input: $input) {
            preferredUsername
            email
            socialMedia {
                type
                link
            }
            avatar {
                sourceUrl
                sourceSet
            }
        }
    }
`;

export const AUTHORS = gql`
    query authors {
        authors {
            preferredUsername
            displayName
            avatar {
                sourceUrl
                sourceSet
            }
        }
    }
`;
