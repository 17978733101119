import React from 'react';
import AutoSuggestVariant, { AutoSuggestInputProps } from './AutoSuggestVariant';

const Layout = (props: LayoutPropTypes) => {
    const { typeVariant, ...rest } = props;

    if (typeVariant === 'autoComplete') {
        return <AutoSuggestVariant {...rest} />;
    } else {
        return null;
    }
};

export interface LayoutPropTypes extends AutoSuggestInputProps {
    typeVariant?: 'autoComplete' | 'search';
}

export default Layout;
