import React from 'react';
import withLayout from 'hocs/withLayout';
import { PageProps } from 'types/page';
import Category from './Category';
import { useRouter } from 'next/router';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';
import { CategoryWithPosts } from 'types/category';
import { useQuery } from '@apollo/client';
import { CATEGORY_WITH_POSTS } from 'lib/graphql/queries/category';

const Index = (props: CategoryIndexProps) => {
    let { category, variables, slug, error, loading, networkStatus } = props;

    const router = useRouter();

    // If slug was not passed directly (via curated routes)
    // Assume this layout was dynamically generated and retrieve the slug
    // from the pathname
    if (!slug) slug = String(router.query.slug);

    const { fetchMore } = useQuery(CATEGORY_WITH_POSTS, {
        fetchPolicy: 'cache-and-network',
        variables,
        notifyOnNetworkStatusChange: true,
    });

    const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;
    const loadingMore = networkStatus === 3;

    const renderContent = () => {
        return (
            <Category
                category={category}
                slug={slug}
                loading={isLoading}
                loadingMore={loadingMore}
                error={error}
                fetchMore={fetchMore}
                variables={variables}
            />
        );
    };

    return (
        <>
            <Seo
                type={'website'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: category ? category.name : 'Category',
                    path: ROUTES.CATEGORY.to + slug,
                    description: 'View Elevator posts in the ' + category + ' category.',
                }}
            />
            {renderContent()}
        </>
    );
};

export interface CategoryIndexProps extends PageProps {
    category?: CategoryWithPosts;
    variables: any;
    slug: string;
    error?: any;
    loading?: any;
    networkStatus?: any;
}

export default withLayout(Index, false);
