import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        disablePaperMargin: {
            margin: 0,
            padding: 0,
        },
        accountMenu: {
            minWidth: '330px',
            border: 'none',
        },
        cardHeader: {
            display: 'flex',
            alignItems: 'center',
            height: '80px',
            width: '100%',
            backgroundColor: theme.palette.background.default,
        },
        avatar: {
            borderRadius: '3px',
            marginLeft: theme.spacing(2),
        },
        accountInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: theme.spacing(2),
        },
        avatarContainer: {
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(1.8),
        },
        accountCardAvatar: {
            width: '45px',
            height: '45px',
            backgroundColor: theme.palette.custom.grey.dark,
        },
        userName: {
            fontSize: '1rem',
        },
        email: {
            maxWidth: '240px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        listItemIcon: {
            paddingRight: theme.spacing(2),
            marginRight: 0,
            minWidth: '14px',
            fontSize: 14,
        },
        defaultColor: {
            color: theme.palette.text.secondary,
        },
        listItemText: {
            fontWeight: 500,
            color: theme.palette.text.secondary,
            padding: theme.spacing(0.5, 0),
            fontSize: '0.85rem',
        },
        highEmphasis: {
            color: theme.palette.primary.main,
            fontWeight: 700,
        },
        listSep: {
            margin: theme.spacing(1, 0),
        },
        listItem: {
            color: 'white',
            textTransform: 'uppercase',
            '&:hover': {
                color: 'red',
                backgroundColor: 'transparent',
                cursor: 'pointer',
            },
        },
        lastItem: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(2, 2, 0.5, 2),
        },
    });

export default makeStyles(styles);
