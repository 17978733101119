import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(2),
            border: `2px solid transparent`,
            transition: '0.3s',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.custom.grey.light,
            },
        },
        activeContainer: {
            padding: theme.spacing(2),
            border: `2px solid black`,
        },

        //Card
        titlePrice: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        socialLink: {
            color: theme.palette.common.black,
            opacity: 0.54,
            textDecoration: 'underline',
            overflowWrap: 'anywhere',
        },
        titleAndIcon: {
            display: 'flex',
            justifyContent: 'flex-left',
            marginBottom: theme.spacing(0.5),
        },
        title: {
            paddingLeft: theme.spacing(1),
        },
        description: {
            color: 'rgba(0, 0, 0, 0.5)',
        },

        //icons
        desktopMac: {
            color: '#017676',
        },
        instagram: {
            background: 'linear-gradient(#F214E1,#DDA91E)',
            color: 'white',
            borderRadius: 6,
            height: '0.9em',
            width: '0.9em',
        },
        youtube: {
            color: '#E64444',
        },
    });

export default makeStyles(styles);
