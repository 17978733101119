import React from 'react';
import useStyles from './styles';
import { AdSlot, DFPManager } from 'react-dfp';
import { getAdConfig } from 'config/Ads';
import { AdSpaceType } from 'types/advertising';
import Typography from '@material-ui/core/Typography';

const AdSpace = (props: AdSpacePropTypes) => {
    const classes = useStyles();
    const { size, type, adUnit, slotId } = props;

    // Used for appending `test-` to an ad unit's name if we are in a testing platform.
    const AdUnitPrefix = process.env.cookieDomain === 'elevatormag.com' ? '' : 'test-';
    const prefixedAdUnit = AdUnitPrefix + adUnit;

    const adConfig = getAdConfig(type);

    const renderAdLabel = () => {
        return (
            <div className={classes.adLabelContainer}>
                <Typography variant={'caption'} color={'textSecondary'}>
                    Advertisement
                </Typography>
            </div>
        );
    };

    const onSlotViewable = () => {
        DFPManager.refresh();
    };

    if (size === 'responsive')
        return (
            <div className={classes.root}>
                {/* {renderAdLabel()} */}
                <div className={classes.mediumAd}>
                    <AdSlot
                        sizes={[adConfig.sizes.md]}
                        sizeMapping={[adConfig.sizeMapping.md]}
                        adUnit={prefixedAdUnit}
                        slotId={slotId}
                        //onSlotIsViewable={(dfpEventData: any) => console.log(`slot ${slotId} viewable!.`)}
                    />
                </div>
                <div className={classes.largeAd}>
                    <AdSlot
                        sizes={[adConfig.sizes.lg]}
                        sizeMapping={[adConfig.sizeMapping.lg]}
                        adUnit={prefixedAdUnit}
                        //slotId={slotId}
                        //onSlotIsViewable={(dfpEventData: any) => console.log(`slot ${slotId} viewable!.`)}
                    />
                </div>
            </div>
        );
    else
        return (
            <div className={classes.root}>
                {/* {renderAdLabel()} */}
                <AdSlot
                    sizes={[adConfig.sizes[size]]}
                    sizeMapping={[adConfig.sizeMapping[size]]}
                    adUnit={prefixedAdUnit}
                    //slotId={slotId}
                />
            </div>
        );
};

export type AdSpacePropTypes = {
    type: AdSpaceType;
    adUnit: string;
    size: 'responsive' | 'lg' | 'md';
    slotId?: string;
};

AdSpace.defaultProps = {
    size: 'responsive',
};

export default AdSpace;
