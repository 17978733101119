import gql from 'graphql-tag';

export const CREATE_CREDIT = gql`
    mutation createCredit($input: CreditInput!) {
        createCredit(input: $input) {
            creditId
            creditType {
                typeName
            }
            name
            user {
                userId
                preferredUsername
            }
            socialMedia {
                type
                link
            }
        }
    }
`;

export const UPDATE_CREDIT = gql`
    mutation updateCredit($id: ID!, $updates: UpdateCreditInput!) {
        updateCredit(id: $id, updates: $updates) {
            creditId
            name
            socialMedia {
                type
                link
            }
            user {
                userId
                preferredUsername
            }
        }
    }
`;

export const DELETE_CREDIT = gql`
    mutation deleteCredit($id: ID!) {
        deleteCredit(id: $id)
    }
`;
