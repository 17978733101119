import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },

        accordionContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            maxWidth: '450px',
            margin: theme.spacing(2.5, 0, 2, 0),
        },

        addButton: {
            borderWidth: '1.5px',
            minWidth: '35px',
        },
        iconButton: {
            padding: 0,
            margin: theme.spacing(2, 2, 3, 0),
        },

        editCreditForm: {
            width: '100%',
        },

        //Typography
        heavyFont: {
            fontWeight: 'bold',
        },
    });

export default makeStyles(styles);
