import React, { useEffect } from 'react';
import { CookieDisclaimer, VerifyEmailPopUp } from 'atoms';
import bugsnagClient from 'lib/errors/bugsnag';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { NavWrapper } from 'organisms';
import { DFPManager, DFPSlotsProvider } from 'react-dfp';
import Error from './_error';
import { reportError } from 'lib/errors';
import ElevatorProvider from '@elevatormedia/duffel-bag/dist/atoms/ElevatorProvider';
import elevatorTheme from '@elevatormedia/duffel-bag/dist/theme/elevatorTheme';

const ErrorBoundary = bugsnagClient.getPlugin('react');
const GA_KEY = 'G-WRGQMCX420';

const App = ({ Component, pageProps }: AppProps) => {
    useEffect(() => {
        DFPManager.load();
    }, []);

    return (
        <ErrorBoundary FallbackComponent={Error}>
            {/*<Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.ga4}`}/>
          <Script strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.ga4}');
            `}
          </Script>*/}
            <ElevatorProvider
                apiHost={new URL(process.env.apiUri).origin}
                theme={elevatorTheme}
                reportError={reportError}
                googleAnalyticsTrackingCode={GA_KEY}
            >
                <DFPSlotsProvider
                    dfpNetworkId={process.env.dfpNetworkId}
                    lazyLoad={true}
                    collapseEmptyDivs
                >
                    <NavWrapper>
                        <Component {...pageProps} />
                        <VerifyEmailPopUp />
                        <CookieDisclaimer />
                    </NavWrapper>
                </DFPSlotsProvider>
            </ElevatorProvider>
        </ErrorBoundary>
    );
};

export default App;
