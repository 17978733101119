import React from 'react';
import useStyles from './styles';
import { useFormikContext } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { NumberInputField } from 'atoms';
import { ImageUploader } from 'molecules';
import { BaseFieldValues, FormField, FormFieldType } from 'types/form';
import { Dayjs } from 'dayjs';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import dynamic from 'next/dynamic';
const DatePicker = dynamic(
    () => import('@elevatormedia/duffel-bag/dist/atoms/DatePicker'),
);
const PhoneNumberInput = dynamic(() => import('atoms/PhoneNumberInput'));

const Field = <T extends BaseFieldValues>(props: FieldProps<T>) => {
    const {
        type,
        name,
        id,
        label,
        placeholder,
        required,
        disabled,
        options,
        helperText,
    } = props;
    const {
        values,
        touched,
        errors,
        initialValues,
        handleChange,
        setFieldValue,
        setFieldTouched,
    } = useFormikContext<T>();
    const classes = useStyles();

    const fileInputField = React.useRef(null);

    const [selected, setSelected] = React.useState();
    const [toggle, handleToggle] = React.useState(false);

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleToggle(event.target.checked);
    };

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldTouched(event.target.name);
        handleChange(event);
    };

    const handlePhoneInputChange = (
        _value: string,
        _data: any,
        event: React.ChangeEvent<HTMLInputElement>,
        _formattedValue: string,
    ) => {
        setFieldTouched(event.target.name);
        handleChange(event);
    };

    const handleDropdownChange = (event: any) => {
        setSelected(event.target.value);
    };

    const handleDateChange = (newDate: Dayjs) => {
        setFieldValue(name, newDate);
        setFieldTouched(name, true);
    };

    const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldTouched(name);
        setFieldValue(name, event.target.files[0]);
    };

    const handleRemoveImage = () => {
        setFieldValue(name, null);
        fileInputField.current.value[name] = '';
    };

    const handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldTouched(name);
        setFieldValue(name, new Array(...event.target.files));
    };

    const handleRemoveFiles = (index: number) => {
        (values[name] as Array<File>).splice(index, 1);
        setFieldValue(name, new Array(...(values[name] as Array<File>)));
    };

    const renderType = (fieldType: FormFieldType) => {
        let content;

        switch (fieldType) {
            case 'text':
                content = (
                    <TextField
                        name={name as string}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        disableUnderline
                        fullWidth
                        // Formik
                        value={values[name]}
                        defaultValue={initialValues[name]}
                        onChange={handleTextFieldChange}
                        error={touched[name] && Boolean(errors[name])}
                        helperText={touched[name] ? errors[name] : ''}
                    />
                );
                break;
            case 'textArea':
                content = (
                    <TextField
                        multiline
                        minRows={5}
                        rowsMax={5}
                        name={name as string}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        disableUnderline
                        fullWidth
                        // Formik
                        value={values[name]}
                        defaultValue={initialValues[name]}
                        onChange={handleTextFieldChange}
                        error={touched[name] && Boolean(errors[name])}
                        helperText={touched[name] ? errors[name] : ''}
                    />
                );
                break;
            case 'dropdown':
                content = (
                    <FormControl fullWidth>
                        <InputLabel
                            className={classes.dropdownLabel}
                            required={required}
                            id={`${id}-select`}
                            htmlFor={id}
                        >
                            {label}
                        </InputLabel>
                        <Select
                            name={name as string}
                            id={id}
                            labelId={`${id}-select`}
                            placeholder={placeholder}
                            disabled={disabled}
                            required={required}
                            variant={'filled'}
                            inputProps={{
                                classes: {
                                    root: classes.dropdownInput,
                                },
                            }}
                            disableUnderline
                            fullWidth
                            // Formik
                            value={values[name]}
                            onChange={handleDropdownChange}
                            error={touched[name] && Boolean(errors[name])}
                        >
                            {options.map((value: any, index: number) => {
                                return (
                                    <MenuItem key={index} value={value}>
                                        {value as string}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                );
                break;
            case 'datePicker':
                content = (
                    <DatePicker
                        pickerType="date"
                        name={name as string}
                        id={id}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        fullWidth
                        // Formik
                        dateValue={values[name] as Dayjs}
                        onDateChange={handleDateChange}
                        defaultValue={initialValues[name]}
                        error={touched[name] && Boolean(errors[name])}
                        helperText={touched[name] ? errors[name] : ''}
                    />
                );
                break;
            case 'checkbox':
                content = (
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={name as string}
                                id={id}
                                required={required}
                                disabled={disabled}
                                color={'primary'}
                                checked={toggle}
                                onChange={handleCheck}
                            />
                        }
                        label={label}
                    />
                );
                break;
            case 'toggle':
                content = (
                    <FormControlLabel
                        control={
                            <Switch
                                name={name as string}
                                id={id}
                                required={required}
                                disabled={disabled}
                                color={'primary'}
                                checked={toggle}
                                onChange={handleCheck}
                            />
                        }
                        label={label}
                    />
                );
                break;
            case 'number':
                content = (
                    <NumberInputField /> // TODO: complete plain number input
                );
                break;
            case 'phone':
                content = (
                    <PhoneNumberInput
                        inputProps={{
                            name: name as string,
                            id: id,
                            required: required,
                            error: touched[name] && Boolean(errors[name]),
                            defaultValue: initialValues[name],
                            helperText: touched[name] ? errors[name] : '',
                        }}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        required={required}
                        // Formik
                        value={values[name] as string}
                        onChange={handlePhoneInputChange}
                    />
                );
                break;
            case 'image':
                content = (
                    <ImageUploader
                        variant={'content'}
                        buttonPosition={'left'}
                        label={label}
                        disabled={disabled}
                        required={required}
                        onRemoveImage={handleRemoveImage}
                        inputProps={{
                            name: name as string,
                            id: id,
                            ref: fileInputField,
                            onChange: handleAddImage,
                        }}
                        // Formik
                        file={values[name]}
                        error={touched[name] && Boolean(errors[name])}
                        helperText={touched[name] ? (errors[name] as string) : helperText}
                    />
                );
                break;
            case 'images':
            case 'files':
                content = (
                    <ImageUploader
                        variant={'multiple'}
                        label={label}
                        disabled={disabled}
                        required={required}
                        onRemoveImage={handleRemoveFiles}
                        inputProps={{
                            name: name as string,
                            id: id,
                            ref: fileInputField,
                            onChange: handleAddFiles,
                        }}
                        // Formik
                        files={values[name] as File[]}
                        error={touched[name] && Boolean(errors[name])}
                        helperText={touched[name] ? (errors[name] as string) : helperText}
                    />
                );
                break;
            default:
                break;
        }
        return content;
    };

    return <div className={classes.fieldContainer}>{renderType(type)}</div>;
};

export type FieldProps<T extends BaseFieldValues> = FormField<T>;

Field.defaultProps = {
    required: false,
    disabled: false,
};

export default Field;
