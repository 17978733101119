import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 0),
    },
    adSpaceContainer: {
        paddingTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

export default makeStyles(styles);
