import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 0),
    },
    mediumAd: {
        display: 'none',
        [theme.breakpoints.down(970)]: {
            display: 'block',
        },
    },
    largeAd: {
        display: 'block',
        [theme.breakpoints.down(970)]: {
            display: 'none',
        },
    },
    adLabelContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
    },
});

export default makeStyles(styles);
