import React from 'react';
import withLayout from 'hocs/withLayout';
import { PageProps } from 'types/page';
import { SEARCH_ALL } from 'lib/graphql/queries/post';
import { useQuery } from '@apollo/client';
import Search from './Search';

const Index = (props: SearchIndexProps) => {
    const { searchParam } = props;

    const variables: SearchQueryVariables = {
        limit: 9,
        skip: 0,
        search: searchParam,
    };

    const { data, loading, networkStatus, error, fetchMore } = useQuery(SEARCH_ALL, {
        fetchPolicy: 'cache-and-network',
        variables,
        notifyOnNetworkStatusChange: true,
    });

    const searchResults = data;
    const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;
    const loadingMore = networkStatus === 3;

    if (isLoading) {
        return null;
    } else {
        return (
            <Search
                loading={isLoading}
                loadingMore={loadingMore}
                searchResults={searchResults}
                searchParam={searchParam}
                error={error}
                fetchMore={fetchMore}
                variables={variables}
            />
        );
    }
};

export interface SearchIndexProps extends PageProps {
    searchParam?: string;
}

export type SearchQueryVariables = {
    search: string;
    limit: number;
    skip: number;
};

export default withLayout(Index, false);
