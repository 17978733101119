import React from 'react';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CardElement } from '@stripe/react-stripe-js';
import useStyles from './styles';
import { FormikProps } from 'formik';
import { CheckoutValues } from 'types/payments';
import useTheme from '@material-ui/core/styles/useTheme';
import dynamic from 'next/dynamic';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import { palette } from '@elevatormedia/duffel-bag/dist/theme/palette';

const LocationAutocomplete = dynamic(() =>
    import('@elevatormedia/duffel-bag/dist/atoms/LocationAutocomplete'),
);

interface CreditCardFormProps
    extends Pick<FormikProps<CheckoutValues>, 'values' | 'errors' | 'touched'> {
    handleInputChange: (fieldName: string, event: any, imperativeValue?: any) => void;
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({
    handleInputChange,
    values,
    errors,
    touched,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [creditInputActive, setCreditInputActive] = React.useState(false);

    const createCardFormOptions = () => {
        return {
            style: {
                base: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '16px',
                    '&$focused': {
                        color: palette.common.black,
                    },
                    '::placeholder': {
                        color: '#ACACAC',
                        fontWeight: 300,
                    },
                    fontWeight: 300,
                    iconColor: '#000000',
                },
                invalid: {
                    color: theme.palette.secondary.light,
                },
                complete: {
                    color: theme.palette.custom.green.faded,
                },
            },
        };
    };

    const getLabelClassName = () => {
        if (touched.cardInfo && Boolean(errors.cardInfo)) {
            return classes.errorText;
        } else if (creditInputActive) {
            return classes.activeText;
        } else {
            return classes.defaultText;
        }
    };

    return (
        <GridContainer direction={'column'} spacing={mobile ? 0 : 3} item={true}>
            <GridItem className={classes.textField}>
                <Typography variant={'subtitle2'} className={getLabelClassName()}>
                    Card *
                </Typography>
                <div className={classes.cardInputContainer}>
                    <CardElement
                        id={'cardInfo'}
                        onChange={(change) => handleInputChange('cardInfo', null, change)}
                        onFocus={() => setCreditInputActive((prev) => !prev)}
                        onBlur={() => setCreditInputActive((prev) => !prev)}
                        {...createCardFormOptions()}
                    />
                </div>
                <Typography variant={'caption'} color={'error'}>
                    {touched.cardInfo ? errors.cardInfo : ''}
                </Typography>
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'nameOnCard'}
                    name={'nameOnCard'}
                    label={'Name on card'}
                    autoComplete={'name'}
                    fullWidth
                    required
                    value={values.nameOnCard}
                    onChange={(event) => handleInputChange('nameOnCard', event)}
                    error={touched.nameOnCard && Boolean(errors.nameOnCard)}
                    helperText={touched.nameOnCard ? errors.nameOnCard : ''}
                />
            </GridItem>
            <GridItem className={classes.textField}>
                <TextField
                    id={'address'}
                    name={'address'}
                    label={'Address'}
                    required
                    placeholder={'123 Fake St.'}
                    autoComplete={'street-address'}
                    value={values.address}
                    fullWidth
                    onChange={(event) => handleInputChange('address', event)}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address ? errors.address : ''}
                />
            </GridItem>
            <GridContainer
                item={true}
                direction={mobile ? 'column' : 'row'}
                spacing={mobile ? 0 : 3}
            >
                <GridItem xs={!mobile} className={classes.textField}>
                    <TextField
                        id={'city'}
                        name={'city'}
                        label={'City'}
                        fullWidth
                        required
                        value={values.city}
                        placeholder={'City'}
                        autoComplete={'address-level2'}
                        onChange={(event) => handleInputChange('city', event)}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city ? errors.city : ''}
                    />
                </GridItem>
                <GridItem xs={!mobile} className={classes.stateTextField}>
                    <LocationAutocomplete
                        type={'state'}
                        inputProps={{
                            id: 'state',
                            name: 'state',
                            label: 'State',
                            placeholder: 'Illinois',
                            value: values.state,
                            required: true,
                            fullWidth: true,
                            error: null,
                            helperText: null,
                        }}
                        onChange={(input: string) =>
                            handleInputChange('state', null, input)
                        }
                        googlePlacesApiKey={process.env.googlePlacesApiKey}
                    />
                </GridItem>
            </GridContainer>
            <GridItem className={classes.textField}>
                <LocationAutocomplete
                    type={'country'}
                    inputProps={{
                        id: 'country',
                        name: 'country',
                        label: 'Country',
                        placeholder: 'United States',
                        value: values.country,
                        required: true,
                        fullWidth: true,
                        error: null,
                        helperText: null,
                    }}
                    onChange={(input: string) =>
                        handleInputChange('country', null, input)
                    }
                    googlePlacesApiKey={process.env.googlePlacesApiKey}
                />
            </GridItem>
        </GridContainer>
    );
};

export default CreditCardForm;
