import gql from 'graphql-tag';

export const UPDATE_MY_PROFILE = gql`
    mutation updateMyProfile($input: UpdateMyProfileInput!) {
        updateMyProfile(input: $input) {
            displayName
            avatar {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            banner {
                path
                sourceUrl
                sourceSet
                metadata {
                    bucket
                    mimetype
                    s3Url
                    filename
                }
            }
            bio
            socialMedia {
                type
                link
            }
        }
    }
`;
