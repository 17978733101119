import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    // Root styles
    userCard: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        minWidth: '200px',
    },
    defaultCardColor: {
        backgroundColor: theme.palette.background.default,
    },
    paperCardColor: {
        backgroundColor: theme.palette.background.paper,
    },
    userCardContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    spaceOut: {
        justifyContent: 'space-between',
    },
    alignTop: {
        justifyContent: 'flex-start',
    },

    // Card Container outer padding by size
    largeCardPadding: {
        padding: theme.spacing(2),
    },
    mediumCardPadding: {
        padding: theme.spacing(2),
    },
    smallCardPadding: {
        padding: theme.spacing(1),
    },

    // User nameplate header
    userFullName: {
        fontWeight: 700,
        lineHeight: '1em',
    },
    largeUserFullNameText: {
        fontSize: theme.typography.pxToRem(20),
    },
    mediumUserFullNameText: {
        fontSize: theme.typography.pxToRem(16),
    },
    smallUserFullNameText: {
        fontSize: theme.typography.pxToRem(12),
    },

    // Text header
    userCardTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        alignItems: 'flex-start',
    },

    // Preferred username
    largePreferredUserNameText: {
        fontSize: theme.typography.pxToRem(16),
    },
    mediumPreferredUserNameText: {
        fontSize: theme.typography.pxToRem(14),
    },
    smallPreferredUserNameText: {
        fontSize: theme.typography.pxToRem(10),
        lineHeight: 'normal',
        paddingTop: '4px',
    },

    socialMediaContainer: {
        paddingTop: '6px',
        marginLeft: '-2px',
    },
});

export default makeStyles(styles);
