import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import { reportError } from 'lib/errors';
import {
    isValidPassword,
    notTooGuessable,
} from '@elevatormedia/duffel-bag/dist/utils/auth';
import { changePassword } from '@elevatormedia/duffel-bag/dist/lib/auth';
import { ChangePasswordInput, User } from '@elevatormedia/duffel-bag/dist/types/user';
import { Formik, FormikHelpers } from 'formik';
import { object as yupObject, string } from 'yup';
import { useSnackbar } from 'notistack';

const Index = (props: ChangePasswordFormIndexProps) => {
    const { currentUser, togglePasswordModalOpen } = props;

    const { enqueueSnackbar } = useSnackbar();

    const validationSchema = yupObject({
        oldPassword: string().required('This field is required'),
        newPassword: string()
            .required('This field is required')
            .test('requirements', 'Password requirements not met', (value) => {
                if (!value) return false;
                return isValidPassword(value);
            })
            .test('notTooGuessable', 'Password too guessable', (value) => {
                if (!value) return false;
                return notTooGuessable(value);
            }),
        confirmPassword: string()
            .test('passwordsMatch', 'Password does not match', function (value) {
                if (!value) return false;
                return value === this.parent.newPassword;
            })
            .required('This field is required'),
    });

    const initialPasswordValues: ChangePasswordInput = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    const handleChangePasswordSubmit = async (
        values: ChangePasswordInput,
        actions: FormikHelpers<ChangePasswordInput>,
    ) => {
        try {
            await changePassword(
                values.oldPassword,
                values.newPassword,
                process.env.authEndpoint,
            );
            actions.setSubmitting(false);
            actions.resetForm();

            enqueueSnackbar(`Password Changed`, {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });

            togglePasswordModalOpen();
        } catch (err) {
            reportError(err, {
                metaData: {
                    operation: 'cognito changePassword',
                },
                user: {
                    username: currentUser.username,
                    email: currentUser.email,
                },
            });
            enqueueSnackbar(`Error Setting New Password`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialPasswordValues}
            onSubmit={handleChangePasswordSubmit}
        >
            <ChangePasswordForm
                togglePasswordModalOpen={togglePasswordModalOpen}
                {...props}
            />
        </Formik>
    );
};

export interface ChangePasswordFormIndexProps {
    togglePasswordModalOpen: () => void;
    currentUser: User;
}

export default Index;
