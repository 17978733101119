import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FieldsProps } from './CheckoutForm';
import useStyles from './styles';
import clsx from 'clsx';
import { CardElement } from '@stripe/react-stripe-js';
import dynamic from 'next/dynamic';
import { palette as paletteConfig } from '@elevatormedia/duffel-bag/dist/theme/palette';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import { ColorVariants } from '@elevatormedia/duffel-bag/dist/types/palette';
const LocationAutocomplete = dynamic(() =>
    import('@elevatormedia/duffel-bag/dist/atoms/LocationAutocomplete'),
);

const CreditCardFields = (props: FieldsProps) => {
    const classes = useStyles();
    const { values, handleChange, touched, errors, setFieldValue } = props;

    const [creditInputActive, setCreditInputActive] = React.useState(false);

    const createCardFormOptions = () => {
        return {
            style: {
                base: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '16px',
                    '&$focused': {
                        color: paletteConfig.common.black,
                    },
                    '::placeholder': {
                        color: '#ACACAC',
                        fontWeight: 300,
                    },
                    fontWeight: 300,
                    iconColor: '#000000',
                },
                invalid: {
                    color: (paletteConfig.secondary as ColorVariants).light,
                },
                complete: {
                    // TODO: Debug why duffel-bag is not referencing it's own Palette interface
                    color: (paletteConfig as any).custom.green.main,
                },
            },
        };
    };

    const getLabelClassName = () => {
        if (touched.cardInfo && Boolean(errors.cardInfo)) {
            return classes.errorText;
        } else if (creditInputActive) {
            return classes.activeText;
        } else {
            return classes.defaultText;
        }
    };

    return (
        <>
            <GridItem xs={12} paddingY={2}>
                <Typography
                    variant={'caption'}
                    color={'textSecondary'}
                    className={getLabelClassName()}
                >
                    Card *
                </Typography>
                <div
                    className={clsx(
                        classes.cardInputContainer,
                        creditInputActive && classes.cardInputActiveContainer,
                    )}
                >
                    <CardElement
                        id={'cardInfo'}
                        onChange={(change) => setFieldValue('cardInfo', change)}
                        onFocus={() => setCreditInputActive((prev) => !prev)}
                        onBlur={() => setCreditInputActive((prev) => !prev)}
                        {...createCardFormOptions()}
                    />
                </div>
                <Typography variant={'caption'} color={'error'}>
                    {touched.cardInfo ? errors.cardInfo : ''}
                </Typography>
            </GridItem>
            <GridItem xs={12} paddingY={2}>
                <TextField
                    id={'nameOnCard'}
                    name={'nameOnCard'}
                    label={'Name on card'}
                    autoComplete={'name'}
                    fullWidth
                    required
                    value={values.nameOnCard}
                    onChange={handleChange}
                    error={touched.nameOnCard && Boolean(errors.nameOnCard)}
                    helperText={touched.nameOnCard ? errors.nameOnCard : ''}
                />
            </GridItem>
            <GridItem xs={12} paddingY={2}>
                <TextField
                    name={'address'}
                    label={'Address'}
                    required
                    placeholder={'123 Fake St.'}
                    autoComplete={'street-address'}
                    value={values.address}
                    onChange={handleChange}
                    fullWidth
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address ? errors.address : ''}
                />
            </GridItem>
            <GridContainer direction={'row'} spacing={3}>
                <GridItem xs={12} sm={6} paddingY={2}>
                    <TextField
                        name={'city'}
                        label={'City'}
                        required
                        placeholder={'City'}
                        autoComplete={'address-level2'}
                        value={values.city}
                        onChange={handleChange}
                        fullWidth
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city ? errors.city : ''}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} paddingY={2} className={classes.stateTextField}>
                    <LocationAutocomplete
                        type={'state'}
                        inputProps={{
                            id: 'state',
                            name: 'state',
                            label: 'State',
                            placeholder: 'Illinois',
                            value: values.state,
                            required: true,
                            fullWidth: true,
                            error: null,
                            helperText: null,
                        }}
                        onChange={(input: string) => setFieldValue('state', input)}
                        googlePlacesApiKey={process.env.googlePlacesApiKey}
                    />
                </GridItem>
            </GridContainer>
            <GridItem xs={12} paddingY={2}>
                <LocationAutocomplete
                    type={'country'}
                    inputProps={{
                        id: 'country',
                        name: 'country',
                        label: 'Country',
                        placeholder: 'United States',
                        value: values.country,
                        required: true,
                        fullWidth: true,
                        error: null,
                        helperText: null,
                    }}
                    onChange={(input: string) => setFieldValue('country', input)}
                    googlePlacesApiKey={process.env.googlePlacesApiKey}
                />
            </GridItem>
        </>
    );
};

export default CreditCardFields;
