import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ROUTES } from 'config/Nav';
import { SocialMediaGroup } from 'molecules';
import useStyles from './styles';
import clsx from 'clsx';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import { SocialMediaLink } from '@elevatormedia/duffel-bag/dist/types/socialMedia';
import { PublicUserInfo } from '@elevatormedia/duffel-bag/dist/types/user';
import { SizeVariant } from '@elevatormedia/duffel-bag/dist/types/variants';
import Avatar from '@elevatormedia/duffel-bag/dist/atoms/Avatar';

const UserCard = (props: UserCardPropTypes) => {
    const classes = useStyles();

    const { user, size, socialMedia, name, cardColor } = props;

    const userNameSizeMap = {
        medium: 'large',
        large: 'large',
        small: 'small',
    };

    const renderAvatarLink = () => {
        if (user) {
            return (
                <Link variant={'wrapper'} href={ROUTES.USER.to + user.preferredUsername}>
                    <Avatar
                        imageProps={{
                            alt: user.preferredUsername,
                            src: user.avatar && user.avatar.sourceUrl,
                            srcSet: user.avatar && user.avatar.sourceSet,
                        }}
                        variant={size}
                        username={
                            user.displayName ? user.displayName : user.preferredUsername
                        }
                    />
                </Link>
            );
        } else {
            return <Avatar variant={size} username={name} />;
        }
    };

    const renderUserBasicInfo = () => {
        return (
            <div
                className={clsx(classes.userCardTextContainer, {
                    [classes.alignTop]: !socialMedia,
                    [classes.spaceOut]: socialMedia,
                })}
            >
                <div>
                    <Typography
                        display={'block'}
                        variant={'subtitle2'}
                        className={clsx(
                            classes.userFullName,
                            classes[
                                `${userNameSizeMap[size]}UserFullNameText` as keyof typeof classes
                            ],
                        )}
                    >
                        {name}
                    </Typography>
                    {user && (
                        <Link
                            variant={'wrapper'}
                            href={ROUTES.USER.to + user.preferredUsername}
                        >
                            <Typography
                                variant={'subtitle2'}
                                color={'secondary'}
                                gutterBottom
                                className={
                                    classes[
                                        `${size}PreferredUserNameText` as keyof typeof classes
                                    ]
                                }
                            >
                                @{user.preferredUsername}
                            </Typography>
                        </Link>
                    )}
                </div>
                {socialMedia && (
                    <div className={classes.socialMediaContainer}>
                        <SocialMediaGroup socialMedia={socialMedia} variant={'small'} />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div
            className={clsx(
                classes.userCard,
                classes[`${size}CardPadding` as keyof typeof classes],
                classes[`${cardColor}CardColor` as keyof typeof classes],
            )}
        >
            <div className={classes.userCardContentContainer}>
                {renderAvatarLink()}
                {renderUserBasicInfo()}
            </div>
        </div>
    );
};

UserCard.defaultProps = {
    size: 'medium',
    cardColor: 'default',
};

export type UserCardPropTypes = {
    key?: number | string;
    name: string;
    size?: SizeVariant;
    user: PublicUserInfo;
    socialMedia?: SocialMediaLink[];
    cardColor?: 'default' | 'paper';
};

export default UserCard;
