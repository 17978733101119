import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        radioGroupContainer: {
            display: 'flex',
            flexDirection: 'row',
            padding: theme.spacing(1, 0, 2, 0),
        },
        radioCard: {
            padding: theme.spacing(1),
            marginRight: theme.spacing(2),
            margin: 0,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '5px',
        },
        radioCardContent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        paymentTypeLabel: {
            color: theme.palette.text.secondary,
        },
        paymentIcon: {
            marginRight: theme.spacing(1),
            color: theme.palette.text.secondary,
        },
        paymentActiveColor: {
            color: theme.palette.primary.main,
        },
        activeRadioCard: {
            backgroundColor: theme.palette.getRgba(theme.palette.primary.main, 0.1),
            border: `1px solid ${theme.palette.primary.main}`,
        },

        // AccountFields
        passwordFieldAdornment: {
            paddingRight: theme.spacing(1),
        },

        // Custom Stripe Elements
        cardInputContainer: {
            padding: theme.spacing(2, 1),
            backgroundColor: theme.palette.custom.grey.light,
            flexDirection: 'row',
            alignItems: 'center',
            zIndex: 0,
            borderBottom: `1px solid ${theme.palette.getRgba(
                theme.palette.common.black,
                0.42,
            )}`,
            '&:hover': {
                borderBottom: `2px solid ${theme.palette.common.black}`,
                paddingBottom: `15px`,
            },
        },
        cardInputActiveContainer: {
            borderBottom: `2px solid ${theme.palette.common.black}`,
            paddingBottom: `15px`,
        },
        errorText: {
            color: 'red',
            fontWeight: 700,
        },
        activeText: {
            color: theme.palette.common.black,
            fontWeight: 700,
        },
        defaultText: {
            color: theme.palette.text.secondary,
            fontWeight: 700,
        },
        menu: {
            padding: 0,
            maxHeight: '300px',
        },
        stateTextField: {
            zIndex: 1000,
        },

        // account form
        checkmark: {
            color: theme.palette.custom.green.main,
        },
    });

export default makeStyles(styles);
