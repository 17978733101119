import React from 'react';

const TwitterEmbed = (props: TwitterEmbedPropTypes) => {
    const { rawHTML, id } = props;

    return <div id={id} dangerouslySetInnerHTML={{ __html: rawHTML }} />;
};

export type TwitterEmbedPropTypes = {
    rawHTML: string;
    id?: string;
};

TwitterEmbed.defaultProps = {
    id: 'container',
};

export default TwitterEmbed;
