import React from 'react';
import { CategoryWithPosts } from 'types/category';
import useStyles from './styles';
import { TeaserGrid } from 'organisms';
import { AdSpace } from 'atoms';
import ScrollToTop from '@elevatormedia/duffel-bag/dist/atoms/ScrollToTop';
import {
    PaginationOptions,
    QueryResult,
} from '@elevatormedia/duffel-bag/dist/types/graphql';

const Category = (props: CategoryPropTypes) => {
    const classes = useStyles();
    const { category, fetchMore, variables, slug, loading, error } = props;

    const [posts, setPosts] = React.useState(category.posts);

    React.useEffect(() => {
        setPosts(category.posts);
    }, [slug, category.posts]);

    const onLoadMore = () => {
        fetchMore({
            variables: {
                cursor: {
                    limit: variables.cursor.limit,
                    skip: posts.length,
                },
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) return prev;

                const newPosts = [
                    ...prev.category.posts,
                    ...fetchMoreResult.category.posts,
                ];

                setPosts(newPosts);

                const newData = Object.assign({}, prev, {
                    category: {
                        ...category,
                        posts: newPosts,
                    },
                });

                return newData;
            },
        });
    };

    const renderAdSpace = () => {
        return (
            <div className={classes.adSpaceContainer}>
                <AdSpace type={'banner-ad'} adUnit={'ELEVATOR-Mid'} />
            </div>
        );
    };

    return (
        <>
            <div className={classes.root}>
                <TeaserGrid
                    posts={posts}
                    onLoadMore={onLoadMore}
                    sectionTitle={category ? category.name : slug}
                    error={error}
                    loadLimit={variables.cursor.limit}
                    isLoading={loading}
                />
                {renderAdSpace()}
            </div>
            <ScrollToTop visibleYOffset={70} />
        </>
    );
};

export type CategoryQueryVariables = {
    slug: string;
    cursor: PaginationOptions;
};

export interface CategoryPropTypes
    extends QueryResult<'category', CategoryWithPosts, CategoryQueryVariables> {
    slug: string;
}

export default Category;
