import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import withLayout from 'hocs/withLayout';
import { Sidebar } from 'layouts';
import React from 'react';
import { PageProps } from 'types/page';
import useStyles from './styles';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';
import clsx from 'clsx';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import Image from 'next/image';

const Scammers = (props: ScammersProps) => {
    const classes = useStyles();

    const TextHeader = ({ children }: any) => (
        <Typography variant={'subtitle1'} className={classes.textHeader}>
            {children}
        </Typography>
    );

    const Section = ({
        header,
        children,
    }: {
        header: string;
        children: React.ReactNode;
    }) => (
        <div className={classes.paragraphContainer}>
            <TextHeader>{header}</TextHeader>

            {children}
        </div>
    );

    const ExternalLink = ({
        href,
        addBold,
        children,
    }: {
        href: string;
        addBold: boolean;
        children: string;
    }) => (
        <a
            href={href}
            target={'_blank'}
            rel={'noreferrer'}
            className={clsx(classes.externalLink, addBold && classes.addBold)}
        >
            {children}
        </a>
    );

    const ExampleImage = ({
        sourceUrl,
        altText,
    }: {
        sourceUrl: string;
        altText: string;
    }) => (
        <Image
            src={sourceUrl}
            alt={altText}
            className={classes.sampleImage}
            width={750}
            height={1624}
        />
    );

    const renderContent = () => {
        return (
            <div className={classes.root}>
                <Link href={ROUTES.SCAMMERS.to} variant={'title-general'}>
                    HOW TO REPORT SCAMMERS
                </Link>
                <div className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                </div>

                <div className={classes.paragraphContainer}>
                    <Typography>
                        ELEVATOR Media and its employees will never email or message you
                        for direct payments via Paypal, CashApp or other banking services.
                        All ELEVATOR Media employees have an official @elevatormag.com
                        email address and will never email you from non-elevatormag.com
                        email address about ELEVATOR opportunities or services.{' '}
                        <strong>DO NOT send ANY payments</strong> to anyone claiming to
                        work for ELEVATOR Media. If You receive any emails or messages
                        about paid uploads, posts, promo services,{' '}
                        <strong>please help us report these scammers</strong> by asking
                        for their payment info, screenshotting their emails or messages
                        and reporting them to the appropriate service using the methods
                        below.
                    </Typography>
                </div>

                <Section header={'Gmail'}>
                    <Typography component={'span'}>
                        If you receive an email from an @gmail.com email address offering
                        promotional services for ELEVATOR you can report them directly to
                        google here:{' '}
                        <ExternalLink
                            href={'https://support.google.com/mail/contact/abuse'}
                            addBold={false}
                        >
                            https://support.google.com/mail/contact/abuse
                        </ExternalLink>
                    </Typography>
                </Section>

                <Section header={'PayPal'}>
                    <Typography display={'block'}>
                        If you receive any emails or messages offering promotional
                        services for ELEVATOR asking for Paypal payments you can report
                        their Paypal email directly to Paypal by DMing their help twitter
                        or the email address below.
                    </Typography>
                    <br />
                    <Typography component={'span'}>
                        Twitter:{' '}
                        <ExternalLink
                            href={'https://twitter.com/askpaypal'}
                            addBold={false}
                        >
                            @askpaypal
                        </ExternalLink>
                    </Typography>
                    <br />
                    <Typography component={'span'}>
                        Email:{' '}
                        <ExternalLink
                            href={'mailto:aupviolations@paypal.com'}
                            addBold={false}
                        >
                            aupviolations@paypal.com
                        </ExternalLink>
                    </Typography>
                </Section>

                <Section header={'Cash App'}>
                    <Typography display={'block'}>
                        If you receive any emails or messages offering promotional
                        services for ELEVATOR asking for CashApp payments you can report
                        their CashApp username directly to CashApp by DMing their help
                        twitter.
                    </Typography>
                    <br />
                    <Typography component={'span'}>
                        Twitter:{' '}
                        <ExternalLink
                            href={'https://twitter.com/cashsupport'}
                            addBold={false}
                        >
                            @cashsupport
                        </ExternalLink>
                    </Typography>
                </Section>
                {/*
                <Typography variant={'h6'}>Example Scam Messages</Typography>
                <div className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                </div>
                <ExampleImage
                    sourceUrl={`https://elevator-media.imgix.net/2019/10/elevator-scammers1.jpg?fm=pjpg&ixlib=php-1.2.1&s=fddd9a6c533746f7a4cf6ea83ddeb9bf`}
                    altText={'Scammer sample image 1'}
                />
                <ExampleImage
                    sourceUrl={`https://elevator-media.imgix.net/2019/10/elevator-scammers2.jpg?fm=pjpg&ixlib=php-1.2.1&s=f555401bbef2cc4c3c3fe3a35279c822`}
                    altText={'Scammer sample image 2'}
                />
                <ExampleImage
                    sourceUrl={`https://elevator-media.imgix.net/2019/10/elevator-scammers3.jpg?fm=pjpg&ixlib=php-1.2.1&s=e02546de6fe5fe340182134850f45d4e`}
                    altText={'Scammer sample image 3'}
                />
                <ExampleImage
                    sourceUrl={`https://elevator-media.imgix.net/2019/10/IMG_7559.jpg?fm=pjpg&ixlib=php-1.2.1&s=753c655bbed39f8f96285bf7aeb8e744`}
                    altText={'Scammer sample image 4'}
                />
                */}
            </div>
        );
    };

    return (
        <>
            <Seo
                type={'article'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: 'How to Report Scammers',
                    path: ROUTES.SCAMMERS.to,
                    article: {
                        tags: ['scam', 'scammers'],
                    },
                }}
            />
            <Sidebar {...props}>{renderContent()}</Sidebar>
        </>
    );
};

export interface ScammersProps extends PageProps {}

export default withLayout(Scammers, true, true);
