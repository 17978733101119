import React, { MouseEventHandler } from 'react';
import useStyles from './styles';
import { PostCard } from 'molecules';
import { PostCardInfo } from 'types/post';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { reportError } from 'lib/errors';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';
import SectionHeader from '@elevatormedia/duffel-bag/dist/atoms/SectionHeader';

const TeaserGrid: React.FC<TeaserGridPropTypes> = ({
    posts,
    error,
    onLoadMore,
    loadLimit,
    sectionTitle,
    loadingMore,
    isLoading,
    variant,
}) => {
    const classes = useStyles();

    const renderContent = () => {
        if (error)
            return (
                <LoadingErrorDisclaimer
                    reportError={reportError}
                    error={error}
                    failedTarget={'posts'}
                />
            );
        else if (posts.length === 0)
            if (isLoading) {
                return Array.from(Array(loadLimit), (_x, index) => index + 1).map(
                    (value) => (
                        <GridItem key={value} xs={12} sm={6} md={4}>
                            <Skeleton variant="rect" width="100%" animation="pulse">
                                <div className={classes.skeletonContainer} />
                            </Skeleton>
                        </GridItem>
                    ),
                );
            } else {
                return (
                    <GridItem
                        xs={12}
                        className={clsx(classes.centeredContainer, classes.contentHeight)}
                    >
                        <Typography>No posts found</Typography>
                    </GridItem>
                );
            }
        else
            return posts.map((data) => (
                <GridItem key={data.slug} xs={12} sm={6} md={4}>
                    <PostCard {...data} variant={variant} />
                </GridItem>
            ));
    };

    return (
        <section>
            <SectionHeader title={sectionTitle} />
            <GridContainer spacing={2} paddingY={2}>
                {renderContent()}
            </GridContainer>
            {onLoadMore && (
                <>
                    {loadingMore && (
                        <div className={classes.loadingMoreContainer}>
                            <CircularProgress />
                        </div>
                    )}
                    {!loadingMore && posts.length > 0 && !error && (
                        <Button
                            onClick={onLoadMore}
                            variant={'primary'}
                            fullWidth
                            disabled={posts.length % loadLimit !== 0}
                        >
                            Load More
                        </Button>
                    )}
                </>
            )}
        </section>
    );
};

export type TeaserGridPropTypes = {
    posts: Array<PostCardInfo>;
    error?: any;
    onLoadMore: MouseEventHandler;
    loadLimit?: number;
    sectionTitle?: string;
    loadingMore?: boolean;
    isLoading: boolean;
    variant?: 'main' | 'sidebar';
};

TeaserGrid.defaultProps = {
    sectionTitle: 'Latest',
    loadLimit: 9,
    variant: 'main',
};

export default TeaserGrid;
