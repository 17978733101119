import React from 'react';
import useStyles from './styles';
import { PostCardInfo } from 'types/post';
import { TeaserGrid } from 'organisms';
import { QueryResult } from '@elevatormedia/duffel-bag/dist/types/graphql';

const PopularPostList = (props: PopularPostListPropTypes) => {
    const classes = useStyles();

    const { posts, error, loading } = props;

    return (
        <section className={classes.root}>
            <TeaserGrid
                sectionTitle={'Trending'}
                posts={posts}
                loadLimit={6}
                isLoading={loading}
                error={error}
                loadingMore={false}
                onLoadMore={null}
                variant={'sidebar'}
            />
        </section>
    );
};

export interface PopularPostListPropTypes
    extends QueryResult<'posts', Array<PostCardInfo>> {}

export default PopularPostList;
