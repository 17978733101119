import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
            },
        },
        padding: {
            paddingTop: theme.spacing(4),
        },
        button: {
            maxHeight: '40px',
            minWidth: '4.3vw',
            padding: theme.spacing(1),
            borderRadius: '4px',
            fontSize: theme.typography.pxToRem(14),
            letterSpacing: theme.typography.pxToRem(1),
        },
        cancelButton: {
            margin: theme.spacing(0, 2, 0, 0),
            borderColor: 'rgba(0, 0, 0, 0.45)',
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2, 0, 0, 0),
            },
        },
    }),
);

export default useStyles;
