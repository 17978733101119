import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        links: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 'bold',
            padding: theme.spacing(0, 0.5),
        },
    }),
);

export default useStyles;
