import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formRoot: {
            width: '100%',
        },
        buttonSpacer: {
            width: theme.spacing(2),
        },
        textFieldContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        displayNameTextField: {
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        saveButton: {
            maxWidth: '500px',
        },
        buttonGroupContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: theme.spacing(4, 2),
            paddingBottom: '10vh',
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingBottom: theme.spacing(3),
        },
        snackbarButton: {
            color: theme.palette.common.white,
        },
        divider: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        sectionHeader: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 'bold',
        },
        gridContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        bio: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },

        //role selection
        roleContainer: {
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        inputLabel: {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        typeSelector: {
            borderRadius: 0,
            margin: theme.spacing(0.5, 0),
            paddingTop: theme.spacing(1),
        },
        selectInput: {
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: 500,
            fontSize: '1.10rem',
            color: 'rgba(0, 0, 0, 0.54)',
            padding: theme.spacing(1.5, 0, 1.5, 2),
            backgroundColor: theme.palette.custom.grey.light + ' !important',
            '&:focused': {
                color: theme.palette.custom.grey
                    ? theme.palette.custom.grey.dark
                    : undefined,
            },
        },
    }),
);

export default useStyles;
