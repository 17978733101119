import React from 'react';
import withLayout from 'hocs/withLayout';
import { useApolloClient, useMutation } from '@apollo/client';
import AccountSettings from './AccountSettings';
import { PageProps } from 'types/page';
import { Formik, FormikHelpers } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import {
    EMAIL_EXISTS,
    MY_USER,
    USERNAME_EXISTS,
    USERNAME_OR_EMAIL_EXISTS,
} from 'lib/graphql/queries/user';
import { reportError } from 'lib/errors';
import { UPDATE_MY_PROFILE } from 'lib/graphql/mutations/user';
import { useSnackbar } from 'notistack';
import { ROUTES } from 'config/Nav';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import {
    UpdateAccountInput,
    UsernameOrEmailExistsArgs,
} from '@elevatormedia/duffel-bag/dist/types/user';
import Loading from '@elevatormedia/duffel-bag/dist/atoms/Loading';

const Index = (props: AccountSettingsIndexProps) => {
    const { currentUser, hydrateUser } = useAuth();

    const [touchedFields, setTouchedFields] = React.useState<string[]>([]);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const checkAuth = async () => {
            const hydratedUser = await hydrateUser(false);
            if (!hydratedUser && window) window.location.replace(ROUTES.AUTH.SIGN_IN.to);
        };

        checkAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const myProfile = currentUser;

    const apolloClient = useApolloClient();

    const checkUniqueness = async (
        args: UsernameOrEmailExistsArgs,
        query = USERNAME_OR_EMAIL_EXISTS,
    ) => {
        try {
            const { data, errors } = await apolloClient.query({
                query,
                variables: args,
                fetchPolicy: 'network-only',
            });

            if (errors)
                return {
                    emailUsed: true,
                    usernameUsed: true,
                };
            else if (data) return data;
        } catch (err) {
            reportError(err, {
                metaData: {
                    page: 'Account Settings',
                    operation: 'query userExists',
                    arguments: args,
                },
                user: {
                    username: myProfile.username,
                    preferredUsername: myProfile.preferredUsername,
                    email: myProfile.email,
                },
            });
            return {
                emailUsed: true,
                usernameUsed: true,
            };
        }
    };

    const [updateMyProfile] = useMutation(UPDATE_MY_PROFILE);

    const handleSubmit = async (
        values: UpdateAccountInput,
        actions: FormikHelpers<UpdateAccountInput>,
    ) => {
        const updates: Partial<UpdateAccountInput> = {};

        touchedFields.forEach((field) => {
            const fieldKey = field as keyof UpdateAccountInput;
            updates[fieldKey] = values[fieldKey];
        });

        let invalidInput = false;

        if (updates.email && updates.preferredUsername) {
            const { emailUsed, usernameUsed } = await checkUniqueness({
                email: updates.email,
                username: updates.preferredUsername,
            });

            if (emailUsed || usernameUsed) {
                if (emailUsed)
                    actions.setFieldError('email', 'This email is already in use');

                if (usernameUsed)
                    actions.setFieldError(
                        'preferredUsername',
                        'This username is already in use',
                    );

                invalidInput = true;
            }
        } else if (updates.email) {
            const { emailUsed } = await checkUniqueness(
                {
                    email: updates.email,
                },
                EMAIL_EXISTS,
            );

            if (emailUsed) {
                actions.setFieldError('email', 'This email is already in use');
                invalidInput = true;
            }
        } else if (updates.preferredUsername) {
            const { usernameUsed } = await checkUniqueness(
                {
                    username: updates.preferredUsername,
                },
                USERNAME_EXISTS,
            );

            if (usernameUsed) {
                actions.setFieldError(
                    'preferredUsername',
                    'This username is already in use',
                );
                invalidInput = true;
            }
        }

        if (invalidInput) {
            actions.setSubmitting(false);
            return;
        }

        try {
            await updateMyProfile({
                variables: { input: updates },
                refetchQueries: [{ query: MY_USER }],
                awaitRefetchQueries: true,
            });

            await hydrateUser();
            actions.setSubmitting(false);
            actions.resetForm();

            enqueueSnackbar(`Account Settings Updated`, {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } catch (err) {
            actions.setSubmitting(false);
            reportError(err, {
                metaData: {
                    page: 'Account Settings',
                    operation: 'mutation updateMyProfile',
                    arguments: { input: updates },
                },
                user: {
                    username: myProfile.username,
                    email: myProfile.email,
                },
            });

            enqueueSnackbar(`Error Saving Profile`, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        }
    };

    if (!currentUser)
        return <Loading loadingIndicatorSize={50} label={'Loading Account'} />;

    /**
     * Basic Form Validation Schema. This Yup object is used by Formik to
     * validate all fields in the form as well as provide adequate help text
     * when necessary.
     */
    const validationSchema = yupObject({
        firstName: yupString().required('This field is required'),
        lastName: yupString().required('This field is required'),
        email: yupString()
            .email()
            .test('noWhiteSpace', 'Emails cannot contain spaces', (value) => {
                return !/\s/.test(value);
            })
            .required('This field is required'),
        preferredUsername: yupString()
            .test('noWhiteSpace', 'Usernames cannot contain spaces', (value) => {
                return !/\s/.test(value);
            })
            .required('This field is required'),
    });

    // Base form defaults used my formik. These fields are NOT UPDATED IN STATE.
    // these fields are accessible via the onSubmit function handler for the from.
    const initialValues: UpdateAccountInput = {
        firstName: myProfile.firstName,
        lastName: myProfile.lastName,
        email: myProfile.email,
        preferredUsername: myProfile.preferredUsername,
    };

    const handleTouchedFields = (field: string, value: string) => {
        const fieldKey = field as keyof UpdateAccountInput;
        if (!touchedFields.includes(field) && value !== initialValues[fieldKey]) {
            touchedFields.push(field);
            setTouchedFields(touchedFields);
        } else if (touchedFields.includes(field) && value === initialValues[fieldKey]) {
            const index = touchedFields.findIndex(
                (touchedField) => field === touchedField,
            );
            if (index >= 0) {
                touchedFields.splice(index, 1);
                setTouchedFields(touchedFields);
            }
        }
    };

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            <AccountSettings
                {...props}
                handleBlur={handleTouchedFields as any}
                currentUser={currentUser}
            />
        </Formik>
    );
};

export type AccountSettingsIndexProps = PageProps & {};

export default withLayout(Index, false, true);
