import React from 'react';
import useStyles from './styles';
import { TeaserGrid } from 'organisms';
import { TagWithPosts } from 'types/tag';
import { AdSpace } from 'atoms';
import ScrollToTop from '@elevatormedia/duffel-bag/dist/atoms/ScrollToTop';
import {
    PaginationOptions,
    QueryResult,
} from '@elevatormedia/duffel-bag/dist/types/graphql';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';

const Tag = (props: TagPropTypes) => {
    const classes = useStyles();
    const { tag, fetchMore, variables, slug, loading, error } = props;

    const onLoadMore = () => {
        fetchMore({
            variables: {
                cursor: {
                    limit: variables.cursor.limit,
                    skip: tag.posts.length,
                },
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) return prev;
                const newPosts = [...prev.tag.posts, ...fetchMoreResult.tag.posts];
                const newData = Object.assign({}, prev, {
                    tag: {
                        ...tag,
                        posts: newPosts,
                    },
                });

                return newData;
            },
        });
    };

    const renderAdSpace = () => {
        return (
            <div className={classes.adSpaceContainer}>
                <AdSpace type={'banner-ad'} adUnit={'ELEVATOR-Mid'} />
            </div>
        );
    };

    return (
        <>
            <div className={classes.root}>
                <Seo
                    type={'website'}
                    data={{
                        baseUrl: DOMAIN_BASE_SHORT,
                        title: tag ? tag.name + ' Posts' : 'Tag',
                        description:
                            'View ELEVATOR posts with the ' + tag
                                ? tag.name
                                : ' ' + ' tag.',
                        path: ROUTES.TAG.to + slug,
                    }}
                />
                <TeaserGrid
                    posts={tag ? tag.posts : []}
                    onLoadMore={onLoadMore}
                    sectionTitle={tag ? tag.name : slug}
                    loadLimit={variables.cursor.limit}
                    error={error}
                    isLoading={loading}
                />
                {renderAdSpace()}
            </div>
            <ScrollToTop visibleYOffset={70} />
        </>
    );
};

export type TagQueryVariables = {
    slug: string;
    cursor: PaginationOptions;
};

export interface TagPropTypes
    extends QueryResult<'tag', TagWithPosts, TagQueryVariables> {
    slug: string;
}

export default Tag;
