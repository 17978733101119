import React, { useEffect } from 'react';
import useStyles from './styles';
import CreatorInfo from './CreatorInfo';
import { baseInitialValues } from '../CreditsAccordion';
import { UsernameSearchInput } from 'molecules';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import Avatar from '@elevatormedia/duffel-bag/dist/atoms/Avatar';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { CreditFormValues, CreditInput } from 'types/credit';
import { CreditType } from '@elevatormedia/duffel-bag/dist/types/credit';
import { SearchUserResult, User } from '@elevatormedia/duffel-bag/dist/types/user';
import { useFormikContext } from 'formik';
import { useQuery } from '@apollo/client';
import { CREDIT_TYPES } from 'lib/graphql/queries/creditTypes';
import { IconButton } from '@material-ui/core';
import { Close } from 'mdi-material-ui';

const EditCreditForm: React.FC<EditCreditFormProps> = ({
    currentUser,
    onCancel,
    saveCredit,
    editMode,
}) => {
    const classes = useStyles();

    const {
        resetForm,
        submitForm,
        isValid,
        values,
        setFieldValue,
        setFieldTouched,
        validateForm,
    } = useFormikContext<CreditFormValues>();

    useEffect(() => {
        validateForm();
    }, [values, validateForm]);

    // Keeps track of the search input text
    const [textEntry, setTextEntry] = React.useState('');
    const [isManuallyAddingCredit, setIsManuallyAddingCredit] = React.useState(editMode);

    const [stagedCredit, setStagedCredit] = React.useState<CreditInput>(null);

    const { data } = useQuery(CREDIT_TYPES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    const creditTypes: CreditType[] = data?.creditTypes || [];

    const onCreditTypeChange: (
        event: React.ChangeEvent<{
            name?: string;
            value: string;
        }>,
    ) => void = (event) => {
        setFieldValue('type', event.target.value);
        setFieldTouched('type', true);
        setStagedCredit({
            ...stagedCredit,
            type: event.target.value,
        });
    };

    /**
     * Handler func for when a user selects an option from a auto-suggested list of
     * users
     */
    const onUserSelected = (user: SearchUserResult) => {
        // Reset any active fields first

        let isCurrentUser = false;

        if (currentUser && currentUser.preferredUsername === user.preferredUsername)
            isCurrentUser = true;

        const newFormValues: CreditInput = {
            name: user.preferredUsername,
            autoLinked: true,
            email: user.email,
            isSubmitter: isCurrentUser,
            type: values.type,
            avatar: user.avatar,
        };
        // Update current state
        setStagedCredit(newFormValues);

        // Clear search input
        setTextEntry('');
    };

    /**
     * Handler function for when the user hits the 'save' button after editing a credit
     */
    const handleOnClickSave = async () => {
        isManuallyAddingCredit ? await submitForm() : await saveCredit(stagedCredit);
        onCancel();
    };

    const handleAddNew = () => {
        setIsManuallyAddingCredit(true);
        resetForm({
            values: {
                ...baseInitialValues,
                type: values.type,
            },
        });
    };

    /**
     * Handler function for when a user decides to clear (or 'unlink' rather) an auto-filled
     * form
     */
    const onClearUser = () => {
        resetForm({ values: baseInitialValues });
        setStagedCredit(null);
        setTextEntry('');
    };

    const renderLinkedBadge = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={1}
                marginLeft={1}
                paddingLeft={1.5}
                borderLeft={'4px solid black'}
            >
                <div className={classes.linkedUser}>
                    <Avatar
                        username={stagedCredit.name}
                        variant={'small'}
                        imageProps={
                            stagedCredit.avatar && {
                                src: stagedCredit.avatar.sourceUrl,
                                srcSet: stagedCredit.avatar.sourceSet,
                                alt: stagedCredit.name,
                            }
                        }
                    />
                    <Typography className={classes.userName} variant={'body2'}>
                        <b>{stagedCredit.name}</b>
                    </Typography>
                </div>
                {/**Unlink button*/}
                <IconButton className={classes.closeButton} onClick={onClearUser}>
                    <Close color={'primary'} />
                </IconButton>
            </Box>
        );
    };

    const renderUserSelection = () => {
        return (
            <Box margin={0}>
                {stagedCredit && stagedCredit.name ? (
                    renderLinkedBadge()
                ) : (
                    <div className={classes.searchFieldContainer}>
                        <UsernameSearchInput
                            id={'search'}
                            typeVariant={'autoComplete'}
                            placeholder={'Username'}
                            disableUnderline={true}
                            onUserSelected={onUserSelected}
                            textFieldInput={textEntry}
                            setTextFieldInput={setTextEntry}
                            disabled={false}
                            isAddCreditOption={true}
                            handleManualAddNew={handleAddNew}
                        />
                    </div>
                )}
            </Box>
        );
    };

    return (
        <div className={classes.addCreditFormRoot}>
            <div className={classes.formRoot}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                >
                    <Box paddingBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel id={'typeLabel'}>Role</InputLabel>
                            <Select
                                className={classes.typeSelector}
                                classes={{ select: classes.selectInput }}
                                id={'type'}
                                labelId={'typeLabel'}
                                label={'Role'}
                                variant={'filled'}
                                fullWidth
                                disableUnderline
                                value={values.type}
                                onChange={onCreditTypeChange}
                            >
                                {creditTypes.map((type) => {
                                    return (
                                        <MenuItem
                                            key={type.typeName}
                                            value={type.typeName}
                                        >
                                            <ListItemText primary={type.typeName} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box paddingBottom={2}>
                        <Collapse in={!isManuallyAddingCredit}>
                            {renderUserSelection()}
                        </Collapse>
                        <Collapse in={isManuallyAddingCredit}>
                            <CreatorInfo />
                        </Collapse>
                    </Box>
                </Box>
            </div>

            <div className={classes.dialogActionsContainer}>
                <Button
                    size={'large'}
                    variant={'text'}
                    onClick={() =>
                        editMode
                            ? onCancel()
                            : isManuallyAddingCredit
                            ? setIsManuallyAddingCredit(false)
                            : onCancel()
                    }
                >
                    Cancel
                </Button>
                <Button
                    disabled={isManuallyAddingCredit ? !isValid : !stagedCredit}
                    size={'large'}
                    variant={'text'}
                    onClick={handleOnClickSave}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export interface EditCreditFormProps {
    onCancel: Function;
    currentUser: User;
    saveCredit?: Function;
    editMode?: boolean;
}

EditCreditForm.defaultProps = {
    editMode: false,
};

export default EditCreditForm;
