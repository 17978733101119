import React from 'react';
import useStyles from './styles';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useCartContext } from 'lib/cart';
import { centsToDollars } from '@elevatormedia/duffel-bag/dist/utils/string';
import { PaymentType } from '@elevatormedia/duffel-bag/dist/types/order';
import clsx from 'clsx';

const CheckoutTotalsCard: React.FC<CheckoutTotalsCardProps> = ({
    paymentType,
    total,
}) => {
    const classes = useStyles();
    const { cart } = useCartContext();

    return (
        <div className={classes.totalsCard}>
            <GridContainer direction={'column'}>
                {cart.services.map((service, index) => {
                    const nameString =
                        paymentType === 'Free' ? 'Preferred Submission' : service.name;
                    const priceString =
                        paymentType === 'Free' ? 0 : centsToDollars(service.cost);

                    return (
                        <GridItem
                            key={index}
                            className={
                                service.parent
                                    ? clsx(classes.service, classes.addOn)
                                    : classes.service
                            }
                        >
                            <Typography
                                className={
                                    service.parent
                                        ? classes.addOnText
                                        : classes.serviceText
                                }
                            >
                                {nameString}
                            </Typography>
                            <Typography
                                className={
                                    service.parent
                                        ? classes.addOnText
                                        : classes.serviceText
                                }
                            >
                                ${priceString}
                            </Typography>
                        </GridItem>
                    );
                })}
                {/* TODO: complete tax line */}
                {/* <GridItem>
                        <Typography> Tax </Typography>
                    </GridItem> */}
            </GridContainer>

            <Divider variant={'fullWidth'} />

            <GridContainer
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
            >
                <GridItem>
                    <Typography className={classes.total}>
                        Total ${centsToDollars(total)}
                    </Typography>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export interface CheckoutTotalsCardProps {
    paymentType: PaymentType;
    total: number;
}

export default CheckoutTotalsCard;
