import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        bottomPadding: {
            paddingBottom: theme.spacing(2),
        },
        rightPadding: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0,
            },
            paddingRight: theme.spacing(2),
        },

        skeletonLeft: {
            margin: theme.spacing(0, 3, 3, 0),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(0, 0, 1, 0),
            },
        },
        skeletonRight: {
            margin: theme.spacing(0, 0, 3, 0),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(0, 0, 1, 0),
            },
        },
    });

export default makeStyles(styles);
