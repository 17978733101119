import React, { MouseEventHandler } from 'react';
import useStyles from './styles';
import SubmissionStatusIndicator from 'atoms/SubmissionStatusIndicator';
import ImageUploader from 'molecules/ImageUploader';
import ActionButtons from 'molecules/ActionButtons';
import CreditsAccordion from 'organisms/CreditsAccordion';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import SectionHeader from '@elevatormedia/duffel-bag/dist/atoms/SectionHeader';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { EditSubmissionValues, SubmissionTypeInfo } from 'types/submissions';
import { CreditInput } from 'types/credit';
import { Credit } from '@elevatormedia/duffel-bag/dist/types/credit';
import { MY_SUBMISSION_BY_ORDER_NUMBER } from 'lib/graphql/queries/submissions';
import {
    CREATE_CREDIT,
    UPDATE_CREDIT,
    DELETE_CREDIT,
} from 'lib/graphql/mutations/credits';
import { OrderStatus, Service, SubmissionStatus } from '@elevatormedia/pitch-hooks';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { ServiceInfo } from 'atoms';

const MAX_DESCRIPTION_CHAR_COUNT = 5000;

const EditSubmissionForm = (props: EditSubmissionFormProps) => {
    const classes = useStyles();

    const {
        orderNumber,
        submissionId,
        creditList,
        credits,
        returnToSubmissions,
        submissionType,
        statusLabel,
        orderStatus,
        orderFlagged,
        addOns,
    } = props;

    const {
        values,
        touched,
        errors,
        isValid,
        isSubmitting,
        handleChange,
        setFieldValue,
        setFieldTouched,
        submitForm,
    } = useFormikContext<EditSubmissionValues>();

    const fileInputField = React.useRef(null);
    const descriptionField = React.useRef(null);

    const [checkCertifyState, setCheckCertifyState] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [createCredit] = useMutation(CREATE_CREDIT);
    const [updateCredit] = useMutation(UPDATE_CREDIT);
    const [deleteCredit] = useMutation(DELETE_CREDIT);

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldTouched(event.target.id);
        handleChange(event);
    };

    const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldTouched('contentImage');
        setFieldValue('contentImage', event.target.files[0]);
    };

    const handleRemoveImage = () => {
        setFieldValue('contentImage', null);
        // Using the ref value to file input, clear its value attr
        fileInputField.current.value = '';
    };

    const handleCertifyCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckCertifyState(event.target.checked);
    };

    const removeCredit = async (index: number) => {
        const creditId = credits[index].creditId;

        try {
            const result = await deleteCredit({
                variables: {
                    id: creditId,
                },
                refetchQueries: [
                    {
                        query: MY_SUBMISSION_BY_ORDER_NUMBER,
                        variables: {
                            orderNumber: orderNumber,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            });

            enqueueSnackbar('Credit has been deleted.', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar('Unable to delete credit.', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'error',
            });
        }
    };

    const saveCredit = async (credit: CreditInput, creditIndex: number) => {
        try {
            const { avatar, socialMedia, ...creditInput } = credit;
            (creditInput as Partial<CreditInput>).socialMedia = socialMedia.map(
                (social) => {
                    return {
                        type: social.type,
                        link: social.link,
                    };
                },
            );
            let status = '';
            if (creditIndex === -1) {
                status = 'Credit has been created.';
                const result = await createCredit({
                    variables: {
                        input: { ...creditInput, submissionId },
                    },
                    refetchQueries: [
                        {
                            query: MY_SUBMISSION_BY_ORDER_NUMBER,
                            variables: {
                                orderNumber: orderNumber,
                            },
                        },
                    ],
                    awaitRefetchQueries: true,
                });
            } else {
                status = 'Credit has been updated.';
                const creditId = credits[creditIndex].creditId;
                const result = await updateCredit({
                    variables: {
                        id: creditId,
                        updates: { ...creditInput },
                    },
                    refetchQueries: [
                        {
                            query: MY_SUBMISSION_BY_ORDER_NUMBER,
                            variables: {
                                orderNumber: orderNumber,
                            },
                        },
                    ],
                    awaitRefetchQueries: true,
                });
            }
            enqueueSnackbar(status, {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'success',
            });
        } catch {
            enqueueSnackbar('Unable to save credit.', {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                variant: 'error',
            });
        }
    };

    return (
        <form onSubmit={(event) => event.preventDefault()}>
            <SectionHeader title={'Submission Info'} size={'small'} />

            <ServiceInfo
                addOns={addOns}
                submissionType={submissionType}
                statusLabel={statusLabel}
                orderStatus={orderStatus}
                orderFlagged={orderFlagged}
            />

            <GridContainer
                direction={'column'}
                spacing={2}
                className={classes.gridContainer}
            >
                {/*Content Title*/}
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        id={'contentTitle'}
                        label={'Submission Title'}
                        fullWidth
                        required
                        placeholder={'Artist Name - Song Title (Ft. Artist)'}
                        value={values.contentTitle}
                        onChange={handleTextFieldChange}
                        disableUnderline
                        error={touched.contentTitle && Boolean(errors.contentTitle)}
                        helperText={touched.contentTitle ? errors.contentTitle : ''}
                    />
                </GridItem>

                {/*Content Link*/}
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        id={'contentUrl'}
                        label={'Content Link'}
                        fullWidth
                        required
                        placeholder={'http://'}
                        value={values.contentUrl}
                        onChange={handleTextFieldChange}
                        disableUnderline
                        error={touched.contentUrl && Boolean(errors.contentUrl)}
                        helperText={
                            touched.contentUrl && Boolean(errors.contentUrl)
                                ? errors.contentUrl
                                : 'Link to song, video or photoset. Private links will be declined.'
                        }
                    />
                </GridItem>

                {/* Image */}
                <GridItem className={classes.textFieldContainer}>
                    <ImageUploader
                        variant={'content'}
                        error={touched.contentImage && Boolean(errors.contentImage)}
                        helperText={
                            touched.contentImage
                                ? (errors.contentImage as string)
                                : 'Please provide 1080x1080px artwork for YouTube uploads.'
                        }
                        inputProps={{
                            id: 'contentImage',
                            ref: fileInputField,
                            onChange: handleAddImage,
                        }}
                        onRemoveImage={handleRemoveImage}
                        file={values.contentImage}
                        buttonPosition={'left'}
                        renderLabel={false}
                    />
                </GridItem>

                {/* Description Text Field */}
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        inputRef={descriptionField}
                        id={'contentDescription'}
                        label={'Description'}
                        placeholder={'Content description'}
                        multiline
                        minRows={10}
                        fullWidth
                        required
                        value={values.contentDescription}
                        onChange={handleTextFieldChange}
                        inputProps={{
                            maxLength: MAX_DESCRIPTION_CHAR_COUNT,
                        }}
                        disableUnderline
                        error={
                            touched.contentDescription &&
                            Boolean(errors.contentDescription)
                        }
                        helperText={
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                component={'span'}
                            >
                                <Typography
                                    variant={'caption'}
                                    color={
                                        touched.contentDescription &&
                                        Boolean(errors.contentDescription)
                                            ? 'error'
                                            : 'initial'
                                    }
                                    className={classes.contentDescriptionHelperText}
                                >
                                    {touched.contentDescription &&
                                    Boolean(errors.contentDescription)
                                        ? errors.contentDescription
                                        : 'Tell us about your content.'}
                                </Typography>
                                <Typography variant={'caption'} color={'initial'}>
                                    {values.contentDescription.length} /{' '}
                                    {MAX_DESCRIPTION_CHAR_COUNT}
                                </Typography>
                            </Box>
                        }
                    />
                </GridItem>
            </GridContainer>

            <SectionHeader title={'Credits'} size={'small'} />

            {/* Credits */}
            <div className={classes.creditsContainer}>
                <CreditsAccordion
                    credits={creditList}
                    removeCredit={removeCredit}
                    saveCredit={saveCredit}
                    header={false}
                />
            </div>

            {/* Checkbox */}
            <GridContainer>
                <GridItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={'primary'}
                                checked={checkCertifyState}
                                onChange={handleCertifyCheck}
                                name="sponsorChecked"
                                required
                            />
                        }
                        label={
                            <b>
                                I certify that I have the expressed permission or
                                authority to submit this content. *
                            </b>
                        }
                    />
                </GridItem>
            </GridContainer>

            <ActionButtons
                cancel={{
                    label: 'Cancel',
                    onClick: returnToSubmissions,
                }}
                submit={{
                    label: 'Save',
                    onClick: submitForm,
                    disabled: !isValid || !checkCertifyState || isSubmitting,
                }}
            />
        </form>
    );
};

export type EditSubmissionFormProps = {
    handleCancel: MouseEventHandler;
    orderNumber: string;
    submissionId: string;
    creditList: CreditInput[];
    credits: Credit[];
    returnToSubmissions: () => void;
    submissionType: SubmissionTypeInfo;
    statusLabel: SubmissionStatus | 'Flagged';
    orderStatus?: OrderStatus;
    orderFlagged?: boolean;
    addOns?: Service[];
};

export default EditSubmissionForm;
