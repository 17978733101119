import {
    AdCampaignLength,
    AdCampaignLengthMap,
    AdCampaignLengthCostMap,
    AdSpaceType,
} from 'types/advertising';

export const AdLengths: AdCampaignLength[] = ['1 Day', '3 Days', '1 Week', '1 Month'];

export const AdLengthsDescriptionMap: AdCampaignLengthMap = {
    '1 Day': '1 Day $350',
    '3 Days': '3 Days $950 (10% Off)',
    '1 Week': '1 Week $2000 (20% Off)',
    '1 Month': '1 Month $7500 (30% Off)',
};

// maps Ad Campaign Lengths to USD monetary values in cents
export const AdLengthsCostMap: AdCampaignLengthCostMap = {
    '1 Day': 35000,
    '3 Days': 95000,
    '1 Week': 200000,
    '1 Month': 750000,
};

// extra fee for ELEVATOR designed campaigns in USD monetary value in cents
export const OriginalAdDesignCost = 15000;

export const ViewportSizes = {
    lg: [1500, 768],
    md: [970, 768],
    sm: [600, 768],
};

export const AdSizes = {
    wideBanner: [970, 250],
    sidebar: [300, 600],
    smallRectangle: [300, 250],
};

export const BannerAdConfig = {
    sizes: {
        lg: AdSizes.wideBanner,
        md: AdSizes.smallRectangle,
    },
    sizeMapping: {
        lg: {
            viewport: ViewportSizes.lg,
            sizes: [AdSizes.wideBanner],
        },
        md: {
            viewport: ViewportSizes.md,
            sizes: [AdSizes.smallRectangle],
        },
    },
};

export const DefaultSizeMapping = [
    {
        viewport: ViewportSizes.lg,
        sizes: [AdSizes.wideBanner, AdSizes.sidebar, AdSizes.smallRectangle],
    },
    {
        viewport: ViewportSizes.md,
        sizes: [AdSizes.sidebar, AdSizes.smallRectangle],
    },
    {
        viewport: ViewportSizes.sm,
        sizes: [AdSizes.smallRectangle],
    },
];

export const getAdConfig = (type: AdSpaceType) => {
    let config;

    switch (type) {
        case 'banner-ad':
            config = BannerAdConfig;
    }

    return config;
};
