import React from 'react';
import useStyles from './styles';
import { Appbar } from 'molecules';
import { Footer } from 'organisms';
import { useRouter } from 'next/router';
import { ROUTES } from 'config/Nav';

const NavWrapper = (props: NavWrapperProps) => {
    const classes = useStyles();
    const router = useRouter();

    // Configure set of routes to be excluded from nav wrapping
    const exlusionRoutes = [ROUTES.PAGE_NOT_FOUND.to, ROUTES.ERROR.to];
    const excludeWrapper = exlusionRoutes.includes(router.route);

    return excludeWrapper ? (
        <>{props.children}</>
    ) : (
        <>
            <Appbar />
            <div className={classes.screenContentOffset}>
                {props.children}
                <Footer />
            </div>
        </>
    );
};

export interface NavWrapperProps {
    children?: React.ReactNode;
}

export default NavWrapper;
