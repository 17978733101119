import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => ({
    container: {
        height: '100%',
        padding: theme.spacing(2),
    },
    textFieldContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    emailFieldContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    verticalFieldContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    formRoot: {
        width: '100%',
    },
    saveButton: {
        maxWidth: '500px',
    },
    saveButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(4, 2),
        paddingBottom: '10vh',
    },
    verifiedLabel: {
        color: theme.palette.success.main,
    },
    notVerifiedLabel: {
        color: theme.palette.error.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        padding: theme.spacing(1),
        width: '40%',
    },
    disablePaperMargins: {
        margin: 0,
        maxWidth: theme.static_component_sizes.content_max_width,
    },
    // App bar
    appbar: {
        height: '60px',
        padding: 0,
    },
    appBarToolbar: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
    },
    checkmark: {
        // TODO
        // color: theme.palette.passwordFeedback.success,
    },
    dialogueContentContainer: {
        margin: 0,
        padding: 0,
        minHeight: '390px',
        marginTop: '60px',
        [theme.breakpoints.up(799)]: {
            marginTop: '0px',
        },
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    mobileDialogueTitleColor: {
        color: theme.palette.common.white,
    },
}));

export default styles;
