import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import useStyles from './styles';
import 'react-phone-input-2/lib/style.css';
import { PhoneInputProps } from 'react-phone-input-2';
import dynamic from 'next/dynamic';
const PhoneInput = dynamic(() => import('react-phone-input-2'));

const PhoneNumberInput = (props: PhoneNumberInputProps) => {
    const { value, onChange, label, required, ...rest } = props;

    const classes = useStyles();

    return (
        <div>
            <InputLabel className={classes.label} required={required}>
                {label}
            </InputLabel>
            <PhoneInput
                {...rest}
                containerClass={classes.container}
                inputClass={classes.input}
                buttonClass={classes.button}
                dropdownClass={classes.dropdown}
                country={'us'}
            />
        </div>
    );
};

export type PhoneNumberInputProps = {
    label: string;
    required: boolean;
} & PhoneInputProps;

export default PhoneNumberInput;
