import React from 'react';
import useStyles from './styles';

const NumberInputField = (props: NumberInputFieldProps) => {
    const classes = useStyles();

    return <div />;
};

export interface NumberInputFieldProps {}

export default NumberInputField;
