import React from 'react';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepOne, { UserInfoFormValues } from './StepOne/StepOne';
import { AdFormValues } from 'organisms/AdForm/AdForm';
import StepTwo from './StepTwo';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';
import useStyles from './styles';
import StepThree from './StepThree';
import { StepThreeValues } from './StepThree/StepThree';
import { FormikHelpers } from 'formik';
import withProgress from 'hocs/withProgress';
import { WithProgressTypes } from 'hocs/withProgress/withProgress';
import { reportError } from 'lib/errors';
import { useSnackbar } from 'notistack';
import { useMutation, useApolloClient } from '@apollo/client';
import { SUBMIT_CAMPAIGN } from 'lib/graphql/mutations/campaigns';
import { SubmitCampaignInput } from 'types/campaign';
import { USERNAME_EXISTS } from 'lib/graphql/queries/user';
import { useRouter } from 'next/router';
import { PageProps } from 'types/page';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { signup } from '@elevatormedia/duffel-bag/dist/lib/auth';
import Layout from '@elevatormedia/duffel-bag/dist/atoms/Layout';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import { PaymentType } from '@elevatormedia/duffel-bag/dist/types/order';
import Image from 'next/image';

const Advertising = (props: AdvertisingPageProps) => {
    const classes = useStyles();
    const STEPS = ['Start Banner Ad Campaign', 'Campaign Details', 'Checkout'];

    const { currentUser } = useAuth();

    const router = useRouter();
    const stripe = useStripe();
    const elements = useElements();

    const [activeStep, setActiveStep] = React.useState(0);

    const [userInfo, setUserInfo] = React.useState(null);

    const [campaign, setCampaign] = React.useState({
        campaignName: '',
        campaignType: null,
        startDate: 'default',
        designProvided: true,
        files: [],
        clickThroughUrl: '',
    } as AdFormValues);

    const [totalCost, setTotalCost] = React.useState(0);

    const apolloClient = useApolloClient();
    const [submitCampaign] = useMutation<any, { input: SubmitCampaignInput }>(
        SUBMIT_CAMPAIGN,
    );

    const { enqueueSnackbar } = useSnackbar();

    const next = () => setActiveStep(activeStep + 1);

    const prev = () => setActiveStep(activeStep - 1);

    const handleSubmitUserInfo = (userInfo: UserInfoFormValues = null) => {
        if (userInfo && !currentUser) setUserInfo(userInfo);
        next();
    };

    const handleSubmitCampaignInfo = (campaignInfo: AdFormValues) => {
        setCampaign(campaignInfo);
        next();
    };

    const handleSubmitPayment = async (
        values: StepThreeValues,
        actions: FormikHelpers<StepThreeValues>,
        paymentType: PaymentType,
        paypalOrderId: string = null,
        bitPayInvoiceId: string = null,
    ) => {
        const { toggleProcess, setProcessingText } = props;

        let isNewUser = false;

        toggleProcess();

        if (!currentUser) {
            isNewUser = true;

            try {
                setProcessingText('Setting up your account...');
                const { data, errors } = await apolloClient.query<
                    { usernameUsed: boolean },
                    { username: string }
                >({
                    query: USERNAME_EXISTS,
                    variables: {
                        username: values.username.trim(),
                    },
                });

                if (data && data.usernameUsed) {
                    actions.setFieldError('username', 'This username is already in use');
                    actions.setSubmitting(false);
                } else if (errors && errors.length > 0) throw errors[0];
            } catch (err) {
                actions.setSubmitting(false);
                enqueueSnackbar('Error checking username availability', {
                    variant: 'error',
                    preventDuplicate: true,
                });
                reportError(err, {
                    metaData: {
                        operation: 'query userExists username',
                        input: values.username,
                    },
                });
                toggleProcess();
                return;
            }

            try {
                // signup user to cognito
                const { username, email, firstName, lastName, password } = values;

                await signup(
                    {
                        username: username.trim(),
                        email: email.trim(),
                        firstName,
                        lastName,
                        password,
                    },
                    process.env.authEndpoint,
                ),
                    enqueueSnackbar('Account creation successful', {
                        variant: 'info',
                    });
            } catch (err) {
                enqueueSnackbar('Account creation failed', {
                    variant: 'error',
                });
                toggleProcess();
                return;
            }
        }

        const { campaignType, campaignName, ...campaignInfo } = campaign;

        const input: SubmitCampaignInput = {
            paymentType,
            paymentMethod: {},
            campaign: {
                ...campaignInfo,
                name: campaignName,
                typeId: campaignType,
            },
            total: totalCost,
        };

        if (paymentType === 'PayPal') {
            setProcessingText('Retrieving PayPal order information...');
            input.paymentMethod.paypalOrderId = paypalOrderId;
        } else if (paymentType === 'BitPay') {
            setProcessingText('Retrieving BitPay order information...');
            input.paymentMethod.bitPayInvoiceId = bitPayInvoiceId;
        } else {
            setProcessingText('Validating card information...');

            try {
                // generate and set stripeCardTokenId
                const cardElement = elements!.getElement(CardElement);
                const { token } = await stripe.createToken(cardElement, {
                    name: values.nameOnCard,
                    address_line1: values.address,
                    address_city: values.city,
                    address_zip: values.cardInfo.value.postalCode,
                    address_state: values.state,
                    address_country: values.country,
                    currency: 'usd',
                });

                input.paymentMethod.stripeCardTokenId = token.id;
            } catch (err) {
                actions.setSubmitting(false);
                reportError(err, {
                    metaData: {
                        operation: 'stripe createToken',
                    },
                    severity: 'error',
                });
                enqueueSnackbar('Invalid card information', {
                    variant: 'error',
                });
                toggleProcess();
                return;
            }
        }

        try {
            setProcessingText('Submitting campaign information and assets...');
            const { data } = await submitCampaign({
                variables: { input },
            });

            const params: any = { campaignId: data.submitCampaign.orderNumber };

            if (isNewUser) params.isNewUser = true;

            router.push({
                pathname: ROUTES.ADVERTISE.CONFIRMATION.to,
                query: params,
            });
        } catch (err) {
            actions.setSubmitting(false);
            reportError(err, {
                metaData: {
                    operation: 'mutation submitCampaign',
                },
                severity: 'error',
            });
            enqueueSnackbar('Invalid card information', {
                variant: 'error',
            });
            toggleProcess();
            return;
        }
    };

    const renderStep = (index: number) => {
        switch (index) {
            case 0:
                return (
                    <StepOne
                        handleSubmit={handleSubmitUserInfo}
                        currentUser={currentUser}
                    />
                );
            case 1:
                return (
                    <StepTwo
                        campaign={campaign}
                        handleSubmit={handleSubmitCampaignInfo}
                        handleBackClick={prev}
                        totalCost={totalCost}
                        updateTotalCost={setTotalCost}
                    />
                );
            case 2:
                return (
                    <StepThree
                        handleSubmit={handleSubmitPayment}
                        handleBackClick={prev}
                        totalCost={totalCost}
                        currentUser={currentUser}
                        userInfo={userInfo}
                        typeId={campaign.campaignType}
                    />
                );
            default:
                setActiveStep(0);
        }
    };

    return (
        <Layout useCard>
            <Seo
                type={'website'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: 'Advertising',
                    description:
                        'Submit your advertising campaigns to run on the ELEVATOR Ad Network.',
                    path: ROUTES.ADVERTISE.to,
                }}
            />
            <div className={classes.container}>
                <Link href={ROUTES.ADVERTISE.to} uppercase variant={'title-general'}>
                    Advertising
                </Link>
                <Image
                    src={
                        'https://elevator-media.imgix.net/app/uploads/2017/03/23104137/daye-jackjpg-950x451.jpg?fm=pjpg&ixlib=php-1.2.1&s=4eac572b0b3b255bf5131a95d242a0a2'
                    }
                    width={1100}
                    height={550}
                    alt={'Elevtor Advertising'}
                />
                <div className={classes.content}>
                    <Typography>
                        ELEVATOR has established itself as one of the leading and most
                        respected tastemakers of music & youth culture, generating over 20
                        million monthly impressions across multiple platforms. Our mission
                        is to spotlight the unsung talent of the underground. The ‘up
                        next’. The people, things and brands across the world on the cusp
                        of hitting the tipping point into the mainstream of music,
                        fashion, art & design and events. Elevatormag.com reaches over 90k
                        unique monthly visitors.
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        orientation={'vertical'}
                        className={classes.stepper}
                    >
                        {STEPS.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>{renderStep(index)}</StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </div>
        </Layout>
    );
};

export interface AdvertisingPageProps extends WithProgressTypes, PageProps {}

const AdvertisingWithProgress = withProgress(Advertising);

export default AdvertisingWithProgress;
