import { SubmissionStatus } from 'types/submissions';

const SUBMISSION_STATUSES: Record<string, SubmissionStatus | 'Flagged'> = {
    REVIEW: 'Review',
    APPROVED: 'Approved',
    APPROVAL: 'Approval',
    FLAGGED: 'Flagged',
    COMPLETED: 'Completed',
    REJECTED: 'Rejected',
};

Object.freeze(SUBMISSION_STATUSES);

export default SUBMISSION_STATUSES;
