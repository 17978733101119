import { ImageProps } from '@elevatormedia/duffel-bag/dist/types/media';
import React from 'react';
import useStyles from './styles';
import Image from 'next/image';

const TeaserThumbnail = (props: TeaserThumbnailPropTypes) => {
    const classes = useStyles();

    const { src, srcSet, alt, children, useSrcSet, ...rest } = props;

    return (
        <div className={classes.teaserThumbnailContainer}>
            <Image
                src={src}
                alt={alt}
                //{...rest}
                className={classes.teaserThumbnail}
                layout="fill"
            >
                {children}
            </Image>
        </div>
    );
};

export interface TeaserThumbnailPropTypes extends ImageProps {
    children?: React.ReactNode;
    useSrcSet?: Boolean;
}

TeaserThumbnail.defaultProps = {
    //useSrcSet: true,
};

export default TeaserThumbnail;
