import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useStyles from './styles';
import { SearchAuthorResult } from '@elevatormedia/duffel-bag/dist/types/user';

const SearchAuthorsDropDown = (props: SearchAuthorsDropDownPropTypes) => {
    const classes = useStyles();

    const { searchAuthors, onChange } = props;

    //local state for tracking what is selected
    //state of form
    const [selectedAuth, setSelectedAuth] = React.useState();

    const handleAuthorChange = (event: any) => {
        setSelectedAuth(event.target.value);
        onChange(event.target.value);
    };

    return (
        <>
            <FormControl fullWidth>
                <FormLabel classes={{ root: classes.inputLabelRoot }}>
                    Preferred Writer
                </FormLabel>
                <Select
                    id={'preferredWriter'}
                    label={'Preferred Writer'}
                    fullWidth
                    required
                    placeholder={'Select'}
                    variant={'filled'}
                    className={classes.inputRoot}
                    classes={{
                        root: classes.selectRoot,
                        icon: classes.dropdownArrow,
                    }}
                    value={selectedAuth}
                    onChange={handleAuthorChange}
                    MenuProps={{
                        classes: {
                            paper: classes.dropdownContainer,
                        },
                    }}
                    renderValue={(preferredUsername: string) => {
                        const currAuthor = searchAuthors.find(
                            (author) => author.preferredUsername === preferredUsername,
                        );
                        const { displayName, avatar } = currAuthor;

                        return (
                            <MenuItem key={preferredUsername} value={preferredUsername}>
                                <ListItemAvatar>
                                    <Avatar
                                        src={avatar && avatar.sourceUrl}
                                        srcSet={avatar && avatar.sourceSet}
                                    >
                                        {preferredUsername[0].toUpperCase()}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={displayName || preferredUsername}
                                />
                            </MenuItem>
                        );
                    }}
                >
                    {searchAuthors.map(({ preferredUsername, displayName, avatar }) => (
                        <MenuItem key={preferredUsername} value={preferredUsername}>
                            <ListItemAvatar>
                                <Avatar src={avatar && avatar.sourceUrl}>
                                    {preferredUsername[0].toUpperCase()}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={displayName || preferredUsername} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export interface SearchAuthorsDropDownPropTypes {
    searchAuthors: Array<SearchAuthorResult>;
    onChange: (preferredUsername: string) => void;
}

export default SearchAuthorsDropDown;
