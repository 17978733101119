import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
    EditProfileFormikValues,
    EditProfileFormikValuesKeys,
} from 'layouts/EditProfile';
import { useFormikContext } from 'formik';
import { EditProfileProps } from 'layouts/EditProfile/EditProfile';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { CREDIT_TYPES } from 'lib/graphql/queries/creditTypes';
import { CreditType } from '@elevatormedia/duffel-bag/dist/types/credit';
import useStyles from './styles';

const MAX_DESCRIPTION_CHAR_COUNT = 5000;

const EditProfileForm: React.FC<EditProfileFormProps> = ({
    handleInputChange,
    handleTouchedFields,
}) => {
    const classes = useStyles();
    const { values, touched, errors, isSubmitting, setFieldValue, setFieldTouched } =
        useFormikContext<EditProfileFormikValues>();

    const [isOpen, setIsOpen] = React.useState(true);

    const { data } = useQuery(CREDIT_TYPES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    const creditTypes: CreditType[] = data?.creditTypes || [];
    const nullTitle: CreditType = {
        typeId: null,
        typeName: 'None',
    };
    const titleOptions: Array<CreditType> = [nullTitle, ...creditTypes];

    const onRoleChange = (value: CreditType) => {
        setFieldValue('title', value);
        setFieldTouched('title', true);
    };

    const handleCollapseClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Accordion expanded={isOpen} onChange={() => handleCollapseClick()}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.sectionHeader}>Basic Info</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.bio}>
                    <GridContainer spacing={1} className={classes.gridContainer}>
                        <Divider variant={'fullWidth'} className={classes.divider} />
                        {/*Display Name*/}
                        <GridItem className={classes.displayNameTextField}>
                            <TextField
                                id={'displayName'}
                                label={'Display Name'}
                                fullWidth
                                required
                                value={values.displayName}
                                onChange={(event) =>
                                    handleInputChange('displayName', event)
                                }
                                onBlur={(event) =>
                                    handleTouchedFields('displayName', event.target.value)
                                }
                                error={touched.displayName && Boolean(errors.displayName)}
                                helperText={
                                    touched.displayName ? errors.displayName : null
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>

                        {/*Role*/}
                        <GridItem className={classes.roleContainer}>
                            <FormControl fullWidth variant={'filled'}>
                                <InputLabel
                                    id={'typeLabel'}
                                    className={classes.inputLabel}
                                    shrink
                                >
                                    Role
                                </InputLabel>
                                <Select
                                    className={classes.typeSelector}
                                    classes={{ select: classes.selectInput }}
                                    id={'title'}
                                    labelId={'typeLabel'}
                                    label={'Role'}
                                    variant={'filled'}
                                    fullWidth
                                    disableUnderline
                                    value={values.title?.typeName}
                                    disabled={isSubmitting}
                                    displayEmpty
                                >
                                    {titleOptions.map((title) => {
                                        return (
                                            <MenuItem
                                                key={title.typeName}
                                                value={title.typeName}
                                                onClick={() => onRoleChange(title)}
                                            >
                                                <ListItemText primary={title.typeName} />
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </GridItem>

                        {/*Bio*/}
                        <GridItem className={classes.textFieldContainer}>
                            <TextField
                                id={'bio'}
                                label={'Bio'}
                                placeholder={'Tell us about yourself'}
                                multiline
                                minRows={10}
                                fullWidth
                                required
                                value={values.bio}
                                onChange={(event) => handleInputChange('bio', event)}
                                onBlur={(event) =>
                                    handleTouchedFields('bio', event.target.value)
                                }
                                inputProps={{
                                    maxLength: MAX_DESCRIPTION_CHAR_COUNT,
                                }}
                                error={touched.bio && Boolean(errors.bio)}
                                helperText={
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'space-between'}
                                        component={'span'}
                                    >
                                        <Typography
                                            variant={'caption'}
                                            color={errors.bio ? 'error' : 'initial'}
                                        >
                                            {touched.bio ? errors.bio : ''}
                                        </Typography>
                                        <Typography variant={'caption'} color={'initial'}>
                                            {values.bio ? values.bio.length : 0} /{' '}
                                            {MAX_DESCRIPTION_CHAR_COUNT}
                                        </Typography>
                                    </Box>
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>
                    </GridContainer>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export type EditProfileFormProps = {
    handleInputChange: (
        fieldName: EditProfileFormikValuesKeys,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        imperativeValue?: boolean,
    ) => void;
} & Pick<EditProfileProps, 'handleTouchedFields'>;

export default EditProfileForm;
