import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { getDefaultSocialMediaPrefixes } from 'util/socials';
import useStyles from './styles';
import Facebook from 'mdi-material-ui/Facebook';
import Instagram from 'mdi-material-ui/Instagram';
import Soundcloud from 'mdi-material-ui/Soundcloud';
import Twitter from 'mdi-material-ui/Twitter';
import Youtube from 'mdi-material-ui/Youtube';
import Apple from 'mdi-material-ui/Apple';
import { EditProfileProps } from 'layouts/EditProfile/EditProfile';
import {
    EditProfileFormikValuesKeys,
    EditProfileFormikValues,
} from 'layouts/EditProfile';
import { buildUrl } from '@elevatormedia/socialite';
import { useFormikContext } from 'formik';
import { SocialMediaType } from '@elevatormedia/duffel-bag/dist/types/socialMedia';
import { normalizeLink } from '@elevatormedia/duffel-bag/dist/utils/string';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
} from '@material-ui/core';
import {
    GridContainer,
    GridItem,
    SectionHeader,
    TextField,
} from '@elevatormedia/duffel-bag';
import { ExpandMore } from '@material-ui/icons';
import SegmentHeader from 'atoms/SegmentHeader';

const EditSocialMediaForm: React.FC<EditSocialMediaFormProps> = ({
    handleTouchedFields,
}) => {
    const { values, touched, errors, isSubmitting, setFieldValue, setFieldTouched } =
        useFormikContext<EditProfileFormikValues>();
    const classes = useStyles();

    const [isOpen, setIsOpen] = React.useState(true);
    const handleCollapseClick = () => {
        setIsOpen(!isOpen);
    };

    const handleInputChange = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const fieldName = event.target.id as EditProfileFormikValuesKeys;
        const { value } = event.target;
        setFieldValue(fieldName, value, true);
        handleTouchedFields(fieldName, event.target.value);
        setFieldTouched(fieldName, true, false);
    };
    /**
     * Generates a real-time social media link preview based on user
     * input. if the input is null, return a null React Node.
     */
    const getSociaLinkPreview = (type: SocialMediaType, value: string) => {
        const emptyValue = value === '';
        let url = getDefaultSocialMediaPrefixes(type);
        if (type === 'youtube') {
            url = emptyValue ? 'Paste YouTube URL' : normalizeLink(value);
        } else if (!emptyValue && type !== 'spotify') {
            url = type === 'appleMusic' ? url + value : buildUrl(value, type);
        }

        return (
            <Typography
                href={emptyValue ? null : url}
                component={emptyValue ? 'span' : 'a'}
                variant={'caption'}
                color={emptyValue ? 'textSecondary' : 'primary'}
                target={'_blank'}
            >
                {url.replace(new RegExp('(https://www.)|(//)'), '')}
            </Typography>
        );
    };

    return (
        <>
            <Accordion expanded={isOpen} onChange={() => handleCollapseClick()}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.sectionHeader}>
                        Social Media
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.socials}>
                    <GridContainer spacing={1}>
                        <Divider variant={'fullWidth'} className={classes.divider} />
                        <GridItem className={classes.textFieldContainer}>
                            <TextField
                                id={'facebook'}
                                fullWidth
                                placeholder={'Username'}
                                startAdornment={<Facebook color={'disabled'} />}
                                label={'Facebook'}
                                value={values.facebook}
                                onChange={handleInputChange}
                                onBlur={(event) =>
                                    handleTouchedFields('facebook', event.target.value)
                                }
                                error={touched.facebook && Boolean(errors.facebook)}
                                helperText={
                                    errors.facebook ||
                                    getSociaLinkPreview('facebook', values.facebook)
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>
                        <GridItem className={classes.textFieldContainer}>
                            <TextField
                                id={'instagram'}
                                fullWidth
                                placeholder={'Username'}
                                startAdornment={<Instagram color={'disabled'} />}
                                label={'Instagram'}
                                value={values.instagram}
                                onChange={handleInputChange}
                                onBlur={(event) =>
                                    handleTouchedFields('instagram', event.target.value)
                                }
                                error={touched.instagram && Boolean(errors.instagram)}
                                helperText={
                                    errors.instagram ||
                                    getSociaLinkPreview('instagram', values.instagram)
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>
                        <GridItem className={classes.textFieldContainer}>
                            <TextField
                                id={'twitter'}
                                label={'Twitter'}
                                value={values.twitter}
                                fullWidth
                                placeholder={'Handle'}
                                startAdornment={<Twitter color={'disabled'} />}
                                onChange={handleInputChange}
                                onBlur={(event) =>
                                    handleTouchedFields('twitter', event.target.value)
                                }
                                error={touched.twitter && Boolean(errors.twitter)}
                                helperText={
                                    errors.twitter ||
                                    getSociaLinkPreview('twitter', values.twitter)
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>

                        <GridItem className={classes.textFieldContainer}>
                            <TextField
                                id={'soundcloud'}
                                label={'SoundCloud'}
                                value={values.soundcloud}
                                placeholder={'Username'}
                                startAdornment={<Soundcloud color={'disabled'} />}
                                fullWidth
                                onChange={handleInputChange}
                                onBlur={(event) =>
                                    handleTouchedFields('soundcloud', event.target.value)
                                }
                                error={touched.soundcloud && Boolean(errors.soundcloud)}
                                helperText={
                                    errors.soundcloud ||
                                    getSociaLinkPreview('soundcloud', values.soundcloud)
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>

                        <GridItem className={classes.textFieldContainer}>
                            <TextField
                                id={'youtube'}
                                label={'YouTube'}
                                value={values.youtube}
                                placeholder={'Your YouTube channel URL'}
                                startAdornment={<Youtube color={'disabled'} />}
                                fullWidth
                                onChange={handleInputChange}
                                onBlur={(event) =>
                                    handleTouchedFields('youtube', event.target.value)
                                }
                                error={touched.youtube && Boolean(errors.youtube)}
                                helperText={
                                    errors.youtube ||
                                    getSociaLinkPreview('youtube', values.youtube)
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>
                        <GridItem className={classes.textFieldContainer}>
                            <TextField
                                id={'appleMusic'}
                                label={'AppleMusic'}
                                value={values.appleMusic}
                                placeholder={'Username'}
                                startAdornment={<Apple color={'disabled'} />}
                                fullWidth
                                onChange={handleInputChange}
                                onBlur={(event) =>
                                    handleTouchedFields('appleMusic', event.target.value)
                                }
                                error={touched.appleMusic && Boolean(errors.appleMusic)}
                                helperText={
                                    errors.appleMusic ||
                                    getSociaLinkPreview('appleMusic', values.appleMusic)
                                }
                                disabled={isSubmitting}
                                disableUnderline
                            />
                        </GridItem>
                    </GridContainer>
                    {/* TODO: add 'Connect Accounts section */}
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export type EditSocialMediaFormProps = Pick<EditProfileProps, 'handleTouchedFields'>;

export default EditSocialMediaForm;
