import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionContainer: {
            padding: theme.spacing(4, 0),
        },
        adSpace: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        bottomPaddingContainer: {
            paddingBottom: theme.spacing(4),
        },
    }),
);

export default useStyles;
