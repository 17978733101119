import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { boolean, mixed, number, object as yupObject, string } from 'yup';
import FormBuilder from './FormBuilder';
import { BaseFieldValues, Form, FormField, ValidationObjectInput } from 'types/form';
import { Dayjs } from 'dayjs';

const Index = <T extends BaseFieldValues>(props: FormBuilderIndexProps<T>) => {
    const { form } = props;
    const { fields, handleSubmit } = form;

    const initialValues = useMemo(() => {
        return fields.reduce((object: any, item) => {
            if (item.defaultValue) {
                object[item.name] = item.defaultValue;
            } else {
                switch (item.type) {
                    case 'text':
                    case 'textArea':
                        object[item.name] = '';
                        break;
                    case 'dropdown':
                        object[item.name] = item.options[0];
                        break;
                    case 'datePicker':
                        object[item.name] = new Dayjs('12/25/2030');
                        break;
                    case 'checkbox':
                    case 'toggle':
                        object[item.name] = false;
                        break;
                    case 'number':
                        object[item.name] = 0;
                        break;
                    /*case 'number':
                        object[item.name] = null;
                        break;*/
                    case 'image':
                    case 'images':
                    case 'files':
                    default:
                        object[item.name] = null;
                        break;
                }
            }
            return object;
        }, {});
    }, [fields]);

    const validationSchema = useMemo(() => {
        const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
        const SUPPORTED_FILE_FORMATS = [
            'audio/mpeg',
            'video/mp4',
            'video/mpeg',
            'application/pdf',
            'audio/wav',
            'application/zip',
            'image/jpg',
            'image/jpeg',
            'image/png',
        ];
        const MAX_FILE_SIZE = 20971520; // ~ 20 Megabytes

        const validationObject = fields.reduce(
            (object: ValidationObjectInput, item: FormField<T>) => {
                let fieldValidation;

                switch (item.type) {
                    case 'text':
                    case 'textArea':
                    case 'dropdown':
                        fieldValidation = string();
                        break;
                    case 'datePicker':
                        fieldValidation = mixed<Dayjs>();
                        break;
                    case 'checkbox':
                    case 'toggle':
                        fieldValidation = boolean();
                        break;
                    case 'number':
                    case 'phone':
                        fieldValidation = number();
                        break;
                    case 'image':
                    case 'images':
                        fieldValidation = mixed()
                            .test(
                                'SupportImageTypes',
                                'We do not support this kind of image.',
                                // A func returning true means it is a valid input
                                (value) => {
                                    // when image instances are empty, its fine as they aren't required all the time
                                    if (!value) return true;

                                    if (value.sourceUrl) return true;
                                    // For when images are local and we need to check for valid formats
                                    return SUPPORTED_IMAGE_FORMATS.includes(value.type);
                                },
                            )
                            .test(
                                'MaxfileSize',
                                'Please provide an image with size <= 20MB.',
                                // A func returning true means it is a valid input
                                (value) => {
                                    if (!value) return true;
                                    if (value.sourceUrl) return true;
                                    return value.size < MAX_FILE_SIZE;
                                },
                            );
                        break;
                    case 'files':
                        fieldValidation = mixed()
                            .test(
                                'SupportFileTypes',
                                'We do not support this kind of file.',
                                (value) => {
                                    if (!value) return true;

                                    return SUPPORTED_FILE_FORMATS.includes(value.type);
                                },
                            )
                            .test(
                                'MaxfileSize',
                                'Please provide an file with size <= 20MB.',
                                // A func returning true means it is a valid input
                                (value) => {
                                    if (!value) return true;
                                    return value.size < MAX_FILE_SIZE;
                                },
                            );
                        break;
                    default:
                        fieldValidation = string();
                        break;
                }

                if (item.required)
                    fieldValidation = fieldValidation.required(
                        `${item.name} is required.`,
                    );

                object[item.name as string] = fieldValidation;
                return object;
            },
            {},
        );
        return yupObject(validationObject);
    }, [fields]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <FormBuilder {...props} />
        </Formik>
    );
};

export type FormBuilderIndexProps<T extends BaseFieldValues> = {
    form: Form<T>;
};

export default Index;
