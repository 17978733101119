import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            width: '100%',
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        content: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: theme.spacing(2, 0),
        },
        stepper: {
            width: '100%',
            maxWidth: '750px',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 0),
            },
        },
    });

export default makeStyles(styles);
