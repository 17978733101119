import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // Root
        formRoot: {
            width: '100%',
        },

        addCreditFormRoot: {
            width: '100%',
            padding: 0,
        },
        typeSelector: {
            borderRadius: 0,
        },
        selectInput: {
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: 500,
            fontSize: '1.10rem',
            color: 'rgba(0, 0, 0, 0.54)',
            padding: theme.spacing(1.5, 0, 1.5, 2),
            backgroundColor: theme.palette.custom.grey.light + ' !important',
            '&:focused': {
                color: theme.palette.custom.grey
                    ? theme.palette.custom.grey.dark
                    : undefined,
            },
        },

        creatorInfoRoot: {
            width: '100%',
            maxWidth: '450px',
            padding: 0,
        },
        cardContentContainer: {
            margin: 0,
        },

        loadingContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
        },

        // App bar
        appbar: {
            height: '60px',
            padding: 0,
        },
        dialogActionsContainer: {
            borderTop: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: theme.spacing(1),
        },
        divider: {
            margin: theme.spacing(2, 0),
        },
        dividerContainer: {
            padding: theme.spacing(2, 0),
            width: '100%',
        },
        dividerSep: {
            width: '48%',
        },
        dividerFull: {
            width: '100%',
        },

        // Credit Type Selection
        chip: {
            borderRadius: 0,
        },
        chipListContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        chipContainer: {
            paddingRight: theme.spacing(1),
        },
        checkmark: {
            color: theme.palette.common.white,
        },

        // Linked Badge
        linkedUser: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        userName: {
            marginLeft: theme.spacing(2),
            fontSize: theme.typography.pxToRem(14),
        },
        closeButton: {
            padding: 0,
        },

        // Text Fields
        textFieldIcon: {
            color: '#393939',
            opacity: '75%',
            marginLeft: theme.spacing(1),
        },
        textFieldContainer: {
            padding: theme.spacing(2, 0, 0, 2),
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
            },
            [theme.breakpoints.up(799)]: {
                paddingLeft: 0,
            },
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        searchFieldContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },

        // Profile claim
        thisIsMeCheckBoxContainer: {
            paddingBottom: theme.spacing(3),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        checkbox: {
            marginBottom: theme.spacing(4),
        },
        checkboxFormControl: {
            marginRight: 0,
        },

        // Social Media
        linkContainer: {
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            wordBreak: 'break-all',
        },
        link: {
            color: theme.palette.primary.main,
        },
        linkIcon: {
            color: theme.palette.primary.main,
        },
        outlinedButton: {
            border: '2px solid black',
            color: 'black',
        },

        //Typography
        heavyFont: {
            fontWeight: 'bold',
        },
    }),
);

export default useStyles;
