import React from 'react';
import useStyles from './styles';
import MenuButtons from '../MenuButtons/MenuButtons';
import { UserBadge } from 'atoms';
import { Searchbar } from 'molecules';
import AnimatedHamburgerMenu from '@elevatormedia/duffel-bag/dist/atoms/AnimatedHamburgerMenu';
import BrandLogo from '@elevatormedia/duffel-bag/dist/atoms/BrandLogo';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Badge from '@material-ui/core/Badge';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxArrowUp from 'mdi-material-ui/InboxArrowUp';
import Settings from 'mdi-material-ui/Settings';
import FileDocumentBoxPlusOutline from 'mdi-material-ui/FileDocumentBoxPlusOutline';
import MonitorDashboard from 'mdi-material-ui/MonitorDashboard';
import { ROUTES, APPBAR_ROUTES } from 'config/Nav';
import { RouteConfig } from 'types/routes';
import { useRouter } from 'next/router';
import clsx from 'clsx';

const MobileAppbar = (props: MobileAppbarPropTypes) => {
    const classes = useStyles();

    const {
        handleSignoutClick,
        currentUser,
        searchInput,
        handleSearchInput,
        onSubmitSearchInput,
    } = props;

    // Tracks whether the menu should be opened or closed
    const [menuIsOpen, setMenuIsOpen] = React.useState(false);

    const router = useRouter();

    /**
     * Custom handler for mobile menu header logo click.
     *
     * Because of the NavWrapper component, we need to close the menu if it
     * was open
     */
    const handleMobileLogoClick = () => {
        if (menuIsOpen) {
            toggleMenu();
        }
        router.push(ROUTES.HOME.to);
    };

    /**
     * Handler for toggling the menu dropdown
     */
    const toggleMenu = (imperativeValue: any = null) => {
        if (imperativeValue && typeof imperativeValue === 'boolean') {
            setMenuIsOpen(imperativeValue);
        }

        setMenuIsOpen((prevState) => !prevState);
    };

    /**
     * Handle interactions when a user clicks outside the menu and the the menu
     * is open
     */
    const handleClickAwayFromMenu = () => {
        menuIsOpen && toggleMenu(false);
    };

    /**
     * Interpolates what kind of user badge to display to the user. On Mobile,
     * certain badges take precedence rather than displaying all applicable badges:
     * 1. Elevator Staff
     * 2. Preferred User
     * 3. Verified User
     */
    const interpolateBadgeType = () => {
        if (currentUser.permissionGroup.groupName !== 'Standard') {
            return <UserBadge variant={'staff'} />;
        } else if (currentUser.preferred) {
            return <UserBadge variant={'preferred'} />;
        } else if (currentUser.verified) {
            return <UserBadge variant={'verified'} />;
        } else {
            return null;
        }
    };

    const renderNavListItem = (
        { navLinkText, to, external }: RouteConfig,
        index: number,
        collection: RouteConfig[],
    ) => {
        const isLastListItem = index === collection.length - 1;
        const isFirstListItem = index === 0;

        return (
            <div key={index}>
                {isFirstListItem && (
                    <Divider component="li" className={classes.listDivider} />
                )}
                <ListItem component={'a'} href={to} className={classes.listItem}>
                    <ListItemText
                        primary={navLinkText}
                        primaryTypographyProps={{
                            className: classes.listItem,
                        }}
                    />
                </ListItem>
                {!isLastListItem && (
                    <Divider component="li" className={classes.listDivider} />
                )}
            </div>
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAwayFromMenu}>
            <div className={classes.root}>
                <AppBar elevation={0} className={classes.mobileAppBar}>
                    <Toolbar className={classes.toolbar}>
                        <AnimatedHamburgerMenu onClick={toggleMenu} isOpen={menuIsOpen} />

                        <div className={classes.headerLogoContainer}>
                            <BrandLogo
                                href="/"
                                onClick={handleMobileLogoClick}
                                variant={'mobile'}
                            />
                        </div>

                        <Button
                            className={classes.submitButton}
                            variant={'contained'}
                            color={'secondary'}
                            href={ROUTES.SUBMIT.to}
                        >
                            <InboxArrowUp className={classes.submitIcon} />
                        </Button>
                        {currentUser && (
                            <Avatar
                                className={clsx(classes.userAvatar, classes.appBarAvatar)}
                                src={currentUser.avatar && currentUser.avatar.sourceUrl}
                            >
                                {currentUser.firstName[0].toUpperCase()}
                            </Avatar>
                        )}
                    </Toolbar>
                </AppBar>
                <div className={classes.collapseMenuContainer}>
                    <Collapse in={menuIsOpen}>
                        <div className={classes.listMenuContainer}>
                            <Searchbar
                                searchInput={searchInput}
                                handleSearchInput={handleSearchInput}
                                onSubmit={(e: any) => onSubmitSearchInput(e, toggleMenu)}
                            />
                            <List
                                component="nav"
                                aria-label="navigation options"
                                className={classes.list}
                            >
                                {APPBAR_ROUTES.map((routeConfig, index, collection) =>
                                    renderNavListItem(routeConfig, index, collection),
                                )}
                            </List>
                            <Divider component="li" className={classes.listDivider} />
                            <List
                                component="nav"
                                aria-label="navigation options"
                                className={classes.userOptionsList}
                                classes={{ root: classes.signInRoot }}
                            >
                                {currentUser && (
                                    <React.Fragment>
                                        <ListItem
                                            className={clsx(
                                                classes.listItem,
                                                classes.mobileList,
                                                classes.profileListItem,
                                            )}
                                            component={'a'}
                                            href={ROUTES.PROFILE.to}
                                        >
                                            <ListItemAvatar>
                                                <Badge
                                                    badgeContent={interpolateBadgeType()}
                                                >
                                                    <Avatar
                                                        className={classes.userAvatar}
                                                        src={
                                                            currentUser.avatar &&
                                                            currentUser.avatar.sourceUrl
                                                        }
                                                    >
                                                        {currentUser.firstName[0].toUpperCase()}
                                                    </Avatar>
                                                </Badge>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={'My Profile'}
                                                primaryTypographyProps={{
                                                    className: classes.listItem,
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listItem}
                                            component={'a'}
                                            href={ROUTES.ACCOUNT.SUBMISSIONS.to}
                                        >
                                            <ListItemIcon className={classes.listIcon}>
                                                <InboxArrowUp />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'My Submissions'}
                                                primaryTypographyProps={{
                                                    className: classes.listItem,
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listItem}
                                            component={'a'}
                                            href={ROUTES.SUBMIT.to}
                                        >
                                            <ListItemIcon className={classes.listIcon}>
                                                <FileDocumentBoxPlusOutline />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'New Submission'}
                                                primaryTypographyProps={{
                                                    className: classes.listItem,
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listItem}
                                            component={'a'}
                                            href={ROUTES.ACCOUNT.to}
                                        >
                                            <ListItemIcon className={classes.listIcon}>
                                                <Settings />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={'Account Settings'}
                                                primaryTypographyProps={{
                                                    className: classes.listItem,
                                                }}
                                            />
                                        </ListItem>

                                        {currentUser.permissionGroup.groupName !==
                                            'Standard' && (
                                            <ListItem
                                                component={'a'}
                                                href={ROUTES.ADMIN.to}
                                                className={clsx(
                                                    classes.listItemHighEmphasis,
                                                    classes.mobileList,
                                                )}
                                            >
                                                <ListItemIcon
                                                    className={classes.listIcon}
                                                >
                                                    <MonitorDashboard />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'Go To Admin Dashboard'}
                                                    primaryTypographyProps={{
                                                        className:
                                                            classes.listItemHighEmphasis,
                                                    }}
                                                />
                                            </ListItem>
                                        )}

                                        <ListItem
                                            className={clsx(
                                                classes.listItem,
                                                classes.mobileList,
                                                classes.lastItem,
                                            )}
                                        >
                                            <MenuButtons
                                                handleSignoutClick={handleSignoutClick}
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                )}
                                {!currentUser && (
                                    <>
                                        <ListItem
                                            component={'a'}
                                            href={ROUTES.AUTH.to}
                                            className={clsx(
                                                classes.listItem,
                                                classes.mobileList,
                                            )}
                                        >
                                            <ListItemText
                                                primary={'Sign In'}
                                                primaryTypographyProps={{
                                                    className: classes.listItem,
                                                }}
                                            />
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </div>
                    </Collapse>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export type MobileAppbarPropTypes = {
    handleSignoutClick: () => void;
    currentUser: User;
    searchInput: string;
    handleSearchInput: (input: string) => void;
    onSubmitSearchInput: (event: any, cb?: Function) => void;
};

export default MobileAppbar;
