import React from 'react';
import Modal from '@material-ui/core/Modal';
import { VerifyEmailSteps } from 'molecules';
import useStyles from './styles';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import Card from '@elevatormedia/duffel-bag/dist/atoms/Card';

const VerifyEmailPopUp: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const { currentUser } = useAuth();

    React.useEffect(() => {
        if (currentUser && !currentUser.emailVerified) {
            setOpen(true);
        }
    }, [currentUser]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        currentUser && (
            <Modal
                open={open}
                onClose={handleClose}
                aria-label={'Verify email'}
                aria-describedby={'Verify your email account'}
            >
                <div>
                    <Card className={classes.card}>
                        <VerifyEmailSteps setOpen={setOpen} />
                    </Card>
                </div>
            </Modal>
        )
    );
};

export default VerifyEmailPopUp;
