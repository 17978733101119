import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    teaserThumbnail: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    teaserThumbnailContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
});

export default makeStyles(styles);
