import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchbarRootContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: 48,
            background: '#3A3A3A',
            padding: theme.spacing(0, 2),
            borderRadius: 4,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 160,
                marginRight: theme.spacing(2),
                marginLeft: theme.spacing(2),
            },
            [theme.breakpoints.up('lg')]: {
                width: 240,
            },
        },
        inputStyling: {
            color: theme.palette.common.white,
            width: '100%',
            '&:focus': {
                borderBottom: `1px solid ${theme.palette.common.white}`,
            },
        },
        clearIcon: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }),
);

export default useStyles;
