import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import CircularProgress from '@material-ui/core/CircularProgress';
import Close from 'mdi-material-ui/Close';
import Cropper from 'react-easy-crop';
import { cropImage } from './CropImage';
import { reportError } from '../../lib/errors';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';

const ImageCropperDialogue: React.FC<ImageCropperDialogueProps> = ({
    cropCandidate,
    onImageCropped,
    aspect,

    // Modal Props
    onClose,
    onImageCropCancel,

    // Rest props are passed to <Dialogue /> component
    ...rest
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    // Create object URL from file
    const cropCandidateUrl = cropCandidate && URL.createObjectURL(cropCandidate);

    // Retrieve information about the current view dimensions
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Cropper state management
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
    const minZoom = 0.1;

    // Loading state
    const [isCropping, setIsCropping] = React.useState(false);

    /**
     * Called every time a zoom percentage is changed
     */
    const onZoomChange = (zoom: number) => {
        setZoom(zoom);
    };

    /**
     * Called every single time crop area is moved
     */
    const onCropChange = (crop: { x: number; y: number }) => {
        setCrop(crop);
    };

    /**
     * Called when the user lets go of the view box which signifies a complete interaction
     */
    const onCropComplete = (_: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    /**
     * Callback for when the hits 'Finished' This will crop the image call parent function with cropped image
     */
    const onFinishClick = async () => {
        setIsCropping(true);
        try {
            const croppedImageResult = await cropImage(
                cropCandidateUrl,
                croppedAreaPixels,
                cropCandidate,
            );

            // Pass result to parent cb
            onImageCropped(croppedImageResult);
            onClose();
        } catch (err) {
            reportError(err, {
                metaData: {
                    operation: 'user tried to crop image',
                },
            });
            showErrorSnackbar('An error ocurred while cropping your image');
        }
        setIsCropping(false);
    };

    const showErrorSnackbar = (msg: string) => {
        enqueueSnackbar(msg, {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            variant: 'error',
            preventDuplicate: true,
        });
    };

    const renderDialogueHeader = () => {
        return (
            <AppBar elevation={0} position={'fixed'} className={classes.appbar}>
                <div className={classes.appBarToolbar}>
                    <IconButton onClick={onClose} color="inherit" aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography variant="h6">Crop Image</Typography>
                </div>
            </AppBar>
        );
    };

    const renderImageCropArea = () => {
        return (
            <Box paddingTop={2}>
                <Box position={'relative'} minHeight={'350px'}>
                    <Cropper
                        image={cropCandidateUrl}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        restrictPosition={false}
                        aspect={aspect}
                        minZoom={minZoom}
                    />
                </Box>
            </Box>
        );
    };

    const renderZoomSlider = () => {
        return (
            <Box
                paddingY={1}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
            >
                <Slider
                    color={'secondary'}
                    defaultValue={0}
                    aria-labelledby="discrete-zoom-slider"
                    valueLabelDisplay="auto"
                    step={0.1}
                    min={minZoom}
                    max={3}
                    onChange={(_, value) => onZoomChange(value as number)}
                    value={zoom}
                />
                <Box paddingLeft={1}>
                    <Typography
                        variant={'caption'}
                        id="discrete-zoom-slider"
                        gutterBottom
                    >
                        Zoom
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Dialog
            aria-labelledby="dialog-title"
            PaperProps={{
                classes: {
                    root: classes.paperStyle,
                },
            }}
            fullScreen={mobile}
            disableEscapeKeyDown
            {...rest}
        >
            {!mobile && <DialogTitle id={'scroll-dialog-title'}>Crop Image</DialogTitle>}
            {mobile && renderDialogueHeader()}
            <DialogContent dividers>
                {isCropping ? (
                    <Box
                        minHeight={mobile ? '100%' : '350px'}
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <CircularProgress color={'primary'} />
                        <Box paddingTop={2}>
                            <Typography variant={'body2'}>Cropping Image</Typography>
                        </Box>
                    </Box>
                ) : (
                    <>
                        {renderImageCropArea()}
                        {renderZoomSlider()}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexDirection={'row'}
                >
                    <Button onClick={onImageCropCancel} variant={'text'} fullWidth>
                        Cancel
                    </Button>
                    <Box paddingX={1} />
                    <Button
                        onClick={onFinishClick}
                        variant={'text'}
                        fullWidth
                        disabled={isCropping}
                    >
                        Done
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export interface ImageCropperDialogueProps extends DialogProps {
    cropCandidate: File;
    onImageCropped: (file: File) => void;
    aspect: number;
    onClose: (_e?: any, reason?: 'backdropClick' | 'escapeKeyDown') => void;
    onImageCropCancel: () => void;
}

export default ImageCropperDialogue;
