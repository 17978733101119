import React from 'react';
import { FormikProps } from 'formik';
import { UserInfoFormValues } from './StepOne';
import useStyles from './styles';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';

const UserInfoForm = (props: UserInfoFormProps) => {
    const classes = useStyles();
    const {
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        isSubmitting,
        isValid,
    } = props;

    return (
        <form onSubmit={(e) => e.preventDefault()} className={classes.form}>
            <GridContainer className={classes.container}>
                <GridItem xs={12} paddingY={2}>
                    <TextField
                        id={'firstName'}
                        name={'firstName'}
                        label={'First Name'}
                        autoComplete={'given-name'}
                        fullWidth
                        required
                        value={values.firstName}
                        onChange={handleChange}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName ? errors.firstName : ''}
                    />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <TextField
                        id={'lastName'}
                        name={'lastName'}
                        label={'Last Name'}
                        autoComplete={'family-name'}
                        fullWidth
                        required
                        value={values.lastName}
                        onChange={handleChange}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName ? errors.lastName : ''}
                    />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <TextField
                        id={'email'}
                        name={'email'}
                        label={'Email'}
                        autoComplete={'email'}
                        fullWidth
                        required
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email ? errors.email : ''}
                    />
                </GridItem>
                <GridItem xs={12} paddingY={2}>
                    <Button
                        variant={'primary'}
                        size={'small'}
                        onClick={() => handleSubmit()}
                        disabled={!isValid || isSubmitting}
                    >
                        next
                    </Button>
                </GridItem>
            </GridContainer>
        </form>
    );
};

export type UserInfoFormProps = FormikProps<UserInfoFormValues> & {};

export default UserInfoForm;
