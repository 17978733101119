import React from 'react';
import { NextComponentType, NextPageContext } from 'next';
import { PageProps } from 'types/page';
import clsx from 'clsx';
import useStyles from './styles';
import Card from '@elevatormedia/duffel-bag/dist/atoms/Card';

const withLayout = <T,>(
    Component: NextComponentType<NextPageContext, any, PageProps & T>,
    useCard: boolean,
    excludePadding?: boolean,
) => (props: PageProps & T) => {
    const classes = useStyles();

    React.useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);

    return (
        <div className={clsx(classes.root, !excludePadding && classes.contentPadding)}>
            {useCard ? (
                <main className={classes.content}>
                    <Card className={classes.removePadding}>
                        <Component {...props} />
                    </Card>
                </main>
            ) : (
                <main className={classes.content}>
                    <Component {...props} />
                </main>
            )}
        </div>
    );
};

export default withLayout;
