import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    tagLink: {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        fontWeight: 400,
        textTransform: 'uppercase',
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
});

export default makeStyles(styles);
