import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { CreditInput } from 'types/credit';
import Avatar from '@elevatormedia/duffel-bag/dist/atoms/Avatar';

const CreditsList = (props: CreditsListProps) => {
    const classes = useStyles();

    const { credits } = props;

    return (
        <div className={classes.root}>
            {credits.map((credit, index) => {
                const { name, type, avatar } = credit;

                return (
                    <Paper key={index} className={classes.userInfo}>
                        <Avatar
                            username={name}
                            variant={'small'}
                            imageProps={
                                avatar && {
                                    src: avatar.sourceUrl,
                                    srcSet: avatar.sourceSet,
                                    alt: name,
                                }
                            }
                        />
                        <div className={classes.summaryText}>
                            <Typography variant="caption" color="textSecondary">
                                {type}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary">
                                {name}
                            </Typography>
                        </div>
                    </Paper>
                );
            })}
        </div>
    );
};

export interface CreditsListProps {
    credits: Array<CreditInput>;
}

export default CreditsList;
