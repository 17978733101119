import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from 'lib/payments';
import { PageProps } from 'types/page';
import Advertising from './Advertising';

const AdvertisingWithPayments = (props: PageProps) => {
    return (
        <Elements stripe={stripePromise}>
            <Advertising {...props} />
        </Elements>
    );
};

export default AdvertisingWithPayments;
