import React from 'react';
import { useQuery } from '@apollo/client';
import Directory from './Directory';
import { PageProps } from 'types/page';
import { USER_DIRECTORY } from 'lib/graphql/queries/user';
import Box from '@material-ui/core/Box';
import Loading from '@elevatormedia/duffel-bag/dist/atoms/Loading';

const Index = (props: DirectoryIndexPropTypes) => {
    const [searchInput, setSearchInput] = React.useState('');
    const [activeLabels, setActiveLabels] = React.useState([]);

    // Keep variables stable across renders
    const variablesRef = React.useRef<any>({});
    const variables = variablesRef.current;

    //NOTE: currently makes 2 query calls upon initial render
    const { data, loading, networkStatus, error, refetch } = useQuery(USER_DIRECTORY, {
        fetchPolicy: 'cache-and-network',
        variables,
        notifyOnNetworkStatusChange: true,
    });

    React.useEffect(() => {
        // Update variables and refetch data whenever activeLabels changes
        variables.creditTypes = activeLabels;
        refetch(variables);
    }, [activeLabels, refetch, variables]);

    const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;

    if (isLoading) {
        return (
            <Box
                width={'100%'}
                height={'100%'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Loading loadingIndicatorSize={40} />
            </Box>
        );
    } else {
        return (
            <Directory
                {...props}
                data={data}
                loading={false}
                error={error}
                refetch={refetch}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                activeLabels={activeLabels}
                setActiveLabels={setActiveLabels}
                variables={variables}
            />
        );
    }
};

export type DirectoryIndexPropTypes = PageProps & {};

export default Index;
