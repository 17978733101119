import React from 'react';
import Typography from '@material-ui/core/Typography';
import ActionIcon from '@material-ui/icons/NotificationImportant';
import DeclinedIcon from '@material-ui/icons/NotInterested';
import ApprovedIcon from '@material-ui/icons/CheckCircle';
import PublishedIcon from '@material-ui/icons/Public';
import PendingIcon from '@material-ui/icons/MoreHoriz';
import MoneyOff from '@material-ui/icons/MoneyOff';
import useStyles from './styles';
import { SubmissionStatus } from 'types/submissions';
import { OrderStatus } from '@elevatormedia/pitch-hooks';

const SubmissionStatusIndicator: React.FC<SubmissionStatusIndicatorProps> = ({
    status,
    orderStatus,
    orderFlagged,
}) => {
    const classes = useStyles();

    let statusText = '';
    let statusIcon: JSX.Element | undefined;

    switch (status) {
        case 'Review':
        case 'Approval':
            statusText = 'Pending';
            statusIcon = (
                <div className={classes.pendingIconContainer}>
                    <PendingIcon className={classes.pendingIcon} />
                </div>
            );
            break;
        case 'Approved':
            statusText = 'Approved';
            statusIcon = <ApprovedIcon className={classes.approvedIcon} />;
            break;
        case 'Rejected':
            statusText = 'Declined';
            statusIcon = <DeclinedIcon className={classes.rejectedIcon} />;
            break;
        case 'Completed':
            statusText = 'Published';
            statusIcon = <PublishedIcon className={classes.approvedIcon} />;
            break;
        case 'Flagged':
            if (orderStatus && (orderStatus == 'failed' || orderFlagged)) {
                statusText = 'Payment Issue';
                statusIcon = <MoneyOff className={classes.rejectedIcon} />;
            } else {
                statusText = 'Action';
                statusIcon = <ActionIcon className={classes.flaggedIcon} />;
            }
            break;
    }

    return (
        <div className={classes.statusContainer}>
            {statusIcon}
            <Typography className={classes.statusText}>{statusText}</Typography>
        </div>
    );
};

export interface SubmissionStatusIndicatorProps {
    status: SubmissionStatus | 'Flagged';
    orderStatus?: OrderStatus;
    orderFlagged?: boolean;
}

export default SubmissionStatusIndicator;
