import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    linkBase: {
        '&:hover': {
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        //https://smad.jmu.edu/shen/webtype/lineheight.html
        lineHeight: '1.5em',
    },
    cardMediaContainer: {
        padding: theme.spacing(2, 0, 2, 2),
        height: '100px',
        width: '100px',
        [theme.breakpoints.up(400)]: {
            height: '120px',
            width: '120px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '130px',
            width: '130px',
            padding: theme.spacing(2, 0, 2, 2),
        },
        [theme.breakpoints.up(700)]: {
            height: '180px',
            width: '180px',
            padding: 0,
        },
    },
    cardInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    cardContentContainer: {
        height: '100%',
        width: '75%',
        [theme.breakpoints.down(400)]: {
            width: '60%',
        },
        margin: theme.spacing(2),
    },
    titleSearchLink: {
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(16),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(20),
        },
        display: 'block',
    },
    linkContainer: {
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        '-ms-word-break': 'break-all',
        wordBreak: 'break-word',
    },
    nakedLink: {
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },
        fontSize: theme.typography.pxToRem(14),
    },
    descriptionContainer: {
        paddingTop: theme.spacing(2),
    },
    excerpt: {
        width: '200px',
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.up('sm')]: {
            width: '300px',
            fontSize: theme.typography.pxToRem(16),
        },
        [theme.breakpoints.up('lg')]: {
            width: '500px',
        },
    },
});

export default makeStyles(styles);
