import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        innerRoot: {
            backgroundColor: 'transparent',
            padding: '0 !important',
            margin: '0 !important',
            '&::before': {
                height: '0 !important',
                content: 'none !important',
            },
        },

        accordionSummary: {
            padding: '0 !important',
            margin: theme.spacing(0, 1, 0.5, 0) + ' !important',
            minHeight: '44px',
            width: '100%',
        },
        accordionSummaryContent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: theme.spacing(0.5, 0) + ' !important',
        },
        userInfo: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        activeInfo: {
            backgroundColor: theme.palette.custom.grey.light,
        },
        summaryText: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(1, 2, 0, 2),
        },

        closeIcon: {
            color: theme.palette.custom.grey.main,
        },
        iconButton: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(1),
        },
    }),
);

export default useStyles;
