import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    totalContainer: {
        width: '100%',
        backgroundColor: theme.palette.common.black,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    totalTextLabel: {
        color: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 700,
    },
    totalTextValue: {
        color: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(24),
    },
});

export default makeStyles(styles);
