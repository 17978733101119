import React, { MouseEventHandler } from 'react';
import { FormikHelpers, Formik } from 'formik';
import { string as yupString, object as yupObject, mixed } from 'yup';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';
import CheckoutForm from './CheckoutForm';
import {
    isValidPassword,
    notTooGuessable,
} from '@elevatormedia/duffel-bag/dist/utils/auth';
import { PaymentType } from '@elevatormedia/duffel-bag/dist/types/order';

const StepThree = (props: StepThreeProps) => {
    const { currentUser, userInfo, totalCost, handleSubmit, handleBackClick, typeId } =
        props;

    const [paymentType, setPaymentType] = React.useState('Stripe' as PaymentType);
    const [paypalOrderId, setPaypalOrderId] = React.useState(null);
    const [bitPayInvoiceId, setBitPayInvoiceId] = React.useState(null);

    const getValidationSchema = () => {
        const creditCardFormValidationSchema = {
            cardInfo: mixed().test(
                'ValidCardInfo',
                'Please enter a valid card',
                // A func returning true means it is a valid input
                (value: any) => value && value.complete && !value.error,
            ),
            nameOnCard: yupString().required('This field is required'),
            address: yupString().required('An address is required to make a payment'),
            city: yupString().required('a city is required to make a payment'),
            state: yupString(),
            country: yupString(),
        };

        const accountValidationSchema = {
            firstName: yupString().required('First Name is required'),
            lastName: yupString().required('Last Name is required'),
            email: yupString()
                .email('Please enter a valid email')
                .test('noWhiteSpace', 'Emails cannot contain spaces', (value) => {
                    return !/\s/.test(value);
                })
                .required('This field is required'),
            username: yupString()
                .required('This field is required')
                .test('noWhiteSpace', 'Usernames cannot contain spaces', (value) => {
                    return !/\s/.test(value);
                }),
            password: yupString()
                .required('This field is required')
                .test(
                    'requirements',
                    'Password requirements not met',
                    (value: string) => {
                        if (!value) return false;
                        return isValidPassword(value);
                    },
                )
                .test('notTooGuessable', 'password is too guessable', (value: string) => {
                    if (!value) return false;
                    return notTooGuessable(value);
                }),
            confirmPassword: yupString()
                .test('passwordsMatch', 'Passwords must match', function (value) {
                    if (!value) return false;
                    return value === this.parent.password;
                })
                .required('This field is required'),
        };

        const recaptchaValidationSchema = {
            recaptcha: yupString().required(),
        };

        return yupObject({
            // Only include new account field validation if this is a new user
            ...(!currentUser && accountValidationSchema),

            // Only include the card form validation if we are checking out via Stripe
            ...(paymentType === 'Stripe' && creditCardFormValidationSchema),

            ...(paymentType === 'Stripe' && recaptchaValidationSchema),
        });
    };

    const getInitialValues = () => {
        const creditCardValues = {
            cardInfo: {},
            nameOnCard: '',
            address: '',
            city: '',
            state: '',
            country: 'United States',
            recaptcha: '',
        };

        return {
            ...(!currentUser && {
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                username: '',
                password: '',
                confirmPassword: '',
            }),
            ...(paymentType === 'Stripe' && creditCardValues),
        };
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={getInitialValues()}
                validationSchema={getValidationSchema()}
                onSubmit={(values, helpers) =>
                    handleSubmit(
                        values,
                        helpers,
                        paymentType,
                        paypalOrderId,
                        bitPayInvoiceId,
                    )
                }
                validateOnMount={paymentType === 'PayPal' && currentUser ? true : false}
            >
                {(props) => (
                    <CheckoutForm
                        {...props}
                        handleBackClick={handleBackClick}
                        totalCost={totalCost}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        paypalOrderId={paypalOrderId}
                        setPaypalOrderId={setPaypalOrderId}
                        setBitPayInvoiceId={setBitPayInvoiceId}
                        typeId={typeId}
                        currentUser={currentUser}
                    />
                )}
            </Formik>
        </div>
    );
};

export type StepThreeValues = {
    readonly firstName?: string;
    readonly lastName?: string;
    readonly email?: string;
    username?: string;
    password?: string;
    confirmPassword?: string;
    cardInfo?: any;
    nameOnCard?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    recaptcha?: string;
};

export type StepThreeProps = {
    currentUser: User;
    userInfo?: any;
    handleSubmit: (
        values: StepThreeValues,
        helpers: FormikHelpers<StepThreeValues>,
        paymentType: PaymentType,
        paypalOrderId?: string,
        bitPayInvoiceId?: string,
    ) => void;
    handleBackClick: MouseEventHandler;
    totalCost: number;
    typeId: string;
};

export default StepThree;
