import React from 'react';
import { PopularPostList } from 'organisms';
import useStyles from './styles';
import { AdSpace } from 'atoms';
import { SocialMediaGroup } from 'molecules';
import { PageProps } from 'types/page';
import { SOCIAL_MEDIA } from 'config/Nav';
import clsx from 'clsx';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import SectionHeader from '@elevatormedia/duffel-bag/dist/atoms/SectionHeader';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import {
    SocialMediaLink,
    SocialMediaType,
} from '@elevatormedia/duffel-bag/dist/types/socialMedia';

const Sidebar = (props: SidebarProps) => {
    const classes = useStyles();
    const { children } = props;

    const getElevatorMediaSocials = () => {
        const socials: SocialMediaLink[] = Object.values(SOCIAL_MEDIA).map((social) => {
            const type = social.type as SocialMediaType;
            return {
                type,
                link: social.route,
            };
        });

        return socials;
    };

    const renderConnectSection = () => {
        return (
            <div>
                <SectionHeader title={'Connect'} size={'small'} />
                <div
                    className={clsx(
                        classes.socialMediaGroupContainer,
                        classes.sectionContainer,
                    )}
                >
                    <SocialMediaGroup
                        variant={'medium'}
                        socialMedia={getElevatorMediaSocials()}
                    />
                </div>
            </div>
        );
    };

    const renderAdSpace = () => {
        return (
            <div className={classes.sectionContainer}>
                <AdSpace
                    type={'banner-ad'}
                    adUnit={'elevator-sidebar-halfpage1'}
                    size={'md'}
                />
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <GridContainer spacing={0}>
                <GridItem xs={12} md={7} className={classes.postContainer}>
                    {children}
                </GridItem>
                <GridItem xs={12} md={5}>
                    <aside className={classes.asideContainer}>
                        {renderConnectSection()}
                        {renderAdSpace()}
                        <div className={classes.sectionContainer}>
                            <PopularPostList />
                        </div>
                    </aside>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export interface SidebarProps extends PageProps {
    children: React.ReactNode;
}

export default Sidebar;
