import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },

    smallPadding: {
        paddingRight: theme.spacing(1),
    },
    mediumPadding: {
        paddingRight: theme.spacing(4),
    },
    largePadding: {
        paddingRight: theme.spacing(3),
    },
});

export default makeStyles(styles);
