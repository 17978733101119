import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import Image from 'next/image';

const CopyrightNotice = () => {
    const classes = useStyles();

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <div className={classes.copyrightNoticeContainer}>
            <Image
                src={'/static/img/footer_logo.svg'}
                className={classes.footerLogo}
                alt={'ELEVATOR Icon'}
                width={20}
                height={20}
            />
            <Typography variant={'subtitle2'} className={classes.copyrightNotice}>
                ELEVATOR © {getCurrentYear()} · All Rights Reserved
            </Typography>
        </div>
    );
};

export default CopyrightNotice;
