import React from 'react';
import useStyles from './styles';
import SubmissionStatusIndicator from 'atoms/SubmissionStatusIndicator';
import Typography from '@material-ui/core/Typography';
import { Submission } from 'types/submissions';
import { ROUTES } from 'config/Nav';
import { Media } from '@elevatormedia/duffel-bag/dist/types/media';
import { formatDate } from '@elevatormedia/duffel-bag/dist/utils/string';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Image from 'next/image';

const SubmissionCard: React.FC<SubmissionCardProps> = (props) => {
    const classes = useStyles();

    const router = useRouter();

    const { submission } = props;

    const {
        contentTitle,
        service,
        contentImage,
        status,
        createdAt,
        flagged,
        orderNumber,
        services,
        order,
    } = submission;

    const statusLabel = flagged ? 'Flagged' : status;

    const onCardClick = () => {
        router.push(ROUTES.ACCOUNT.SUBMISSIONS.to + '/' + orderNumber);
    };

    const addOns = services.filter((service) => service.parent);
    const addOnNameArray = addOns.map((addOn) => addOn.name);

    return (
        <div className={classes.paper} onClick={onCardClick}>
            <div className={classes.imageContainer}>
                <div
                    className={clsx(classes.horizontalStatus, {
                        [classes.colorPending]:
                            statusLabel === 'Approval' || statusLabel === 'Review',
                        [classes.colorApproved]:
                            statusLabel === 'Completed' || statusLabel === 'Approved',
                        [classes.colorRejected]: statusLabel === 'Rejected',
                        [classes.colorFlagged]: statusLabel === 'Flagged',
                    })}
                />
                {contentImage && (
                    <Image
                        src={(contentImage as Media).sourceUrl}
                        className={classes.attachedImage}
                        alt={'submission image'}
                        layout="fill"
                    />
                )}
                <div className={classes.textContainer}>
                    <Typography className={classes.dateAndType}>
                        {formatDate(parseInt(createdAt))} - {service?.name}
                        {addOns.length > 0 && '  + ' + addOnNameArray}
                    </Typography>
                    <Typography className={classes.submissionTitle}>
                        {contentTitle}
                    </Typography>
                </div>
            </div>
            <SubmissionStatusIndicator
                status={statusLabel}
                orderStatus={order.status}
                orderFlagged={order.flagged}
            />
        </div>
    );
};

export interface SubmissionCardProps {
    submission: Submission;
}

export default SubmissionCard;
