import gql from 'graphql-tag';

export const CATEGORY_WITH_POSTS = gql`
    query category($slug: String, $cursor: PagniationOptions) {
        category(slug: $slug, postsCursor: $cursor) {
            name
            description
            posts {
                title
                slug
                image {
                    sourceUrl
                    sourceSet
                }
                type
                publishedAt
                authors {
                    displayName
                    preferredUsername
                }
                categories {
                    name
                    slug
                }
            }
        }
    }
`;
