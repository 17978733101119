import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';

const AcceptTerms: React.FC = () => {
    const classes = useStyles();

    return (
        <Typography gutterBottom={false} variant={'caption'}>
            By clicking &quot;Pay & Submit&quot; I acknowledge that I have read and agree
            to the ELEVATOR
            <Link href="/legal/terms-of-service" inline className={classes.links}>
                Terms of Use
            </Link>
            and
            <Link href="/legal/privacy-policy" inline className={classes.links}>
                Privacy Policy
            </Link>
        </Typography>
    );
};

export default AcceptTerms;
