import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useRouter } from 'next/router';
import useStyles from './styles';
import { ROUTES } from 'config/Nav';
import { PageProps } from 'types/page';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import PageTitleDescription from '@elevatormedia/duffel-bag/dist/atoms/PageTitleDescription';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import Callout from '@elevatormedia/duffel-bag/dist/atoms/Callout';
import Loading from '@elevatormedia/duffel-bag/dist/atoms/Loading';
import Layout from '@elevatormedia/duffel-bag/dist/atoms/Layout';

const Confirmation: React.FC<PageProps> = () => {
    const classes = useStyles();
    const router = useRouter();
    const { currentUser, hydrateUser } = useAuth();

    const {
        pathname,
        query: { campaignId, isNewUser, submissionId },
    } = router;
    const isAd = pathname === '/advertising/confirmation';

    React.useEffect(() => {
        window && window.scrollTo(0, 0);

        const checkAuth = async () => {
            const updatedUser = await hydrateUser(false);
            if (!updatedUser) window.open(ROUTES.AUTH.SIGN_IN.to, '_self');
        };

        if (!currentUser) checkAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <div className={classes.root}>
                <div className={classes.contentContainer}>
                    {currentUser ? (
                        <>
                            <PageTitleDescription
                                pageTitle={`${isAd ? 'CAMPAIGN' : 'SUBMISSION'} SENT`}
                                divider={true}
                                description={
                                    <Typography gutterBottom>
                                        Your{' '}
                                        {isAd ? 'advertising campaign' : 'submission'} has
                                        been received by the ELEVATOR team! Your{' '}
                                        {isAd ? 'Campaign' : 'Submission'} ID is{' '}
                                        <b className={classes.extraBold}>
                                            {isAd ? campaignId : submissionId}
                                        </b>
                                        . Please keep track of it in the event you need to
                                        contact support.
                                    </Typography>
                                }
                            />
                            <div className={classes.content}>
                                {isNewUser && (
                                    <Callout>
                                        <Typography variant={'body1'} component={'span'}>
                                            Your account has been created! Please note
                                            that email verification is required if you
                                            ever need to reset your password.{' '}
                                        </Typography>
                                        <Typography
                                            color={'error'}
                                            variant={'subtitle1'}
                                            component={'span'}
                                        >
                                            Please check your email.
                                        </Typography>
                                    </Callout>
                                )}
                                <GridContainer
                                    direction={'column'}
                                    spacing={2}
                                    className={classes.linkContainer}
                                >
                                    <GridItem>
                                        {isAd ? (
                                            <Link
                                                variant={'wrapper'}
                                                href={ROUTES.ACCOUNT.to}
                                            >
                                                <Typography className={classes.linkText}>
                                                    Go to My Account
                                                </Typography>
                                            </Link>
                                        ) : (
                                            <Link
                                                variant={'wrapper'}
                                                href={ROUTES.ACCOUNT.SUBMISSIONS.to}
                                            >
                                                <Typography className={classes.linkText}>
                                                    Go to My Submissions
                                                </Typography>
                                            </Link>
                                        )}
                                    </GridItem>
                                    <GridItem className={classes.bottomButton}>
                                        <Link variant={'wrapper'} href={ROUTES.HOME.to}>
                                            <Typography className={classes.linkText}>
                                                Continue Browsing
                                            </Typography>
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default Confirmation;
