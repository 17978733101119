import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const getReleaseStage = () => {
    if (process.env.cookieDomain === 'elevatormag.com') {
        return 'production';
    } else if (process.env.cookieDomain === 'alpha.elevatormag.com') {
        return 'testing';
    } else {
        return 'localhost';
    }
};

const bugsnagClient = bugsnag({
    apiKey: process.env.bugsnagApiKey,
    appVersion: require('../../../package.json').version,
    notifyReleaseStages: ['testing', 'production'],
    releaseStage: getReleaseStage(),
    beforeSend: (report) => {
        // https://stackoverflow.com/a/64602711
        if (
            report.errorMessage.includes('Illegal invocation') &&
            report.request.url.includes('fbclid')
        )
            report.ignore();
    },
});
bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
