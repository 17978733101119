import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
            flexWrap: 'wrap',
        },

        userInfo: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 'fit-content',
            margin: theme.spacing(1, 1.5, 1.5, 0),
        },
        summaryText: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(1, 2, 0, 2),
        },
    });

export default makeStyles(styles);
