import React from 'react';
import useStyles from './styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/core/styles/useTheme';
import { VerifyEmailSteps } from 'molecules';

const VerifyEmail = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box
            height={'70vh'}
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Box
                height={'70vh'}
                width={'100%'}
                maxWidth={theme.static_component_sizes.content_max_width}
            >
                <Paper className={classes.container}>
                    <VerifyEmailSteps />
                </Paper>
            </Box>
        </Box>
    );
};

export default VerifyEmail;
