const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
};

const createImage = (url: string): Promise<CanvasImageSource> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
        return image;
    });
};

type ImageCropType = {
    unit: 'px' | '%';
    x: number;
    y: number;
    width: number;
    height: number;
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
const cropImage = async (
    imageSrc: string,
    pixelCrop: ImageCropType,
    originalFile: File,
): Promise<File> => {
    // Create image object
    const image = await createImage(imageSrc);

    // Create canvas object to draw image on
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
    );

    /**
     * Add polyfill for Micrsofy Edge browsers. Retrieved from:
     * https://stackoverflow.com/questions/47475647/canvas-to-blob-on-edge
     * with additional privision from revision:
     * https://wiki.developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob$revision/1559998
     */
    if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
            value: function(callback: (arg0: Blob) => void, type: any, quality: any) {
                var dataURL = this.toDataURL(type, quality).split(',')[1];
                setTimeout(function() {
                    var binStr = atob(dataURL),
                        len = binStr.length,
                        arr = new Uint8Array(len);

                    for (var i = 0; i < len; i++) {
                        arr[i] = binStr.charCodeAt(i);
                    }

                    callback(new Blob([arr], { type: type || 'image/jpeg' }));
                });
            },
        });
    }

    // As a blob
    return new Promise(function(resolve) {
        canvas.toBlob(
            function(blob) {
                const fileBlob = new File([blob], originalFile.name, { type: blob.type });
                resolve(fileBlob);
            },
            'image/jpeg',
            1,
        );
    });
};

export { getRadianAngle, createImage, cropImage };
