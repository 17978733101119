import React from 'react';
import useStyles from './styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import withLayout from 'hocs/withLayout';
import Alien from 'mdi-material-ui/Alien';
import { TitleSearchbar, UserCard } from 'molecules';
import { Element, Link } from 'react-scroll';
import { CreativeDirectory, CreativeDirectoryUserResult } from 'types/creativeDirectory';
import { reportError } from 'lib/errors';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import { QueryResult } from '@elevatormedia/duffel-bag/dist/types/graphql';
import ScrollToTop from '@elevatormedia/duffel-bag/dist/atoms/ScrollToTop';
import Card from '@elevatormedia/duffel-bag/dist/atoms/Card';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';

const Directory = (props: DirectoryPropTypes) => {
    const classes = useStyles();

    const {
        data,
        refetch,
        searchInput,
        setSearchInput,
        activeLabels,
        setActiveLabels,
        variables,
        error,
    } = props;

    if (error) {
        return (
            <LoadingErrorDisclaimer
                error={error}
                failedTarget={'creative directory results'}
                reportError={reportError}
            />
        );
    }

    const { userDirectory, creditTypes: roles } = data;

    const handleClickChip = (typeId: string) => {
        const typeIdIndex = activeLabels.findIndex(
            (currentTypeId) => currentTypeId === typeId,
        );
        if (typeIdIndex === -1) activeLabels.push(typeId);
        else activeLabels.splice(typeIdIndex, 1);

        setActiveLabels([...activeLabels]);
    };

    const hasNoResults = () => {
        return userDirectory.every((directoryItem) => {
            return directoryItem.results.length === 0;
        });
    };

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        variables.searchInput = searchInput;
        refetch(variables);
    };

    const handleClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setSearchInput('');
        variables.searchInput = '';
        refetch(variables);
    };

    const renderNoSearchResults = () => {
        return (
            <div className={classes.noResultsContainer}>
                <div className={classes.noResultsContent}>
                    <Alien fontSize="large" />
                    <Typography variant="h6" className={classes.noResultsIcon}>
                        No results found.
                    </Typography>
                </div>
            </div>
        );
    };

    const renderAlphabetTabs = () => {
        return (
            <Card className={classes.cardContainer}>
                <div className={classes.alphabetContainer}>
                    {userDirectory.map(({ char, results }, index) => {
                        return (
                            <div className={classes.letterBox} key={index}>
                                {results.length > 0 ? (
                                    <Link
                                        to={char}
                                        smooth={true}
                                        offset={-100}
                                        className={classes.letter}
                                    >
                                        {char.toUpperCase()}
                                    </Link>
                                ) : (
                                    <Typography className={classes.disabledLetter}>
                                        {char.toUpperCase()}
                                    </Typography>
                                )}
                            </div>
                        );
                    })}
                </div>
            </Card>
        );
    };

    const renderUserResults = () => {
        return (
            <List>
                {userDirectory.map(({ char, results }, index) => {
                    return (
                        <Element name={char.toLowerCase()} key={char.toLowerCase()}>
                            {results.length > 0 ? (
                                <li className={classes.alphabetSection} key={char}>
                                    <Typography
                                        variant={'subtitle1'}
                                        className={classes.letterHeader}
                                    >
                                        {char}
                                    </Typography>
                                    <GridContainer
                                        spacing={2}
                                        className={classes.gridContainer}
                                    >
                                        {results.map(
                                            (user: CreativeDirectoryUserResult) => {
                                                return (
                                                    <GridItem
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        className={
                                                            classes.userCardContainer
                                                        }
                                                        key={user.userId}
                                                    >
                                                        <UserCard
                                                            user={user}
                                                            name={
                                                                user.displayName ||
                                                                user.preferredUsername
                                                            }
                                                            socialMedia={user.socialMedia}
                                                            cardColor={'paper'}
                                                        />
                                                    </GridItem>
                                                );
                                            },
                                        )}
                                    </GridContainer>
                                </li>
                            ) : null}
                        </Element>
                    );
                })}
            </List>
        );
    };

    return (
        <div className={classes.content}>
            <Card className={classes.outerCard}>
                <div className={classes.searchSection}>
                    <div className={classes.searchBar}>
                        <TitleSearchbar
                            onChange={(event) =>
                                setSearchInput(event.currentTarget.value)
                            }
                            onClear={handleClear}
                            handleSubmit={handleSearch}
                            id={'search'}
                            value={searchInput}
                            placeholder={'Search Creator Directory'}
                        />
                    </div>

                    <div className={classes.chipList}>
                        {roles.map(({ typeName, typeId }) => {
                            return (
                                <Chip
                                    key={typeId}
                                    label={
                                        <div className={classes.staffChipContainer}>
                                            {/* {typeName === 'STAFF' && (
                                                <img
                                                    src={'/static/img/Brand.svg'}
                                                    className={classes.staffIcon}
                                                />
                                            )} */}
                                            {typeName}
                                        </div>
                                    }
                                    onClick={() => {
                                        handleClickChip(typeId);
                                    }}
                                    className={clsx(
                                        classes.chip,
                                        activeLabels.includes(typeId) &&
                                            classes.activeChip,
                                    )}
                                />
                            );
                        })}
                    </div>
                </div>
                {renderAlphabetTabs()}
            </Card>
            {hasNoResults() ? renderNoSearchResults() : renderUserResults()}
            <ScrollToTop visibleYOffset={70} />
        </div>
    );
};

export interface DirectoryPropTypes
    extends QueryResult<
        'data',
        CreativeDirectory,
        { staff?: boolean; creditTypes?: Array<string>; searchInput?: string }
    > {
    searchInput: string;
    setSearchInput: Function;
    activeLabels: Array<string>;
    setActiveLabels: Function;
}

export default withLayout(Directory, false);
