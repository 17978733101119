import React from 'react';
import { Credit } from '@elevatormedia/duffel-bag/dist/types/credit';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import { PublicUserInfo } from '@elevatormedia/duffel-bag/dist/types/user';
import CreditChip from 'molecules/CreditChip';

const CreditField = (props: CreditFieldPropTypes) => {
    const { credits } = props;

    const getUsername = (user: PublicUserInfo) => {
        return user.displayName ? user.displayName : user.preferredUsername;
    };

    return (
        <GridContainer direction={'row'} spacing={2} paddingX={0} paddingY={0}>
            {credits.map((credit, index) => {
                const name = credit.user ? getUsername(credit.user) : credit.name;
                return (
                    <GridItem key={index}>
                        <CreditChip
                            variant={'view'}
                            type={credit.creditType.typeName}
                            name={name}
                            avatar={credit.user && credit.user.avatar}
                            preferredUsername={
                                credit.user && credit.user.preferredUsername
                            }
                        />
                    </GridItem>
                );
            })}
        </GridContainer>
    );
};

export type CreditFieldPropTypes = {
    credits: Credit[];
    key?: string | number;
};

export default CreditField;
