import React from 'react';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import InboxArrowUp from 'mdi-material-ui/InboxArrowUp';
import { ROUTES } from 'config/Nav';

const MenuButtons = (props: MenuButtonsProps) => {
    const classes = useStyles();

    const { handleSignoutClick } = props;

    return (
        <>
            <Button
                className={classes.submitButton}
                variant={'contained'}
                size={'small'}
                color={'secondary'}
                href={ROUTES.SUBMIT.to}
            >
                <InboxArrowUp className={classes.submitIcon} />
                Submit
            </Button>
            <Button
                className={classes.signOutButton}
                variant={'outlined'}
                size={'small'}
                onClick={handleSignoutClick}
            >
                Sign Out
            </Button>
        </>
    );
};

export interface MenuButtonsProps {
    handleSignoutClick: () => void;
}

export default MenuButtons;
