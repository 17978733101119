import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        paddingTop: theme.spacing(2),
    },
    postItem: {
        paddingBottom: theme.spacing(2),
    },
    teaserContainer: {
        height: '125px',
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '150px',
        },
    },
    titleContainer: {
        paddingTop: theme.spacing(1),
    },
    skeleton: {
        paddingTop: theme.spacing(0.5),
    },
});

export default makeStyles(styles);
