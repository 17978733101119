import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    // StepOne
    stepContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    sampleImage: {
        width: '70%',
        padding: `${theme.spacing(2)}px 0`,
    },

    // UserInfoForm
    form: {
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
});

export default makeStyles(styles);
