import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    rcInputNumber: {
        display: 'inline-block',
        height: '26px',
        margin: 0,
        padding: 0,
        fontSize: '12px',
        lineHeight: '26px',
        verticalAlign: 'middle',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        transition: 'all 0.3s',
        '&:hover': {
            borderColor: '#1890ff',
        },
    },
    rcInputNnumberFocused: {
        borderColor: '#1890ff',
        boxShadow: '0 0 0 2px rgba(24,144,255,0.2)',
    },
    rcInputNumberOutOfRangeInput: {
        color: 'red',
    },
    rcInputNumberHandler: {
        display: 'block',
        height: '12px',
        overflow: 'hidden',
        lineHeight: '12px',
        textAlign: 'center',
        touchAction: 'none',
        opacity: 0.3,
        '&:hover': {
            color: '#999',
            borderColor: '#d9d9d9',
        },
    },
    rcInputNumberHandlerActive: {
        background: '#ddd',
    },
    rcInputNumberHandlerUpInner: {
        color: ' #666666',
        '-webkit-user-select': 'none',
        userSelect: 'none',
    },
    rcInputNumberHandlerDownInner: {
        color: ' #666666',
        '-webkit-user-select': 'none',
        userSelect: 'none',
        '&:after': {
            content: '-',
        },
    },
    rcInputNumberHandlerUp: {
        borderColor: '#1890ff',
        paddingTop: '1px',
        borderBottom: '1px solid #d9d9d9',
        transition: 'all 0.3s',
        '&:after': {
            content: '+',
        },
    },
    rcInputNumberHandlerWrap: {
        borderColor: '#1890ff',
        float: 'right',
        width: '20px',
        height: '100%',
        borderLeft: '1px solid #d9d9d9',
        transition: 'all 0.3s',
    },
    rcInputNumberDisabled: {
        backgroundColor: '#f3f3f3',
        cursor: 'not-allowed',
        opacity: 0.72,
        '&:hover': {
            borderColor: '#d9d9d9',
        },
        // opacity: 0.3,
        color: '#999',
        borderColor: '#d9d9d9',
    },
    rcInputNumberInputWrap: {
        height: '100%',
        overflow: 'hidden',
    },
    rcInputNumberInput: {
        width: '100%',
        height: '100%',
        padding: 0,
        color: '#666666',
        lineHeight: '26px',
        textAlign: 'center',
        border: 0,
        borderRadius: '4px',
        outline: 0,
        transition: 'all 0.3s ease',
        // transition: 'all 0.3s',
        '-moz-appearance': 'textfield',
        backgroundColor: '#f3f3f3',
        cursor: 'not-allowed',
        opacity: 0.72,
    },
    rcInputNumberHandlerDown: {
        transition: 'all 0.3s',
    },
    rcInputNumberHandlerDownDisabled: {
        opacity: 0.3,
        '&:hover': {
            color: '#999',
            borderColor: '#d9d9d9',
        },
    },
    rcInputNumberHandlerUpDisabled: {
        opacity: 0.3,
        '&:hover': {
            color: '#999',
            borderColor: '#d9d9d9',
        },
    },
});

export default makeStyles(styles);
