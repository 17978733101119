import React from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { ServiceInfo, SubmissionStatusChip } from 'atoms';
import { Formik, FormikHelpers } from 'formik';
import { EditSubmissionForm } from 'organisms';
import { DOMAIN_BASE, ROUTES } from 'config/Nav';
import SUBMISSION_CONTENT_TYPES from 'config/SubmissionContentTypes';
import SUBMISSION_STATUSES from 'config/SubmissionStatuses';
import { Submission, SubmissionContentType, SubmissionContent } from 'types/submissions';
import { CreditInput } from 'types/credit';
import useStyles from './styles';
import { useMutation } from '@apollo/client';
import { UPDATE_MY_SUBMISSION } from 'lib/graphql/mutations/submissions';
import { mixed, object as yupObject, string } from 'yup';
import { useSnackbar } from 'notistack';
import { MY_SUBMISSION_BY_ORDER_NUMBER } from 'lib/graphql/queries/submissions';
import CreditsList from 'molecules/CreditsList';
import { useRouter } from 'next/router';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import SectionHeader from '@elevatormedia/duffel-bag/dist/atoms/SectionHeader';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import { Media } from '@elevatormedia/duffel-bag/dist/types/media';
import ShareButtonGroup from '@elevatormedia/duffel-bag/dist/atoms/ShareButtonGroup';
import { Service } from '@elevatormedia/pitch-hooks';
import { Credit } from '@elevatormedia/duffel-bag/dist/types/credit';
import Image from 'next/image';

const SubmissionComponent: React.FC<SubmissionProps> = ({ submission }) => {
    const classes = useStyles();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        contentTitle,
        contentUrl,
        status,
        flagged,
        contentDescription,
        contentImage,
        credits,
        submissionId,
        orderNumber,
        submissionType,
        services,
        order,
    } = submission;

    const statusLabel = flagged ? 'Flagged' : status;

    const [updateMySubmission] = useMutation(UPDATE_MY_SUBMISSION);

    const { currentUser } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const router = useRouter();

    const addOns: Service[] = services.filter((service) => {
        return service.parent;
    });

    const creditList: CreditInput[] = credits.map((credit) => {
        return {
            type: credit.creditType.typeName,
            name: credit.name,
            userId: credit.user && credit.user.userId ? credit.user.userId : null,
            socialMedia: credit.socialMedia,
            avatar: credit.user && credit.user.avatar ? credit.user.avatar : null,
            isSubmitter:
                credit.user && credit.user.userId
                    ? credit.user.userId && credit.user.userId === currentUser.userId
                    : false,
        };
    });

    const initialValues = {
        contentUrl: submission.contentUrl,
        contentTitle: submission.contentTitle,
        contentImage: submission.contentImage,
        contentDescription: submission.contentDescription,
        credits: creditList,
        preferredWriter: '',
    };

    const MAX_FILE_SIZE = 20971520; // ~ 20 Megabytes
    const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

    const validationSchema = yupObject({
        contentUrl: string()
            .url(`Please enter a valid url. All content links must start with 'https://'`)
            .required('A valid url is required to make a submission')
            .test(
                'allowedDomain',
                'Sponsored submissions require a valid YouTube video link',
                (value) => {
                    //TODO: determine URLs for promo services
                    return true;
                    /* eslint-disable no-unreachable */
                    let url;
                    try {
                        url = new URL(value);
                    } catch (error) {
                        return false;
                    }

                    return [
                        'www.youtube.com',
                        'youtube.com',
                        'www.youtu.be',
                        'm.youtube.com',
                        'youtu.be',
                    ].includes(url.hostname);
                    /* eslint-disable no-unreachable */
                },
            ),

        contentTitle: string().required('This field is required'),
        contentImage: mixed()
            .test(
                'SupportImageTypes',
                'We do not support this kind of image',
                // A func returning true means it is a valid input
                (value) => {
                    // when image instances are empty, its fine as they aren't required all the time
                    if (!value) return true;

                    if (value.sourceUrl) return true;
                    // For when images are local and we need to check for valid formats
                    return SUPPORTED_IMAGE_FORMATS.includes(value.type);
                },
            )
            .test(
                'MaxfileSize',
                'Please provide an image with size <= 20MB',
                // A func returning true means it is a valid input
                (value) => {
                    if (!value) return true;
                    if (value.sourceUrl) return true;
                    return value.size < MAX_FILE_SIZE;
                },
            ),
        contentDescription: string().required('This field is required'),
        // preferredWriter: string(),
    });

    /**
     * Returns post url using domain base and slug.
     */
    const getPostUrl = () => {
        const { slug } = submission.post;
        const qualifiedUrl = DOMAIN_BASE + '/' + slug;

        return qualifiedUrl;
    };

    const returnToSubmissions = () => {
        router.push(ROUTES.ACCOUNT.SUBMISSIONS.to);
    };

    const handleSubmit = async (
        values: SubmissionContent,
        actions: FormikHelpers<SubmissionContent>,
    ) => {
        const { contentTitle, contentUrl, contentDescription } = values;

        try {
            const updates: Partial<SubmissionContent> = {
                contentTitle,
                contentUrl,
                contentDescription,
            };

            if (values.contentImage instanceof File) {
                updates.contentImage = values.contentImage;
            }

            await updateMySubmission({
                variables: {
                    id: submissionId,
                    updates,
                },
                refetchQueries: [
                    {
                        query: MY_SUBMISSION_BY_ORDER_NUMBER,
                        variables: {
                            orderNumber,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            });

            enqueueSnackbar('Submission Updated', {
                variant: 'success',
                preventDuplicate: true,
            });
        } catch (error) {
            enqueueSnackbar('Error Saving Submission', {
                variant: 'error',
                preventDuplicate: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const renderShareButtonGroup = () => {
        const postUrl = getPostUrl();
        return (
            <ShareButtonGroup
                shareLink={postUrl}
                size={'large'}
                postTitle={contentTitle}
            />
        );
    };

    const renderCardSectionItem = (
        fieldName: string,
        type: SubmissionContentType,
        value: string | Credit[] | Media,
    ) => {
        let content;

        switch (type) {
            case SUBMISSION_CONTENT_TYPES.LINK:
                content = (
                    <Link
                        href={value as string}
                        className={classes.link}
                        variant={'default'}
                    >
                        {value}
                    </Link>
                );
                break;
            case SUBMISSION_CONTENT_TYPES.IMAGE:
                if (!contentImage) {
                    content = <Typography>(None provided)</Typography>;
                } else {
                    content = (
                        <Image
                            src={(value as Media).sourceUrl}
                            className={classes.image}
                            width={0}
                            height={0}
                            alt="Submission Image"
                            style={{ width: '100%', height: 'auto' }} // optional
                        />
                    );
                }
                break;
            case SUBMISSION_CONTENT_TYPES.STRING:
            default:
                content = <Typography>{value}</Typography>;

                break;
        }

        return (
            <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
                <Typography
                    variant={'caption'}
                    color={'textSecondary'}
                    className={classes.sectionHeader}
                >
                    {fieldName}
                </Typography>

                <Box>{content}</Box>
            </Box>
        );
    };

    const renderCredits = (value: CreditInput[]) => {
        return (
            <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
                <SectionHeader title={'Credits'} size={'small'} />
                <Box className={classes.creditContainer}>
                    <CreditsList credits={value} />
                </Box>
            </Box>
        );
    };

    const renderLivePostCard = () => {
        const postUrl = getPostUrl();
        return (
            <Box paddingBottom={2} width={'100%'}>
                <div className={classes.content}>
                    <Typography variant={'h5'} gutterBottom>
                        Your post is live! 🤝
                    </Typography>
                    <Typography variant={'body1'} component={'span'} gutterBottom>
                        &quot;{contentTitle}&quot; is now live at{' '}
                        <a href={postUrl} className={classes.link}>
                            {postUrl}
                        </a>
                        . Make sure to share the news with your followers on social media!
                    </Typography>
                    <Box
                        paddingTop={2}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        {renderShareButtonGroup()}
                    </Box>
                </div>
            </Box>
        );
    };

    const renderSubmissionInfoCard = () => {
        return (
            <Box paddingBottom={2} width={'100%'}>
                <div className={classes.content}>
                    {renderCardSectionItem(
                        'Content Link',
                        SUBMISSION_CONTENT_TYPES.LINK,
                        contentUrl,
                    )}
                    {renderCardSectionItem(
                        'Description',
                        SUBMISSION_CONTENT_TYPES.STRING,
                        contentDescription,
                    )}
                    {renderCardSectionItem(
                        'Image',
                        SUBMISSION_CONTENT_TYPES.IMAGE,
                        contentImage as Media,
                    )}
                </div>
            </Box>
        );
    };

    return (
        <div className={classes.root}>
            <Box className={classes.formHeader}>
                <Link href={ROUTES.ACCOUNT.SUBMISSIONS.to} className={classes.backLink}>
                    {'\u2190'} My Submissions
                </Link>
                <div>
                    <b>Order</b> #{orderNumber}
                </div>
            </Box>
            <Divider variant={'fullWidth'} className={classes.divider} />

            {(submission.status == 'Review' || submission.status == 'Approval') &&
            order.status != 'failed' &&
            order.flagged === false ? (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    <EditSubmissionForm
                        handleCancel={returnToSubmissions}
                        orderNumber={orderNumber}
                        submissionId={submissionId}
                        creditList={creditList}
                        credits={credits}
                        returnToSubmissions={returnToSubmissions}
                        submissionType={submissionType}
                        statusLabel={statusLabel}
                        addOns={addOns}
                        orderStatus={order.status}
                        orderFlagged={order.flagged}
                    />
                </Formik>
            ) : (
                // View Mode
                <div>
                    <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                        className={classes.sectionTitleContainer}
                    >
                        <SectionHeader title={'Submission Info'} size={'small'} />

                        {/* Status Chip */}
                        <SubmissionStatusChip
                            variant={SUBMISSION_STATUSES[statusLabel.toUpperCase()]}
                            orderStatus={order.status}
                            orderFlagged={order.flagged}
                        />
                    </Box>

                    <ServiceInfo
                        statusLabel={SUBMISSION_STATUSES[statusLabel.toUpperCase()]}
                        orderStatus={order.status}
                        orderFlagged={order.flagged}
                        submissionType={submissionType}
                        addOns={addOns}
                    />

                    <Typography
                        variant={'caption'}
                        color={'textSecondary'}
                        className={classes.sectionHeader}
                    >
                        {'Submission Title'}
                    </Typography>
                    <Typography variant={'body1'} className={classes.contentTitle}>
                        {contentTitle}
                    </Typography>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        paddingBottom={1}
                        width={'100%'}
                    >
                        {statusLabel === SUBMISSION_STATUSES.COMPLETED &&
                            renderLivePostCard()}
                        {renderSubmissionInfoCard()}
                    </Box>

                    {/* Credits */}
                    {renderCredits(creditList)}

                    {/* Disabled Checkbox */}
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Checkbox
                            color={'secondary'}
                            checked={true}
                            name="sponsorChecked"
                            required
                            disabled
                        />
                        <Typography
                            align={'left'}
                            color={'textSecondary'}
                            display={'inline'}
                        >
                            I certify that I have the expressed permission or authority to
                            submit this content. *
                        </Typography>
                    </Box>
                    <div className={classes.pageContent}>
                        <Button
                            onClick={returnToSubmissions}
                            variant={'primary'}
                            className={classes.closeButton}
                            fullWidth={mobile}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

interface SubmissionProps {
    submission: Submission;
}

export default SubmissionComponent;
