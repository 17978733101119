import React from 'react';
import { Post } from 'organisms';
import { Post as PostType } from 'types/post';
import { reportError } from 'lib/errors';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';

const PostLayout = (props: PostPropTypes) => {
    const { post, error, hasInstagramEmbed, hasTwitterEmbed, isLoading } = props;

    if (error) {
        return (
            <LoadingErrorDisclaimer
                error={error}
                failedTarget={'post'}
                reportError={reportError}
            />
        );
    }

    const {
        title,
        slug,
        type,
        description,
        content,
        publishedAt,
        authors,
        categories,
        tags,
        submission,
        credits,
        modifiedAt,
    } = post;

    const postHeaderProps = {
        title,
        description,
        slug,
        type,
        publishedAt,
        authors,
        categories,
        modifiedAt,
    };

    return (
        <Post
            content={content}
            submission={submission}
            tags={tags}
            credits={credits}
            hasInstagramEmbed={hasInstagramEmbed}
            hasTwitterEmbed={hasTwitterEmbed}
            isLoading={isLoading}
            {...postHeaderProps}
        />
    );
};

export interface PostPropTypes {
    post?: PostType;
    slug: string;
    error?: any;
    hasInstagramEmbed: Boolean;
    hasTwitterEmbed: Boolean;
    isLoading: Boolean;
}

export default PostLayout;
