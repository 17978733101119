import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: theme.spacing(10),
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(54),
            },
        },
        bannerImage: {
            height: '350px',
            width: '100%',
            objectFit: 'cover',
            marginBottom: theme.spacing(22),
            [theme.breakpoints.down('sm')]: {
                height: '300px',
                marginBottom: theme.spacing(0),
            },
        },
        inviteContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '150px',
            alignItems: 'center',
            padding: theme.spacing(4, 6, 4, 6),
            boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
            borderRadius: '3px',
            backgroundColor: 'white',
            maxWidth: '500px',
            [theme.breakpoints.down('sm')]: {
                top: '200px',
                boxShadow: 'none',
                borderRadius: 0,
                padding: theme.spacing(3),
                margin: theme.spacing(2),
            },
        },
        orgInfoContainer: {
            display: 'flex',
            flexDirection: 'row',
            margin: theme.spacing(4, 0, 4, 0),
            [theme.breakpoints.down('sm')]: {
                marginBottom: 0,
            },
        },
        avatar: {
            height: theme.spacing(15),
            width: theme.spacing(15),
        },
        text: {
            textAlign: 'center',
        },
        orgText: {
            marginLeft: theme.spacing(2),
        },
        buttonContainer: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginTop: theme.spacing(3),
            },
        },
        acceptButton: {
            marginLeft: theme.spacing(2),
            borderRadius: '3px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginTop: theme.spacing(1),
            },
        },
        declineButton: {
            borderWidth: '2px',
            borderColor: 'black',
            borderRadius: '3px',
        },
        descriptiontext: {
            margin: theme.spacing(2, 0, 2, 0),
            alignSelf: 'flex-start',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical',
            fontWeight: 300,
        },
    }),
);

export default useStyles;
