import React from 'react';
import DesktopAppbar from './Desktop';
import MobileAppbar from './Mobile';
import useStyles from './styles';
import { useRouter } from 'next/router';
import { ROUTES } from 'config/Nav';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import { signOut } from '@elevatormedia/duffel-bag/dist/lib/auth';
import { deleteCartMeta } from 'lib/cart';

const Appbar = () => {
    const classes = useStyles();

    const router = useRouter();

    // Tracks the current search input field
    const [searchInput, setSearchInput] = React.useState('');

    const { currentUser } = useAuth();

    const onSignout = async () => {
        await signOut(process.env.authEndpoint);
        await deleteCartMeta();
        window.open(ROUTES.AUTH.SIGN_IN.to, '_self');
    };

    /**
     * Handler for the current search input value
     */
    const handleSearchInput = (input: string) => {
        setSearchInput(input);
    };

    /**
     * Handler for submiting the search input
     */
    const onSubmitSearchInput = (event: any, cb?: Function) => {
        event.preventDefault();

        // sanitize spaces
        const sanitizedInput = searchInput;
        setSearchInput(''); // clear input

        // If a callback was defined, trigger
        cb && cb();

        router.push({
            pathname: ROUTES.SEARCH.to,
            query: {
                s: sanitizedInput,
            },
        });
    };

    return (
        <>
            <div className={classes.mobileRoot}>
                <MobileAppbar
                    handleSignoutClick={onSignout}
                    currentUser={currentUser}
                    searchInput={searchInput}
                    handleSearchInput={handleSearchInput}
                    onSubmitSearchInput={onSubmitSearchInput}
                />
            </div>
            <div className={classes.desktopRoot}>
                <DesktopAppbar
                    handleSignoutClick={onSignout}
                    currentUser={currentUser}
                    searchInput={searchInput}
                    handleSearchInput={handleSearchInput}
                    onSubmitSearchInput={onSubmitSearchInput}
                />
            </div>
        </>
    );
};

export default Appbar;
