import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Camera from 'mdi-material-ui/Camera';
import Close from 'mdi-material-ui/Close';
import useStyles from './styles';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import Image from 'next/image';

const ImageUploader = <T extends ImageUploaderVariant>(
    props: ImageUploaderPropTypes<T>,
) => {
    const classes = useStyles();

    const {
        error,
        helperText,
        inputProps,
        file,
        files,
        onRemoveImage,
        label,
        renderLabel,
        variant,
        avatarName,
        disabled,
        required,
        buttonPosition,
    } = props;

    const getImageURL = (renderedFile = file) => {
        if ((renderedFile as any) instanceof Blob)
            return URL.createObjectURL(renderedFile);
        else if (Object.keys(renderedFile).includes('sourceUrl'))
            return renderedFile.sourceUrl;
        else return null;
    };

    const getSourceSet = () => {
        if (Object.keys(file).includes('sourceSet')) return file.sourceSet;
        return null;
    };

    const renderAvatarVariant = () => {
        return (
            <>
                <Box className={classes.avatarContainer}>
                    <Box
                        position={'absolute'}
                        bottom={0}
                        className={`${classes.gradientFade} ${classes.avatarGradient}`}
                    />
                    {file && (
                        <Grow in>
                            <>
                                <Image
                                    src={getImageURL()}
                                    className={classes.avatar}
                                    alt="Image Upload"
                                    width={0}
                                    height={0}
                                    style={{ width: '100%', height: 'auto' }} // optional
                                />
                                {renderRemoveButton()}
                            </>
                        </Grow>
                    )}
                    {!file && (
                        <Grow in>
                            <Avatar className={classes.avatar}>{avatarName}</Avatar>
                        </Grow>
                    )}
                    {renderUploadButton()}
                </Box>
                <Box paddingTop={1}>
                    <Typography
                        color={'textSecondary'}
                        variant={'caption'}
                        className={clsx(
                            classes.imageUploadsDisclaimer,
                            error && classes.errorText,
                        )}
                    >
                        {helperText}
                    </Typography>
                </Box>
            </>
        );
    };

    const renderContentVariant = (buttonPosition: string) => {
        switch (buttonPosition) {
            case 'top':
                return (
                    <>
                        <Typography variant={'subtitle2'}>
                            {renderLabel ? label : ''}
                            {required && ' *'}
                        </Typography>
                        <GridContainer
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                        >
                            <GridItem>
                                {file ? (
                                    <Typography
                                        variant={'caption'}
                                        className={classes.fileName}
                                    >
                                        {file.name || file.path}
                                    </Typography>
                                ) : (
                                    <div className={classes.noImageBox} />
                                )}
                                {file && (
                                    <Tooltip title={'Remove this image'}>
                                        <IconButton
                                            size={'small'}
                                            onClick={onRemoveImage}
                                        >
                                            <Close color={'error'} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </GridItem>
                            <GridItem>
                                <Button
                                    className={classes.uploadButton}
                                    variant={'contained'}
                                    component={'label'}
                                    fullWidth
                                    size={'small'}
                                    onClick={() => {}}
                                >
                                    UPLOAD IMAGE
                                    <input
                                        accept="image/*"
                                        type={'file'}
                                        className={classes.imageInput}
                                        {...inputProps}
                                    />
                                </Button>
                            </GridItem>
                        </GridContainer>

                        <Typography
                            color={'textSecondary'}
                            variant={'caption'}
                            className={clsx(
                                classes.imageUploadsDisclaimer,
                                error && classes.errorText,
                            )}
                        >
                            {helperText}
                        </Typography>

                        {file && (
                            <Grow in>
                                <div className={classes.imageContainer}>
                                    <Image
                                        src={getImageURL()}
                                        alt="Image Upload"
                                        width={0}
                                        height={0}
                                        style={{ width: '100%', height: 'auto' }} // optional
                                    />
                                </div>
                            </Grow>
                        )}
                    </>
                );
            case 'left':
                return (
                    <>
                        <Typography variant={'subtitle2'}>
                            {renderLabel ? label : ''}
                            {required && ' *'}
                        </Typography>
                        <GridContainer
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                        >
                            {/* left : button and filename */}
                            <GridItem direction={'column'} xs={7} alignContent={'center'}>
                                <GridItem>
                                    <Button
                                        variant={'contained'}
                                        component={'label'}
                                        size={'small'}
                                        onClick={() => {}}
                                    >
                                        UPLOAD IMAGE
                                        <input
                                            accept="image/*"
                                            type={'file'}
                                            className={classes.imageInput}
                                            {...inputProps}
                                        />
                                    </Button>
                                </GridItem>
                                <GridItem>
                                    <Typography variant={'caption'}>
                                        {file ? file.name : 'No Image Uploaded'}
                                    </Typography>
                                </GridItem>
                            </GridItem>
                            {/* right : image */}
                            <GridItem xs={5}>
                                {file && (
                                    <div className={classes.imageContainerButtonLeft}>
                                        <Grow in>
                                            <div className={classes.imageContainer}>
                                                <Image
                                                    src={getImageURL()}
                                                    alt="Image Upload"
                                                    width={0}
                                                    height={0}
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                    }} // optional
                                                />
                                            </div>
                                        </Grow>
                                        <Tooltip
                                            title={'Remove this image'}
                                            className={classes.removeImage}
                                        >
                                            <IconButton
                                                size={'small'}
                                                onClick={onRemoveImage}
                                            >
                                                <Close color={'error'} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                            </GridItem>
                        </GridContainer>
                    </>
                );
        }
    };

    const renderMultipleFileVariant = () => {
        return (
            <>
                <Typography variant={'subtitle2'}>
                    {renderLabel ? label : ''}
                    {required && ' *'}
                </Typography>
                <GridContainer direction={'row'} spacing={1} alignItems={'center'}>
                    <GridItem>
                        <Button
                            variant={'primary'}
                            component={'label'}
                            fullWidth
                            size={'small'}
                            onClick={() => {}}
                        >
                            Choose File(s)
                            <input
                                multiple
                                type={'file'}
                                className={classes.imageInput}
                                {...inputProps}
                            />
                        </Button>
                    </GridItem>
                    <GridItem>
                        <Typography variant={'caption'} className={classes.fileName}>
                            {files && files.length > 0
                                ? `${files.length} file(s) selected`
                                : 'No files chosen'}
                        </Typography>
                    </GridItem>
                </GridContainer>

                <Typography
                    color={'textSecondary'}
                    variant={'caption'}
                    className={clsx(
                        classes.imageUploadsDisclaimer,
                        error && classes.errorText,
                    )}
                >
                    {helperText}
                </Typography>

                {files && files.length > 0 && (
                    <Grow in>
                        <GridContainer paddingX={2}>
                            {files.map((selectedFile, index) => (
                                <GridItem
                                    xs={12}
                                    paddingY={2}
                                    className={classes.imageContainerMultiple}
                                    key={selectedFile.name}
                                >
                                    <Image
                                        src={getImageURL(selectedFile as any)}
                                        className={classes.multiSelectImageContainer}
                                        alt="Image Upload"
                                        width={0}
                                        height={0}
                                        style={{ width: '100%', height: 'auto' }} // optional
                                    />
                                    <div>
                                        <Typography
                                            className={classes.fileName}
                                            variant={'caption'}
                                        >
                                            {selectedFile.name}
                                        </Typography>
                                        <Button
                                            variant={'text'}
                                            size={'small'}
                                            onClick={() => onRemoveImage(index)}
                                        >
                                            Remove File
                                        </Button>
                                    </div>
                                </GridItem>
                            ))}
                        </GridContainer>
                    </Grow>
                )}
            </>
        );
    };

    const renderUploadButton = () => {
        if (disabled) return null;
        return (
            <IconButton component={'label'} size={'small'} className={classes.iconButton}>
                <Camera />
                <input
                    accept="image/*"
                    type={'file'}
                    className={classes.imageInput}
                    {...inputProps}
                />
            </IconButton>
        );
    };

    const renderRemoveButton = () => {
        if (disabled) return null;
        return (
            <Tooltip title={'Remove this image'}>
                <IconButton
                    component={'label'}
                    size={'small'}
                    className={classes.removeButton}
                    onClick={onRemoveImage}
                >
                    <Close />
                </IconButton>
            </Tooltip>
        );
    };

    const renderBannerVariant = () => {
        return (
            <>
                <Box className={classes.bannerContainer}>
                    <Box
                        position={'absolute'}
                        bottom={0}
                        className={classes.gradientFade}
                    />
                    {file && (
                        <>
                            <Image
                                src={getImageURL()}
                                className={classes.banner}
                                alt="Image Upload"
                                width={0}
                                height={0}
                                style={{ width: '100%', height: 'auto' }} // optional
                            />
                            {renderRemoveButton()}
                        </>
                    )}

                    {/* NOTE will not work without file */}
                    {!file && (
                        <Image
                            src={'/static/img/Auth_Image.png'}
                            className={classes.banner}
                            alt="Auth Image"
                            width={0}
                            height={0}
                            style={{ width: '100%', height: 'auto' }} // optional
                        />
                    )}
                    {renderUploadButton()}
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                    <Typography
                        color={'textSecondary'}
                        variant={'caption'}
                        className={clsx(
                            classes.imageUploadsDisclaimer,
                            error && classes.errorText,
                        )}
                    >
                        {helperText}
                    </Typography>
                </Box>
            </>
        );
    };

    const interpolateUploaderVariant = () => {
        switch (variant) {
            case 'avatar':
                return renderAvatarVariant();
            case 'content':
                return renderContentVariant(buttonPosition);
            case 'banner':
                return renderBannerVariant();
            case 'multiple':
                return renderMultipleFileVariant();
            default:
                // eslint-disable-next-line no-console
                console.error('attempted to render unknown image uploader: ' + variant);
                break;
        }
    };

    return <>{interpolateUploaderVariant()}</>;
};

export type ImageUploaderVariant = 'content' | 'avatar' | 'banner' | 'multiple';
export type ButtonPositionVariant = 'left' | 'top';

export type ImageUploaderPropTypes<T extends ImageUploaderVariant> = {
    variant?: T;
    error: boolean;
    helperText: string;
    inputProps: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >;
    // If variant is 'multiple', this prop will not be allowed
    file?: T extends 'multiple' ? never : File | any;
    files?: T extends 'multiple' ? Array<File> : never;
    onRemoveImage: any;
    label?: string;
    avatarName?: string;
    disabled?: boolean;
    required?: boolean;
    renderLabel?: boolean;
    buttonPosition?: T extends 'content' ? ButtonPositionVariant : never;
};

ImageUploader.defaultProps = {
    label: 'Image',
    variant: 'content' as ImageUploaderVariant,
    avatarName: 'E',
    disabled: false,
    required: false,
    buttonPosition: 'top' as ButtonPositionVariant,
    renderLabel: true,
};

export default ImageUploader;
