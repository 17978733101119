import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import { PostType } from 'types/post';
import Star from 'mdi-material-ui/Star';
import { ROUTES } from 'config/Nav';
import Typography from '@material-ui/core/Typography';
import BaseLink from '@elevatormedia/duffel-bag/dist/atoms/BaseLink';

const PostTypeLink = (props: PostTypeLinkPropTypes) => {
    const { type, sponsoredBy } = props;
    const classes = useStyles();

    const className = [classes.link];
    let textContent: string = type;
    let href = ROUTES.SUBMIT.to;
    let external = ROUTES.SUBMIT.external;
    let otherProps: any = {};

    switch (type) {
        case 'Submitted':
            className.push(classes.blueBackground);
            className.push(classes.whiteText);
            href = ROUTES.TYPE.to + 'submissions';
            otherProps.as = ROUTES.TYPE.fileApiPath;
            break;
        case 'Premiere':
            className.push(classes.yellowBackground);
            className.push(classes.blackText);
            break;
        case 'Paid':
            href = ROUTES.CONTACT.to;
            external = ROUTES.CONTACT.external;
            textContent += ' Placement';
            break;
        case 'Sponsored':
            {
                sponsoredBy
                    ? ((href = ROUTES.SUBMIT.to),
                      className.push(classes.sponsoredByContainer))
                    : (href = ROUTES.TYPE.to + 'sponsored');
            }
            otherProps.as = ROUTES.TYPE.fileApiPath;
            className.push(classes.blackBackground);
            className.push(classes.whiteText);
            break;
        case 'Promoted':
            textContent = 'Promoted';
            className.push(classes.blackBackground);
            className.push(classes.whiteText);
            break;
        case 'Standard':
        case 'Featured':
        default:
            return null;
    }

    return (
        <BaseLink
            external={external}
            href={href}
            className={clsx(className)}
            {...otherProps}
        >
            {textContent}
            {type === 'Premiere' && <Star classes={{ root: classes.icon }} />}
            {type === 'Sponsored' && sponsoredBy && (
                <Typography className={classes.sponsoredBy}>by {sponsoredBy}</Typography>
            )}
        </BaseLink>
    );
};

export type PostTypeLinkPropTypes = {
    type: PostType;
    sponsoredBy?: string;
};

export default PostTypeLink;
