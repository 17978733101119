import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        textFieldContainer: {
            width: '100%',
        },
        imageUploadsDisclaimer: {
            paddingTop: theme.spacing(2),
        },
        errorText: {
            color: 'red',
        },
        imageContainer: {
            paddingTop: theme.spacing(1),
            width: '100%',
        },
        imageContainerMultiple: {
            paddingTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        fileName: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
        fileNameContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        noImageBox: {
            height: '13vh',
            width: '10vw',
            marginRight: theme.spacing(10),
            borderStyle: 'dotted',
            borderWidth: '1.5px',
            borderRadius: '2px',
            borderColor: 'rgba(0, 0, 0, 0.5)',
            [theme.breakpoints.down('sm')]: {
                width: '40vw',
                height: '11vh',
                marginRight: theme.spacing(5),
            },
        },
        uploadButton: {
            maxHeight: '30px',
            borderRadius: '2.5px !important',
        },
        iconButton: {
            color: 'white',
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            zIndex: 2,
        },
        removeButton: {
            color: 'white',
            position: 'absolute',
            bottom: theme.spacing(6),
            right: theme.spacing(1),
            zIndex: 2,
        },
        imageContainerButtonLeft: {
            position: 'relative',
            width: '100%',
        },
        removeImage: {
            position: 'absolute',
            top: '9px',
            right: '1px',
            zIndex: 10,
        },
        bannerContainer: {
            height: '350px',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                height: '300px',
            },
            position: 'relative',
        },
        gradientFade: {
            height: '100%',
            width: '100%',
            zIndex: 1,
            background: 'linear-gradient(0deg, rgba(0,0,0,0.6) 05%, rgba(0,0,0,0) 100%)',
        },
        banner: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        avatar: {
            width: '100%',
            height: '100%',
            borderRadius: '4%',
            backgroundColor: theme.palette.primary.light,
            fontSize: theme.typography.pxToRem(40),
        },
        avatarContainer: {
            position: 'relative',
            height: '150px',
            width: '150px',
        },
        avatarGradient: {
            borderRadius: '4%',
        },
        multiSelectImageContainer: {
            margin: '8px',
            width: 'auto',
            maxWidth: '40%',
            height: 'auto',
            maxHeight: '20vh',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        },
        imageInput: {
            display: 'none',
        },
    }),
);

export default styles;
