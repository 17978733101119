import { Theme, makeStyles, createStyles } from '@material-ui/core';
import { INTERNAL_SERVER_ERROR_IMAGE, PAGE_NOT_FOUND_IMAGE } from './Error.utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            color: theme.palette.common.white,
        },
        '404Background': {
            backgroundImage: `url('${PAGE_NOT_FOUND_IMAGE.src}')`,
        },
        '500Background': {
            backgroundImage: `url(${INTERNAL_SERVER_ERROR_IMAGE.src})`,
        },
        errorTitle: {
            color: 'inherit',
        },
        filterContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        textContainer: {
            maxWidth: theme.static_component_sizes.content_max_width,
        },
        bodyText: {
            color: 'inherit',
            fontSize: theme.typography.pxToRem(18),
        },
        buttonContainer: {
            paddingTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        giphyRef: {
            color: theme.palette.common.white,
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    }),
);

export default useStyles;
