import React from 'react';
import useStyles from './styles';
import { PostType as PostTypeEnum, PostCardInfo } from 'types/post';
import { TeaserGrid } from 'organisms';
import { AdSpace } from 'atoms';
import {
    PaginationOptions,
    QueryResult,
} from '@elevatormedia/duffel-bag/dist/types/graphql';
import ScrollToTop from '@elevatormedia/duffel-bag/dist/atoms/ScrollToTop';

const PostType = (props: PostTypePropTypes) => {
    const classes = useStyles();
    const { type, posts, loading, error, fetchMore, variables, sectionTitle } = props;

    const onLoadMore = () => {
        fetchMore({
            variables: {
                limit: variables.limit,
                skip: posts.length,
                type,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) return prev;

                const newPosts = [
                    ...prev.publishedPosts,
                    ...fetchMoreResult.publishedPosts,
                ];

                return Object.assign({}, prev, {
                    publishedPosts: newPosts,
                });
            },
        });
    };

    return (
        <>
            <div className={classes.root}>
                <TeaserGrid
                    sectionTitle={sectionTitle ? sectionTitle : type}
                    posts={posts}
                    onLoadMore={onLoadMore}
                    error={error}
                    isLoading={loading}
                />
                <div className={classes.adSpaceContainer}>
                    <AdSpace type={'banner-ad'} adUnit={'ELEVATOR-Mid'} />
                </div>
            </div>
            <ScrollToTop visibleYOffset={70} />
        </>
    );
};

export type PostsByTypeQueryVariables = PaginationOptions & {
    type: PostTypeEnum;
};

export type PostTypePropTypes = QueryResult<
    'posts',
    PostCardInfo[],
    PostsByTypeQueryVariables
> & {
    type: PostTypeEnum;
    sectionTitle?: string;
};

export default PostType;
