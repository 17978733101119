import React from 'react';
import { PDFDoc } from 'atoms';
import useStyles from './styles';
import PageTitleDescription from '@elevatormedia/duffel-bag/dist/atoms/PageTitleDescription';

const Legal = (props: LegalProps) => {
    const classes = useStyles();

    const { pageTitle, pdfURL } = props;

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <PageTitleDescription pageTitle={pageTitle} divider={true} />
            </div>
            <div className={classes.contentContainer}>
                <PDFDoc url={pdfURL} />
            </div>
        </div>
    );
};

export interface LegalProps {
    pageTitle: string;
    pdfURL: string;
}

export default Legal;
