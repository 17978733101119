import React, { useMemo } from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import { PostTypeLink } from 'atoms';
import Typography from '@material-ui/core/Typography';
import { PostType, SubmittedInfo } from 'types/post';
import { PostCategory } from 'types/category';
import { DOMAIN_BASE, ROUTES } from 'config/Nav';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import { formatDate } from '@elevatormedia/duffel-bag/dist/utils/string';
import ShareButtonGroup from '@elevatormedia/duffel-bag/dist/atoms/ShareButtonGroup';
import { ShareLinkOptions } from '@elevatormedia/duffel-bag/dist/types/socialMedia';
import { PostAuthor } from '@elevatormedia/duffel-bag/dist/types/user';
import Avatar from '@elevatormedia/duffel-bag/dist/atoms/Avatar';
import { Skeleton } from '@material-ui/lab';
import { useRouter } from 'next/router';

const PostHeader = (props: PostHeaderPropTypes) => {
    const classes = useStyles();

    const {
        title,
        description,
        slug,
        type,
        publishedAt,
        authors,
        categories,
        shareGroupProps,
        submission,
        isLoading,
    } = props;

    const router = useRouter();

    const author = authors[0];
    const isFeature = type === 'Featured';

    const renderSkeleton = useMemo(() => {
        return router.isFallback || isLoading;
    }, [router.isFallback, isLoading]);

    const renderPostTitleInfo = () => {
        return (
            <div
                className={clsx(classes.postMetaContainer, {
                    [classes.featuredPostMetaContainer]: isFeature,
                })}
            >
                <div
                    className={clsx(classes.spacedRow, {
                        [classes.featureSpacedRow]: isFeature,
                    })}
                >
                    <div className={isFeature ? classes.featureSpacedRowItem : undefined}>
                        {renderSkeleton ? (
                            <>
                                <Skeleton
                                    className={classes.categoryNameSkeleton}
                                    animation="pulse"
                                    variant="rect"
                                    width={150}
                                    height={16}
                                />
                                <Skeleton
                                    animation={'pulse'}
                                    variant={'rect'}
                                    width={80}
                                    height={12}
                                />
                            </>
                        ) : (
                            <>
                                {categories[0] && (
                                    <Link
                                        variant={'category-post'}
                                        href={`${ROUTES.CATEGORY.to}${categories[0].slug}`}
                                    >
                                        {categories[0].name}
                                    </Link>
                                )}
                                <Typography variant={'caption'} color={'textSecondary'}>
                                    {formatDate(parseInt(publishedAt as string))}
                                </Typography>
                            </>
                        )}
                    </div>
                    {submission && submission.sponsoredBy ? (
                        <div
                            className={
                                isFeature
                                    ? clsx(
                                          classes.featureSpacedRowItem,
                                          classes.featureSpacedRowLinkItem,
                                      )
                                    : undefined
                            }
                        >
                            <PostTypeLink
                                type={type}
                                sponsoredBy={submission.sponsoredBy}
                            />
                        </div>
                    ) : (
                        <div
                            className={
                                isFeature
                                    ? clsx(
                                          classes.featureSpacedRowItem,
                                          classes.featureSpacedRowLinkItem,
                                      )
                                    : undefined
                            }
                        >
                            <PostTypeLink type={type} />
                        </div>
                    )}
                </div>

                <div className={classes.postMetaTitleSubContainer}>
                    <div className={classes.gutterBottom}>
                        {renderSkeleton ? (
                            <Skeleton
                                animation={'pulse'}
                                variant={'rect'}
                                width={300}
                                height={40}
                            />
                        ) : (
                            <Link
                                variant={'title-general'}
                                href={ROUTES.POST.fileApiPath}
                                as={`/${slug}`}
                            >
                                {title}
                            </Link>
                        )}
                    </div>
                    {renderSkeleton ? (
                        <Skeleton
                            animation={'pulse'}
                            variant={'rect'}
                            width={500}
                            height={55}
                        />
                    ) : (
                        <Typography
                            variant={'body1'}
                            color={'textSecondary'}
                            className={classes.italics}
                        >
                            {description}
                        </Typography>
                    )}
                </div>
            </div>
        );
    };

    const renderPostAuthorRow = () => {
        return (
            <div className={classes.spacedRow}>
                <div className={classes.authorContainer}>
                    {renderSkeleton ? (
                        <Skeleton
                            animation={'pulse'}
                            variant={'rect'}
                            height={50}
                            width={50}
                        />
                    ) : (
                        <Avatar
                            variant={'x-small'}
                            imageProps={
                                author.avatar && {
                                    src: author.avatar.sourceUrl,
                                    srcSet: author.avatar.sourceSet,
                                    alt: author.displayName || author.preferredUsername,
                                }
                            }
                            username={author.displayName || author.preferredUsername}
                        />
                    )}
                    <div className={classes.authorMetaContainer}>
                        {renderSkeleton ? (
                            <>
                                <Skeleton
                                    animation={'pulse'}
                                    variant={'text'}
                                    width={50}
                                    height={18}
                                />
                                <Skeleton
                                    animation={'pulse'}
                                    variant={'text'}
                                    width={55}
                                    height={22}
                                />
                            </>
                        ) : (
                            <>
                                <Typography
                                    display={'block'}
                                    color={'textSecondary'}
                                    variant={'subtitle2'}
                                >
                                    {author.role}
                                </Typography>
                                <Link
                                    variant={'author-post'}
                                    href={`${ROUTES.AUTHORS.to}${author.preferredUsername}`}
                                    inline
                                >
                                    {author.displayName || author.preferredUsername}
                                </Link>
                            </>
                        )}
                    </div>
                </div>
                <ShareButtonGroup
                    shareLink={`${DOMAIN_BASE}/${slug}`}
                    size={'small'}
                    postTitle={title}
                    {...shareGroupProps}
                />
            </div>
        );
    };

    return (
        <div className={clsx(classes.root)}>
            {renderPostTitleInfo()}
            {renderPostAuthorRow()}
        </div>
    );
};

export type PostHeaderPropTypes = {
    title: string;
    description: string;
    slug: string;
    type: PostType;
    publishedAt: string | Date;
    authors: Array<PostAuthor>;
    categories: Array<PostCategory>;
    shareGroupProps?: ShareLinkOptions;
    submission: SubmittedInfo;
    isLoading: Boolean;
};

export default PostHeader;
