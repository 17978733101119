import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        submitIcon: {
            fontSize: theme.typography.pxToRem(20),
            marginRight: theme.spacing(0.5),
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(16),
            },
        },
        submitButton: {
            boxShadow: 'none',
        },
        signOutButton: {
            borderWidth: '2px',
            borderColor: 'black',
            [theme.breakpoints.down('sm')]: {
                borderColor: 'white',
                color: 'white',
            },
        },
    });

export default makeStyles(styles);
