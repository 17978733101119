import React from 'react';
import { useFormikContext } from 'formik';
import { CheckoutValues } from 'types/payments';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Box from '@material-ui/core/Box';
import CreditCardOutline from 'mdi-material-ui/CreditCardOutline';
import Paypal from 'mdi-material-ui/Paypal';
import useStyles from './styles';
import CreditCardForm from './CreditCardForm';
import CardSectionHeader from '@elevatormedia/duffel-bag/dist/atoms/CardSectionHeader';
import { PaymentType } from '@elevatormedia/duffel-bag/dist/types/order';
import RadioCard from '@elevatormedia/duffel-bag/dist/atoms/RadioCard';

const CheckoutPaymentCard: React.FC<CheckoutPaymentProps> = ({
    paymentType,
    setPaymentType,
}) => {
    const classes = useStyles();
    const {
        values,
        errors,
        touched,
        validateForm,
        setFieldValue,
        handleChange,
        setFieldTouched,
    } = useFormikContext<CheckoutValues>();

    React.useEffect(() => {
        validateForm();
    }, [paymentType, validateForm]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentType(event.target.value as PaymentType);
    };

    const handleCreditCardForm = (
        fieldName: string,
        event: any,
        imperativeValue: any = undefined,
    ) => {
        // if value is being set imperatively, use the standard React event.target.value scheme.
        if (imperativeValue !== undefined) {
            setFieldValue(fieldName, imperativeValue, true);
        } else {
            handleChange(event);
        }
        setFieldTouched(fieldName, true);
    };

    return (
        <Box flexDirection="column" width="100%">
            <Typography variant={'h6'} className={classes.paymentInfoHeader}>
                Payment Info
            </Typography>
            {/* TODO fix PayPal */}
            <CardSectionHeader sectionTitle={'Payment Method'} />
            <RadioGroup
                id={'paymentType'}
                aria-label="Payment Type"
                name="paymentType"
                value={paymentType}
                onChange={handleRadioChange}
                className={classes.radioGroupContainer}
            >
                <RadioCard
                    value="Stripe"
                    Icon={CreditCardOutline}
                    displayText="Card"
                    active={paymentType === 'Stripe'}
                />
                <RadioCard
                    value="PayPal"
                    Icon={Paypal}
                    displayText="PayPal"
                    active={paymentType === 'PayPal'}
                />
                {/* <div className={classes.bitPayContainer}>
                    <RadioCard
                        value="BitPay"
                        Icon={BitPayIcon}
                        displayText="BitPay"
                        active={paymentType === 'BitPay'}
                    />
                </div> */}
            </RadioGroup>
            {paymentType === 'PayPal' && (
                <Typography>PayPal will open in a new window upon checkout.</Typography>
            )}
            {paymentType === 'Stripe' && (
                <CreditCardForm
                    handleInputChange={handleCreditCardForm}
                    values={values}
                    errors={errors}
                    touched={touched}
                />
            )}
        </Box>
    );
};

export interface CheckoutPaymentProps {
    paymentType: PaymentType;
    setPaymentType: (val: PaymentType) => void;
}

export default CheckoutPaymentCard;
