import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: '260px',
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(4, 2, 0, 2),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                padding: theme.spacing(2, 2, 0, 2),
            },
        },

        footerContentContainer: {
            width: '99%',
        },
        footerContentTop: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginRight: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'center',
                margin: 0,
            },
        },

        logo: {
            width: '20%',
            height: '20%',
            marginRight: theme.spacing(9),
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                width: '50%',
                height: '50%',
                margin: theme.spacing(3, 0, 4, 0),
            },
        },
        topLinks: {
            width: '25%',
            margin: theme.spacing(2, 6, 1, 0),
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
                margin: theme.spacing(1, 0, 7, 0),
            },
        },
        socialMediaIcon: {
            fontSize: theme.typography.pxToRem(35),
            color: theme.palette.custom.grey.main,
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.pxToRem(25),
            },
        },

        divider: {
            backgroundColor: theme.palette.custom.grey.dark,
            marginTop: theme.spacing(1),
            height: theme.typography.pxToRem(1.5),
        },

        bottomFooterRow: {
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(2),
            },
        },
        bottomFooterLinks: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(3, 0, 4, 0),
            },
        },
        footerLinkContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        copyrightNoticeContainer: {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(5),
            },
        },
    });

export default makeStyles(styles);
