import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';
import { formatMoney } from '@elevatormedia/duffel-bag/dist/utils/string';

const TotalBar = (props: TotalBarPropTypes) => {
    const { total } = props;
    const classes = useStyles();

    return (
        <div className={classes.totalContainer}>
            <Typography component={'h5'} className={classes.totalTextLabel}>
                Total
            </Typography>
            <Typography component={'h5'} className={classes.totalTextValue}>
                {formatMoney(total)}
            </Typography>
        </div>
    );
};

export type TotalBarPropTypes = {
    total: number;
};

export default TotalBar;
