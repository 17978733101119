import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textFieldContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            wordBreak: 'break-all',
        },
        socialIconContainer: {
            padding: theme.spacing(0, 2, 0, 0),
            height: '80px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 100,
        },
        divider: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        sectionHeader: {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 'bold',
        },
        socials: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

export default useStyles;
