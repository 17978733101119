import React from 'react';
import Typography from '@material-ui/core/Typography';
import Check from 'mdi-material-ui/Check';
import SquareSmall from 'mdi-material-ui/SquareSmall';
import useStyles from './styles';
import clsx from 'clsx';
import {
    hasDigit,
    hasLowerCaseLetter,
    hasSpecialCharacter,
    hasUpperCaseLetter,
    isAtLeastEightChars,
    notTooGuessable,
} from '@elevatormedia/duffel-bag/dist/utils/auth';

const PasswordFeedbackComponent: React.FC<PasswordFeedbackProps> = (props) => {
    const classes = useStyles();
    const { password } = props;

    const getStyledText = (text: string, active: boolean) => {
        return (
            <Typography
                variant={'caption'}
                component={'span'}
                className={clsx(classes.feedbackRoot, {
                    [classes.notSatisfiedFeedback]: !active,
                    [classes.positiveFeedback]: active,
                })}
            >
                {active && (
                    <div className={classes.feedbackIconContainer}>
                        <Check className={classes.checkmark} />
                    </div>
                )}
                {!active && (
                    <div className={classes.feedbackIconContainer}>
                        <SquareSmall className={classes.checkmark} />
                    </div>
                )}
                {text}
            </Typography>
        );
    };

    return (
        <Typography variant={'caption'} color={'textPrimary'} component={'span'}>
            {'Password Requirements: '}
            {getStyledText('At least 8 characters ', isAtLeastEightChars(password))}
            {getStyledText('1 lowercase letter ', hasLowerCaseLetter(password))}
            {getStyledText(' 1 uppercase letter ', hasUpperCaseLetter(password))}
            {getStyledText(
                '1 special character (not + or =) ',
                hasSpecialCharacter(password),
            )}
            {getStyledText('1 number ', hasDigit(password))}
            {getStyledText('Not guessable', notTooGuessable(password))}
        </Typography>
    );
};

export type PasswordFeedbackProps = {
    password: string;
};

// Use React.memo to optimize the component
const PasswordFeedback = React.memo(
    PasswordFeedbackComponent,
    (prev, next) => prev.password === next.password,
);

export default PasswordFeedback;
