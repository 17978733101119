import Twitter from 'mdi-material-ui/Twitter';
import Soundcloud from 'mdi-material-ui/Soundcloud';
import Instagram from 'mdi-material-ui/Instagram';
import Youtube from 'mdi-material-ui/Youtube';
import Facebook from 'mdi-material-ui/Facebook';
import { RouteConfig } from 'types/routes';
import { Spotify } from 'mdi-material-ui';

export const DOMAIN_BASE = 'https://elevatormag.com';
export const DOMAIN_BASE_SHORT = 'elevatormag.com';

export const ROUTES = {
    POST: {
        fileApiPath: '/[slug]',
        navLinkText: 'Post',
        external: false,
    },
    PAGE_NOT_FOUND: {
        to: '/404',
        navLinkText: '404 Not Found',
        external: false,
    },
    ERROR: {
        to: '/_error',
        navLinkText: 'Error Page',
        external: false,
    },
    HOME: {
        to: '/',
        navLinkText: 'Home',
        external: false,
    },
    SEARCH: {
        to: '/search',
        navLinkText: 'Search',
        external: false,
    },
    CATEGORY: {
        to: '/category/',
        fileApiPath: '/category/[slug]',
        navLinkText: 'Category',
        external: false,
    },
    TYPE: {
        to: '/type/',
        fileApiPath: '/type/[slug]',
        navLinkText: 'Type',
        external: false,
    },
    MUSIC: {
        to: '/category/music/',
        navLinkText: 'Music',
        external: false,
    },
    VIDEOS: {
        to: '/category/videos/',
        navLinkText: 'Videos',
        external: false,
    },
    FEATURES: {
        to: '/category/features/',
        navLinkText: 'Features',
        external: false,
    },
    INTERVIEWS: {
        to: '/category/interviews/',
        navLinkText: 'Interviews',
        external: false,
    },
    TAG: {
        to: '/tag/',
        fileApiPath: '/tag/[slug]',
        navLinkText: 'Tag',
        external: false,
    },
    AUTHORS: {
        to: `/users/`,
        navLinkText: 'Authors',
        external: false,
    },
    SHOP: {
        to: 'https://shop.elevatormag.com/',
        navLinkText: 'Shop',
        external: true,
    },
    ADVERTISE: {
        to: '/campaigns',
        navLinkText: 'Advertise',
        external: false,
        CONFIRMATION: {
            to: '/campaigns/confirmation',
            navLinkText: 'Confirmation',
            external: false,
        },
    },
    DISTRIBUTION: {
        to: '/distribution',
        navLinkText: 'Distribution',
        external: false,
    },
    HELP: {
        to: 'https://help.elevatormag.com/',
        navLinkText: 'Help',
        external: true,
    },
    SCAMMERS: {
        to: '/how-to-report-scammers',
        navLinkText: 'How to report scammers',
        external: false,
    },
    USER: {
        to: `/users/`,
        navLinkText: 'User',
        external: true,
    },
    STAFF: {
        to: '/staff',
        navLinkText: 'Staff',
        external: false,
    },
    SUBMIT: {
        to: '/submit',
        navLinkText: 'Submit',
        external: false,
        CONFIRMATION: {
            to: '/submit/confirmation',
            navLinkText: 'Confirmation',
            external: false,
        },
    },
    ACCOUNT: {
        to: '/account/settings',
        navLinkText: 'Account',
        external: false,
        ACCOUNT_SETTINGS: {
            to: '/account/settings',
            navLinkText: 'Account Settings',
            external: false,
        },
        SUBMISSIONS: {
            to: '/account/submissions',
            navLinkText: 'Your Submissions',
            external: false,
        },
    },
    VERIFY: {
        to: '/verify',
        navLinkText: 'Verify Account',
        external: false,
    },
    CONTACT: {
        to: '/contact',
        navLinkText: 'Contact',
        external: false,
    },
    PROFILE: {
        to: '/profile',
        navLinkText: 'Profile',
        external: false,
        EDIT_PROFILE: {
            to: '/profile/edit',
            navLinkText: 'Edit Profile',
            external: false,
        },
    },
    LEGAL: {
        TOS: {
            to: '/legal/terms-of-service',
            navLinkText: 'Terms',
            external: false,
        },
        PRIVACY: {
            to: '/legal/privacy-policy',
            navLinkText: 'Privacy Policy',
            external: false,
        },
    },
    ADMIN: {
        to: process.env.spotterRedirect,
        navLinkText: 'Admin Dashboard',
        external: true,
    },
    AUTH: {
        to: process.env.authRedirect,
        navLinkText: 'Sign In',
        external: true,
        SIGN_IN: {
            to: `${process.env.authRedirect}`,
            navLinkText: 'Sign In',
            external: true,
        },
        SIGN_UP: {
            to: `${process.env.authRedirect}/signup`,
            navLinkText: 'Sign Up',
            external: true,
        },
    },
};

export const APPBAR_ROUTES: RouteConfig[] = [
    ROUTES.MUSIC,
    ROUTES.VIDEOS,
    ROUTES.FEATURES,
    ROUTES.INTERVIEWS,
    ROUTES.SHOP,
];

export const FOOTER_ROUTES: RouteConfig[] = [
    ROUTES.FEATURES,
    ROUTES.SHOP,
    ROUTES.SUBMIT,
    ROUTES.ADVERTISE,
    ROUTES.STAFF,
    ROUTES.CONTACT,
    ROUTES.HELP,
    ROUTES.LEGAL.TOS,
    ROUTES.LEGAL.PRIVACY,
];

export const SOCIAL_MEDIA = {
    FACEBOOK: {
        route: 'https://www.facebook.com/ElevatorMag/',
        type: 'facebook',
        icon: Facebook,
    },
    INSTAGRAM: {
        route: 'https://www.instagram.com/elevator_/',
        type: 'instagram',
        icon: Instagram,
    },
    TWITTER: {
        route: 'https://twitter.com/elevator_',
        type: 'twitter',
        icon: Twitter,
    },
    YOUTUBE: {
        route: 'https://www.youtube.com/user/LVTRmag',
        type: 'youtube',
        icon: Youtube,
    },
    SOUNDCLOUD: {
        route: 'https://soundcloud.com/lvtrmag',
        type: 'soundcloud',
        icon: Soundcloud,
    },
    SPOTIFY: {
        route: 'https://open.spotify.com/playlist/31jkZXTNwXu0QNvAIG4psQ?si=kHiK9w4ZQRm8Xs59buaP1Q&nd=1',
        type: 'spotify',
        icon: Spotify,
    },
};
