import React from 'react';
import withLayout from 'hocs/withLayout';
import { PageProps } from 'types/page';
import { useQuery } from '@apollo/client';
import Staff from './Staff';
import { STAFF } from 'lib/graphql/queries/user';
import Seo from '@elevatormedia/duffel-bag/dist/atoms/Seo';
import { DOMAIN_BASE_SHORT, ROUTES } from 'config/Nav';

const StaffIndex = (props: StaffIndexTypes) => {
    const { data, loading, networkStatus, error, fetchMore } = useQuery(STAFF, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    const staff = data && data.staffUsers ? data.staffUsers : null;
    const isLoading = (loading && networkStatus !== 3) || networkStatus === 4;
    const loadingMore = networkStatus === 3;

    const renderContent = () => {
        if (isLoading) {
            return null;
        } else {
            return (
                <Staff
                    staffUsers={staff}
                    loading={isLoading}
                    loadingMore={loadingMore}
                    error={error}
                    fetchMore={fetchMore}
                />
            );
        }
    };

    return (
        <>
            <Seo
                type={'website'}
                data={{
                    baseUrl: DOMAIN_BASE_SHORT,
                    title: 'Our Staff',
                    description: 'Meet the ELEVATOR team.',
                    path: ROUTES.STAFF.to,
                }}
            />
            {renderContent()}
        </>
    );
};

export interface StaffIndexTypes extends PageProps {}

export default withLayout(StaffIndex, true, true);
