import gql from 'graphql-tag';

export const CREDIT_TYPES = gql`
    query {
        creditTypes {
            typeId
            typeName
        }
    }
`;
