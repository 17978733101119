import React from 'react';
import withLayout from 'hocs/withLayout';
import { FeaturedPostGrid, TeaserGrid } from 'organisms';
import { HomePosts } from 'types/home';
import useStyles from './styles';
import { AdSpace } from 'atoms';
import { PostCardInfo } from 'types/post';
import { reportError } from 'lib/errors';
import LoadingErrorDisclaimer from '@elevatormedia/duffel-bag/dist/atoms/LoadingErrorDisclaimer';
import ScrollToTop from '@elevatormedia/duffel-bag/dist/atoms/ScrollToTop';
import { QueryResult } from '@elevatormedia/duffel-bag/dist/types/graphql';

const Home: React.FC<HomePropTypes> = ({
    variables,
    fetchMore,
    homePosts,
    error,
    loadingMore,
    loading,
}) => {
    const classes = useStyles();

    if (error) {
        return (
            <LoadingErrorDisclaimer
                error={error}
                failedTarget={'home page posts'}
                reportError={reportError}
            />
        );
    }
    const onLoadMore = () => {
        fetchMore({
            variables: {
                limit: variables.limit,
                skip: homePosts.publishedPosts.length,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
                if (!fetchMoreResult) return prev;
                const newPosts = [
                    ...prev.publishedPosts,
                    ...fetchMoreResult.publishedPosts,
                ];
                const newData = Object.assign({}, prev, {
                    ...homePosts.featuredPosts,
                    publishedPosts: newPosts,
                });

                return newData;
            },
        });
    };

    const renderAdSpace = (unit: string) => {
        return (
            <div className={classes.adSpace}>
                <AdSpace type={'banner-ad'} adUnit={unit} />
            </div>
        );
    };

    const emptyPostcard: PostCardInfo = {
        title: '',
        image: {
            mediaId: 'e647bc92-ee7c-44aa-9ed3-ec5e1791627d',
            path: '2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg',
            sourceUrl:
                'https://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=400&h=300&ixlib=js-2.1.2&s=b43ca36e9ae014eefa63c9fafa0c8d5d',
            sourceSet:
                'https://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=100&ixlib=js-2.1.2&s=d11e388e45c8825f19f97e878b453ea1 100w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=116&ixlib=js-2.1.2&s=94bc0315bde9773f73c25b5e55e62fe8 116w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=134&ixlib=js-2.1.2&s=97a151ff076d56f030172490462d6c48 134w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=156&ixlib=js-2.1.2&s=b1cdd44aef8234e1b9e8b4ea1b6fc241 156w,\nhttps://elevator-media.imgix.net/2019/10/26fa0941-1a48-4cc3-ba2f-4f550c868ff6.jpeg?w=182&ixlib=js-2.1.2&s=f40a2ba5c5407fac1f310247d18f59b7',
        },
        slug: '',
        type: null,
        publishedAt: '',
        authors: [],
        categories: [],
    };

    const emptyPublishedPosts: Array<PostCardInfo> = [emptyPostcard];

    return (
        <>
            <div>
                <div className={classes.sectionContainer}>
                    <FeaturedPostGrid
                        position1Post={
                            homePosts ? homePosts.featuredPosts._1 : emptyPostcard
                        }
                        position2Post={
                            homePosts ? homePosts.featuredPosts._2 : emptyPostcard
                        }
                        position3Post={
                            homePosts ? homePosts.featuredPosts._3 : emptyPostcard
                        }
                        position4Post={
                            homePosts ? homePosts.featuredPosts._4 : emptyPostcard
                        }
                        isLoading={loading}
                        error={error}
                    />
                </div>
                {renderAdSpace('ELEVATOR-Mid')}
                <div className={classes.sectionContainer}>
                    <TeaserGrid
                        posts={homePosts ? homePosts.publishedPosts : emptyPublishedPosts}
                        onLoadMore={onLoadMore}
                        error={error}
                        loadingMore={loadingMore}
                        isLoading={loading}
                    />
                </div>
                <div className={classes.bottomPaddingContainer}>
                    {renderAdSpace('ELEVATOR-Bottom')}
                </div>
            </div>
            <ScrollToTop visibleYOffset={800} />
        </>
    );
};

interface HomePropTypes
    extends QueryResult<'homePosts', HomePosts, { skip: number; limit: number }> {
    loadingMore: boolean;
}

export default withLayout(Home, false);
