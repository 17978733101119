import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        gridContainer: {
            paddingBottom: theme.spacing(3),
        },
        textFieldContainer: {
            width: '100%',
        },
        contentDescriptionHelperText: {
            maxWidth: '80%',
        },
        creditsContainer: {
            padding: theme.spacing(0, 0, 2, 0),
        },
    });

export default makeStyles(styles);
