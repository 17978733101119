import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    fieldContainer: {
        margin: theme.spacing(2, 0, 2, 0),
        width: '100%',
    },

    // Dropdown
    dropdownInput: {
        borderRadius: 0,
        backgroundColor: theme.palette.custom.grey.light,
    },
    dropdownLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        '&:focused': {
            color: theme.palette.custom.grey.dark,
        },
        fontSize: '1.10rem',
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: 500,
        padding: theme.spacing(1, 0, 1, 2),
        zIndex: 10,
    },
});

export default makeStyles(styles);
