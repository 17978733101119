import React from 'react';
import { Formik } from 'formik';
import {
    string as yupString,
    object as yupObject,
    mixed,
    boolean as yupBool,
    array,
} from 'yup';
import isURL from 'validator/lib/isURL';
import Fields from './Fields';
import { AdStartDate } from 'types/advertising';
import { CampaignType } from 'types/campaign';
import { StepTwoProps } from 'layouts/Advertising/StepTwo/StepTwo';

const AdForm = (props: AdFormPropTypes) => {
    const {
        campaign,
        handleSubmit,
        handleBackClick,
        campaignTypes,
        totalCost,
        updateTotalCost,
    } = props;

    let initialCampaignType = campaignTypes[0].typeId;
    let initialCampaignCost = campaignTypes[0].cost;

    if (campaign.campaignType) {
        initialCampaignType = campaign.campaignType;
        const selectedType = campaignTypes.find(
            (type) => initialCampaignType === type.typeId,
        );
        if (selectedType) initialCampaignCost = selectedType.cost;
        if (!campaign.designProvided) initialCampaignCost += 15000;
    }

    React.useEffect(() => {
        updateTotalCost(initialCampaignCost);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationSchema = yupObject({
        campaignName: yupString().required('Campaign Name is required'),
        campaignType: yupString().required('Campaign Length is required'),
        startDate: mixed<AdStartDate>().required('Start date is required'),
        designProvided: yupBool().required('Design source is required'),
        files: array<File>().min(
            1,
            `Requirements not met: Please upload a .zip file containing ALL of the following ad sizes 
        970x250, 300x600, 300x250, 300x50 ( png / jpg / gif, 1mb max size per file) 
        OR creative assets (photos, logos, cover art files, etc) to be used in the ad designs.`,
        ),
        clickThroughUrl: yupString()
            .test('validURL', 'Please enter a valid click-through Url', (value) => {
                if (!value) {
                    return false;
                }

                const isValid = isURL(value);

                return isValid;
            })
            .required('A url is required'),
    });

    const initialValues: AdFormValues = {
        ...campaign,
        campaignType: initialCampaignType,
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnMount={campaign.campaignType ? true : false}
        >
            {(props) => (
                <Fields
                    onBackClick={handleBackClick}
                    campaignTypes={campaignTypes}
                    totalCost={totalCost}
                    updateTotalCost={updateTotalCost}
                    {...props}
                />
            )}
        </Formik>
    );
};

export type AdFormValues = {
    campaignName: string;
    campaignType: string;
    startDate: AdStartDate;
    designProvided: boolean;
    files: Array<File>;
    clickThroughUrl: string;
};

export type AdFormPropTypes = StepTwoProps & {
    campaign: AdFormValues;
    campaignTypes: Array<CampaignType>;
};

export default AdForm;
