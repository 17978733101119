import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardContainer: {
            width: '100%',
            paddingBottom: theme.spacing(2),
        },

        totalsCard: {
            backgroundColor: theme.palette.background.default,
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(1),
            },
        },

        service: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: theme.spacing(0, 0, 1, 1),
        },
        serviceText: {
            fontWeight: 450,
        },
        addOn: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(0.5),
        },
        addOnText: {
            fontSize: theme.typography.pxToRem(14),
        },
        total: {
            paddingTop: theme.spacing(1),
            fontSize: '1.1rem',
            fontWeight: 700,
        },
        buttonSet: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
            },
        },
        paypalSubmitButtonContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            [theme.breakpoints.up('sm')]: {
                marginTop: 0,
                maxWidth: 400,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '& > div': {
                height: '40px',
            },
        },
        bitPayButtonContainer: {
            width: '100%',
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginTop: 0,
                paddingLeft: theme.spacing(2),
                maxWidth: 400,
            },
        },
    }),
);

export default useStyles;
