import React from 'react';
import useStyles from './styles';
import { Avatar, Button, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useInvite } from '@elevatormedia/pitch-hooks/organization/use-invite';
import { ROUTES } from 'config/Nav';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import Image from 'next/image';

const Invite = () => {
    const classes = useStyles();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { enqueueSnackbar } = useSnackbar();

    const router = useRouter();
    const { id } = router.query;

    const inviteReply = useInvite(id as string);
    const { inviteInfo, accept, decline } = inviteReply;

    const declineInvite = async () => {
        try {
            await decline();
            enqueueSnackbar(`Invite declined.`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            router.push(ROUTES.HOME.to);
        } catch {
            enqueueSnackbar(`Failed to decline invite.`, {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        }
    };

    const acceptInvite = async () => {
        try {
            await accept();
            enqueueSnackbar(`Invite accepted.`, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            router.push(ROUTES.HOME.to);
        } catch {
            enqueueSnackbar(`Failed to accept invite.`, {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        }
    };

    const renderButtons = () => {
        return (
            <div className={classes.buttonContainer}>
                <Button
                    className={classes.declineButton}
                    variant={'outlined'}
                    onClick={() => declineInvite()}
                    fullWidth={mobile}
                >
                    Decline
                </Button>
                <Button
                    className={classes.acceptButton}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => acceptInvite()}
                    fullWidth={mobile}
                >
                    Accept
                </Button>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <Image
                className={classes.bannerImage}
                src={inviteInfo?.organization.banner.sourceUrl}
                alt={'organization-banner'}
                layout="fill"
            />
            <div className={classes.inviteContainer}>
                <Typography className={classes.text} variant={'body1'}>
                    You have been invited to join {inviteInfo?.organization.name}!
                </Typography>
                <div className={classes.orgInfoContainer}>
                    <Avatar
                        className={classes.avatar}
                        variant={'rounded'}
                        src={inviteInfo?.organization.avatar.sourceUrl}
                        srcSet={inviteInfo?.organization.avatar.sourceSet}
                        alt={'organization-avatar'}
                    >
                        {inviteInfo?.organization.name.charAt(0)}
                    </Avatar>
                    <div className={classes.orgText}>
                        <Typography variant={'h6'}>
                            {inviteInfo?.organization.name}
                        </Typography>
                        {!mobile && (
                            <Typography className={classes.descriptiontext}>
                                {inviteInfo?.organization.description}
                            </Typography>
                        )}
                    </div>
                </div>
                {mobile && (
                    <Typography className={classes.descriptiontext}>
                        {inviteInfo?.organization.description}
                    </Typography>
                )}
                <Typography className={classes.text} variant={'body1'}>
                    Would you like to accept?
                </Typography>
                {renderButtons()}
            </div>
        </div>
    );
};

export default Invite;
