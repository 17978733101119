import React, { useEffect } from 'react';
import useStyles from './styles';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CreditFormValues } from 'types/credit';
import { useFormikContext } from 'formik';

const CreatorInfo: React.FC = () => {
    const classes = useStyles();

    const { values, errors, handleChange, setFieldTouched, touched } =
        useFormikContext<CreditFormValues>();

    useEffect(() => {
        setManualEntry(!values.autoLinked);
    }, [values.autoLinked]);

    // Keeps track of whether the modal is being loaded in a read only state
    // or writable. Here, readonly state applies to where a user searched for an existing
    // Elevator user and links it to their submission
    const [manualEntry, setManualEntry] = React.useState(true);

    const handleNameChange = (e: React.ChangeEvent) => {
        setFieldTouched('name');
        handleChange(e);
    };

    return (
        <Box className={classes.creatorInfoRoot}>
            <Box paddingBottom={2}>
                <TextField
                    id={'name'}
                    label={'Display Name'}
                    required
                    placeholder={'Young Jasper'}
                    value={values.name}
                    fullWidth
                    disableUnderline
                    onChange={handleNameChange}
                    error={touched.name && Boolean(errors.name)}
                    disabled={!manualEntry}
                    helperText={
                        touched.name && errors.name ? (
                            <Typography variant="caption" color="error">
                                {errors.name}
                            </Typography>
                        ) : (
                            ''
                        )
                    }
                />
            </Box>
            <Box paddingBottom={2} className={classes.textFieldContainer}>
                <TextField
                    id={'email'}
                    label={'Email'}
                    placeholder={'artist@gmail.com'}
                    value={values.email}
                    fullWidth
                    disableUnderline
                    onChange={handleChange}
                    error={Boolean(errors.email)}
                    disabled={!manualEntry}
                    hidden={values.autoLinked}
                    helperText={errors.email || ''}
                />
            </Box>
        </Box>
    );
};

export default CreatorInfo;
