import React from 'react';
import useStyles from './styles';
import Typography from '@material-ui/core/Typography';

const SegmentHeader = (props: SegmentHeaderProps) => {
    const classes = useStyles();

    const { label, description } = props;

    return (
        <div className={classes.root}>
            <Typography variant={'body2'} gutterBottom className={classes.heavyFont}>
                {label}
            </Typography>
            {description && (
                <Typography variant={'caption'} className={classes.description}>
                    {description}
                </Typography>
            )}
        </div>
    );
};

export interface SegmentHeaderProps {
    label: string;
    description?: string;
}

export default SegmentHeader;
