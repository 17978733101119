import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    modalContentContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    displayText: {
        paddingTop: theme.spacing(1),
        color: 'inherit',
    },
});

export default makeStyles(styles);
