import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '450px',
        minWidth: '30vw',
        margin: theme.spacing(5, 'auto', 5, 'auto'),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(3, 'auto', 3, 'auto'),
            minWidth: '90%',
            maxWidth: '100%',
        },
    },
    description: {
        padding: theme.spacing(1, 0, 2, 0),
    },
    actionButtons: {
        padding: theme.spacing(2, 0, 5, 0),
        marginLeft: 'auto',
    },
});

export default makeStyles(styles);
