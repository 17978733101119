import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: '700px',
        },
        errorRootContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
        },
        errorLoadingDisclaimer: {
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(0, 2),
            },
        },
        textContainer: {
            maxWidth: theme.static_component_sizes.content_max_width,
        },
        bodyText: {
            color: 'inherit',
            fontSize: theme.typography.pxToRem(18),
        },
        buttonContainer: {
            paddingTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

export default makeStyles(styles);
