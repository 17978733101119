import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '100%',
    },
    contentPadding: {
        [theme.breakpoints.down(theme.static_component_sizes.content_max_width)]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    removePadding: {
        padding: 0,
    },
    content: {
        width: '100%',
        maxWidth: theme.static_component_sizes.content_max_width,
    },
});

export default makeStyles(styles);
