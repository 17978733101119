import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    content: {
        paddingBottom: theme.spacing(15),
    },
    outerCard: {
        padding: theme.spacing(0, 2, 2, 2),
    },
    searchSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: theme.typography.pxToRem(24),
        padding: theme.spacing(2.5, 2, 1, 2),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(2.5, 0, 1, 0),
        },
    },
    searchBar: {
        width: '100%',
        maxWidth: '80%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },

    chipList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingLeft: 0,
        },
    },
    chip: {
        backgroundColor: theme.palette.custom.grey.light,

        '&:hover': {
            backgroundColor: theme.palette.custom.grey.dark,
            color: theme.palette.common.white,
            opacity: 0.9,
        },

        borderRadius: 0,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    activeChip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:focus': {
            backgroundColor: theme.palette.custom.grey.dark,
            color: theme.palette.common.white,
        },
    },
    staffChipContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    staffIcon: {
        height: '16px',
        marginRight: theme.spacing(1),
    },
    letterHeader: {
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(24),
    },
    alphabetSection: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    gridContainer: {
        paddingTop: theme.spacing(2),
    },
    userCardContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    alphabetContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: theme.spacing(0, 6, 0, 1),
        padding: theme.spacing(1, 0, 2, 6),
        flexWrap: 'wrap',
    },
    letterBox: {
        height: '32px',
        width: '32px',
        padding: theme.spacing(0, 1, 0, 1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    letter: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 'bold',
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(4),
        },
        '&:hover': {
            color: theme.palette.custom.red.light,
            fontWeight: 'bold',
            cursor: 'pointer',
        },
        '&:disabled': {
            color: theme.palette.custom.grey.light,
        },
    },
    letterActive: {
        color: theme.palette.custom.red.light,
        borderBottom: `3px solid ${theme.palette.custom.red.light}`,
    },
    disabledLetter: {
        color: theme.palette.text.disabled,
    },
    noResultsContainer: {
        minHeight: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResultsContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    noResultsIcon: {
        paddingTop: theme.spacing(2),
    },
    loadingSkeletonContainer: {
        minHeight: '100vh',
    },
});

export default makeStyles(styles);
