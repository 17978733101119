import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    inputStyling: {
        fontSize: theme.typography.pxToRem(24),
        [theme.breakpoints.up(700)]: {
            fontSize: theme.typography.pxToRem(40),
        },
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    searchIcon: {
        fontSize: theme.typography.pxToRem(24),
        [theme.breakpoints.up(700)]: {
            fontSize: theme.typography.pxToRem(40),
        },
    },
    clearButton: {
        border: 'none',
        backgroundColor: theme.palette.common.white,
    },
    clearIcon: {
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.custom.grey.main,
        [theme.breakpoints.up(700)]: {
            fontSize: theme.typography.pxToRem(40),
        },
    },
    submitButton: {
        display: 'none',
    },
});

export default makeStyles(styles);
