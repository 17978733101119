import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFormikContext } from 'formik';
import { ROUTES } from 'config/Nav';
import useStyles from './styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { UpdateAccountInput, User } from '@elevatormedia/duffel-bag/dist/types/user';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import Button from '@elevatormedia/duffel-bag/dist/atoms/Button';
import CardSectionHeader from '@elevatormedia/duffel-bag/dist/atoms/CardSectionHeader';
import PageTitleDescription from '@elevatormedia/duffel-bag/dist/atoms/PageTitleDescription';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import TextField from '@elevatormedia/duffel-bag/dist/atoms/TextField';
import Link from '@elevatormedia/duffel-bag/dist/atoms/Link';
import ChangePasswordForm from './ChangePasswordForm';

const AccountSettings = (props: AccountSettingsProps) => {
    const {
        values,
        touched,
        errors,
        handleSubmit,
        isValid,
        handleChange,
        setFieldTouched,
        setFieldValue,
        isSubmitting,
    } = useFormikContext<UpdateAccountInput>();

    const { handleBlur, currentUser } = props;

    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(799));

    const [passwordModalOpen, setPasswordModalOpen] = React.useState(false);

    const togglePasswordModalOpen = () => {
        setPasswordModalOpen(!passwordModalOpen);
    };

    /**
     * Handler func for field changes in the formik form. Will set the new state value
     * as well a mark the field as "touched" for validation purposes.
     */
    const handleInputChange = async (
        fieldName: string,
        event: any,
        imperativeValue: any = undefined,
    ) => {
        // if value is being set imperatively, use the standard React event.target.value scheme.
        if (imperativeValue !== undefined) {
            setFieldValue(fieldName, imperativeValue);
        } else {
            handleChange(event);
        }
        setFieldTouched(fieldName, true);
        handleBlur(fieldName, imperativeValue || event.target.value);
    };

    const renderFields = () => {
        return (
            <GridContainer direction={'column'} spacing={3}>
                <GridItem className={classes.textFieldContainer}>
                    <GridContainer direction={'row'} spacing={3}>
                        <GridItem xs className={classes.textFieldContainer}>
                            <TextField
                                id={'firstName'}
                                label={'First Name'}
                                fullWidth
                                required
                                value={values.firstName}
                                onChange={(event) =>
                                    handleInputChange('firstName', event)
                                }
                                error={touched.firstName && Boolean(errors.firstName)}
                                helperText={touched.firstName ? errors.firstName : ''}
                                disabled={isSubmitting}
                            />
                        </GridItem>
                        <GridItem xs className={classes.textFieldContainer}>
                            <TextField
                                id={'lastName'}
                                label={'Last Name'}
                                fullWidth
                                required
                                value={values.lastName}
                                onChange={(event) => handleInputChange('lastName', event)}
                                error={touched.lastName && Boolean(errors.lastName)}
                                helperText={touched.lastName ? errors.lastName : ''}
                                disabled={isSubmitting}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem className={classes.emailFieldContainer}>
                    <TextField
                        id={'email'}
                        label={'Email'}
                        fullWidth
                        required
                        value={values.email}
                        onChange={(event) => handleInputChange('email', event)}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email ? errors.email : ''}
                        disabled={isSubmitting}
                    />
                    {currentUser.emailVerified ? (
                        <Typography variant="caption" className={classes.verifiedLabel}>
                            Verified
                        </Typography>
                    ) : (
                        <Link href={ROUTES.VERIFY.to}>Not Verified, Verify Now?</Link>
                    )}
                </GridItem>
                <GridItem className={classes.textFieldContainer}>
                    <TextField
                        id={'preferredUsername'}
                        label={'Username'}
                        fullWidth
                        required
                        value={values.preferredUsername}
                        onChange={(event) =>
                            handleInputChange('preferredUsername', event)
                        }
                        error={
                            touched.preferredUsername && Boolean(errors.preferredUsername)
                        }
                        helperText={
                            touched.preferredUsername
                                ? errors.preferredUsername
                                : 'Your username is used to sign into your account and considered your elevator handle'
                        }
                        disabled={isSubmitting}
                    />
                </GridItem>
                <GridItem className={classes.textFieldContainer}>
                    <Button variant={'text'} onClick={togglePasswordModalOpen}>
                        Change Password
                    </Button>
                </GridItem>
            </GridContainer>
        );
    };

    return (
        <Box width={'100%'} paddingTop={1} paddingBottom={1}>
            <PageTitleDescription pageTitle={'ACCOUNT SETTINGS'} divider={false} />
            <form className={classes.formRoot}>
                <Paper className={classes.container}>
                    <CardSectionHeader sectionTitle={'Account'} />
                    {renderFields()}
                </Paper>
                <Box className={classes.saveButtonContainer}>
                    <Box width={'100%'} maxWidth={'450px'}>
                        <Button
                            type={'submit'}
                            disabled={!isValid || isSubmitting}
                            variant={'contained'}
                            fullWidth
                            size={'large'}
                            onClick={() => handleSubmit()}
                        >
                            {isSubmitting && (
                                <Box
                                    paddingRight={1}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <CircularProgress color={'primary'} size={18} />
                                </Box>
                            )}
                            {isSubmitting ? 'Saving Changes' : 'Save'}
                        </Button>
                    </Box>
                </Box>
            </form>
            <Dialog
                open={passwordModalOpen}
                onClose={togglePasswordModalOpen}
                fullScreen={mobile}
                PaperProps={{
                    // Disable paper override
                    classes: {
                        root: classes.disablePaperMargins,
                    },
                }}
            >
                <ChangePasswordForm
                    togglePasswordModalOpen={togglePasswordModalOpen}
                    {...props}
                />
            </Dialog>
        </Box>
    );
};

export type AccountSettingsProps = {
    currentUser: User;
    handleBlur: (field: string, value: string) => void;
};

export default AccountSettings;
