import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '-webkit-transform': 'translate(-50%, -50%)',
            width: '40vw',
            height: '39vh',
            padding: theme.spacing(2),
            maxWidth: theme.static_component_sizes.content_max_width,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                maxWidth: '80vw',
                height: '100%',
                maxHeight: '33vh',
            },
        },
    }),
);

export default useStyles;
