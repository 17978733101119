import React from 'react';
import { ROUTES } from 'config/Nav';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { reportError } from 'lib/errors';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import { requestEmailVerificationCode } from '@elevatormedia/duffel-bag/dist/lib/auth';

const VerifyEmail: React.FC<VerifyEmailProps> = ({ setOpen }) => {
    const Router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const { currentUser, hydrateUser } = useAuth();
    const [step, setStep] = React.useState(1);

    React.useEffect(() => {
        // send verification code when page mounts
        if (currentUser && !currentUser.emailVerified)
            requestEmailVerificationCode(process.env.authEndpoint)
                .then(() =>
                    enqueueSnackbar('Verification code sent!', {
                        variant: 'info',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    }),
                )
                .catch((err: any) => {
                    reportError(err, {
                        metaData: {
                            operation: 'cognito requestEmailVerificationCode',
                        },
                        user: {
                            username: currentUser.username,
                        },
                    });
                    enqueueSnackbar(
                        `Could not send verification code, please make sure you're logged in`,
                        {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                        },
                    );
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!currentUser || currentUser.emailVerified) {
        const action = currentUser
            ? {
                  message: 'Your email has already been verified.',
                  buttonText: 'Go Home',
                  onClick: () => Router.replace(ROUTES.HOME.to),
              }
            : {
                  message: 'You must be signed in to verify your email.',
                  buttonText: 'Sign In',
                  onClick: () => Router.replace(ROUTES.AUTH.to),
              };

        return (
            <Box
                height={'100%'}
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography variant={'h6'}>{action.message}</Typography>
                <Button onClick={action.onClick}>{action.buttonText}</Button>
            </Box>
        );
    }

    /**
     * Handles change in state between form 1 (Email Field) & Confirmation Message
     */
    const handleNextClick = (forward = true) => {
        const newStep = forward ? step + 1 : step - 1;
        setStep(newStep);
    };

    /**
     * Renders current form step
     */
    const renderStep = () => {
        if (step === 1) {
            return <StepOne onSuccess={handleNextClick} currentUser={currentUser} />;
        } else if (step === 2) {
            return <StepTwo hydrateUser={hydrateUser} setOpen={setOpen} />;
        } else {
            //console.error('Unknown State Step ' + step);
            setStep(1);
        }
    };

    return renderStep();
};

export interface VerifyEmailProps {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default VerifyEmail;
