import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            margin: theme.spacing(1),
            fontSize: 100,
        },
        formContainer: {
            padding: theme.spacing(0, 2),
        },
        userEmail: {
            overflowWrap: 'break-word',
        },
    }),
);

export default useStyles;
