import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'relative',
        height: '90vh',
        minHeight: '400px',
        [theme.breakpoints.up('lg')]: {
            height: '85vh',
            minHeight: '800px',
        },
    },
    heroImage: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: '50% 20%',
    },
    gradientContainer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '50%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingBottom: theme.spacing(4),
        background: `linear-gradient(0deg, ${theme.palette.getRgba(
            theme.palette.common.black,
            0.9,
        )} 11%, rgba(0,0,0,0) 100%)`,
    },
    postTitle: {
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        margin: theme.spacing(0, 2),
        [theme.breakpoints.up('lg')]: {
            margin: 0,
        },
    },
    skeleton: {
        backgroundColor: theme.palette.custom.grey,
    },
});

export default makeStyles(styles);
