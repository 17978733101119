import React from 'react';
import Typography from '@material-ui/core/Typography';
import { UserCard } from 'molecules';
import useStyles from './styles';
import { PublicUserInfo } from '@elevatormedia/duffel-bag/dist/types/user';

const SearchResultUserList = (props: SearchResultUserListPropTypes) => {
    const { title, users } = props;

    const classes = useStyles();

    if (users.length === 0) {
        return (
            <div className={classes.paddedContainer}>
                <Typography variant={'h6'} color={'textSecondary'}>
                    No posts found
                </Typography>
            </div>
        );
    } else {
        return (
            <>
                {title && (
                    <div className={classes.sectionHeaderContainer}>
                        <Typography
                            variant={'subtitle1'}
                            className={classes.sectionHeaderText}
                        >
                            {title}
                        </Typography>
                    </div>
                )}
                {users.map((user: PublicUserInfo) => (
                    <div
                        key={`user-search-result-item-${user.preferredUsername}`}
                        className={classes.searchResultListItem}
                    >
                        <UserCard
                            user={user}
                            socialMedia={user.socialMedia}
                            name={
                                user.displayName
                                    ? user.displayName
                                    : user.preferredUsername
                            }
                            cardColor={'paper'}
                        />
                    </div>
                ))}
            </>
        );
    }
};

export type SearchResultUserListPropTypes = {
    title?: string;
    users: PublicUserInfo[];
};

export default SearchResultUserList;
