import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '70vh',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(10, 0, 10, 0),
            maxWidth: '450px',
            margin: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(3),
            },
        },
        backLink: {
            textDecoration: 'underline',
        },
        contentTitle: {
            maxWidth: theme.static_component_sizes.content_max_width,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '300px',
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: '250px',
            },
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            hyphens: 'auto',
        },
        link: {
            wordBreak: 'break-all',
        },
        image: {
            width: '55vw',
            [theme.breakpoints.up('md')]: {
                width: '300px',
            },

            objectFit: 'cover',
        },
        fieldName: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
        content: {
            padding: theme.spacing(0, 0, 3.5, 0),
        },
        divider: {
            margin: theme.spacing(1, 0, 2, 0),
        },
        formHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        pageContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        closeButton: {
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(3),
            },
        },
        editButton: {
            padding: 0,
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(1),
            height: '100%',
        },
        sectionTitleContainer: {
            marginBottom: theme.spacing(1),
        },
        creditContainer: {
            width: '75%',
            height: 'auto',
            paddingBottom: theme.spacing(3),
        },
        sectionHeader: {
            paddingTop: theme.spacing(2),
        },
        checkboxText: {
            color: theme.palette.custom.grey.main,
        },
    }),
);

export default useStyles;
