import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useFormikContext } from 'formik';
import useStyles from './styles';
import { Field } from 'molecules';
import ActionButtons from 'molecules/ActionButtons';
import { BaseFieldValues, Form } from 'types/form';

const FormBuilder = <T extends BaseFieldValues>(props: FormBuilderProps<T>) => {
    const { form } = props;

    const {
        fields,
        label,
        description,
        handleCancel,
        submitButtonLabel,
        cancelButtonLabel,
    } = form;

    const { isValid, isSubmitting, handleSubmit } = useFormikContext<T>();

    const classes = useStyles();

    const renderFields = () => {
        const fieldMap = fields.map((field) => {
            return (
                <Field
                    key={field.id}
                    type={field.type}
                    name={field.name}
                    id={field.id}
                    required={field.required}
                    label={field.label}
                    placeholder={field.placeholder}
                    options={field.options}
                />
            );
        });
        return fieldMap;
    };

    return (
        <form className={classes.root}>
            <Typography variant="h6">{label}</Typography>
            <Typography variant="body1" className={classes.description}>
                {description}
            </Typography>
            {renderFields()}
            <div className={classes.actionButtons}>
                <ActionButtons
                    cancel={{
                        label: cancelButtonLabel,
                        onClick: handleCancel,
                    }}
                    submit={{
                        label: submitButtonLabel,
                        onClick: () => handleSubmit(),
                        disabled: !isValid || isSubmitting,
                    }}
                />
            </div>
        </form>
    );
};

export type FormBuilderProps<T extends BaseFieldValues> = {
    form: Form<T>;
};

export default FormBuilder;
