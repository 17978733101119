import React from 'react';
import useStyles from './styles';
import CreditChip from 'molecules/CreditChip';
import EditCreditForm from './EditCreditForm';
import Plus from 'mdi-material-ui/Plus';
import GridContainer from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridContainer';
import GridItem from '@elevatormedia/duffel-bag/dist/atoms/Grid/GridItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Close from 'mdi-material-ui/Close';
import { CreditFormValues, CreditInput } from 'types/credit';
import { useAuth } from '@elevatormedia/duffel-bag/dist/hooks/useAuth';
import { Formik, FormikHelpers } from 'formik';
import { object as yupObject, string, boolean as yupBoolean } from 'yup';
import SegmentHeader from 'atoms/SegmentHeader';

// Base form defaults used by formik. These fields are NOT UPDATED IN STATE.
// these fields are accessible via the onSubmit function handler for the from.
export const baseInitialValues: CreditFormValues = {
    type: 'Artist',
    name: '',
    email: '',
    isSubmitter: false,
    autoLinked: false,
};

const CreditsAccordion = (props: CreditsAccordionProps) => {
    const classes = useStyles();

    const { credits, removeCredit, saveCredit, header, label, description } = props;

    const [editIndex, setEditIndex] = React.useState(-1);

    const [initialValues, setInitialValues] = React.useState(baseInitialValues);
    const [isAddingCredit, setIsAddingCredit] = React.useState(false);

    const { currentUser } = useAuth();

    const validationSchema = yupObject<CreditFormValues>({
        type: string().required('A credit type is required'),
        name: string().required('This field is required'),
        email: string().email('Please enter a valid email address'),
        isSubmitter: yupBoolean().required('This field is required'),
        autoLinked: yupBoolean().required('This field is required'),
    });

    const handleAddNew = () => {
        setEditIndex(-1);
        setIsAddingCredit(true);
    };

    const handleClose = () => {
        setEditIndex(-1);
        setIsAddingCredit(false);
    };

    const editCredit = (index: number) => {
        // Set initial values for Formik component by retrieving the
        // proper values from the credits[] array.
        const intialValues = {
            name: credits[index].name,
            email: credits[index].email,
            type: credits[index].type,
            isSubmitter: credits[index].isSubmitter,

            autoLinked: credits[index].autoLinked,
        };

        setEditIndex(index);
        setInitialValues(intialValues);
    };

    const handleSubmitCredit = async (
        values: CreditFormValues,
        actions: FormikHelpers<CreditFormValues>,
    ) => {
        const { type, name, email, isSubmitter, autoLinked } = values;

        //restructure state to credit with socials array
        // Each social will by dynamically included in array based on empty strings
        const creditToSave: CreditInput = {
            type,
            name,
            email,
            isSubmitter,
            autoLinked,
        };

        await saveCredit(creditToSave, editIndex);

        actions.resetForm();

        isAddingCredit && setIsAddingCredit(false);
    };

    const renderEditCreditForm = (editMode: boolean) => {
        return (
            <div className={classes.editCreditForm}>
                <EditCreditForm
                    currentUser={currentUser}
                    onCancel={() => {
                        setIsAddingCredit(false);
                        setEditIndex(-1);
                    }}
                    saveCredit={(credit: CreditInput) => {
                        saveCredit(credit, editIndex);
                    }}
                    editMode={editMode}
                />
            </div>
        );
    };

    return (
        <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmitCredit}
        >
            <>
                <div className={classes.root}>
                    {header && (
                        <GridContainer
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <GridItem>
                                <SegmentHeader label={label} description={description} />
                            </GridItem>
                            <GridItem>
                                <Button
                                    className={classes.addButton}
                                    variant={'outlined'}
                                    onClick={isAddingCredit ? handleClose : handleAddNew}
                                >
                                    <Plus />
                                </Button>
                            </GridItem>
                        </GridContainer>
                    )}
                    <div className={classes.accordionContainer}>
                        {credits.map((item, index) => {
                            const { name, type, avatar } = item;
                            const isActive = editIndex === index;

                            return (
                                <>
                                    <CreditChip
                                        variant={'edit'}
                                        name={name}
                                        avatar={avatar}
                                        type={type}
                                        isActive={isActive}
                                        index={index}
                                        editCredit={editCredit}
                                        removeCredit={removeCredit}
                                        setEditIndex={setEditIndex}
                                    />
                                    {isActive && renderEditCreditForm(true)}
                                </>
                            );
                        })}
                    </div>
                </div>
                {isAddingCredit && renderEditCreditForm(false)}
            </>
        </Formik>
    );
};

export interface CreditsAccordionProps {
    credits: Array<CreditInput>;
    removeCredit: Function;
    saveCredit: Function;
    header?: boolean;
    label?: string;
    description?: string;
}

CreditsAccordion.defaultProps = {
    header: true,
    label: 'Credits',
};

export default CreditsAccordion;
