import React from 'react';
import useStyles from './styles';
import Avatar from '@elevatormedia/duffel-bag/dist/atoms/Avatar';
import { Media } from '@elevatormedia/duffel-bag/dist/types/media';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { CloseCircle } from 'mdi-material-ui';
import { ROUTES } from 'config/Nav';
import clsx from 'clsx';
import { useRouter } from 'next/router';

const CreditChip = <T extends ChipVariant>(props: CreditChipProps<T>) => {
    const classes = useStyles();

    const router = useRouter();

    const {
        variant,
        name,
        preferredUsername,
        avatar,
        type,
        index,
        isActive,
        editCredit,
        removeCredit,
        setEditIndex,
    } = props;

    const isUser = Boolean(preferredUsername);

    const renderChip = () => {
        return (
            <Paper
                className={
                    isActive
                        ? clsx(classes.userInfo, classes.activeInfo)
                        : classes.userInfo
                }
                onClick={
                    variant === 'edit'
                        ? () => editCredit(index)
                        : isUser
                        ? () => router.push(ROUTES.USER.to + preferredUsername)
                        : null
                }
            >
                <Avatar
                    username={name}
                    variant={'small'}
                    imageProps={
                        avatar && {
                            src: avatar.sourceUrl,
                            srcSet: avatar.sourceSet,
                            alt: name,
                        }
                    }
                />
                <div className={classes.summaryText}>
                    <Typography variant="caption" color="textSecondary">
                        {type}
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                        {name}
                    </Typography>
                </div>
                {variant === 'edit' && (
                    <IconButton
                        className={classes.iconButton}
                        onClick={(event) => {
                            removeCredit(index);
                            event.stopPropagation();
                            setEditIndex(-1);
                        }}
                    >
                        <CloseCircle className={classes.closeIcon} />
                    </IconButton>
                )}
            </Paper>
        );
    };

    return (
        <Accordion key={index} expanded={false} classes={{ root: classes.innerRoot }}>
            <AccordionSummary
                className={classes.accordionSummary}
                classes={{ content: classes.accordionSummaryContent }}
            >
                {renderChip()}
            </AccordionSummary>
        </Accordion>
    );
};

export type ChipVariant = 'view' | 'edit';

export interface CreditChipProps<T extends ChipVariant> {
    variant: ChipVariant;
    name: string;
    preferredUsername?: string;
    avatar: Media;
    type: string;
    // edit variant props
    isActive?: T extends 'edit' ? boolean : never;
    index?: T extends 'edit' ? number : never;
    editCredit?: T extends 'edit' ? (index: number) => void : never;
    removeCredit?: T extends 'edit' ? Function : never;
    setEditIndex?: T extends 'edit' ? any : never;
}

export default CreditChip;
