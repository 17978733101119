import React from 'react';
import useStyles from './styles';
import { Searchbar, AccountPopover } from 'molecules';
import NavLink from '@elevatormedia/duffel-bag/dist/atoms/NavLink';
import { User } from '@elevatormedia/duffel-bag/dist/types/user';
import BrandLogo from '@elevatormedia/duffel-bag/dist/atoms/BrandLogo';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { InboxArrowUp } from 'mdi-material-ui';
import { APPBAR_ROUTES, ROUTES } from 'config/Nav';
import { RouteConfig } from 'types/routes';
import clsx from 'clsx';

const DesktopAppbar = (props: DesktopAppbarPropTypes) => {
    const classes = useStyles();

    const {
        currentUser,
        handleSignoutClick,
        searchInput,
        handleSearchInput,
        onSubmitSearchInput,
    } = props;

    // Set up react state hooks
    const [anchorEl, setAnchorEl] = React.useState(null);

    /**
     * Handler function for when the user clicks the avatar. Will set the accountPopOver component's
     * anchor ref point to the avatar.
     */
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const renderNavListItem = (
        { navLinkText, to, external }: RouteConfig,
        index: number,
    ) => {
        const classNames = [classes.navLinkContainer];

        if (index === 0) classNames.push(classes.firstNavLink);

        return (
            <div className={clsx(...classNames)} key={index}>
                <NavLink href={to} external={external} variant={'header'}>
                    {navLinkText}
                </NavLink>
            </div>
        );
    };

    const renderNavLinks = () => {
        return (
            <div className={classes.headerLeftContainer}>
                <div className={classes.desktopTopBrandLogoContainer}>
                    <BrandLogo href="/" />
                </div>
                <div className={classes.fadeContainer}>
                    <div className={classes.fadeLeft} />
                    <div className={classes.navLinksContainer}>
                        {APPBAR_ROUTES.map((routeConfig, index) =>
                            renderNavListItem(routeConfig, index),
                        )}
                    </div>
                    <div className={classes.fadeRight} />
                </div>
            </div>
        );
    };

    const renderAccountHandler = () => {
        return (
            <div className={classes.desktopAccountHandlerContainer}>
                <div className={classes.desktopSearchContainer}>
                    <Searchbar
                        rootContainerClass={classes.searchBar}
                        searchInput={searchInput}
                        handleSearchInput={handleSearchInput}
                        onSubmit={onSubmitSearchInput}
                    />
                </div>
                <Button
                    className={classes.submitButton}
                    variant={'contained'}
                    color={'secondary'}
                    href={ROUTES.SUBMIT.to}
                >
                    <InboxArrowUp className={classes.submitIcon} />
                    Submit
                </Button>
                {!currentUser && (
                    <div className={classes.signInLink}>
                        <NavLink variant={'header'} external href={ROUTES.AUTH.to}>
                            Sign in
                        </NavLink>
                    </div>
                )}
                {currentUser && (
                    <>
                        <div className={classes.desktopSearchContainer}>
                            <IconButton
                                aria-label="Account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleClick}
                                color="inherit"
                            >
                                <Avatar
                                    className={classes.userAvatar}
                                    src={
                                        currentUser.avatar && currentUser.avatar.sourceUrl
                                    }
                                    variant={'square'}
                                >
                                    {currentUser.firstName[0].toUpperCase()}
                                </Avatar>
                            </IconButton>
                            <AccountPopover
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                                avatar={currentUser.avatar}
                                username={currentUser.preferredUsername}
                                email={currentUser.email}
                                handleSignoutClick={handleSignoutClick}
                                isPreferred={currentUser.preferred}
                                isElevatorStaff={
                                    currentUser.permissionGroup.groupName !== 'Standard'
                                }
                                isVerified={currentUser.verified}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <AppBar elevation={0} className={classes.desktopAppbar}>
            <Toolbar className={classes.headerToolbarContainer}>
                {renderNavLinks()}
                {renderAccountHandler()}
            </Toolbar>
        </AppBar>
    );
};

type DesktopAppbarPropTypes = {
    handleSignoutClick: () => void;
    currentUser: User | null;
    searchInput: string;
    handleSearchInput: (input: string) => void;
    onSubmitSearchInput: (event: any) => void;
};

export default DesktopAppbar;
