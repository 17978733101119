import gql from 'graphql-tag';

export const CAMPAIGN_TYPES = gql`
    query campaignTypes {
        campaignTypes {
            typeId
            name
            cost
        }
    }
`;
