import React, { Dispatch } from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MyAccountIcon from '@material-ui/icons/AccountBoxOutlined';
import SignOutIcon from '@material-ui/icons/ExitToAppOutlined';
import HelpIcon from '@material-ui/icons/Help';
import InboxArrowUp from 'mdi-material-ui/InboxArrowUp';
import Settings from 'mdi-material-ui/Settings';
import FileDocumentBoxPlusOutline from 'mdi-material-ui/FileDocumentBoxPlusOutline';
import MonitorDashboard from 'mdi-material-ui/MonitorDashboard';
import clsx from 'clsx';
import useStyles from './styles';
import { ROUTES } from 'config/Nav';
import UserBadge from 'atoms/UserBadge';
import { useRouter } from 'next/router';
import ListItem from '@material-ui/core/ListItem';
import MenuButtons from 'molecules/MenuButtons';
import { Avatar } from '@material-ui/core';
import { Media } from '@elevatormedia/duffel-bag';

/**
 * Account Popover component: a popup card used to display account information
 * about the user as well as clickable options for navigation.
 */
const AccountPopover = (props: AccountPopoverPropTypes) => {
    const {
        avatar,
        anchorEl,
        username,
        isPreferred,
        isElevatorStaff,
        isVerified,
        email,
        setAnchorEl,
        handleSignoutClick,
    } = props;

    const classes = useStyles();
    const router = useRouter();

    const open = Boolean(anchorEl);
    const id = open ? 'account-popover' : undefined;

    // Configurable Menu Options as JS objects
    const menuOptions = [
        {
            listItemIcon: <MyAccountIcon />,
            listItemText: 'My Profile',
            onListItemPress: () => {
                router.push(ROUTES.PROFILE.to);
            },
            beta: false,
            highEmphasis: false,
        },
        {
            listItemIcon: <InboxArrowUp />,
            listItemText: 'My Submissions',
            onListItemPress: () => {
                router.push(ROUTES.ACCOUNT.SUBMISSIONS.to);
            },
            beta: false,
            highEmphasis: false,
        },
        {
            listItemIcon: <FileDocumentBoxPlusOutline />,
            listItemText: 'New Submission',
            onListItemPress: () => {
                router.push(ROUTES.SUBMIT.to);
            },
            beta: false,
            highEmphasis: false,
        },
        {
            seperator: true,
        },
        {
            listItemIcon: <Settings />,
            listItemText: 'Account Settings',
            onListItemPress: () => {
                router.push(ROUTES.ACCOUNT.to);
            },
            beta: false,
            highEmphasis: false,
        },
        ...(isElevatorStaff
            ? [
                  {
                      listItemIcon: <MonitorDashboard />,
                      listItemText: 'Go To Admin Dashboard',
                      onListItemPress: () => {
                          window.open(ROUTES.ADMIN.to, '_self');
                      },
                      beta: false,
                      highEmphasis: true,
                  },
              ]
            : []),
        {
            listItemIcon: <HelpIcon />,
            listItemText: 'Help',
            onListItemPress: () => {
                router.push(ROUTES.HELP.to);
            },
            beta: false,
            highEmphasis: false,
        },
    ];

    /**
     * Wrapping function which assures that the AccountPopover component
     * closes upon the callback of the parameter function.
     */
    const handleListItemPress = (itemOnPress: Function) => {
        itemOnPress();
        handleClose();
    };

    /**
     * Handler function for closing the popover menu by setting its achor point ref to null
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * Dynamically Renders Menu Items based on JS object configuration
     */
    const renderPopoverMenuItems = () => {
        return menuOptions.map((menuOptionConfig, index) => {
            if (menuOptionConfig.seperator) {
                return <Divider key={index} className={classes.listSep} />;
            }

            const { listItemIcon, listItemText, onListItemPress, beta, highEmphasis } =
                menuOptionConfig;

            return (
                <MenuItem
                    key={index}
                    onClick={
                        onListItemPress
                            ? () => {
                                  handleListItemPress(onListItemPress);
                              }
                            : null
                    }
                    disabled={beta}
                >
                    <ListItemIcon
                        className={clsx({
                            [classes.defaultColor]: !highEmphasis,
                            [classes.highEmphasis]: highEmphasis,
                        })}
                    >
                        {listItemIcon}
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            className: clsx(
                                classes.listItemText,
                                highEmphasis && classes.highEmphasis,
                            ),
                        }}
                        primary={beta ? `${listItemText} (Coming Soon)` : listItemText}
                    />
                </MenuItem>
            );
        });
    };

    return (
        <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            classes={{
                paper: classes.disablePaperMargin,
            }}
        >
            <div id="menu" className={classes.accountMenu}>
                <div className={classes.cardHeader}>
                    <Avatar
                        className={classes.avatar}
                        src={avatar && avatar.sourceUrl}
                        variant={'square'}
                    >
                        {username.toUpperCase()}
                    </Avatar>
                    <div className={classes.accountInfoContainer}>
                        <Typography
                            component={'span'}
                            variant={'h6'}
                            className={classes.userName}
                        >
                            {username}
                            {isElevatorStaff && <UserBadge variant={'staff'} />}
                            {isPreferred && <UserBadge variant={'preferred'} />}
                            {isVerified && <UserBadge variant={'verified'} />}
                        </Typography>
                        <Typography
                            className={classes.email}
                            component={'p'}
                            variant={'body2'}
                            color={'textSecondary'}
                        >
                            {email}
                        </Typography>
                    </div>
                </div>
                <MenuList>
                    {renderPopoverMenuItems()}
                    <ListItem className={clsx(classes.listItem, classes.lastItem)}>
                        <MenuButtons handleSignoutClick={handleSignoutClick} />
                    </ListItem>
                </MenuList>
            </div>
        </Popover>
    );
};

export type AccountPopoverPropTypes = {
    anchorEl?: any;
    setAnchorEl: Dispatch<any>;
    username: string;
    email: string;
    handleSignoutClick: () => void;
    isPreferred?: boolean;
    isElevatorStaff?: boolean;
    isVerified?: boolean;
    avatar: Media;
};

AccountPopover.defaultProps = {
    isPreferred: false,
    isElevatorStaff: false,
    isVerified: false,
};

export default AccountPopover;
