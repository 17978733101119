import { Theme, makeStyles, createStyles } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    styledText: {
        // TODO OVERRIDE
        lineHeight: theme.typography.pxToRem(26),
        paddingBottom: theme.typography.pxToRem(24),
    },
    styledLink: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
    },
    styledBold: {
        fontWeight: 'bold',
    },
    styledImage: {
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
    },
    centeredEmbedContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    responsiveEmbedContainer: {
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
    },
    styledVideoEmbed: {
        border: '0px',
        verticalAlign: 'bottom',
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        paddingBottom: theme.spacing(2),
    },
    styledAudioEmbed: {
        width: '100%',
        padding: theme.spacing(2, 0),
    },
    styledDiv: {
        width: '100%',
    },
    /**
     * NOTE: instagram embeds have min-width of 320px
     * This may cause overflow issues on really small
     * screens. However, mitigating this would have the
     * need to remove all padding surrounding the post
     * This causes the component to be absolutely positioned
     * in the screen. Thus we opt out of supporting
     * a really small view port.
     */
    socialMediaEmbedContainer: {
        overflow: 'scroll',
        width: 'unset',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        position: 'relative',
    },
    styledBlockQuote: {
        margin: 0,
        width: '100%',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.custom.grey.light,
        color: theme.palette.primary.light,
        fontStyle: 'italic',
        fontWeight: 400,
        // TODO OVERRIDE
        fontSize: theme.typography.pxToRem(21),
        marginBottom: theme.typography.pxToRem(24),
        '& p': {
            paddingBottom: 0,
        },
    },
}));

export default styles;
